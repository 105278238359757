import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';

import IconCard from 'components/custom_v2/IconCard';
import IconCardCRM from 'components/custom_v2/IconCard/CRM';
import UserActions from 'store/reducers/User';
import SettingsActions from 'store/reducers/Settings';
import IntlMessages from 'utils/IntlMessages';
import SessionService from 'services/Session';
import SessionInfoBar from 'routes/App/components/User/SessionInfoBar';
import Util from 'utils/Util';
import Ticker from 'react-ticker';


const Dashboard = ( { user, changeActiveSession, toggleAlert, toggleLoading } ) => {
  const [workgroup, setWorkgroup] = useState( {} );
  const [data, setData] = useState( {} );

  let Stock1 = '<><><>';
  let StockArrow1 = '';
  let TextColor1 = '';
  let Stock2 = '';
  let StockArrow2 = '';
  let TextColor2 = '';
  let Stock3 = '';
  let StockArrow3 = '';
  let TextColor3 = '';
  let Stock4 = '';
  let StockArrow4 = '';
  let TextColor4 = '';
  let Stock5 = '';
  let StockArrow5 = '';
  let TextColor5 = '';

  if ( data.workGroups && data.workGroups.length > 4 ) {
    Stock1 = `${data.workGroups[0].name} ${Util.formatCurrency( data.workGroups[0].points )}  ${data.workGroups[0].percentagePoints}%  `;
    if ( data.workGroups[0].percentagePoints && data.workGroups[0].percentagePoints < 0 ) {
      StockArrow1 = 'fa-arrow-down';
      TextColor1 = 'text-danger';
    } else if ( data.workGroups[0].percentagePoints && data.workGroups[0].percentagePoints > 0 ) {
      StockArrow1 = 'fa-arrow-up';
      TextColor1 = 'text-primary';
    } else {
      StockArrow1 = 'fa-arrow-right';
      TextColor1 = 'text-light';
    }
    Stock2 = `${data.workGroups[1].name} ${Util.formatCurrency( data.workGroups[1].points )}  ${data.workGroups[1].percentagePoints}%  `;
    if ( data.workGroups[1].percentagePoints && data.workGroups[1].percentagePoints < 0 ) {
      StockArrow2 = 'fa-arrow-down';
      TextColor2 = 'text-danger';
    } else if ( data.workGroups[1].percentagePoints && data.workGroups[1].percentagePoints > 0 ) {
      StockArrow2 = 'fa-arrow-up';
      TextColor2 = 'text-primary';
    } else {
      StockArrow2 = 'fa-arrow-right';
      TextColor2 = 'text-light';
    }
    Stock3 = `${data.workGroups[2].name} ${Util.formatCurrency( data.workGroups[2].points )}  ${data.workGroups[2].percentagePoints}%  `;
    if ( data.workGroups[2].percentagePoints && data.workGroups[2].percentagePoints < 0 ) {
      StockArrow3 = 'fa-arrow-down';
      TextColor3 = 'text-danger';
    } else if ( data.workGroups[2].percentagePoints && data.workGroups[2].percentagePoints > 0 ) {
      StockArrow3 = 'fa-arrow-up';
      TextColor3 = 'text-primary';
    } else {
      StockArrow3 = 'fa-arrow-right';
      TextColor3 = 'text-light';
    }
    Stock4 = `${data.workGroups[3].name} ${Util.formatCurrency( data.workGroups[3].points )}  ${data.workGroups[3].percentagePoints}%  `;
    if ( data.workGroups[3].percentagePoints && data.workGroups[3].percentagePoints < 0 ) {
      StockArrow4 = 'fa-arrow-down';
      TextColor4 = 'text-danger';
    } else if ( data.workGroups[3].percentagePoints && data.workGroups[3].percentagePoints > 0 ) {
      StockArrow4 = 'fa-arrow-up';
      TextColor4 = 'text-primary';
    } else {
      StockArrow4 = 'fa-arrow-right';
      TextColor4 = 'text-light';
    }
    Stock5 = `${data.workGroups[4].name} ${Util.formatCurrency( data.workGroups[4].points )}  ${data.workGroups[4].percentagePoints}%  `;
    if ( data.workGroups[4].percentagePoints && data.workGroups[4].percentagePoints < 0 ) {
      StockArrow5 = 'fa-arrow-down';
      TextColor5 = 'text-danger';
    } else if ( data.workGroups[4].percentagePoints && data.workGroups[4].percentagePoints > 0 ) {
      StockArrow5 = 'fa-arrow-up';
      TextColor5 = 'text-primary';
    } else {
      StockArrow5 = 'fa-arrow-right';
      TextColor5 = 'text-light';
    }
  }
  /*
  if ( data.workGroups ) {
    Stock = data.workGroups.map( item => (
      <span key={item.id}>
        {item.name}
        {'  '}
        {Util.formatCurrency( item.points )}
        {'  '}
        {`${item.percentagePoints}%`}
        {'  '}
        {item.percentagePoints && item.percentagePoints < 0 && (
          <span className="text-right"><i className="fa fa-arrow-down text-danger mx-2" /></span>
        )}
        {item.percentagePoints && item.percentagePoints > 0 && (
          <span className="text-right"><i className="fa fa-arrow-up text-primary mx-2" /></span>
        )}
        {item.percentagePoints && item.percentagePoints === 0 && (
          <span className="text-right"><i className="fa fa-arrow-right text-light mx-2" /></span>
        )}
      </span>
    ) );
  }
   */

  useEffect( () => {
    // toggleLoading( true );

    SessionService.getSessionCurrentWorkgroupRound( user.activeSession.id )
      .then( ( response ) => {
        if ( response.ok ) {
          setWorkgroup( response.data );
        }
      } );

    SessionService.getSession( user.activeSession.id )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) return toggleAlert( response.errors );
        changeActiveSession( response.data );
        setData( response.data );

        if ( !user.activeSession ) {
          return toggleAlert( 'sessionNotConfigured', 'danger', true );
        }
        if ( !user.activeSession.currentRound ) {
          return toggleAlert( 'sessionNoRounds', 'danger', true );
        }

        const license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
        if ( !license || !license.workGroup ) {
          return toggleAlert( 'sessionNotConfigured', 'danger', true );
        }

        if ( response.data.status === 'READY_NO_ROUND' ) {
          return toggleAlert( 'sessionNoRounds', 'danger', true );
        }

        if ( !response.data.status ) {
          return toggleAlert( 'sessionNotConfigured', 'danger', true );
        }
      } );
  }, [user.activeSession.id] ); // eslint-disable-line

  return (

    <>
      <SessionInfoBar data={data} workgroup={workgroup} />

      <div className="container-fluid">
        <div className="app-dashboard-container app-user-dashboard-container">
          <Row>
            <Col md={6}>
              <div className="text-center border jr-border-radius p-3">
                <Row>
                  <Col md={12}>
                    <h1 className="mb-3 text-center text-primary">
                      <IntlMessages id="informationDashboard" />
                    </h1>
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg={{
                      size: 4,
                      offset: 2,
                    }}
                    md={{
                      size: 5,
                      offset: 2,
                    }}
                  >
                    <IconCard
                      icon="building"
                      title="company"
                      bgColor="bg-primary"
                      link="/sessions/info/company"
                    />
                  </Col>
                  <Col lg={4} md={5}>
                    <IconCard
                      icon="server"
                      title="products"
                      bgColor="bg-primary"
                      link="/sessions/info/products"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg={{
                      size: 4,
                      offset: 2,
                    }}
                    md={{
                      size: 5,
                      offset: 2,
                    }}
                  >
                    <IconCard
                      icon="chart-line"
                      title="market"
                      bgColor="bg-primary"
                      link="/sessions/info/market"
                    />
                  </Col>
                  <Col lg={4} md={5}>
                    <IconCard
                      icon="search"
                      title="research"
                      bgColor="bg-primary"
                      link="/sessions/info/research"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg={{
                      size: 4,
                      offset: 2,
                    }}
                    md={{
                      size: 5,
                      offset: 2,
                    }}
                  />
                  <Col lg={4} md={5}>
                    <IconCardCRM
                      icon="users"
                      title="customer"
                      link="/sessions/info/customer"
                    />
                  </Col>
                </Row>
              </div>
            </Col>

            <Col md={6}>
              <div className="text-center border jr-border-radius p-3">
                <h1 className="mb-3 text-center  text-primary">
                  <IntlMessages id="decisionDashboard" />
                </h1>
                <Row>
                  <Col lg={4} md={5}>
                    <IconCard
                      icon="server"
                      title="products"
                      bgColor="cbg-blue"
                      link="/sessions/general/products"
                    />
                  </Col>
                  <Col lg={4} md={5}>
                    <IconCard
                      icon="clipboard-list"
                      title="services"
                      bgColor="cbg-blue"
                      link="/sessions/general/services"
                    />
                  </Col>
                  <Col lg={4} md={5}>
                    <IconCard
                      icon="dollar-sign"
                      title="pricing"
                      bgColor="cbg-blue"
                      link="/sessions/general/price"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} md={5}>
                    <IconCard
                      icon="truck-moving"
                      title="distribution"
                      bgColor="cbg-blue"
                      link="/sessions/general/distribution"
                    />
                  </Col>
                  <Col lg={4} md={5}>
                    <IconCard
                      icon="universal-access"
                      title="cem"
                      bgColor="cbg-blue"
                      link="/sessions/general/cem"
                    />
                  </Col>
                  <Col lg={4} md={5}>
                    <IconCard
                      icon="satellite-dish"
                      title="communications"
                      bgColor="cbg-blue"
                      link="/sessions/general/crm"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} md={5}>
                    <IconCardCRM
                      icon="bullhorn"
                      title="1to1Campaigns"
                      bgColor="cbg-orange"
                      link="/sessions/general/one-to-one"
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>

        <div className="cbg-primary-dark  text-center text-white">
          <Ticker offset="run-in" speed={5}>
            {/* eslint-disable-next-line no-empty-pattern */}
            {( { } ) => (
              <>
                <span>
                  {' '}
                  {Stock1}
                </span>
                <i className={`fa ${StockArrow1} ${TextColor1} mx-2`} />
                <span>
                  {' '}
                  {Stock2}
                </span>
                <i className={`fa ${StockArrow2} ${TextColor2} mx-2`} />
                <span>
                  {' '}
                  {Stock3}
                </span>
                <i className={`fa ${StockArrow3} ${TextColor3} mx-2`} />
                <span>
                  {' '}
                  {Stock4}
                </span>
                <i className={`fa ${StockArrow4} ${TextColor4} mx-2`} />
                <span>
                  {' '}
                  {Stock5}
                </span>
                <i className={`fa ${StockArrow5} ${TextColor5} mx-2`} />
                <span>
                  {' <><><>'}
                </span>
              </>
            )}
          </Ticker>
        </div>
      </div>

    </>
  );
};

const mapStateToProps = ( { user } ) => ( { user } );
const mapDispatchToProps = {
  changeActiveSession: UserActions.changeActiveSession,
  toggleLoading: SettingsActions.toggleLoading,
  toggleAlert: SettingsActions.toggleAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( Dashboard );
