import React from 'react';
import { Field } from 'react-final-form';
import {
  Radio,
  FormControl,
  FormControlLabel,
  FormHelperText,
  RadioGroup
} from '@material-ui/core';
import classNames from 'classnames';
import _ from 'lodash';

import IntlMessages from 'utils/IntlMessages';

export default ( {
                   containerClass, displayBlock, field, idKey, label, onBlur, translate = true, labelPlacement="end",
                   onChange, options, valueKey, validate, disabled = false, extraContent, radioClassName
                 } ) => (
  <Field name={field} validate={validate}>
    {( { meta, input } ) => {
      const idKey2 = idKey || 'id';
      const valueKey2 = valueKey || 'value';

      return (
        <FormControl error={meta.touched && !!meta.error}
                     className={classNames( 'w-100', containerClass )}>
          {extraContent}
          {label && (
            <label>
              <IntlMessages id={label}/>
            </label>
          )}

          <RadioGroup
            className={classNames( { 'd-flex flex-row mt-2': !displayBlock }, radioClassName )}
            field={field}
            value={input.value}
            onChange={( e, val ) => {
              input.onChange( val );
              if (onChange) onChange( val, input.value );
            }}
          >
            {_.map( options, ( option, index ) => (
              <FormControlLabel
                key={index}
                value={option[idKey2].toString()}
                labelPlacement={labelPlacement}
                control={<Radio color="primary" disabled={option.disabled || disabled}
                                className="app-radio-button"/>}
                label={option[valueKey2] ? <span className="label-font-sm">{translate ?
                  <IntlMessages id={option[valueKey2]}/> : option[valueKey2]}</span> : null}
              />
            ) )}
          </RadioGroup>
          {meta.touched && meta.error &&
          <FormHelperText>{<IntlMessages id={meta.error}/>}</FormHelperText>}
        </FormControl>
      );
    }}
  </Field>
);
