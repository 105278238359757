import api from '../config/Api';

const endpoint = 'v1/session';

export default {

  getPop( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/pop`, params );
  },

  getCurrentPop( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/pop/current`, params );
  },

  updatePop( sessionId, data ) {
    return api.put( `${endpoint}/${sessionId}/pop`, data );
  },
};
