import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import BreadcrumbComp from 'components/custom_v2/Breadcrumb';
import SettingsActions from 'store/reducers/Settings';
import CommunicationService from 'services/Communication';
import CampaignDetail from 'routes/App/components/User/Campaign/Detail';

class EditCampaign extends React.Component {
  state = { detailData: {} };
  entityId = null;

  componentDidMount() {
    const { toggleAlert, user, match } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }
    this.activeSession = user.activeSession;
    this.entityId = match.params.id;
    this.getData();
  }

  getData = () => {
    const { toggleLoading, toggleAlert } = this.props;

    toggleLoading( true );
    CommunicationService.getCampaign( this.activeSession.id, this.entityId,
      { workgroup: this.license.workGroup.id } )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) return toggleAlert( response.errors );

        const responseData = response.data;
        const data = {
          ...response.data,
          fromRound: responseData.fromRound ? responseData.fromRound.largeName : '',
          toRound: responseData.toRound ? responseData.toRound.largeName : '',
          mediaAttributes: _.map( responseData.monthlyBudgets, item => ( {
            ...item,
            selected: true,
            mediaChannel: item.attributeMedia.mediaChannel,
            // regions: _.map( item.region, region => ( { id: region } ) ),
            regions: [],
          } ) ),
          internetPlans: [],
        };

        if ( responseData.socialNetworkDisplay ) {
          data.internetPlans.push( {
            id: 'social_network_display',
            name: 'socialNetworkDisplay',
            // regions: _.map( responseData.socialNetworkDisplay.region,
            //  region => ( { id: region } ) ),
            regions: [],
            gender: responseData.socialNetworkDisplay.gender,
            ageFrom: responseData.socialNetworkDisplay.ageFrom,
            ageTo: responseData.socialNetworkDisplay.ageTo,
            monthlyInvestment: responseData.socialNetworkDisplay.monthlyInvestment,
            body: [
              ..._.map( responseData.socialNetworkDisplay.education, item => ( {
                type: 'education',
                id: item,
              } ) ),
              ..._.map( responseData.socialNetworkDisplay.occupation, item => ( {
                type: 'occupation',
                id: item,
              } ) ),
            ],
          } );
        }

        if ( responseData.search ) {
          data.internetPlans.push( {
            id: 'search',
            name: 'search',
            bodyLabel: 'searchingFor',
            // regions: _.map( responseData.search.region, region => ( { id: region } ) ),
            regions: [],
            gender: responseData.search.gender,
            ageFrom: responseData.search.ageFrom,
            ageTo: responseData.search.ageTo,
            monthlyInvestment: responseData.search.monthlyInvestment,
            body: _.map( responseData.search.searchingFor, body => ( { id: body } ) ),
          } );
        }

        if ( responseData.retargeting ) {
          data.internetPlans.push( {
            id: 'retargeting',
            name: 'retargeting',
            bodyLabel: 'retargetPeopleLabel',
            // regions: _.map( responseData.retargeting.region, region => ( { id: region } ) ),
            regions: [],
            gender: responseData.retargeting.gender,
            ageFrom: responseData.retargeting.ageFrom,
            ageTo: responseData.retargeting.ageTo,
            monthlyInvestment: responseData.retargeting.monthlyInvestment,
            body: _.map( responseData.retargeting.retargetLookingFor, body => ( { id: body } ) ),
          } );
        }

        if ( responseData.artificialIntelligence ) {
          data.internetPlans.push( {
            id: 'artificial_intelligence',
            name: 'artificialIntelligence',
            bodyLabel: 'peoplePosting',
            // regions: _.map( responseData.artificialIntelligence.region,
            //  region => ( { id: region } ) ),
            regions: [],
            gender: responseData.artificialIntelligence.gender,
            ageFrom: responseData.artificialIntelligence.ageFrom,
            ageTo: responseData.artificialIntelligence.ageTo,
            monthlyInvestment: responseData.artificialIntelligence.monthlyInvestment,
            body: responseData.artificialIntelligence.peoplePosting,
          } );
        }

        if ( responseData.contentType === 'PRODUCT' ) {
          data.product = responseData.product ? responseData.product.name : '';
          data.productFeature = [];
          if ( responseData.productFeature ) {
            data.productFeature.push( { id: responseData.productFeature.name } );
          }
          if ( responseData.productFeature2 ) {
            data.productFeature.push( { id: responseData.productFeature2.name } );
          }
          data.promotePurchasingDirectChannel = responseData.promotePurchasingDirectChannel;
        }

        if ( responseData.contentType === 'SERVICE' ) {
          data.service = responseData.service ? responseData.service.name : '';
          data.serviceFeature = [];
          if ( responseData.serviceFeature ) {
            data.serviceFeature.push( { id: responseData.serviceFeature.name } );
          }
          if ( responseData.serviceFeature2 ) {
            data.serviceFeature.push( { id: responseData.serviceFeature2.name } );
          }
          data.promotePurchasingDirectChannel = responseData.promotePurchasingDirectChannel;
        }

        if ( responseData.contentType === 'BUNDLE' ) {
          data.bundle = responseData.bundle ? responseData.bundle.name : '';
          data.promotePurchasingDirectChannel = responseData.promotePurchasingDirectChannel;
        }

        this.setState( { detailData: data } );
      } );
  };

  render() {
    const { detailData } = this.state;

    return (
      <>
        <BreadcrumbComp links={[
          {
            url: '/',
            name: 'decisionDashboard',
          },
          {
            url: '/sessions/general/crm',
            name: 'crm',
          },
          { name: 'campaignDetail' },
        ]}
        />

        <CampaignDetail data={detailData} defaultOpened />
      </>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( EditCampaign );
