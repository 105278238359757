import { addLocaleData } from 'react-intl';
import enLang from './entries/en-US';
import arLang from './entries/ar_SA';
import esLang from './entries/es_ES';

const AppLocale = {
  en: enLang,
  ar: arLang,
  es: esLang,
};
addLocaleData( AppLocale.en.data );
addLocaleData( AppLocale.es.data );
addLocaleData( AppLocale.ar.data );

export default AppLocale;
