import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Form } from 'react-final-form';

import IntlMessages from 'utils/IntlMessages';
import { Select, Buttons } from 'components/custom/FormElements';
import { required } from 'config/InputErrors';
import { connect } from 'react-redux';
import UserLicenseService from 'services/UserLicense';
import SettingsActions from 'store/reducers/Settings';

const WorkGroupEditForm = (
  { open, sessionId, data, toggleAlert, toggleLoading, onConfirm, onClose, workGroups },
) => {
  function submitForm( formData ) {
    toggleLoading( true );

    UserLicenseService.updateUserLicenseWorkGroup( sessionId, data.id,
      { workGroup: formData.workGroup } )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) return toggleAlert( response.errors );

        toggleAlert( 'dataSaved', 'info' );
        onConfirm();
      } );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      classes={{ paperScrollPaper: 'overflow-visible' }}
    >
      <Form
        initialValues={data && data.workGroup ? { workGroup: data.workGroup.id } : {}}
        onSubmit={submitForm}
        render={( { handleSubmit, form } ) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle className="bg-dark text-white">
              <IntlMessages id="moveUser" />
            </DialogTitle>
            <DialogContent className="p-3 overflow-visible">
              <div className="row">
                <div className="col-md-6">
                  <Select
                    field="workGroup"
                    validate={required}
                    label="workGroup"
                    options={workGroups}
                    translateOptions={false}
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Buttons onClickCancel={onClose} />
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps,
)( WorkGroupEditForm );
