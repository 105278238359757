import React from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import async from 'async';

import IntlMessages from 'utils/IntlMessages';
import { Checkbox, Select, TextField } from 'components/custom/FormElements';
import AxisTick from 'components/custom/Charts/AxisTick';
import CardBox from 'components/theme/CardBox';
import Table from 'components/custom/Table/Custom';
import ContainerHeader from 'components/theme/ContainerHeader';
import { required } from 'config/InputErrors';
import SettingsActions from 'store/reducers/Settings';
import ProductService from 'services/Product';
import ProductionService from 'services/Production';
import WorkGroupService from 'services/WorkGroup';
import Util from 'utils/Util';
import ConfirmationModal from 'components/custom/ConfirmationModal';

class Products extends React.Component {
  state = {
    selectedProduct: {},
    productionCost: 0,
    workgroup: {},
    products: [],
    rounds: [],
    showActiveProducts: '1',
    deactivatedModal: false,
  };

  componentDidMount() {
    const { toggleAlert, user } = this.props;
    if ( !user.activeSession ) {
      this.setState( { corruptedData: true } );
      return toggleAlert( 'ERROR' );
    }

    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      this.setState( { corruptedData: true } );
      return toggleAlert( 'ERROR' );
    }
    this.activeSession = user.activeSession;
    this.getData();
  }

  getData = () => {
    const { toggleAlert, toggleLoading } = this.props;

    toggleLoading( true );
    async.waterfall( [
      this.getWorkgroup,
      this.getProducts,
      this.getProduct,
    ], ( error, results ) => {
      toggleLoading( false );
      if ( error ) {
        this.setState( { corruptedData: true } );
        return toggleAlert( 'ERROR' );
      }
      this.setState( results );
    } );
  };

  getWorkgroup = ( cb ) => {
    WorkGroupService.getWorkGroup( this.activeSession.id, this.license.workGroup.id )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        this.setState( { workgroup: response.data } );
        cb();
      } );
  };

  getProducts = ( cb ) => {
    const { toggleLoading, user } = this.props;
    const { selectedCategoryId, showActiveProducts } = this.state;
    if ( !cb ) toggleLoading( true );

    const filters = {
      workgroup: this.license.workGroup.id,
      'filters[category]': selectedCategoryId,
      //  'filters[canceled]': 0,
      limit: -1,
    };
    if ( showActiveProducts ) {
      filters['filters[active]'] = showActiveProducts;
    }
    ProductService.getProductsBySession( user.activeSession.id, filters )
      .then( ( response ) => {
        if ( !cb ) toggleLoading( false );
        if ( !response.ok ) return cb ? cb( true ) : null;

        if ( cb ) return cb( null, { products: response.data.data } );
        this.setState( { products: response.data.data } );
      } );
  };

  getProduct = ( data, cb ) => {
    const { toggleLoading, user } = this.props;
    const { selectedProduct } = this.state;
    const product = data.id ? data.id : selectedProduct.id
      ? selectedProduct.id : data.products.length ? data.products[0].id : null;

    if ( !product ) {
      return cb ? cb( null, {
        ...data,
        selectedProduct: {},
        rounds: [],
      } ) : null;
    }
    if ( !cb ) toggleLoading( true );

    ProductService.getProduct( user.activeSession.id,
      {
        product,
        workgroup: this.license.workGroup.id,
      } )
      .then( ( response ) => {
        if ( !response.ok ) return cb ? cb( true ) : null;

        const rounds = response.data.productions || [];
        let currentProduction = null;
        if ( user.activeSession.currentRound ) {
          currentProduction = _.find( response.data.productions,
            { round: { id: user.activeSession.currentRound.id } } );
        }
        this.currentProduction = currentProduction;
        if ( cb ) {
          cb( null, {
            ...data,
            selectedProduct: {
              ...response.data,
              value: currentProduction ? currentProduction.totalProduction : '',
            },
            productionCost: currentProduction
              ? currentProduction.totalProduction * currentProduction.unitaryProductionCost : 0,
            rounds,
          } );
        } else {
          toggleLoading( false );
          this.setState( {
            selectedProduct: {
              ...response.data,
              value: currentProduction ? currentProduction.totalProduction : null,
            },
            productionCost: currentProduction
              ? currentProduction.totalProduction * currentProduction.unitaryProductionCost : 0,
            rounds,
          } );
        }
      } );
  };

  checkProductStatus = ( formData ) => {
    this.formData = formData;
    const { selectedProduct } = this.state;
    if ( selectedProduct.active && !formData.active ) {
      return this.toggleDeactivatedModal();
    }
    this.submitForm( this.formData );
  };

  submitForm = ( formData ) => {
    const { toggleAlert, toggleLoading } = this.props;
    const { selectedProduct } = this.state;
    if ( !selectedProduct.canceled && !selectedProduct.active && !formData.active ) return toggleAlert( 'errorProductActive', 'error', true );
    if ( !this.currentProduction ) return toggleAlert( 'notValidProduction', 'error', true );

    toggleLoading( true );
    const dataToSend = {
      workgroup: this.license.workGroup.id,
      production: this.currentProduction.id,
      value: formData.value,
      active: !!formData.active,
      replicate: formData.replicate,
    };

    ProductionService.updateProduction( this.activeSession.id, dataToSend )
      .then( ( response ) => {
        if ( !response.ok ) {
          toggleLoading( false );
          return toggleAlert( response.errors );
        }

        toggleAlert( 'dataSaved', 'info' );
        this.getData();
      } );
  };

  toggleDeactivatedModal = () => {
    const { deactivatedModal } = this.state;
    this.setState( { deactivatedModal: !deactivatedModal } );
  };

  render() {
    const {
      products, selectedProduct, rounds, corruptedData,
      showActiveProducts, workgroup, productionCost, deactivatedModal,
    } = this.state;
    const { user } = this.props;
    const currentRoundId = user.activeSession && user.activeSession.currentRound
      ? user.activeSession.currentRound.id : null;

    return (
      <Form
        initialValues={{
          ...selectedProduct,
          replicate: true,
        }}
        onSubmit={this.checkProductStatus}
        render={( { handleSubmit, form } ) => (
          <form onSubmit={handleSubmit}>
            <ContainerHeader
              title="production"
              rightContent={(
                selectedProduct && ( selectedProduct.canceled || !selectedProduct.available ) ? null
                  : (
                    <Button
                      disabled={corruptedData}
                      variant="contained"
                      color="primary"
                      className="jr-btn jr-btn-lg"
                      type="submit"
                    >
                      <i className="fa fa-save" />
                      <IntlMessages id="saveBtn" />
                    </Button>
                  )
              )}
            />

            <div className="row">
              <div className="col-12">
                <CardBox styleName="mt-3 mt-lg-0">
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <p>
                        <i className="zmdi zmdi-money zmdi-hc-fw zmdi-hc-lg mr-2" />
                        <IntlMessages id="cash" />
                        {': '}
                        {Util.formatCurrency( workgroup.cash || 0 )}
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <p>
                        <i className="zmdi zmdi-money zmdi-hc-fw zmdi-hc-lg mr-2" />
                        <IntlMessages id="stock" />
                        {': '}
                        {Util.formatNumber( workgroup.stock ? workgroup.stock.stock : 0 )}
                        {' ('}
                        {Util.formatCurrency( workgroup.stock ? workgroup.stock.value : 0 )}
                        )
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <p className="font-weight-bold">
                        <i className="zmdi zmdi-info zmdi-hc-fw zmdi-hc-lg mr-2" />
                        <IntlMessages id="currentRoundProductionCost" />
                        {': '}
                        {Util.formatCurrency( workgroup.currentRoundProductionCost || 0 )}
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <p className="font-weight-bold">
                        <i className="zmdi zmdi-money zmdi-hc-fw zmdi-hc-lg mr-2" />
                        <IntlMessages id="balance" />
                        {': '}
                        {Util.formatCurrency( workgroup.balance || 0 )}
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <p className="font-weight-bold">
                        <i className="zmdi zmdi-money zmdi-hc-fw zmdi-hc-lg mr-2" />
                        <IntlMessages id="costId" />
                        {': '}
                        {Util.formatCurrency( workgroup.currentRoundTotalIPlusDCost
                          ? workgroup.currentRoundTotalIPlusDCost : 0 )}
                      </p>
                    </div>
                  </div>
                </CardBox>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-12">
                <ExpansionPanel defaultExpanded className="mb-3 expansion-panel">
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Products List</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className="p-0 d-block">
                    <div className="col-sm-3 offset-sm-9 mb-2">
                      <Select
                        placeholder="status"
                        value={showActiveProducts}
                        marginContainer={false}
                        translateOptions={false}
                        onChange={( value ) => {
                          this.setState( { showActiveProducts: value }, this.getProducts );
                        }}
                        isDisabled={corruptedData}
                        options={[
                          {
                            id: '1',
                            name: 'Active',
                          },
                          {
                            id: '0',
                            name: 'Not Active',
                          },
                        ]}
                      />
                    </div>

                    <div className="col-12 p-0">
                      {products.length === 0
                        ? (
                          <div
                            className="text-muted h-100 d-flex align-items-center justify-content-center p-3"
                          >
                            No products found
                          </div>
                        )
                        : (
                          <Table
                            data={products}
                            pageSize={products.length}
                            rowsText=""
                            showPaginationTop={false}
                            columns={[
                              {
                                Header: <IntlMessages id="category" />,
                                accessor: 'category',
                              },
                              {
                                Header: <IntlMessages id="code" />,
                                accessor: 'code',
                                maxWidth: 100,
                              },
                              {
                                Header: <IntlMessages id="name" />,
                                accessor: 'name',
                              },
                              {
                                Header: <IntlMessages id="description" />,
                                accessor: 'description',
                              },
                              {
                                Header: <IntlMessages id="cost" />,
                                accessor: 'cost',
                                maxWidth: 60,
                                Cell: ( { value } ) => Util.formatCurrency( value ),
                              },
                              {
                                Header: <IntlMessages id="lastRoundProduction" />,
                                accessor: 'lastRoundProduction',
                                Cell: ( { value } ) => Util.formatNumber( value ),
                              },
                              {
                                Header: <IntlMessages id="lastRoundSales" />,
                                accessor: 'lastRoundSales',
                                Cell: ( { value } ) => Util.formatNumber( value ),
                              },
                              {
                                Header: <IntlMessages id="stock" />,
                                accessor: 'allStock',
                                maxWidth: 60,
                                Cell: ( { value } ) => Util.formatNumber( value ),
                              },
                              {
                                Header: <IntlMessages id="production" />,
                                accessor: 'productionCurrentRound',
                                maxWidth: 100,
                                Cell: ( { value } ) => Util.formatNumber( value ),
                              },
                            ]}
                            getTrProps={( state, rowInfo ) => (
                              {
                                className: selectedProduct.id === rowInfo.original.id ? 'font-weight-bold pointer' : 'pointer',
                                onClick: () => this.getProduct( rowInfo.original ),
                              } )}
                          />
                        )}
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <CardBox styleName="mt-0" heading="information">
                  <div className="row">
                    <div className="col-md-6">
                      <p>
                        <strong>
                          <IntlMessages id="product" />
                          :
                          {' '}
                        </strong>
                        {' '}
                        {selectedProduct.name}
                      </p>
                      <p>
                        <strong>
                          <IntlMessages id="description" />
                          :
                          {' '}
                        </strong>
                        {' '}
                        {selectedProduct.description}
                      </p>

                      <Checkbox
                        marginContainer={false}
                        field="active"
                        label="active"
                        disabled={selectedProduct
                        && ( selectedProduct.canceled || !selectedProduct.available )}
                      />

                      <Checkbox
                        field="replicate"
                        label="replicate"
                        disabled={selectedProduct
                        && ( selectedProduct.canceled || !selectedProduct.available )}
                      />
                    </div>
                    <div className="col-md-6">
                      <p>
                        <strong>
                          <IntlMessages id="currentProduction" />
                          {': '}
                        </strong>
                        {' '}
                        {Util.formatNumber(
                          this.currentProduction ? this.currentProduction.totalProduction : 0,
                        )}
                      </p>
                      <p>
                        <strong>
                          <IntlMessages id="unitaryCost" />
                          {': '}
                        </strong>
                        {' '}
                        {Util.formatCurrency(
                          this.currentProduction ? this.currentProduction.unitaryProductionCost : 0,
                        )}
                      </p>
                      <p>
                        <strong>
                          <IntlMessages id="totalProductionCost" />
                          {': '}
                        </strong>
                        {' '}
                        {Util.formatCurrency( productionCost )}
                      </p>

                      <TextField
                        field="value"
                        label="newProduction"
                        allowZero
                        type="number"
                        disabled={selectedProduct
                        && ( selectedProduct.canceled || !selectedProduct.available )}
                        validate={required}
                        onChange={( value ) => {
                          if ( this.currentProduction ) {
                            // eslint-disable-next-line max-len
                            this.setState( { productionCost: value * Number( this.currentProduction.unitaryProductionCost ) } );
                          }
                        }}
                      />
                    </div>
                  </div>
                </CardBox>

                <CardBox styleName="mt-3" cardStyle="px-4 mb-0">
                  <ResponsiveContainer width="100%" height={200}>
                    <LineChart
                      data={selectedProduct.productions}
                      margin={{ left: -25, right: 20 }}
                    >
                      <XAxis
                        interval={0}
                        dataKey="round.name"
                        height={60}
                        tick={<AxisTick />}
                      />
                      <Legend verticalAlign="top" />
                      <YAxis orientation="left" stroke="#03275b" />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Line
                        type="monotone"
                        name="Production"
                        dataKey="totalProduction"
                        stroke="#3367d6"
                        activeDot={{ r: 8 }}
                      />
                      <Line type="monotone" name="Stock" dataKey="stock" stroke="#ffc658" />
                      <Line type="monotone" name="Sales" dataKey="sales" stroke="#04A89E" />
                    </LineChart>
                  </ResponsiveContainer>
                </CardBox>
              </div>

              <div className="col-lg-6">
                <CardBox styleName="mt-3 mt-lg-0" heading="rounds">
                  <Table
                    data={rounds}
                    pageSize={rounds.length}
                    showPaginationTop={false}
                    columns={[
                      {
                        Header: <IntlMessages id="round" />,
                        accessor: 'round.name',
                        Cell: ( { value, original } ) => `${value} ${_.get( original, 'round.startDateInMonth', '' )}`,
                      },
                      {
                        Header: <IntlMessages id="production" />,
                        accessor: 'totalProduction',
                        Cell: ( { value } ) => Util.formatNumber( value ),
                      },
                      {
                        Header: <IntlMessages id="sales" />,
                        accessor: 'sales',
                        Cell: ( { value, original } ) => Util.formatNumber(
                          value + original.tradeSales,
                        ),
                      },
                      {
                        Header: <IntlMessages id="stock" />,
                        accessor: 'stock',
                        Cell: ( { value } ) => Util.formatNumber( value ),
                      },
                      {
                        Header: <IntlMessages id="cost" />,
                        accessor: 'unitaryProductionCost',
                        Cell: ( { value } ) => Util.formatCurrency( value ),
                      },
                    ]}
                    getTrProps={( state, rowInfo ) => ( { className: currentRoundId === rowInfo.original.round.id ? 'font-weight-bold' : '' } )}
                  />
                </CardBox>
              </div>
            </div>

            <ConfirmationModal
              open={deactivatedModal}
              title="confirmDeactivateProductMsg"
              confirmBtnText="yesModalBtn"
              onSubmit={() => {
                this.toggleDeactivatedModal();
                this.submitForm( this.formData );
              }}
              onClose={this.toggleDeactivatedModal}
            />

          </form>
        )}
      />
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( Products );
