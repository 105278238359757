import React from 'react';
import { Field } from 'react-final-form';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import classNames from 'classnames';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';

import IntlMessages from 'utils/IntlMessages';

function DatePickerComp( {
                           append, dateFormat, timeFormat, formatDate, field, prefix, suffix, label,
                           marginContainer = true, onChange, placeholder, intl, validate, translateValues, ...rest
                         } ) {
  function renderInput( props, meta ) {
    function clear() {
      props.onChange( { target: { value: '' } } );
    }

    return (
      <div>
        {label ? <TextField {...props}
                            error={meta.touched && !!meta.error}
                            label={<IntlMessages id={label}/>}/>
          :
          <TextField {...props}
                     error={meta.touched && !!meta.error}
          />
        }
        {props.value ?
          <div className="text-muted pointer">
            <i onClick={clear} className="fa fa-times"
               style={{
                 position: 'absolute',
                 bottom: 5,
                 right: 5,
               }}/>
          </div>
          : null}
      </div>
    );
  }

  return (
    <Field name={field} validate={validate}>
      {( { meta, input } ) => {
        return (
          <FormControl error={meta.touched && !!meta.error}
                       className={classNames( 'w-100 mt-custom', { 'mb-4': marginContainer } )}>
            {append ?
              <div className="input-group" id={field}>
                {prefix ? <span className="input-group-addon"
                                dangerouslySetInnerHTML={{ __html: prefix }}/> : null}
                <DatePicker
                  {...input}
                  {...rest}
                  closeOnSelect
                  error={meta.touched && !!meta.error}
                  renderInput={( props ) => renderInput( props, meta )}
                  value={input.value ? moment( Number( input.value ) )
                    .format( dateFormat || formatDate || 'DD/MM/YYYY' ) : ''}
                  onChange={( value ) => {
                    input.onChange( value );
                    if (onChange) onChange( value );
                  }}
                  timeFormat={timeFormat ? timeFormat : false}
                  dateFormat={dateFormat ? dateFormat : 'DD/MM/YYYY'}
                  inputProps={placeholder ? { placeholder: intl.formatMessage( { id: placeholder } ) } : {}}
                />
                {suffix ? <span className="input-group-addon"
                                dangerouslySetInnerHTML={{ __html: suffix }}/> : null}
              </div> :
              <DatePicker
                {...input}
                {...rest}
                closeOnSelect
                renderInput={( props ) => renderInput( props, meta )}
                value={input.value ? moment( Number( input.value ) )
                  .format( dateFormat || formatDate || 'DD/MM/YYYY' ) : ''}
                onChange={( value ) => {
                  input.onChange( value );
                  if (onChange) onChange( value );
                }}
                timeFormat={timeFormat ? timeFormat : false}
                dateFormat={dateFormat ? dateFormat : 'DD/MM/YYYY'}
                inputProps={placeholder ? { placeholder: intl.formatMessage( { id: placeholder } ) } : {}}
              />
            }
            {meta.touched && meta.error
            && (
              <FormHelperText>
                <IntlMessages id={meta.error} values={translateValues}/>
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    </Field>
  );
}

export default DatePickerComp;
