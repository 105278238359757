import api from '../config/Api';

const endpoint = 'v2/session';

export default {
  getCampaigns( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/communication`, params );
  },

  getOneToOneCampaigns( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/crm`, params );
  },

  getCampaign( sessionId, id, params ) {
    return api.get( `${endpoint}/${sessionId}/communication/${id}`, params );
  },

  changeStatusCampaign( sessionId, id, params ) {
    return api.put( `${endpoint}/${sessionId}/campaign/${id}`, params );
  },

  getOneToOneCampaign( sessionId, id, params ) {
    return api.get( `${endpoint}/${sessionId}/crm/${id}`, params );
  },

  getOneToOneCampaignRoundList( sessionId, id, params ) {
    return api.get( `${endpoint}/${sessionId}/crm/${id}/reports`, params );
  },
  getCostIndex( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/communication/cost/index`, params );
  },

  getOneToOneCostIndex( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/crm/cost/index`, params );
  },

  saveCampaign( sessionId, data ) {
    return api.post( `${endpoint}/${sessionId}/communication`, data );
  },

  saveOneToOneCampaign( sessionId, data ) {
    return api.post( `${endpoint}/${sessionId}/crm`, data );
  },
};
