import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';

import IntlMessage from 'utils/IntlMessages';

const BreadcrumbComp = ( { links } ) => {
  return (
    <Breadcrumb listClassName="bg-transparent p-0" tag="nav">
      {links.map( ( link, index ) => (
          <BreadcrumbItem active={links.length === index + 1}
                          tag={links.length === index + 1 ? 'span' : Link}
                          key={index}
                          to={link.url}>{<IntlMessage id={link.name}/>}</BreadcrumbItem>
        )
      )}
    </Breadcrumb>
  );
};

export default BreadcrumbComp;

