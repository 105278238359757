import React from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import BasicCard from 'components/custom_v2/BasicCards';

// eslint-disable-next-line react/prefer-stateless-function
class Tutorial extends React.Component {
  render() {
    return (
      <>
        <div className="animated slideInUpTiny animation-duration-3">
          <div style={{ margin: 20, paddingLeft: 2, paddingRight: 2 }}>
            <div className="row mb-md-3">
              <div className="col-lg-4 col-sm-6 col-12">
                <BasicCard
                  image="https://via.placeholder.com/500x330"
                  title="Card Title"
                  subTitle="Mixed Content With Fixed Width"
                  description="Small plates, salads & sandwiches in an intimate setting with 12 indoor seats plus patio seating"
                  btnText="Go Somewhere"
                />
              </div>

              <div className="col-lg-4 col-sm-6 col-12 order-lg-3">
                <BasicCard
                  image="https://via.placeholder.com/500x330"
                  title="Card Title"
                  subTitle="Mixed Content With Fixed Width"
                  description="Small plates, salads & sandwiches in an intimate setting with 12 indoor seats plus patio seating"
                  btnText="Go Somewhere"
                />
              </div>

              <div className="col-lg-4 col-sm-6 col-12 order-lg-3">
                <BasicCard
                  image="https://via.placeholder.com/500x330"
                  title="Card Title"
                  subTitle="Mixed Content With Fixed Width"
                  description="Small plates, salads & sandwiches in an intimate setting with 12 indoor seats plus patio seating"
                  btnText="Go Somewhere"
                />
              </div>
            </div>
            <div className="row mb-md-3">
              <div className="col-lg-4 col-sm-6 col-12">
                <BasicCard
                  image="https://via.placeholder.com/500x330"
                  title="Card Title"
                  subTitle="Mixed Content With Fixed Width"
                  description="Small plates, salads & sandwiches in an intimate setting with 12 indoor seats plus patio seating"
                  btnText="Go Somewhere"
                />
              </div>

              <div className="col-lg-4 col-sm-6 col-12 order-lg-3">
                <BasicCard
                  image="https://via.placeholder.com/500x330"
                  title="Card Title"
                  subTitle="Mixed Content With Fixed Width"
                  description="Small plates, salads & sandwiches in an intimate setting with 12 indoor seats plus patio seating"
                  btnText="Go Somewhere"
                />
              </div>

              <div className="col-lg-4 col-sm-6 col-12 order-lg-3">
                <BasicCard
                  image="https://via.placeholder.com/500x330"
                  title="Card Title"
                  subTitle="Mixed Content With Fixed Width"
                  description="Small plates, salads & sandwiches in an intimate setting with 12 indoor seats plus patio seating"
                  btnText="Go Somewhere"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {
  toggleLoading: SettingsActions.toggleLoading,
  toggleAlert: SettingsActions.toggleAlert,
};

export default connect( null, mapDispatchToProps )( Tutorial );
