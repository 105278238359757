import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import _ from 'lodash';

import IntlMessages from 'utils/IntlMessages';
import { TextField, Buttons } from 'components/custom/FormElements';
import { required } from 'config/InputErrors';
import { connect } from 'react-redux';
import WorkGroupService from 'services/WorkGroup';
import SettingsActions from 'store/reducers/Settings';

const WorkGroupEditForm = (
  { open, sessionId, data, toggleAlert, toggleLoading, onConfirm, onClose },
) => {
  function submitForm( formData, form ) {
    const dataToSend = {};

    toggleLoading( true );
    _.map( _.keys( form.getState().modified ), ( key ) => {
      dataToSend[key] = _.get( formData[key], 'id' ) ? formData[key].id : formData[key];
    } );

    const promise = data && data.id
      ? WorkGroupService.updateWorkGroup( sessionId, data.id, dataToSend )
      : WorkGroupService.saveWorkGroup( sessionId, dataToSend );
    promise.then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) return toggleAlert( response.errors );

      toggleAlert( 'dataSaved', 'info' );
      onConfirm();
    } );
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <Form
        initialValues={data}
        onSubmit={submitForm}
        render={( { handleSubmit, form } ) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle className="bg-dark text-white">
              <IntlMessages id={data && data.id ? 'editWorkGroup' : 'newWorkGroup'} />
            </DialogTitle>
            <DialogContent className="p-3 overflow-visible">
              <div className="row">
                <div className="col-md-6">
                  <TextField field="name" validate={required} label="name" />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Buttons onClickCancel={onClose} />
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps,
)( WorkGroupEditForm );
