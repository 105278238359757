import React from 'react';
import {
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Col, Row } from 'reactstrap';
import CardBox from 'components/custom_v2/CardBox';
import Util from 'utils/Util';
import IntlMessages from 'utils/IntlMessages';

const CompModal = ( { title, open, onClose, data } ) => (
  <Dialog
    fullScreen
    open={open}
    onClose={onClose}
    TransitionComponent={Slide}
    TransitionProps={{ direction: 'up' }}
  >
    <AppBar className="position-relative">
      <Toolbar>
        <IconButton onClick={onClose} aria-label="Close" className="text-white">
          <CloseIcon />
        </IconButton>
        <Typography
          color="inherit"
          style={{ flex: 1 }}
        >
          <IntlMessages id={title} />
        </Typography>
      </Toolbar>
    </AppBar>
    <p />
    {!!data && data.length > 0 && (
      <Row style={{ paddingLeft: '15px', paddingRight: '15px' }}>
        <Col style={{ width: 'auto', margin: 0, paddingLeft: 2, paddingRight: 2 }}>

          <CardBox heading="ProfitLossStatement" headerBg="bg-product-laptop">
            <div className="jr-link" id="PLossCompdiv" />
            <Row>
              <Col lg={2}>&nbsp;</Col>
              <Col lg={2} className="text-center"><b>{data[0].workgroup.name}</b></Col>
              <Col lg={2} className="text-center"><b>{data[1].workgroup.name}</b></Col>
              <Col lg={2} className="text-center"><b>{data[2].workgroup.name}</b></Col>
              <Col lg={2} className="text-center"><b>{data[3].workgroup.name}</b></Col>
              <Col lg={2} className="text-center"><b>{data[4].workgroup.name}</b></Col>
            </Row>

            <Row>
              <Col><b><IntlMessages id="Revenues" /></b></Col>
            </Row>
            <Row>
              <Col lg={2}><IntlMessages id="RevenuesFromGoodSold" /></Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[0].revenuesFromGoodsSold )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[1].revenuesFromGoodsSold )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[2].revenuesFromGoodsSold )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[3].revenuesFromGoodsSold )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[4].revenuesFromGoodsSold )}</Col>
            </Row>
            <Row>
              <Col lg={2}><IntlMessages id="RevenuesFromServ" /></Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[0].revenuesFromServicesSold )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[1].revenuesFromServicesSold )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[2].revenuesFromServicesSold )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[3].revenuesFromServicesSold )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[4].revenuesFromServicesSold )}</Col>
            </Row>
            <Row>
              <Col lg={2}><IntlMessages id="RevenuesFromBundlesSales" /></Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[0].revenuesFromBundlesSold )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[1].revenuesFromBundlesSold )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[2].revenuesFromBundlesSold )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[3].revenuesFromBundlesSold )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[4].revenuesFromBundlesSold )}</Col>
            </Row>
            <Row>
              <Col lg={2} className="bg-gray-3"><IntlMessages id="TotalRevenuesFromSales" /></Col>
              <Col lg={2} className="text-right bg-gray-3">{Util.formatNumber( data[0].totalRevenuesFromSales )}</Col>
              <Col lg={2} className="text-right bg-gray-3">{Util.formatNumber( data[1].totalRevenuesFromSales )}</Col>
              <Col lg={2} className="text-right bg-gray-3">{Util.formatNumber( data[2].totalRevenuesFromSales )}</Col>
              <Col lg={2} className="text-right bg-gray-3">{Util.formatNumber( data[3].totalRevenuesFromSales )}</Col>
              <Col lg={2} className="text-right bg-gray-3">{Util.formatNumber( data[4].totalRevenuesFromSales )}</Col>
            </Row>
            <Row>
              <Col><b><IntlMessages id="DirectExpenses" /></b></Col>
              <Col />
            </Row>
            <Row>
              <Col lg={2}><IntlMessages id="CostOfGoodsSold" /></Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[0].costOfGoodsSold )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[1].costOfGoodsSold )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[2].costOfGoodsSold )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[3].costOfGoodsSold )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[4].costOfGoodsSold )}</Col>
            </Row>
            <Row>
              <Col lg={2}><IntlMessages id="CostOfServicesSold" /></Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[0].costOfServicesSold )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[1].costOfServicesSold )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[2].costOfServicesSold )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[3].costOfServicesSold )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[4].costOfServicesSold )}</Col>
            </Row>
            <Row>
              <Col lg={2}><IntlMessages id="CostOfBundlesSold" /></Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[0].costOfBundlesSold )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[1].costOfBundlesSold )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[2].costOfBundlesSold )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[3].costOfBundlesSold )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[4].costOfBundlesSold )}</Col>
            </Row>
            <Row>
              <Col lg={2} className="bg-gray-3"><IntlMessages id="TotalCostFromSales" /></Col>
              <Col lg={2} className="text-right bg-gray-3">{Util.formatNumber( data[0].totalCostsFormSales )}</Col>
              <Col lg={2} className="text-right bg-gray-3">{Util.formatNumber( data[1].totalCostsFormSales )}</Col>
              <Col lg={2} className="text-right bg-gray-3">{Util.formatNumber( data[2].totalCostsFormSales )}</Col>
              <Col lg={2} className="text-right bg-gray-3">{Util.formatNumber( data[3].totalCostsFormSales )}</Col>
              <Col lg={2} className="text-right bg-gray-3">{Util.formatNumber( data[4].totalCostsFormSales )}</Col>
            </Row>
            <Row>
              <Col lg={2} className="bg-gray-3 bg-gray-3" style={{ fontWeight: 'bold', fontSize: '16px' }}><b><IntlMessages id="Grossprofit" /></b></Col>
              <Col lg={2} className="text-right bg-gray-3" style={{ fontWeight: 'bold', fontSize: '16px' }}><b>{Util.formatNumber( data[0].grossProfit )}</b></Col>
              <Col lg={2} className="text-right bg-gray-3" style={{ fontWeight: 'bold', fontSize: '16px' }}><b>{Util.formatNumber( data[1].grossProfit )}</b></Col>
              <Col lg={2} className="text-right bg-gray-3" style={{ fontWeight: 'bold', fontSize: '16px' }}><b>{Util.formatNumber( data[2].grossProfit )}</b></Col>
              <Col lg={2} className="text-right bg-gray-3" style={{ fontWeight: 'bold', fontSize: '16px' }}><b>{Util.formatNumber( data[3].grossProfit )}</b></Col>
              <Col lg={2} className="text-right bg-gray-3" style={{ fontWeight: 'bold', fontSize: '16px' }}><b>{Util.formatNumber( data[4].grossProfit )}</b></Col>
            </Row>
            <Row>
              <Col><b><IntlMessages id="IndirectExpenses" /></b></Col>
            </Row>
            <Row>
              <Col lg={2}><IntlMessages id="GeneralAdministrative" /></Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[0].generalAdministrative )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[1].generalAdministrative )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[2].generalAdministrative )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[3].generalAdministrative )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[4].generalAdministrative )}</Col>
            </Row>

            <Row>
              <Col lg={2}><IntlMessages id="MarketingCosts" /></Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[0].marketingCost )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[1].marketingCost )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[2].marketingCost )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[3].marketingCost )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[4].marketingCost )}</Col>
            </Row>
            <Row>
              <Col lg={2}><IntlMessages id="DirectSalesCosts" /></Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[0].directSalesCost )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[1].directSalesCost )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[2].directSalesCost )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[3].directSalesCost )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[4].directSalesCost )}</Col>
            </Row>
            <Row>
              <Col lg={2}><IntlMessages id="StockHandlingCosts" /></Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[0].stockHandlingCosts )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[1].stockHandlingCosts )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[2].stockHandlingCosts )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[3].stockHandlingCosts )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[4].stockHandlingCosts )}</Col>
            </Row>
            <Row>
              <Col lg={2}><IntlMessages id="RandD" /></Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[0].rd )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[1].rd )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[2].rd )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[3].rd )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[4].rd )}</Col>
            </Row>
            <Row>
              <Col lg={2}><IntlMessages id="InterestsFromLoans" /></Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[0].interestsFromLoans )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[1].interestsFromLoans )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[2].interestsFromLoans )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[3].interestsFromLoans )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[4].interestsFromLoans )}</Col>
            </Row>
            <Row>
              <Col lg={2}><IntlMessages id="TotalIndirectsExpenses" /></Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[0].totalIndirectExpenses )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[1].totalIndirectExpenses )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[2].totalIndirectExpenses )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[3].totalIndirectExpenses )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[4].totalIndirectExpenses )}</Col>
            </Row>
            <Row>
              <Col lg={2} className="bg-gray-3"><b><IntlMessages id="TotalExpenses" /></b></Col>
              <Col lg={2} className="text-right bg-gray-3">{Util.formatNumber( data[0].totalExpenses )}</Col>
              <Col lg={2} className="text-right bg-gray-3">{Util.formatNumber( data[1].totalExpenses )}</Col>
              <Col lg={2} className="text-right bg-gray-3">{Util.formatNumber( data[2].totalExpenses )}</Col>
              <Col lg={2} className="text-right bg-gray-3">{Util.formatNumber( data[3].totalExpenses )}</Col>
              <Col lg={2} className="text-right bg-gray-3">{Util.formatNumber( data[4].totalExpenses )}</Col>
            </Row>
            <Row>
              <Col lg={2} className="bg-gray-3"><IntlMessages id="EarningsBeforeTax" /></Col>
              <Col lg={2} className="text-right bg-gray-3">{Util.formatNumber( data[0].earningBeforeTaxes )}</Col>
              <Col lg={2} className="text-right bg-gray-3">{Util.formatNumber( data[1].earningBeforeTaxes )}</Col>
              <Col lg={2} className="text-right bg-gray-3">{Util.formatNumber( data[2].earningBeforeTaxes )}</Col>
              <Col lg={2} className="text-right bg-gray-3">{Util.formatNumber( data[3].earningBeforeTaxes )}</Col>
              <Col lg={2} className="text-right bg-gray-3">{Util.formatNumber( data[4].earningBeforeTaxes )}</Col>
            </Row>
            <Row>
              <Col lg={2}><IntlMessages id="Incometaxes" /></Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[0].incomeTaxes )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[1].incomeTaxes )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[2].incomeTaxes )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[3].incomeTaxes )}</Col>
              <Col lg={2} className="text-right">{Util.formatNumber( data[4].incomeTaxes )}</Col>
            </Row>
            <Row>
              <Col lg={2} className="bg-gray-3" style={{ fontWeight: 'bold', fontSize: '16px' }}><b><IntlMessages id="Netearnings" /></b></Col>
              <Col lg={2} className="text-right bg-gray-3" style={{ fontWeight: 'bold', fontSize: '16px' }}><b>{Util.formatNumber( data[0].netEarnings )}</b></Col>
              <Col lg={2} className="text-right bg-gray-3" style={{ fontWeight: 'bold', fontSize: '16px' }}><b>{Util.formatNumber( data[1].netEarnings )}</b></Col>
              <Col lg={2} className="text-right bg-gray-3" style={{ fontWeight: 'bold', fontSize: '16px' }}><b>{Util.formatNumber( data[2].netEarnings )}</b></Col>
              <Col lg={2} className="text-right bg-gray-3" style={{ fontWeight: 'bold', fontSize: '16px' }}><b>{Util.formatNumber( data[3].netEarnings )}</b></Col>
              <Col lg={2} className="text-right bg-gray-3" style={{ fontWeight: 'bold', fontSize: '16px' }}><b>{Util.formatNumber( data[4].netEarnings )}</b></Col>
            </Row>
            <Row>
              <hr />
            </Row>

          </CardBox>
        </Col>
      </Row>
    )}
  </Dialog>
);

export default CompModal;
