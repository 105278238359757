import React from 'react';
import { Field } from 'react-final-form';
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core';
import classNames from 'classnames';

import IntlMessages from 'utils/IntlMessages';

export default ( { field, containerClass, fullWidth = true, label, onChange, marginContainer = true, ...rest } ) => (
  field ?
    <Field name={field} type="checkbox">
      {( { meta, input } ) => (
        <FormControl
          error={meta.touched && !!meta.error}
          className={classNames( containerClass, { 'mb-4': marginContainer } )}
          fullWidth={fullWidth}
        >
          <FormControlLabel
            label={label ? <IntlMessages id={label}/> : ''}
            className={classNames( {
              'm-0': !label
            } )}
            control={(
              <Checkbox
                {...input}
                color="primary"
                {...rest}
                value={!!input.checked || false}
                onChange={( e ) => {
                  input.onChange( e.target.checked );
                  if (onChange) onChange( e.target.checked, e );
                }}
              />
            )}
          />
          {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
      )}
    </Field> :
    <FormControl
      className={classNames( containerClass, { 'mb-4': marginContainer } )}
      fullWidth={fullWidth}
    >
      <FormControlLabel
        label={label ? <IntlMessages id={label}/> : ''}
        control={(
          <Checkbox
            color="primary"
            {...rest}
            value={!!rest.checked || false}
            onChange={( e ) => {
              if (onChange) onChange( e.target.checked, e );
            }}
          />
        )}
      />
    </FormControl>
);
