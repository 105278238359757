import _ from 'lodash';
import numeral from 'numeral';

export default {

  getUserSessions( user ) {
    let licences = [];
    try {
      licences = user.licenses && user.licenses[0].session ? _.map( user.licenses, 'session' ) : [];
    } catch ( error ) {
      licences = [];
    }
    return licences;
  },

  getUserActiveSession( user ) {
    // eslint-disable-next-line max-len
    return user.activeSession ? user.activeSession : user.licenses && user.licenses[0].session ? user.licenses[0].session : {};
  },

  formatCurrency( number, currency ) {
    if ( number === 0 ) return '$0';
    if ( !number ) return number;

    let format = '$0,0.[00]';
    if ( currency === 'EUR' ) {
      format = '0,0.[00] EUR';
    }

    return numeral( number ).format( format );
  },

  formatCurrencyNoDecimals( number, currency ) {
    if ( number === 0 ) return '$0';
    if ( !number ) return number;

    let format = '$0,0';
    if ( currency === 'EUR' ) {
      format = '0,0 EUR';
    }

    return numeral( number ).format( format );
  },
  formatNumber( number ) {
    if ( !number ) return number;
    if ( !_.isNumber( number ) ) return number;
    return numeral( number ).format( '0,0.[00]' );
  },

  formatNumberNoDecimals( number ) {
    if ( !number ) return number;
    if ( !_.isNumber( number ) ) return number;
    return numeral( number ).format( '0,0' );
  },
};
