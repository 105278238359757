import React from 'react';
import { Field } from 'react-final-form';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel
} from '@material-ui/core';
import _ from 'lodash';
import classNames from 'classnames';
import IntlMessages from 'utils/IntlMessages';

export default ( {
                   containerClass, displayBlock, field, idKey, label, onBlur, labelClassName, labelContainerClass,
                   onChange, options, valueKey, marginContainer = true, validate, labelPlacement, translate = true, translateValues, ...props
                 } ) => {
  const fieldId = ( fieldName, index ) => `${fieldName}_${index}`;
  const idKey2 = idKey || 'id';
  const valueKey2 = valueKey || 'value';

  return (
    <Field name={field} validate={validate}>
      {( { meta, input } ) => (
        <FormControl error={meta.touched && !!meta.error} className={classNames( containerClass, {
          'mb-4': marginContainer
        } )}>
          {label && <InputLabel className="label-shrink">{label}</InputLabel>}

          <div className={classNames( { 'mt-2': label }, labelContainerClass )}>
            {_.map( options, ( option, index ) => (
              <FormControlLabel
                key={index}
                labelPlacement={labelPlacement}
                className={classNames( { 'd-flex flex-row': displayBlock }, labelClassName )}
                id={fieldId( field, index )}
                label={option[valueKey2] && translate ? <IntlMessages id={option[valueKey2]}/> : option[valueKey2]}
                control={(
                  <Checkbox
                    color="primary"
                    {...props}
                    checked={!!_.find( input.value, { id: option[idKey2] } ) || false}
                    value={option[idKey2].toString()}
                    onChange={( e ) => {
                      const values = _.cloneDeep( input.value ) || [];

                      if (e.target.checked) {
                        values.push( option );
                      } else {
                        const unCheckedIndex = _.findIndex(
                          input.value, item => item[idKey2].toString() === e.target.value,
                        );
                        values.splice( unCheckedIndex, 1 );
                      }
                      input.onChange( values );
                      if (onChange) onChange( values );
                    }}
                  />
                )}
              />
            ) )}
          </div>
          {meta.touched && meta.error
          && (
            <FormHelperText>
              <IntlMessages id={meta.error} values={translateValues}/>
            </FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  );
};
