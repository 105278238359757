import React from 'react';
import { Field } from 'react-final-form';
import { FormControl, FormHelperText } from '@material-ui/core';
import Dropzone from 'react-dropzone';

import IntlMessages from 'utils/IntlMessages';

const DropzoneInput = ( {
                          field, onChange, accept, btn, translateValues, validate
                        } ) => (
  <Field name={field} validate={validate}>
    {( { meta, input } ) => (
      <FormControl
        error={meta.touched && !!meta.error}
      >
        <Dropzone
          accept={accept}
          onDrop={( files ) => {
            if (files && files[0]) {
              const reader = new FileReader();
              const file = files[0];
              reader.readAsDataURL( file );
              reader.onload = ( event ) => {
                input.onChange( {
                  file,
                  fileDecoded: event.target.result
                } );
                if (onChange) {
                  onChange( {
                    file,
                    fileDecoded: event.target.result
                  } );
                }
              };
            }
          }}
        >
          {( { getRootProps, getInputProps } ) => (
            <div
              className="dropzone dropzone-single dz-clickable m-0"
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {btn || <button type="button" className="btn btn-link p-0 m-0">Upload file</button>}

              {input.value && input.value.file
                ? <p className="mb-0 mt-2">
                  {input.value.file.name}
                  <i className="fa fa-times ml-2"
                     onClick={( e ) => {
                       e.stopPropagation();
                       input.onChange( null );
                       if (onChange) onChange( null );
                     }}/>
                </p> : null
              }
            </div>
          )}
        </Dropzone>
        {meta.touched && meta.error
        && (
          <FormHelperText>
            <IntlMessages id={meta.error} values={translateValues}/>
          </FormHelperText>
        )}
      </FormControl>
    )}
  </Field>
);

export default DropzoneInput;
