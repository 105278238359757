import React from 'react';

import CardBox from 'components/theme/CardBox';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import _ from 'lodash';
import { connect } from 'react-redux';
import DashboardStudentService from 'services/DashboardStudent';
import { Row, Col } from 'reactstrap';
import ChartModal from 'routes/App/components/User/Chart';

class Chart extends React.Component {
  state = { chartModal: false };

  componentDidMount() {
    const { user } = this.props;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );

    // const chart2 = am4core.create( 'chartdiv2', am4charts.XYChart );
    //  const chart3 = am4core.create( 'chartdiv3', am4charts.PieChart3D );

    const params = { workgroup: this.license.workGroup.id, limit: -1 };
    DashboardStudentService.getRankingChart( '229a3318-20d3-11ea-8c97-6e21ce73e356', params )
      .then( ( response ) => {
        if ( response.ok ) {
          this.config = {
            data: _.map( response.data.data[0].workgroupRound, item => ( {
              category: item.round.name,
              points: item.points,
              accumulated: item.accumulated,
            } ) ),
            type: am4charts.XYChart,
            xAxes: [{
              type: 'CategoryAxis',
              dataFields: { category: 'category' },
            }],
            yAxes: [{ type: 'ValueAxis' }],
            series: [
              {
                type: 'LineSeries',
                dataFields: {
                  valueY: 'points',
                  categoryX: 'category',
                },
              },
              {
                type: 'LineSeries',
                dataFields: {
                  valueY: 'accumulated',
                  categoryX: 'category',
                },
              },
            ],
          };
          this.chart = am4core.createFromConfig( _.cloneDeep( this.config ), 'chartdiv' );

          /*   // line chart
          chart.data = _.map( response.data.data[0].workgroupRound, item => ( {
            category: item.round.name,
            points: item.points,
            accumulated: item.accumulated,
          } ) );
          // chart.cursor = new am4charts.XYCursor();

          const categoryAxis = chart.xAxes.push( new am4charts.CategoryAxis() );
          categoryAxis.dataFields.category = 'category';

          chart.yAxes.push( new am4charts.ValueAxis() );

          const points = chart.series.push( new am4charts.LineSeries() );
          points.dataFields.categoryX = 'category';
          points.dataFields.valueY = 'points';
          // points.tooltipText = 'Points - {categoryX}: {valueY.value}';

          const accumulated = chart.series.push( new am4charts.LineSeries() );
          accumulated.dataFields.categoryX = 'category';
          accumulated.dataFields.valueY = 'accumulated';
          // accumulated.tooltipText = 'Accumulated - {categoryX}: {valueY.value}';

          /!* const scrollbarX = new am4charts.XYChartScrollbar();
          scrollbarX.series.push( accumulated );
          scrollbarX.series.push( points );
          chart.scrollbarX = scrollbarX; *!/

          this.chart = chart; */


          // bar chart
        /*  chart2.data = _.map( response.data.data[0].workgroupRound, item => ( {
            category: item.round.name,
            accumulated: item.accumulated,
          } ) );
          chart2.cursor = new am4charts.XYCursor();

          const categoryAxis2 = chart2.xAxes.push( new am4charts.CategoryAxis() );
          categoryAxis2.tooltip.disabled = true;
          categoryAxis2.dataFields.category = 'category';

          const valueAxis2 = chart2.yAxes.push( new am4charts.ValueAxis() );
          valueAxis2.tooltip.disabled = true;

          const accumulated2 = chart2.series.push( new am4charts.ColumnSeries() );
          accumulated2.dataFields.categoryX = 'category';
          accumulated2.dataFields.valueY = 'accumulated';
          accumulated2.tooltipText = 'Accumulated - {categoryX}: {valueY.value}';

          const scrollbarX2 = new am4charts.XYChartScrollbar();
          scrollbarX2.background.fill = am4core.color( '#04A89E' );
          scrollbarX2.background.fillOpacity = 0.5;
          scrollbarX2.minHeight = 10;

          // scrollbarX2.series.push( accumulated );
          chart2.scrollbarX = scrollbarX2;
          this.chart2 = chart2;

          chart3.data = _.map( response.data.data[0].workgroupRound, item => ( {
            category: item.round.name,
            points: item.points,
            accumulated: item.accumulated,
          } ) );
          chart3.hiddenState.properties.opacity = 0;
          chart3.legend = new am4charts.Legend();
          const series = chart3.series.push( new am4charts.PieSeries3D() );
          series.dataFields.value = 'points';
          series.dataFields.category = 'category'; */
        }
      } );
  }

  componentWillUnmount() {
    if ( this.chart ) {
      this.chart.dispose();
    }
    if ( this.chart2 ) {
      this.chart2.dispose();
    }
  }

  toggleChartModal = () => {
    this.setState( previousState => ( { chartModal: !previousState.chartModal } ) );
  };

  render() {
    const { chartModal } = this.state;

    return (
      <>
        <Row>
          <Col lg={4}>
            <CardBox heading="Laptop Sales ( Thousands of units )" onClick={this.toggleChartModal}>
              <div id="chartdiv" style={{ width: '100%', height: '400px' }} />
            </CardBox>
          </Col>
        </Row>

        <ChartModal
          open={chartModal}
          title="Laptop Sales ( Thousands of units )"
          onClose={this.toggleChartModal}
          seriesTitle={['Points', 'Accumulated']}
          config={this.config}
        />
      </>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

export default connect( mapStateToProps )( Chart );
