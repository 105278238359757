import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { Button } from '@material-ui/core';
import { Col, Row } from 'reactstrap';

import async from 'async';
import _ from 'lodash';

import { composeValidators, email, required } from 'config/InputErrors';
import TextField from 'components/custom/FormElements/TextField';
import IntlMessages from 'utils/IntlMessages';
import UserActions from 'store/reducers/User';
import SettingsActions from 'store/reducers/Settings';
import AuthService from 'services/Auth';
import UserService from 'services/User';
import logo from 'assets/images/logo.png';
import ValidateCodeModal from 'components/custom/ValidateCodeModal';

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = { confirmSaveModal: false };
  }

  componentDidMount() {
    const { accessToken, isLoading, logoutUser, toggleLoading } = this.props;

    if ( accessToken ) logoutUser();
    if ( isLoading ) toggleLoading( false );
  }

  loginUser = ( data, cb ) => {
    AuthService.loginUser( data )
      .then( response => cb( response.errors, response.data ) );
  };

  apiValidateCode = ( data, cb ) => {
    UserService.validateCode( data )
      .then( response => cb( response.errors, response.data ) );
  };

  validateCode = ( response ) => {
    const { toggleAlert, history, toggleLoading } = this.props;
    toggleLoading( true );
    const data = { registrationCode: response.code };
    async.waterfall( [
      this.apiValidateCode.bind( this, data ),
    ], ( error, results ) => {
      if ( error ) {
        toggleLoading( false );
        return toggleAlert( error );
      }
      if ( _.get( results, 'id' ) ) {
        toggleLoading( false );
        history.push( `sign-up2/${results.id}` );
      }
    } );
  }
  getUser = ( data, cb ) => {
    UserService.getCurrentUser( data )
      .then( ( response ) => {
        if ( response.errors ) return cb( response.errors );
        return cb( null, { ...data, ...response.data } );
      } );
  };

  hasPermissions = ( user, role, license ) => user.roles && user.roles.indexOf( role ) !== -1
    && user.licenses && _.find( user.licenses, { owner: license } );

  submitForm = ( data ) => {
    const { loginUser, changeActiveSession, toggleLoading, toggleAlert, history } = this.props;

    toggleLoading( true );

    async.waterfall( [
      this.loginUser.bind( this, data ),
      this.getUser,
    ], ( error, results ) => {
      toggleLoading( false );
      if ( error ) {
        if ( error === 'invalid_grant' ) {
          return toggleAlert( 'wrongUserPass', 'danger', true );
        }
        return toggleAlert( error );
      }
      if ( _.get( results, 'licenses[0].session' ) ) {
        changeActiveSession( results.licenses[0].session );
        loginUser( results );
        history.push( '/' );
      } else {
        loginUser( results );
        history.push( '/sessions/add' );
      }
    } );
  };
  toggleConfirmSaveModal = () => {
    const { confirmSaveModal } = this.state;
    this.setState( { confirmSaveModal: !confirmSaveModal } );
  };
  render() {
    const { confirmSaveModal } = this.state;
    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3"
      >
        <div className="app-login-main-content">

          <div
            className="app-logo-content bg-primary d-flex align-items-center justify-content-center"
          >
            <Link className="logo-lg" to="/" title="MK">
              <img src={logo} alt="MK" title="MK" width={200} />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>Log In</h1>
            </div>

            <div className="app-login-form">
              <Form
                onSubmit={this.submitForm}
                render={( { handleSubmit } ) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <Row>
                      <Col>
                        <TextField
                          field="username"
                          label="email"
                          validate={composeValidators( required, email )}
                        />
                      </Col>

                    </Row>
                    <Row>
                      <Col>
                        <TextField
                          field="password"
                          type="password"
                          label="password"
                          validate={required}
                        />
                      </Col>

                    </Row>
                    <Row>
                      <Col>
                        <div className="d-flex justify-content-between align-items-center">
                          <Link to="forgot-password">
                            <IntlMessages id="forgotPasswordBtn" />
                          </Link>

                          <Button
                            variant="contained"
                            color="primary"
                            className="jr-btn jr-btn-lg"
                            type="submit"
                          >
                            <i className="fa fa-sign-in-alt" />
                            <IntlMessages id="loginBtn" />
                          </Button>
                        </div>
                      </Col>

                    </Row>

                  </form>
                )}
              />
              <ValidateCodeModal
                open={confirmSaveModal}
                title="confirmValidateCode"
                confirmBtnText="validateCodeConfirmBtn"
                onSubmit={( response ) => {
                  this.validateCode( response );
                }}
                onClose={this.toggleConfirmSaveModal}
              />
            </div>


          </div>
          <div className="app-separator-content">
            <div className="loginSeparator" />
          </div>


          <div className="app-validate-content" style={{ verticalAlign: 'bottom' }}>
            <div className="app-login-header mb-4">
              <h1><IntlMessages id="signUp2" /></h1>
            </div>

            <div className="app-login-form" style={{ verticalAlign: 'bottom' }}>
              <Form
                onSubmit={this.validateCode}
                render={( { handleSubmit } ) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <Row>
                      <Col>
                        <TextField
                          field="code"
                          type="password"
                          label="enterCodeForRegistration"
                          validate={required}
                        />
                      </Col>
                    </Row>
                    <Row style={{ height: 85 }}>
                      <Col>
                        &nbsp;
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="d-flex justify-content-between align-items-end">
                          <div />
                          <Button
                            variant="contained"
                            color="primary"
                            className="jr-btn jr-btn-lg align-items-end"
                            type="submit"
                          >
                            <i className="fa fa-sign-in-alt" />
                            <IntlMessages id="validateCodeConfirmBtn" />
                          </Button>
                        </div>
                      </Col>
                    </Row>


                  </form>
                )}
              />

            </div>


          </div>
        </div>
      </div>


    );
  }
}

const mapStateToProps = ( { user, settings } ) => ( {
  accessToken: user.access_token,
  isLoading: settings.isLoading,
} );

const mapDispatchToProps = ( {
  loginUser: UserActions.loginUser,
  logoutUser: UserActions.logoutUser,
  toggleLoading: SettingsActions.toggleLoading,
  toggleAlert: SettingsActions.toggleAlert,
  changeActiveSession: UserActions.changeActiveSession,
} );

export default connect( mapStateToProps, mapDispatchToProps )( SignIn );
