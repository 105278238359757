import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { Button } from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
import _ from 'lodash';
import async from 'async';

import SettingsActions from 'store/reducers/Settings';
import GlobalVariableService from 'services/GlobalVariable';
import ServiceTypeService from 'services/ServiceType';
import CategoryService from 'services/Category';
import AttributeService from 'services/Attribute';
import AttributeCemService from 'services/AttributeCem';
import AttributeMediaService from 'services/AttributeMedia';
import AttributeServiceService from 'services/AttributeService';
import VersionVariableService from 'services/VersionVariable';
import AttributeCostParamService from 'services/AttributeCostParam';
import AttributeCostCemService from 'services/AttributeCostCem';
import AttributeIDCostParamService from 'services/AttributeIDCostParam';
import AttributeCostServiceService from 'services/AttributeCostService';
import AttributeIDAchievableParamService from 'services/AttributeIDAchievableParam';
import AttributeIDFormulaParamService from 'services/AttributeIDFormulaParam';
import CardBox from 'components/theme/CardBox';
import { Select } from 'components/custom/FormElements';
import IntlMessages from 'utils/IntlMessages';
import NewEntityBtn from 'components/custom/NewEntityBtn';
import VariablesCard from 'routes/App/components/Admin/Params/VariablesCard';
import CheckboxCard from 'routes/App/components/Admin/Params/CheckboxCard';
import AttributeCostParams from 'routes/App/components/Admin/Params/AttributeCostParams';
import AttributeIDCostParams from 'routes/App/components/Admin/Params/AttributeIDCostParams';
import CategoriesServicesCard from 'routes/App/components/Admin/Params/CategoriesServicesCard';
import AttributeMediaCard from 'routes/App/components/Admin/Params/AttributeMediaCard';
import ConfirmationModal from 'components/custom/ConfirmationModal';
import ContainerHeader from 'components/theme/ContainerHeader';
import ReportService from 'services/Report';

class Params extends React.Component {
  state = {
    data: {},
    currentVersion: null,
    versions: [],
    newVersionModal: false,
    selectedVariable: 1,
    selectedAttribute: null,
    selectedAttributeID: null,
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { toggleLoading, toggleAlert } = this.props;

    toggleLoading( true );
    VersionVariableService.getVersions( { 'sortby[createdAt]': 'desc' } )
      .then( ( response ) => {
        if ( !response.ok ) return toggleLoading( false );

        const currentVersion = response.data.data[0];
        if ( !currentVersion ) {
          toggleLoading( false );
          toggleAlert( 'No versions available' );
        } else {
          this.setState( {
            versions: response.data.data,
            currentVersion: currentVersion.id,
          } );
          this.getVersionData( currentVersion.id );
        }
      } );
  }

  getVersionData = ( versionId ) => {
    const { toggleLoading } = this.props;
    toggleLoading( true );

    async.parallel( {
      categories: this.getCategories.bind( this, versionId ),
      serviceTypes: this.getServiceTypes.bind( this, versionId ),
      productAttributes: this.getProductAttributes.bind( this, versionId ),
      cemAttributes: this.getCemAttributes.bind( this, versionId ),
      serviceAttributes: this.getServiceAttributes.bind( this, versionId ),
      variables: this.getVariables.bind( this, versionId ),
      mediaAttributes: this.getMediaAttributes.bind( this, versionId ),
    }, ( error, results ) => {
      if ( error ) return toggleLoading( false );
      const costVariables = _.filter( results.variables, { typeVariable: 'Cost' } );
      const generalVariables = _.filter( results.variables, { typeVariable: 'General' } );
      const mediaAttributesRadio = _.filter( results.mediaAttributes, { mediaType: 'RADIO' } );
      const mediaAttributesTv = _.filter( results.mediaAttributes, { mediaType: 'TV' } );
      const mediaAttributesInternet = _.filter( results.mediaAttributes, { mediaType: 'INTERNET' } );
      const mediaAttributesOutdoor = _.filter( results.mediaAttributes, { mediaType: 'OUTDOOR' } );

      if ( results.productAttributes && results.categories ) {
        async.parallel( {
          costParamDetail: cb => this.getAttributeDetail(
            1,
            results.productAttributes[0].id,
            results.categories[0].id, cb,
          ),
          attributes: cb => this.getAttributeIDDetail(
            results.productAttributes[0].id,
            results.categories[0].id, cb,
          ),
        }, ( detailError, detailResults ) => {
          if ( detailError ) {
            this.setState( {
              data: {
                categories: results.categories,
                serviceTypes: results.serviceTypes,
                productAttributes: results.productAttributes,
                serviceAttributes: results.serviceAttributes,
                cemAttributes: results.cemAttributes,
                mediaAttributes: results.mediaAttributes,
                costVariables,
                generalVariables,
                mediaAttributesRadio,
                mediaAttributesTv,
                mediaAttributesInternet,
                mediaAttributesOutdoor,
              },
            } );
          } else {
            this.setState( {
              selectedVariable: 1,
              selectedAttribute: results.productAttributes[0].id,
              selectedAttributeID: results.productAttributes[0].id,
              selectedCategory: results.categories[0].id,
              selectedCategoryID: results.categories[0].id,
              data: {
                categories: results.categories,
                serviceTypes: results.serviceTypes,
                productAttributes: results.productAttributes,
                cemAttributes: results.cemAttributes,
                serviceAttributes: results.serviceAttributes,
                costVariables,
                generalVariables,
                mediaAttributesRadio,
                mediaAttributesTv,
                mediaAttributesInternet,
                mediaAttributesOutdoor,
              },
              attributes: detailResults.costParamDetail,
              attributesID: detailResults.attributes.attributeIDCostDetail,
              attributesIDAchievable: detailResults.attributes.attributeIDAchievableDetail,
              attributesIDFormula: detailResults.attributes.attributeIDFormulaDetail,
            } );

            toggleLoading( false );
          }
        } );
      }
    } );
  };

  getAttributeDetail = ( variableId, attributeId, categoryId, cb ) => {
    const { toggleLoading } = this.props;
    if ( !cb ) toggleLoading( true );

    let promise = null;
    if ( variableId === 1 ) {
      promise = AttributeCostParamService.getAttributeCostParams( attributeId, {
        limit: -1,
        category: categoryId,
      } );
    }
    if ( variableId === 2 ) {
      promise = AttributeCostServiceService.getAttributeCostService( attributeId, {
        limit: -1,
        serviceType: categoryId,
      } );
    }
    if ( variableId === 3 ) {
      promise = AttributeCostCemService.getAttributeCostCem( attributeId );
    }

    if ( promise ) {
      promise.then( ( response ) => {
        if ( cb ) return cb( response.errors, response.data );

        toggleLoading( false );
        if ( response.ok ) {
          this.setState( {
            attributes: response.data,
            selectedVariable: variableId,
            selectedAttribute: attributeId,
            selectedCategory: categoryId,
          } );
        }
      } );
    }
  };

  getAttributeIDDetail = ( attributeId, categoryId, cb ) => {
    const { toggleLoading } = this.props;
    if ( !cb ) toggleLoading( true );

    async.parallel( {
      attributeIDCostDetail: pCb => this.getAttributeIDCostDetail( attributeId, categoryId, pCb ),
      attributeIDAchievableDetail: pCb => this.getAttributeIDAchievableDetail(
        attributeId,
        categoryId, pCb,
      ),
      attributeIDFormulaDetail: pCb => this.getAttributeIDFormulaDetail(
        attributeId,
        categoryId, pCb,
      ),
    }, ( error, results ) => {
      if ( cb ) return cb( error, results );
      if ( !error ) {
        this.setState( {
          attributesID: results.attributeIDCostDetail,
          attributesIDAchievable: results.attributeIDAchievableDetail,
          attributesIDFormula: results.attributeIDFormulaDetail,
          selectedAttributeID: attributeId,
          selectedCategoryID: categoryId,
        } );
      }

      toggleLoading( false );
    } );
  };

  getAttributeIDCostDetail = ( attributeId, categoryId, cb ) => {
    AttributeIDCostParamService.getAttributeIDCostParams( attributeId, {
      limit: -1,
      category: categoryId,
    } ).then( response => cb( response.errors, response.data ) );
  };

  getAttributeIDAchievableDetail = ( attributeId, categoryId, cb ) => {
    AttributeIDAchievableParamService.getAttributeIDAchievableParams( attributeId, {
      limit: -1,
      category: categoryId,
    } ).then( response => cb( response.errors, response.data ) );
  };

  getAttributeIDFormulaDetail = ( attributeId, categoryId, cb ) => {
    AttributeIDFormulaParamService.getAttributeIDFormulaParams( attributeId, {
      limit: -1,
      category: categoryId,
    } ).then( response => cb( response.errors, response.data ) );
  };

  getCategories = ( versionId, cb ) => {
    CategoryService.getCategories( { 'filters[version]': versionId } )
      .then( response => cb( response.errors, response.data ? response.data.data : [] ) );
  };

  getServiceTypes = ( versionId, cb ) => {
    ServiceTypeService.getServiceTypes( { 'filters[version]': versionId } )
      .then( response => cb( response.errors, response.data ? response.data.data : [] ) );
  };

  getProductAttributes = ( versionId, cb ) => {
    AttributeService.getAttributes( { 'filters[version]': versionId } )
      .then( response => cb( response.errors, response.data ? response.data.data : [] ) );
  };

  getCemAttributes = ( versionId, cb ) => {
    AttributeCemService.getAttributes( { 'filters[version]': versionId } )
      .then( response => cb( response.errors, response.data ? response.data.data : [] ) );
  };

  getServiceAttributes = ( versionId, cb ) => {
    AttributeServiceService.getAttributes( { 'filters[version]': versionId } )
      .then( response => cb( response.errors, response.data ? response.data.data : [] ) );
  };

  getMediaAttributes = ( versionId, cb ) => {
    AttributeMediaService.getAttributes( { 'filters[version]': versionId, limit: -1 } )
      .then( response => cb( response.errors, response.data ? response.data.data : [] ) );
  };

  getVariables = ( versionId, cb ) => {
    GlobalVariableService.getVariables( { 'filters[version]': versionId } )
      .then( response => cb( response.errors, response.data ? response.data.data : [] ) );
  };

  submitForm = ( data ) => {
    const { toggleLoading, toggleAlert } = this.props;
    toggleLoading( true );

    async.parallel( {
      categories: this.saveCategories.bind( this, data ),
      serviceTypes: this.saveServiceTypes.bind( this, data ),
      productAttributes: this.saveProductAttributes.bind( this, data ),
      serviceAttributes: this.saveServiceAttributes.bind( this, data ),
      cemAttributes: this.saveCemAttributes.bind( this, data ),
      mediaAttributes: this.saveMediaAttributes.bind( this, data ),
      variables: this.saveVariables.bind( this, data ),
    }, ( error ) => {
      toggleLoading( false );
      if ( error ) {
        toggleAlert( error );
      } else {
        toggleAlert( 'dataSaved', 'info' );
      }
    } );
  };

  saveCategories = ( data, cb ) => {
    const categories = _.map( data.categories, item => ( {
      id: item.id,
      name: item.name,
      active: item.active,
    } ) );

    CategoryService.updateCategories( { categories } )
      .then( response => cb( response.errors ) );
  };

  saveServiceTypes = ( data, cb ) => {
    const serviceTypes = _.map( data.serviceTypes, item => ( {
      id: item.id,
      name: item.name,
      active: item.active,
    } ) );

    ServiceTypeService.updateServiceTypes( { serviceTypes } )
      .then( response => cb( response.errors ) );
  };

  saveProductAttributes = ( data, cb ) => {
    const productAttributes = _.map( data.productAttributes, item => ( {
      id: item.id,
      name: item.name,
      active: item.active,
    } ) );

    AttributeService.saveAttributes( { attributes: productAttributes } )
      .then( response => cb( response.errors ) );
  };

  saveServiceAttributes = ( data, cb ) => {
    const attributesService = _.map( data.serviceAttributes, item => ( {
      id: item.id,
      name: item.name,
      active: item.active,
    } ) );

    AttributeServiceService.saveAttributes( { attributesService } )
      .then( response => cb( response.errors ) );
  };

  saveCemAttributes = ( data, cb ) => {
    const attributesCEM = _.map( data.cemAttributes, item => ( {
      id: item.id,
      name: item.name,
      active: item.active,
    } ) );

    AttributeCemService.saveAttributes( { attributesCEM } )
      .then( response => cb( response.errors ) );
  };

  saveMediaAttributes = ( data, cb ) => {
    const attributesMedia = _.map( [
      ...data.mediaAttributesRadio,
      ...data.mediaAttributesTv,
      ...data.mediaAttributesOutdoor,
      ...data.mediaAttributesInternet,
    ], item => ( {
      id: item.id,
      mediaChannel: item.mediaChannel,
      description: item.description,
    } ) );

    AttributeMediaService.saveAttributes( { attributesMedia } )
      .then( response => cb( response.errors ) );
  };

  saveVariables = ( data, cb ) => {
    const globalVariables = _.map( [...data.generalVariables, ...data.costVariables], item => ( {
      id: item.id,
      name: item.name,
      value: item.value,
    } ) );

    GlobalVariableService.updateVariables( { globalVariables } )
      .then( response => cb( response.errors ) );
  };

  createNewVersion = () => {
    const { toggleLoading, toggleAlert } = this.props;
    toggleLoading( true );
    VersionVariableService.saveVersion()
      .then( ( response ) => {
        if ( !response.ok ) {
          toggleLoading( false );
          toggleAlert( response.error );
        } else {
          this.toggleNewVersionModal();
          this.getData();
        }
      } );
  };

  toggleNewVersionModal = () => {
    this.setState( previousState => ( { newVersionModal: !previousState.newVersionModal } ) );
  };

  getReport = () => {
    const { toggleAlert, toggleLoading } = this.props;
    const { currentVersion } = this.state;
    toggleLoading( true );

    ReportService.getAdminReport( currentVersion ).then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) return toggleAlert( response.errors );
      window.location.assign( response.data.url );
    } );
  };

  render() {
    const {
      data, attributes, attributesID, attributesIDAchievable, attributesIDFormula, currentVersion,
      versions, newVersionModal, selectedVariable, selectedAttribute, selectedAttributeID,
      selectedCategory, selectedCategoryID,
    } = this.state;

    return (
      <div className="app-wrapper">
        <Form
          initialValues={data}
          onSubmit={this.submitForm}
          mutators={{ ...arrayMutators }}
          render={( { handleSubmit, form, values } ) => (
            <form onSubmit={handleSubmit}>
              <ContainerHeader
                title="params"
                rightContent={(
                  <div className="col-md-8">
                    <div className="row justify-content-start justify-content-sm-end">
                      <div className="col-md-4 p-0 mb-1 mb-sm-0 pr-sm-1">
                        <Select
                          isClearable={false}
                          translateOptions={false}
                          marginContainer={false}
                          containerClass="mr-2"
                          labelKey="version"
                          options={versions}
                          onChange={( version ) => {
                            this.setState( { currentVersion: version } );
                            this.getVersionData( version );
                          }}
                          value={currentVersion}
                        />
                      </div>

                      <NewEntityBtn
                        label="newVersion"
                        className="mb-1 mb-sm-0"
                        onClick={this.toggleNewVersionModal}
                      />

                      <Button
                        variant="contained"
                        color="primary"
                        className="jr-btn jr-btn-lg"
                        type="submit"
                      >
                        <i className="fa fa-save" />
                        <IntlMessages id="saveBtn" />
                      </Button>

                      <Button
                        variant="contained"
                        className="jr-btn jr-btn-lg bg-blue-grey text-white ml-3"
                        onClick={this.getReport}
                      >
                        <i className="fa fa-chart-line" />
                        <IntlMessages id="report" />
                      </Button>
                    </div>
                  </div>
                )}
              />

              <div className="row">
                <div className="col-12 col-lg-4">
                  <CardBox
                    styleName="col-12 p-0"
                    cardStyle="jr-full-card m-0"
                    headingStyle="mb-0 pb-3"
                    heading={[
                      <i
                        key={1}
                        className="zmdi zmdi-ticket-star zmdi-hc-lg text-warning mr-2"
                      />,
                      <IntlMessages id="categoriesServices" key={2} />,
                    ]}
                  >
                    <div className="table-responsive-material pb-2" style={{ maxHeight: 400 }}>
                      <table className="table custom-table remove-table-border table-sm mb-0">
                        <tbody>
                          <CategoriesServicesCard
                            title="category"
                            fieldName="categories"
                            values={values.categories}
                          />

                          <CategoriesServicesCard
                            title="service"
                            fieldName="serviceTypes"
                            values={values.serviceTypes}
                          />
                        </tbody>
                      </table>
                    </div>
                  </CardBox>
                </div>

                <div className="col-12 col-lg-4">
                  <CheckboxCard
                    title="productVariables"
                    iconClass="zmdi-view-module text-primary"
                    fieldName="productAttributes"
                    values={values.productAttributes}
                  />
                </div>

                <div className="col-12 col-lg-4">
                  <CheckboxCard
                    title="serviceVariables"
                    iconClass="zmdi-label text-blue-grey"
                    fieldName="serviceAttributes"
                    values={values.serviceAttributes}
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-12 col-lg-4">
                  <CheckboxCard
                    title="cemVariables"
                    iconClass="zmdi-view-day text-dark"
                    fieldName="cemAttributes"
                    values={values.cemAttributes}
                  />
                </div>

                <div className="col-12 col-lg-4">
                  <VariablesCard
                    title="generalVariables"
                    fieldName="generalVariables"
                    variables={values.generalVariables}
                    iconClass="zmdi-chart-donut text-blue-grey"
                  />
                </div>

                <div className="col-12 col-lg-4">
                  <VariablesCard
                    title="costVariables"
                    fieldName="costVariables"
                    variables={values.costVariables}
                    iconClass="zmdi-money-box text-danger"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-lg-6 mt-3">
                  <AttributeMediaCard
                    title="mediaAttributesRadio"
                    iconClass="zmdi-radio text-blue-grey"
                    fieldName="mediaAttributesRadio"
                    values={values.mediaAttributesRadio}
                  />
                </div>
                <div className="col-12 col-lg-6 mt-3">
                  <AttributeMediaCard
                    title="mediaAttributesTv"
                    iconClass="zmdi-tv text-primary"
                    fieldName="mediaAttributesTv"
                    values={values.mediaAttributesTv}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-lg-6 mt-3">
                  <AttributeMediaCard
                    title="mediaAttributesInternet"
                    iconClass="zmdi-cloud-box text-danger"
                    fieldName="mediaAttributesInternet"
                    values={values.mediaAttributesInternet}
                  />
                </div>
                <div className="col-12 col-lg-6 mt-3">
                  <AttributeMediaCard
                    title="mediaAttributesOutdoor"
                    iconClass="zmdi-badge-check text-dark"
                    fieldName="mediaAttributesOutdoor"
                    values={values.mediaAttributesOutdoor}
                  />
                </div>
              </div>
            </form>
          )}
        />

        <AttributeCostParams
          options={data}
          data={attributes}
          selectedVariable={selectedVariable}
          selectedCategory={selectedCategory}
          selectedAttribute={selectedAttribute}
          getAttributeDetail={this.getAttributeDetail}
        />

        <AttributeIDCostParams
          options={data}
          data={{ attributesID, attributesIDAchievable, attributesIDFormula }}
          selectedCategory={selectedCategoryID}
          selectedAttribute={selectedAttributeID}
          getAttributeDetail={this.getAttributeIDDetail}
        />

        <ConfirmationModal
          open={newVersionModal}
          title="confirmCreateVersionMsg"
          confirmBtnText="yesModalBtn"
          onSubmit={this.createNewVersion}
          onClose={this.toggleNewVersionModal}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps,
)( Params );
