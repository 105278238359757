import React, { useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import _ from 'lodash';

import { TextField } from 'components/custom/FormElements';

export default ( { values, data, fieldName, renderField } ) => {
  const [activeRoundColumn, setActiveRoundColumn] = useState();

  const renderTd = field => (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <td
      style={{ width: 100 }}
      onClick={( e ) => {
        e.stopPropagation();
        setActiveRoundColumn( field );
      }}
    >
      {activeRoundColumn === field
        ? (
          renderField ? renderField( field ) : (
            <TextField
              marginContainer={false}
              field={field}
              autoFocus
              type="number"
            />
          )
        )
        : <span>{_.get( values, field )}</span>}
    </td>
  );

  return (
    <div className="table-responsive-material pb-2" style={{ maxHeight: 450 }}>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <table
        className="table custom-table custom-table-border table-sm mb-0"
        onClick={() => {
          setActiveRoundColumn( null );
        }}
      >
        <thead className="">
          <tr>
            <td><strong>Round</strong></td>
            {_.map( new Array( 10 ), ( item, index ) => (
              <td key={index}>
                <strong>{index + 1}</strong>
              </td>
            ) )}
          </tr>
        </thead>
        <tbody>
          <FieldArray name={fieldName}>
            {( { fields } ) => (
              fields.map( ( round, index ) => (
                <tr
                  tabIndex={-1}
                  key={data[index].id}
                >
                  <td>
                    <div className="py-2 d-flex flex-row align-items-center">
                      <div className="">
                        <h5 className="m-0">
                          {data[index].round}
                        </h5>
                      </div>
                    </div>
                  </td>
                  {renderTd( `${round}.value1` )}
                  {renderTd( `${round}.value2` )}
                  {renderTd( `${round}.value3` )}
                  {renderTd( `${round}.value4` )}
                  {renderTd( `${round}.value5` )}
                  {renderTd( `${round}.value6` )}
                  {renderTd( `${round}.value7` )}
                  {renderTd( `${round}.value8` )}
                  {renderTd( `${round}.value9` )}
                  {renderTd( `${round}.value10` )}
                </tr>
              ) )
            )}
          </FieldArray>
        </tbody>
      </table>
    </div>
  );
};
