import api from '../config/Api';

const endpoint = 'v1/license';

export default {

  getLicenseByToken( token ) {
    return api.get( `${endpoint}-token/${token}` );
  },

  updateLicenseByToken( token, data ) {
    return api.put( `${endpoint}-token/${token}`, data );
  },
};
