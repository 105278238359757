import React from 'react';
import ReactTable from 'react-table';
import _ from 'lodash';

import TablePagination from '../Pagination';

class AsyncTable extends React.Component {

  getData = ( params ) => {
    const apiParams = {
      limit: params.pageSize,
      offset: ( params.page * params.pageSize ) + 1,
    };
    if( params.sorted.length ) apiParams[`sortby[${params.sorted[0].id}]`] = params.sorted[0].desc ? 'DESC' : 'ASC';

    this.props.onFetchData( apiParams );
  };

  render() {
    const { className, showPageSizeOptions, ...rest } = this.props;

    return (
      <ReactTable
        className={`app-table -highlight ${className}`}
        defaultPageSize={20}
        manual
        minRows={rest.data.length}
        rowsText={''}
        pageSizeOptions={[20, 40, 60]}
        resizable={false}
        showPaginationBottom={false}
        showPaginationTop
        getTheadFilterThProps={() => ( { style: { position: 'inherit', overflow: 'inherit' } } )}
        showPageSizeOptions={_.isUndefined( showPageSizeOptions ) ? true : showPageSizeOptions}
        PaginationComponent={props => <TablePagination {...props} />}
        {...rest}
        onFetchData={this.getData}
        getTrProps={() => {
          return {
            className: 'pointer'
          };
        }}
      />
    );
  }
}

export default AsyncTable;
