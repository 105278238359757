import React from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core';
import {
  CartesianGrid,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ComposedChart,
} from 'recharts';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import async from 'async';

import IntlMessages from 'utils/IntlMessages';
import { Buttons, TextField } from 'components/custom/FormElements';
import CardBox from 'components/theme/CardBox';
import Table from 'components/custom/Table/Custom';
import SettingsActions from 'store/reducers/Settings';
import AxisTick from 'components/custom/Charts/AxisTick';
import ContainerHeader from 'components/theme/ContainerHeader';
import PopService from 'services/Pop';
import { composeValidators, greaterThanValue, required } from 'config/InputErrors';
import Util from 'utils/Util';

class Historical extends React.Component {
  state = {
    data: {},
    historical: [],
  };

  componentDidMount() {
    const { toggleAlert, user } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }
    this.activeSession = user.activeSession;
    this.getData();
  }

  getData = () => {
    const { toggleAlert, toggleLoading } = this.props;
    toggleLoading( true );

    async.parallel( {
      pop: this.getCurrentPop,
      historical: this.getHistorical,
    }, ( error, results ) => {
      toggleLoading( false );
      if ( error ) {
        return toggleAlert( 'ERROR' );
      }

      this.setState( { ...results, data: { pop: results.pop ? results.pop.pop : null } } );
    } );
  };

  getCurrentPop = ( cb ) => {
    PopService.getCurrentPop( this.activeSession.id,
      { workgroup: this.license.workGroup.id } )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        return cb( null, response.data );
      } );
  };

  getHistorical = ( cb ) => {
    PopService.getPop( this.activeSession.id,
      { workgroup: this.license.workGroup.id } )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        return cb( null, response.data );
      } );
  };

  submitForm = ( formData ) => {
    const { toggleAlert, toggleLoading } = this.props;

    toggleLoading( true );

    PopService.updatePop( this.activeSession.id, {
      pop: formData.pop,
      workgroup: this.license.workGroup.id,
    } ).then( ( response ) => {
      if ( !response.ok ) {
        toggleLoading( false );
        return toggleAlert( response.errors );
      }

      toggleAlert( 'dataSaved', 'info' );
      this.getData();
    } );
  };

  render() {
    const { data, historical } = this.state;

    return (
      <Form
        onSubmit={this.submitForm}
        initialValues={data}
        render={( { handleSubmit } ) => (
          <form onSubmit={handleSubmit}>
            <ContainerHeader
              title="distribution"
              rightContent={(
                <Buttons cancelBtn={false} />
              )}
            />

            <div className="row">
              <div className="col-md-12">
                <CardBox styleName="p-0">
                  <div className="row">
                    <div className="col-md-4">
                      <TextField
                        field="pop"
                        label="pop"
                        type="number"
                        marginContainer={false}
                        validate={composeValidators( required, greaterThanValue( 0 ) )}
                        translateValues={{ value: 0 }}
                      />
                    </div>
                  </div>
                </CardBox>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6">
                <ExpansionPanel defaultExpanded className="mb-3 expansion-panel">
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><IntlMessages id="rounds" /></Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className="p-0 d-block">
                    <div className="col-12 p-0">
                      {historical.length === 0
                        ? (
                          <div
                            className="text-muted h-100 d-flex align-items-center justify-content-center p-3"
                          >
                            No rounds found
                          </div>
                        )
                        : (
                          <Table
                            data={historical}
                            pageSize={historical.length}
                            showPaginationTop={false}
                            columns={[
                              {
                                Header: <IntlMessages id="round" />,
                                accessor: 'roundName',
                                Cell: ( { value, original } ) => `${value} ${original.startDateInMonth || ''}`,
                              },
                              {
                                Header: <IntlMessages id="pop" />,
                                accessor: 'pop',
                                maxWidth: 100,
                                Cell: ( { value } ) => Util.formatCurrency( value ),
                              },
                              {
                                Header: <IntlMessages id="totalSalesDistribution" />,
                                accessor: 'totalTradeSales',
                                Cell: ( { value } ) => Util.formatNumber( value ),
                              },
                              {
                                Header: <IntlMessages id="totalMarginDistribution" />,
                                accessor: 'totalTradeMargin',
                                Cell: ( { value } ) => Util.formatCurrency( value ),
                              },
                              {
                                Header: <IntlMessages id="balance" />,
                                accessor: 'tradeBalance',
                                Cell: ( { value } ) => Util.formatCurrency( value ),
                                maxWidth: 80,
                              },
                            ]}
                          />
                        )}
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
              <div className="col-md-6">
                <CardBox
                  styleName="mt-3 mt-lg-0"
                  heading="historic"
                >
                  <ResponsiveContainer width="100%" height={200}>
                    <ComposedChart
                      data={historical}
                      margin={{
                        left: -10,
                        right: 20,
                        top: 10,
                      }}
                    >
                      <XAxis
                        interval={0}
                        dataKey="roundName"
                        height={60}
                        tick={<AxisTick />}
                      />
                      <YAxis />
                      <Tooltip />
                      <Legend verticalAlign="top" />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Line
                        type="monotone"
                        name="Trade Margin"
                        dataKey="totalTradeMargin"
                        stroke="#59AA2B"
                      />
                      <Line
                        type="monotone"
                        name="Trade Sales"
                        dataKey="totalTradeSales"
                        stroke="#ffc658"
                      />
                      <Line
                        type="monotone"
                        name="POP"
                        dataKey="pop"
                        stroke="#3367d6"
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                </CardBox>
              </div>
            </div>
          </form>
        )}
      />
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( Historical );
