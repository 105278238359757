import api from '../config/Api';

const endpoint = 'v1/session';

export default {

  getCommodities( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/commodity`, params );
  },

  getUnitSalesMargin( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/commodity/units-sales-margin`, params );
  },

  getGlobalAchievement( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/commodity/global-achievement`, params );
  },

  updateCommodityTarget( sessionId, data ) {
    return api.put( `${endpoint}/${sessionId}/commodity/target`, data );
  },

  simulateData( sessionId, data ) {
    return api.post( `${endpoint}/${sessionId}/commodity/price-simulation`, data );
  },

  updatePrices( sessionId, data ) {
    return api.put( `${endpoint}/${sessionId}/commodity/prices`, data );
  },
};
