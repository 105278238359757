import React from 'react';
import { FieldArray } from 'react-final-form-arrays';

import { required } from 'config/InputErrors';
import { TextField, Checkbox } from 'components/custom/FormElements';
import IntlMessages from 'utils/IntlMessages';

export default ( { title, fieldName, values } ) => (
  <FieldArray name={fieldName}>
    {( { fields } ) => (
      fields.map( ( field, index ) => (
        <tr
          tabIndex={-1}
          key={values[index].id}
        >
          <td width="5%" className="pl-0">
            {values[index].id === values[values.length - 1].id
            && (
              <Checkbox
                marginContainer={false}
                fullWidth={false}
                field={`${field}.active`}
              />
            )}
          </td>
          <td>
            <div className="py-2 d-flex flex-row align-items-center">
              <div className="">
                <h5 className="m-0">
                  <IntlMessages id={title} />
                  {' '}
                  {index + 1}
                </h5>
              </div>
            </div>
          </td>
          <td>
            <TextField
              marginContainer={false}
              field={`${field}.name`}
              validate={required}
            />
          </td>
        </tr>
      ) )
    )}
  </FieldArray>
);
