import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Error404 from 'components/theme/Error404';
import Dashboard from './Dashboard';
import Edit from './Edit';
import Detail from './Detail';

const Crm = ( { match } ) => (
  <Switch>
    <Route
      path={`${match.url}/new/campaign`}
      component={Edit}
    />
    <Route
      path={`${match.url}/campaign/:id/clone`}
      component={Edit}
    />
    <Route
      path={`${match.url}/campaign/:id`}
      component={Detail}
    />
    <Route
      path={match.url}
      component={Dashboard}
      exact
    />
    <Route component={Error404} />
  </Switch>
);

export default Crm;
