import React from 'react';

import CardBox from 'components/custom_v2/CardBox';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import _ from 'lodash';
import { connect } from 'react-redux';
import DashboardStudentService from 'services/DashboardStudent';
import ChartModal from 'routes/App/components/User/Chart';
import { Row, Col } from 'reactstrap';
import SessionService from 'services/Session';
import Util from 'utils/Util';
import BreadcrumbComp from 'components/custom_v2/Breadcrumb';
import CompPAndL from './CompPAndL';
import CompCashAndDebt from './CompCashAndDebt';


class CompanyChart extends React.Component {
  state = {
    CompModalB: false,
    chart1Modal: false,
    chart2Modal: false,
    chart3Modal: false,
  };
  seriesTitle1 = [];
  seriesTitleEarnings = [];
  seriesTitleRevenues = [];
  componentDidMount() {
    const { user } = this.props;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );

    /**
     * Call to endpointstock-value/all-workgroup
     */
    DashboardStudentService.getStockValueAllWorkgroup( user.activeSession.id )
      .then( ( response ) => {
        if ( response.ok ) {
          const roundsX = [];
          const dataWG = [];

          /**
           * Fill dataWG variable WorkGroup for cash and dept component
           */
          _.map( response.data, ( item ) => {
            const dataWGToPush = {
              cash: item.cash,
              name: item.name,
              outstandingDebt: item.outstandingDebt,
            };
            dataWG.push( dataWGToPush );

            /**
             * Fill StockPrices Var
             */
            _.map( item.historical, ( round ) => {
              const exist = _.findIndex( roundsX, { id: round.round.id } );
              if ( exist === -1 ) {
                const dataToPush = { attribute: round.round.shortName, id: round.round.id };
                dataToPush[item.id] = round.stockValue;
                roundsX.push( dataToPush );
              } else {
                roundsX[exist][item.id] = round.stockValue;
              }
            } );
            this.seriesTitle1.push( item.name );
          } );

          this.config3 = {
            data: roundsX,
            type: am4charts.XYChart,
            xAxes: [{
              type: 'CategoryAxis',
              dataFields: { category: 'attribute' },
            }],
            yAxes: [{ type: 'ValueAxis' }],
            series: _.map( response.data, itemX => ( {
              type: 'LineSeries',
              dataFields: {
                valueY: itemX.id,
                categoryX: 'attribute',
              },
              name: itemX.name,
              strokeWidth: 2,
            } ) ),
          };
          this.chart3 = am4core.createFromConfig( _.cloneDeep( this.config3 ), 'chartStockdiv' );
          this.setState( ( { dataWG } ) );
        }
      } );

    DashboardStudentService.getPLComparison( user.activeSession.id,
      { limit: -1 } )
      .then( ( responsePL ) => {
        if ( responsePL.ok ) {
          this.setState( { data: responsePL.data } );
        }
      } );
    DashboardStudentService.getPLComparison( user.activeSession.id,
      { limit: -1 } )
      .then( ( response ) => {
        if ( response.ok ) {
          /**
           * Fill Revenues Variables for config Graph
           */
          const roundsR = [];
          _.map( response.data, ( item ) => {
            _.map( item.historical, ( round ) => {
              const exist = _.findIndex( roundsR, { id: round.round.id } );
              if ( exist === -1 ) {
                const dataToPush = { attribute: round.round.shortName, id: round.round.id };
                dataToPush[item.workgroup.id] = round.revenues;
                roundsR.push( dataToPush );
              } else {
                roundsR[exist][item.workgroup.id] = round.revenues;
              }
            } );
            this.seriesTitleRevenues.push( item.workgroup.name );
          } );

          this.config = {
            data: roundsR,
            type: am4charts.XYChart,
            xAxes: [{
              type: 'CategoryAxis',
              dataFields: { category: 'attribute' },
            }],
            yAxes: [{ type: 'ValueAxis' }],
            series: _.map( response.data, itemX => ( {
              type: 'LineSeries',
              dataFields: {
                valueY: itemX.workgroup.id,
                categoryX: 'attribute',
              },
              name: itemX.workgroup.name,
              strokeWidth: 2,
            } ) ),
          };

          /**
           * Fill Earnings Variables for Graph
           */
          const roundsE = [];
          _.map( response.data, ( item ) => {
            _.map( item.historical, ( round ) => {
              const exist = _.findIndex( roundsE, { id: round.round.id } );
              if ( exist === -1 ) {
                const dataToPush = { attribute: round.round.shortName, id: round.round.id };
                dataToPush[item.workgroup.id] = round.earnings;
                roundsE.push( dataToPush );
              } else {
                roundsE[exist][item.workgroup.id] = round.earnings;
              }
            } );
            this.seriesTitleEarnings.push( item.workgroup.name );
          } );
          this.chart = am4core.createFromConfig( _.cloneDeep( this.config ), 'chartdiv' );

          this.config2 = {
            data: roundsE,
            type: am4charts.XYChart,
            xAxes: [{
              type: 'CategoryAxis',
              dataFields: { category: 'attribute' },
            }],
            yAxes: [{ type: 'ValueAxis' }],
            series: _.map( response.data, itemX => ( {
              type: 'LineSeries',
              dataFields: {
                valueY: itemX.workgroup.id,
                categoryX: 'attribute',
              },
              name: itemX.workgroup.name,
              strokeWidth: 2,
            } ) ),
          };
          this.chart2 = am4core.createFromConfig( _.cloneDeep( this.config2 ), 'chartEarningsdiv' );
        }
      } );


    SessionService.getSession( user.activeSession.id )
      .then( ( response ) => {
        if ( response.ok ) {
          if ( response.data.workGroups ) {
            const stock = _.map( response.data.workGroups, item => ( {
              id: item.id,
              name: item.name,
              points: item.points,
              percentagePoints: item.percentagePoints,
            } ) );

            stock.sort( ( a, b ) => b.points - a.points );

            this.setState( ( { stock } ) );
          }
        }
      } );
  }
  componentWillUnmount() {
    if ( this.chart ) {
      this.chart.dispose();
    }
    if ( this.chart2 ) {
      this.chart2.dispose();
    }
    if ( this.chart3 ) {
      this.chart3.dispose();
    }
  }

  toggleCompModal = () => {
    this.setState( previousState => ( { CompModalB: !previousState.CompModalB } ) );
  };

  toggleChart1Modal = () => {
    this.setState( previousState => ( { chart1Modal: !previousState.chart1Modal } ) );
  };


  toggleChart2Modal = () => {
    this.setState( previousState => ( { chart2Modal: !previousState.chart2Modal } ) );
  };

  toggleChart3Modal = () => {
    this.setState( previousState => ( { chart3Modal: !previousState.chart3Modal } ) );
  };

  render() {
    const {
      chart1Modal, chart2Modal, chart3Modal,
      stock, data, dataWG,
    } = this.state;
    return (
      <>
        <BreadcrumbComp links={[
          {
            url: '/',
            name: 'informationDashboard',
          },
          { name: 'company' },
        ]}
        />
        <Row>
          <Col lg={9}>
            <CardBox heading="StockPricesTrend" onClick={this.toggleChart3Modal} headerBg="bg-product-laptop" icon="expand">
              <div className="jr-link" id="chartStockdiv" style={{ width: '100%', height: '250px' }} />
            </CardBox>
          </Col>
          <Col lg={3}>
            <CardBox heading="StockPrices" headerBg="bg-product-laptop">
              {_.map( stock, item => (
                <Row key={item.id}>
                  <Col lg={4} className={this.license.id === item.id ? 'font-weight-bold' : 'font-weight-normal'}>
                    {item.name}
                  </Col>
                  <Col lg={3} className={this.license.id === item.id ? 'font-weight-bold text-right' : 'font-weight-normal text-right'}>
                    {Util.formatCurrency( item.points )}
                  </Col>
                  <Col lg={3} className={this.license.id === item.id ? 'font-weight-bold text-right pr-0' : 'font-weight-normal text-right pr-0'}>
                    {`${item.percentagePoints}%`}
                  </Col>
                  {item.percentagePoints && item.percentagePoints < 0 && (
                    <Col lg={2} className="text-right"><i className="fa fa-arrow-down text-danger mx-2" /></Col>
                  )}
                  {item.percentagePoints && item.percentagePoints > 0 && (
                    <Col lg={2} className="text-right"><i className="fa fa-arrow-up text-primary mx-2" /></Col>
                  )}
                  {item.percentagePoints && item.percentagePoints === 0 && (
                    <Col lg={2} className="text-right"><i className="fa fa-arrow-right text-light mx-2" /></Col>
                  )}
                </Row>
              ) )}
            </CardBox>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>

            <CardBox heading="Revenues" onClick={this.toggleChart1Modal} headerBg="bg-product-laptop">
              <div className="jr-link" id="chartdiv" style={{ width: '100%', height: '250px' }} />
            </CardBox>

          </Col>
          <Col lg={6}>
            <CardBox heading="Earnings" onClick={this.toggleChart2Modal} headerBg="bg-product-laptop">
              <div className="jr-link" id="chartEarningsdiv" style={{ width: '100%', height: '250px' }} />
            </CardBox>
          </Col>
        </Row>

        <CompCashAndDebt data={dataWG} />
        <CompPAndL data={data} />

        <ChartModal
          open={chart1Modal}
          title="Revenues"
          onClose={this.toggleChart1Modal}
          seriesTitle={this.seriesTitleRevenues}
          config={this.config}
        />
        <ChartModal
          open={chart2Modal}
          title="Earnings"
          onClose={this.toggleChart2Modal}
          seriesTitle={this.seriesTitleEarnings}
          config={this.config2}
        />
        <ChartModal
          open={chart3Modal}
          title="StockPricesTrend"
          onClose={this.toggleChart3Modal}
          seriesTitle={this.seriesTitle1}
          config={this.config3}
        />
      </>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

export default connect( mapStateToProps )( CompanyChart );
