import React from 'react';
import Select from 'react-select/async';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const customStyles = {
  control: () => ( {
    display: 'flex',
    alignItems: 'center',
    border: 0,
    height: 'auto',
    minWidth: '50px',
    background: 'transparent',
    ':hover': { boxShadow: 'none' },
  } ),
  clearIndicator: provided => ( {
    ...provided,
    cursor: 'pointer',
    color: '#6c757d'
  } ),
  singleValue: provided => ( {
    ...provided,
    marginLeft: 0,
  } ),
  valueContainer: provided => ( {
    ...provided,
    paddingLeft: '0px',
    cursor: 'pointer',
  } ),
  menu: provided => ( {
    ...provided,
    zIndex: '2000'
  } ),
  container: provided => ( {
    ...provided,
    height: '32px !important',
    padding: '0 !important',
  } ),
  input: provided => ( {
    ...provided,
    marginLeft: 0,
    paddingBottom: '4.7px',
  } ),
  placeholder: provided => ( {
    ...provided,
    marginLeft: 0,
    paddingBottom: '4.7px',
  } ),
  indicatorSeparator: provided => ( {
    ...provided,
    width: 0,
  } ),
  multiValueRemove: provided => ( {
    ...provided,
    cursor: 'pointer',
  } ),
};

function SelectWrapper( props ) {
  return (
    <Select
      {...props}
      isClearable
      cacheOptions
      classNamePrefix="react-select"
      styles={customStyles}
      components={{
        DropdownIndicator: arrowProps => (
          arrowProps.isFocused ? <ArrowDropUpIcon className="pointer"/> : <ArrowDropDownIcon className="pointer"/>
        ),
        NoOptionsMessage: () => <div className="p-2 text-muted">No results found</div>,
      }}
    />
  );
}

export default SelectWrapper;
