import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import _ from 'lodash';
import arrayMutators from 'final-form-arrays';
import { Row, Col } from 'reactstrap';
import { Button, Divider, Step, StepLabel, Stepper } from '@material-ui/core';
import { FieldArray } from 'react-final-form-arrays';
import classnames from 'classnames';
import async from 'async';

import CardBox from 'components/custom_v2/CardBox';
import BreadcrumbComp from 'components/custom_v2/Breadcrumb';
import {
  TextField,
  Select,
  RadioButtons,
  Checkboxes,
  Checkbox,
} from 'components/custom/FormElements';
import SettingsActions from 'store/reducers/Settings';
import IntlMessages from 'utils/IntlMessages';
import { composeValidators, greaterThan, required } from 'config/InputErrors';
import Util from 'utils/Util';
import RoundService from 'services/Round';
import AttributeMediaService from 'services/AttributeMedia';
import CommodityService from 'services/Commodity';
import AttributeService from 'services/Attribute';
import AttributeServiceService from 'services/AttributeService';
import CommunicationService from 'services/Communication';
import CampaignDetail from 'routes/App/components/User/Campaign/Detail';

const contentTypes = [
  {
    id: 'BRANDING',
    value: 'branding',
  },
  {
    id: 'PRODUCT',
    value: 'productPromotion',
  },
  {
    id: 'SERVICE',
    value: 'servicePromotion',
  },
  {
    id: 'BUNDLE',
    value: 'bundlePromotion',
  },
  {
    id: 'REGISTER_PRODUCT',
    value: 'productRegistration',
  },
];

// TODO: Poner la traduccion
const internetPlans = [
  {
    id: 'social_network_display',
    label: 'socialNetworkDisplay',
    name: 'socialNetworkDisplay',
    bodyList: [
      {
        id: 'EXECUTIVES',
        value: 'executives',
        type: 'segmentation',
      },
      {
        id: 'PROFESSIONAL',
        value: 'Professionals',
        type: 'segmentation',
      },
      {
        id: 'EMPLOYEES',
        value: 'Employees',
        type: 'segmentation',
      },
      {
        id: 'STUDENTS',
        value: 'Students',
        type: 'segmentation',
      },
      {
        id: 'HOME',
        value: 'Home',
        type: 'segmentation',
      },
    ],
  },
  {
    id: 'search',
    name: 'search',
    label: 'searchInternetPlan',
    bodyLabel: 'searchingFor',
    bodyList: [
      {
        id: 'LAPTOPS',
        value: 'laptops',
      },
      {
        id: 'CELL_PHONES',
        value: 'cellPhones',
      },
      {
        id: 'TABLETS',
        value: 'tablets',
      },
      {
        id: 'SERIES_MOVIES',
        value: 'series&Movies',
      },
      {
        id: 'MUSIC',
        value: 'music',
      },
      {
        id: 'NEWS',
        value: 'news',
      },
    ],
  },
  {
    id: 'retargeting',
    name: 'retargeting',
    label: 'retargeting',
    bodyLabel: 'retargetPeopleLabel',
    bodyList: [
      {
        id: 'LAPTOPS',
        value: 'laptops',
      },
      {
        id: 'CELL_PHONES',
        value: 'cellPhones',
      },
      {
        id: 'TABLETS',
        value: 'tablets',
      },
      {
        id: 'SERIES_MOVIES',
        value: 'series&Movies',
      },
      {
        id: 'MUSIC',
        value: 'music',
      },
      {
        id: 'NEWS',
        value: 'news',
      },
    ],
  },
  {
    id: 'artificial_intelligence',
    name: 'artificialIntelligence',
    label: 'artificialIntelligence',
    bodyLabel: 'peoplePosting',
    bodyList: [
      {
        id: 'POSITIVE_SENTIMENT',
        value: 'positiveSentiment',
      },
      {
        id: 'NEGATIVE_SENTIMENT',
        value: 'negativeSentiment',
      },
    ],
    bodyListLevel2: [
      {
        id: 'OUR_BRANDS',
        value: 'aboutOurBrand',
      },
      {
        id: 'OTHER_BRANDS',
        value: 'aboutOtherBrands',
      },
    ],
  },
];

const Header = ( { title } ) => (
  <div className="p-2 bg-primary">
    <h4 className="card-heading m-0 text-white">
      <IntlMessages id={title} />
    </h4>
  </div>
);

class EditCampaign extends React.Component {
  state = {
    rounds: [],
    products: [],
    services: [],
    bundles: [],
    productAttributes: [],
    mediaAttributes: [],
    serviceAttributes: [],
    activeStep: 0,
    costIndex: { value: 1 },
    mediaCostLabel: [],
    detailData: {},
    steps: [
      {
        id: 1,
        label: 'info',
      },
      {
        id: 3,
        label: 'media',
      },
      {
        id: 4,
        label: 'internet',
      },
      {
        id: 5,
        label: 'finalConfirmation',
      },
    ],
  };
  entityId = null;

  componentDidMount() {
    const { toggleAlert, user, match } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }


    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }
    this.activeSession = user.activeSession;
    this.entityId = match.params.id;
    this.getData( this.entityId );
  }

  getData = () => {
    const { steps } = this.state;
    const { toggleLoading, toggleAlert } = this.props;
    const newSteps = _.cloneDeep( steps );

    toggleLoading( true );
    async.parallel( {
      entity: this.getEntity,
      costIndex: this.getCostIndex,
      rounds: this.getRounds,
      commodities: this.getCommodities,
      productAttributes: this.getProductAttributes,
      serviceAttributes: this.getServiceAttributes,
      mediaAttributes: this.getMediaAttributes,
    }, ( error, results ) => {
      toggleLoading( false );
      if ( error ) {
        return toggleAlert( error );
      }
      let entity = {};
      if ( this.entityId ) {
        entity = {
          ...results.entity,
          fromRound: results.entity.fromRound ? results.entity.fromRound.id : '',
          toRound: results.entity.toRound ? results.entity.toRound.id : '',
          mediaAttributes: _.map( results.mediaAttributes, ( item ) => {
            const selected = _.find( results.entity.monthlyBudgets,
              { attributeMedia: { id: item.id } } );
            return {
              ...item,
              selected: !!selected,
              intensity: selected && selected.intensity ? selected.intensity.toString() : null,
            };
          } ),
          internetPlans: _.cloneDeep( internetPlans ),
        };
        if ( entity.socialNetworkDisplay ) {
          entity.internetPlans[0] = {
            ...entity.internetPlans[0],
            gender: entity.socialNetworkDisplay.gender,
            ageFrom: entity.socialNetworkDisplay.ageFrom,
            ageTo: entity.socialNetworkDisplay.ageTo,
            monthlyInvestment: entity.socialNetworkDisplay.monthlyInvestment,
            body: [
              ..._.map( entity.socialNetworkDisplay.segmentation, item => ( {
                type: 'segmentation',
                id: item,
              } ) ),
            ],
          };
        }
        if ( entity.search ) {
          entity.internetPlans[1] = {
            ...entity.internetPlans[1],
            gender: entity.search.gender,
            ageFrom: entity.search.ageFrom,
            ageTo: entity.search.ageTo,
            monthlyInvestment: entity.search.monthlyInvestment,
            body: _.map( entity.search.searchingFor, body => ( { id: body } ) ),
          };
        }
        if ( entity.retargeting ) {
          entity.internetPlans[2] = {
            ...entity.internetPlans[2],
            gender: entity.retargeting.gender,
            ageFrom: entity.retargeting.ageFrom,
            ageTo: entity.retargeting.ageTo,
            monthlyInvestment: entity.retargeting.monthlyInvestment,
            body: _.map( entity.retargeting.retargetLookingFor, body => ( { id: body } ) ),
          };
        }
        if ( entity.artificialIntelligence ) {
          entity.internetPlans[3] = {
            ...entity.internetPlans[3],
            gender: entity.artificialIntelligence.gender,
            ageFrom: entity.artificialIntelligence.ageFrom,
            ageTo: entity.artificialIntelligence.ageTo,
            monthlyInvestment: entity.artificialIntelligence.monthlyInvestment,
            body: entity.artificialIntelligence.peoplePosting,
            body2: entity.artificialIntelligence.aboutBrand,
          };
        }
        if ( entity.contentType === 'PRODUCT' ) {
          entity.product = entity.product ? entity.product.id : '';
          entity.productFeature = [];
          if ( results.entity.productFeature ) {
            entity.productFeature.push( { id: results.entity.productFeature.id } );
          }
          if ( entity.productFeature2 ) {
            entity.productFeature.push( { id: results.entity.productFeature2.id } );
          }
        }
        if ( entity.contentType === 'SERVICE' ) {
          entity.service = entity.service ? entity.service.id : '';
          entity.serviceFeature = [];
          if ( results.entity.serviceFeature ) {
            entity.serviceFeature.push( { id: results.entity.serviceFeature.id } );
          }
          if ( entity.serviceFeature2 ) {
            entity.serviceFeature2.push( { id: results.entity.serviceFeature2.id } );
          }
        }
        if ( entity.contentType === 'BUNDLE' ) {
          entity.bundle = entity.bundle ? entity.bundle.id : '';
        }

        if ( entity.contentType === 'PRODUCT' || entity.contentType === 'SERVICE' || entity.contentType === 'BUNDLE' ) {
          if ( newSteps[1].id !== 2 ) {
            newSteps.splice( 1, 0, {
              id: 2,
              label: 'Promotion',
            } );
          }
        }
      }

      this.setState( {
        ...results,
        entity,
        steps: newSteps,
        products: _.filter( results.commodities, { owner: 'PRODUCT' } ),
        services: _.filter( results.commodities, { owner: 'SERVICE' } ),
        bundles: _.filter( results.commodities, { owner: 'BUNDLE' } ),
        rounds: _.map( results.rounds, round => ( {
          id: round.id,
          name: round.largeName,
        } ) ),
      }, () => this.setState( {
        detailData: this.entityId
          ? this.loadDataDetail( entity ) : {},
      } ) );
    } );
  };

  getEntity = ( cb ) => {
    if ( !this.entityId ) return cb();
    CommunicationService.getCampaign( this.activeSession.id, this.entityId,
      { workgroup: this.license.workGroup.id } )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        return cb( null, response.data );
      } );
  };

  getCostIndex = ( cb ) => {
    CommunicationService.getCostIndex( this.activeSession.id,
      { workgroup: this.license.workGroup.id } )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        return cb( null, response.data );
      } );
  };

  getRounds = ( cb ) => {
    RoundService.getRoundsBySession( this.activeSession.id, { limit: -1, 'filters[noStatus]': 'CLOSED' } )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        return cb( null, response.data.data );
      } );
  };

  getCommodities = ( cb ) => {
    CommodityService.getCommodities( this.activeSession.id, {
      workgroup: this.license.workGroup.id,
      limit: -1,
    } )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        return cb( null, response.data.data );
      } );
  };

  getProductAttributes = ( cb ) => {
    AttributeService.getAttributes( {
      'filters[session]': this.activeSession.id,
      limit: -1,
    } )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        cb( null, response.data.data );
      } );
  };

  getServiceAttributes = ( cb ) => {
    AttributeServiceService.getAttributes( {
      'filters[session]': this.activeSession.id,
      limit: -1,
    } )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        cb( null, response.data.data );
      } );
  };

  getMediaAttributes = ( cb ) => {
    AttributeMediaService.getAttributes( {
      'filters[session]': this.activeSession.id,
      'sortby[orderAttribute]': 'asc',
      limit: -1,
    } )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        cb( null, response.data.data );
      } );
  };

  submitForm = ( data ) => {
    const { steps, activeStep } = this.state;

    if ( steps[activeStep].id < 5 ) return;
    const { toggleLoading, toggleAlert, history } = this.props;
    const dataToSend = {
      workgroup: this.license.workGroup.id,
      name: data.name,
      contentType: data.contentType,
      fromRound: data.fromRound,
      toRound: data.toRound,
      monthlyBudgets: [],
      promotePurchasing: !!data.promotePurchasing,
      socialNetworkDisplay: {},
      search: {},
      retargeting: {},
      artificialIntelligence: {},
    };

    if ( data.contentType === 'PRODUCT' ) {
      dataToSend.product = data.product;
      if ( data.productFeature ) {
        if ( data.productFeature.length === 1 ) {
          dataToSend.productFeature = data.productFeature[0].id;
        }
        if ( data.productFeature.length === 2 ) {
          dataToSend.productFeature = data.productFeature[0].id;
          dataToSend.productFeature2 = data.productFeature[1].id;
        }
      }
    }
    if ( data.contentType === 'SERVICE' ) {
      dataToSend.service = data.service;
      if ( data.serviceFeature ) {
        if ( data.serviceFeature.length === 1 ) {
          dataToSend.serviceFeature = data.serviceFeature[0].id;
        }
        if ( data.serviceFeature.length === 2 ) {
          dataToSend.serviceFeature = data.serviceFeature[0].id;
          dataToSend.serviceFeature2 = data.serviceFeature[1].id;
        }
      }
    }
    if ( data.contentType === 'BUNDLE' ) {
      dataToSend.bundle = data.bundle;
    }

    if ( data.mediaAttributes ) {
      const selected = _.filter( data.mediaAttributes, { selected: true } );
      dataToSend.monthlyBudgets = _.map( selected, item => ( {
        attributeMedia: item.id,
        intensity: item.intensity,
      } ) );
    }

    _.map( data.internetPlans, ( plan ) => {
      if ( plan.id === 'social_network_display' ) {
        dataToSend.socialNetworkDisplay = {
          gender: plan.gender,
          ageFrom: plan.ageFrom,
          ageTo: plan.ageTo,
          monthlyInvestment: plan.monthlyInvestment,
          segmentation: _.chain( plan.body )
            .filter( { type: 'segmentation' } )
            .map( 'id' )
            .value(),
        };
      }
      if ( plan.id === 'search' ) {
        dataToSend.search = {
          gender: plan.gender,
          ageFrom: plan.ageFrom,
          ageTo: plan.ageTo,
          searchingFor: _.map( plan.body, 'id' ),
          monthlyInvestment: plan.monthlyInvestment,
        };
      }
      if ( plan.id === 'retargeting' ) {
        dataToSend.retargeting = {
          gender: plan.gender,
          ageFrom: plan.ageFrom,
          ageTo: plan.ageTo,
          retargetLookingFor: _.map( plan.body, 'id' ),
          monthlyInvestment: plan.monthlyInvestment,
        };
      }
      if ( plan.id === 'artificial_intelligence' ) {
        dataToSend.artificialIntelligence = {
          gender: plan.gender,
          ageFrom: plan.ageFrom,
          ageTo: plan.ageTo,
          peoplePosting: plan.body,
          aboutBrand: plan.body2,
          monthlyInvestment: plan.monthlyInvestment,
        };
      }
    } );

    toggleLoading( true );
    CommunicationService.saveCampaign( this.activeSession.id, dataToSend )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) return toggleAlert( response.errors );
        toggleAlert( 'dataSaved', 'info' );
        history.push( '/sessions/general/crm' );
      } );
  };

  loadDataDetail=( dataDetail ) => {
    const {
      rounds, products, services, bundles,
      productAttributes, serviceAttributes,
    } = this.state;
    const data = _.cloneDeep( dataDetail );
    if ( data.fromRound ) {
      const fromRound = _.find( rounds, { id: data.fromRound } );
      if ( fromRound ) data.fromRound = fromRound.name;
    }
    if ( data.toRound ) {
      const toRound = _.find( rounds, { id: data.toRound } );
      if ( toRound ) data.toRound = toRound.name;
    }
    if ( data.product ) {
      const product = _.find( products, { id: data.product } );
      if ( product ) data.product = product.name;
    }
    if ( data.service ) {
      const service = _.find( services, { id: data.service } );
      if ( service ) data.service = service.name;
    }
    if ( data.bundle ) {
      const bundle = _.find( bundles, { id: data.bundle } );
      if ( bundle ) data.bundle = bundle.name;
    }
    data.productFeature = _.map( data.productFeature, ( item ) => {
      const productAttribute = _.find( productAttributes, { id: item.id } );
      if ( productAttribute ) return { id: productAttribute.name };
      return item.id;
    } );
    data.serviceFeature = _.map( data.serviceFeature, ( item ) => {
      const serviceAttribute = _.find( serviceAttributes, { id: item.id } );
      if ( serviceAttribute ) return { id: serviceAttribute.name };
      return item.id;
    } );

    return data;
  };

  handleNext = ( data ) => {
    const { activeStep } = this.state;

    this.setState( {
      activeStep: activeStep + 1,
      detailData: this.loadDataDetail( data ),
    } );
  };

  handleBack = ( data ) => {
    const { activeStep } = this.state;
    this.setState( {
      activeStep: activeStep - 1,
      detailData: this.loadDataDetail( data ),
    } );
  };

  getStepButtons = ( form ) => {
    const { activeStep } = this.state;
    return (
      <Row>
        <Col md={4} className="m-md-auto">
          <div className="text-center">
            {activeStep > 0
            && (
              <Button
                variant="contained"
                className="jr-btn jr-btn-lg bg-blue-grey text-white"
                type="submit"
                onClick={() => {
                  if ( form.getState().valid ) this.handleBack( form.getState().values );
                }}
              >
                <i className="fa fa-angle-double-left" />
                <IntlMessages id="back" />
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              className="jr-btn jr-btn-lg"
              type="submit"
              onClick={() => {
                if ( form.getState().valid ) this.handleNext( form.getState().values );
              }}
            >
              <IntlMessages id="next" />
              <i className="fa fa-angle-double-right" />
            </Button>
          </div>
        </Col>
      </Row>
    );
  };

  getInternetCost = ( obj, objCost ) => {
    switch ( obj.id ) {
    case 'social_network_display':
      return objCost.socialNetworkCost;
    case 'search':
      return objCost.searchCost;
    case 'retargeting':
      return objCost.retargetingCost;
    case 'artificial_intelligence':
      return objCost.artIntelligenceCost;
    default:
      return 1;
    }
  }

  getMediaCost = ( defaultValue, objMediaCost ) => {
    switch ( defaultValue ) {
    case '1':
      return objMediaCost.cost;
    case '2':
      return objMediaCost.cost2;
    case '3':
      return objMediaCost.cost3;
    case '4':
      return objMediaCost.cost4;
    case '5':
      return objMediaCost.cost5;
    default:
      return 0;
    }
  }
  calcMonthyInvestment( monthlyInvestment, internetPlan, costIndex ) {
    // eslint-disable-next-line max-len
    const result = Math.round( ( ( monthlyInvestment / this.getInternetCost( internetPlan, costIndex ) ) * 10 ) / 10 );
    return result;
  }
  render() {
    const {
      entity, rounds, products, services, bundles, activeStep, steps,
      productAttributes, mediaAttributes, costIndex, mediaCostLabel,
      detailData,
    } = this.state;

    return (
      <>
        <BreadcrumbComp links={[
          {
            url: '/',
            name: 'decisionDashboard',
          },
          {
            url: '/sessions/general/crm',
            name: 'crm',
          },
          { name: 'newCampaign' },
        ]}
        />

        <CampaignDetail data={detailData} />

        <Form
          initialValues={{
            mediaAttributes,
            internetPlans,
            ...entity,
          }}
          validate={( values ) => {
            const errors = {};
            const mediaAttributesErrors = [];
            if ( values.mediaAttributes && values.mediaAttributes.length ) {
              _.map( values.mediaAttributes, ( item, index ) => {
                if ( !item.selected ) return;

                if ( !item.intensity ) {
                  mediaAttributesErrors[index] = {};
                  mediaAttributesErrors[index].intensity = 'fieldError.required';
                }
              } );
              if ( mediaAttributesErrors.length ) {
                errors.mediaAttributes = mediaAttributesErrors;
              }
            }
            if ( steps[activeStep].id === 4 ) {
              const internetPlansErrors = [];
              // const MAX_PEOPLE_REACHED = 50000000;
              if ( values.internetPlans && values.internetPlans.length ) {
                _.map( values.internetPlans, ( item, index ) => {
                  if ( item.id === 'social_network_display'
                    && !item.monthlyInvestment
                    && ( item.ageFrom || item.ageTo || item.gender || (
                      item.body && item.body.length ) ) ) {
                    internetPlansErrors[index] = {};
                    internetPlansErrors[index].monthlyInvestment = 'fieldError.required';
                  }
                  if ( item.id !== 'social_network_display'
                    && !item.monthlyInvestment
                    && ( item.ageFrom || item.ageTo || item.gender || (
                      item.body && item.body.length ) ) ) {
                    internetPlansErrors[index] = {};
                    internetPlansErrors[index].monthlyInvestment = 'fieldError.required';
                  }
                  /* if ( item.monthlyInvestment > MAX_PEOPLE_REACHED ) {
                    internetPlansErrors[index] = {};
                    internetPlansErrors[index].monthlyInvestment = 'fieldError.lessOrEqualThan50M';
                  }
                  */

                  if ( item.id === 'artificial_intelligence'
                    && ( item.body && item.body.length && !item.body2 ) ) {
                    internetPlansErrors[index] = {};
                    internetPlansErrors[index].body2 = 'fieldError.required';
                  }
                } );
                if ( internetPlansErrors.length ) {
                  errors.internetPlans = internetPlansErrors;
                }
              }
            }
            return errors;
          }}
          onSubmit={this.submitForm}
          mutators={{ ...arrayMutators }}
          render={( { handleSubmit, form, values } ) => (
            <form onSubmit={handleSubmit}>
              <Stepper activeStep={activeStep} className="horizontal-stepper-linear bg-transparent">
                {steps.map( ( step, index ) => (
                  <Step key={step.id}>
                    <StepLabel
                      className={`horizontal-stepper ${index === activeStep ? 'active' : ''}`}
                    >
                      <IntlMessages id={step.label} />
                    </StepLabel>
                  </Step>
                ) )}
              </Stepper>

              {steps[activeStep].id === 1
              && (
                <>
                  <Row>
                    <Col md={6}>
                      <CardBox header={<Header title="campaignInfoDate" />}>
                        <Row>
                          <Col md={6}>
                            <TextField field="name" label="campaignName" validate={required} />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <Select
                              field="fromRound"
                              label="startDate"
                              validate={required}
                              translateOptions={false}
                              options={rounds}
                            />
                          </Col>
                          <Col md={6}>
                            <Select
                              field="toRound"
                              label="endDate"
                              translateOptions={false}
                              validate={required}
                              options={rounds}
                            />
                          </Col>
                        </Row>
                      </CardBox>
                    </Col>
                    <Col md={6}>
                      <CardBox header={<Header title="campaignMainObjective" />}>
                        <RadioButtons
                          field="contentType"
                          validate={required}
                          options={contentTypes}
                          onChange={( value ) => {
                            const newSteps = _.cloneDeep( steps );
                            if ( value === 'PRODUCT' || value === 'SERVICE' || value === 'BUNDLE' ) {
                              if ( newSteps[1].id !== 2 ) {
                                newSteps.splice( 1, 0, {
                                  id: 2,
                                  label: 'Promotion',
                                } );
                                this.setState( { steps: newSteps } );
                              }
                            } else if ( newSteps[1].id === 2 ) {
                              newSteps.splice( 1, 1 );
                              this.setState( { steps: newSteps } );
                            }
                            form.change( 'product', null );
                            form.change( 'productFeature', null );
                            form.change( 'service', null );
                            form.change( 'serviceFeature', null );
                            form.change( 'bundle', null );
                          }}
                        />
                      </CardBox>
                    </Col>
                  </Row>
                  {this.getStepButtons( form )}
                </>
              )}
              {steps[activeStep].id === 2
              && (
                <>
                  {values.contentType === 'PRODUCT'
                  && (
                    <Row>
                      <Col md={12}>
                        <CardBox
                          header={<Header title="productPromotionCampaign" />}
                          styleName=""
                        >
                          <Row>
                            <Col md={3}>
                              <p className="mb-2">
                                <IntlMessages id="selectProductPromote" />
                              </p>
                              <div className="bg-primary p-2 text-white">
                                <IntlMessages id="model" />
                              </div>
                              <div className="pl-3 pb-2 bg-gray-1">
                                <RadioButtons
                                  field="product"
                                  translate={false}
                                  marginContainer={false}
                                  valueKey="name"
                                  options={products}
                                  displayBlock
                                  validate={required}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <p className="mb-2">
                                <IntlMessages id="defineAttributePromote" />
                              </p>
                              <Checkboxes
                                marginContainer={false}
                                field="productFeature"
                                labelClassName="col-md-12 mr-0"
                                labelContainerClass="row"
                                valueKey="name"
                                options={productAttributes}
                              />
                            </Col>
                            <Col md={3} className="d-flex align-items-center">
                              <Checkbox
                                marginContainer={false}
                                field="promotePurchasing"
                                label="promotePurchasingDirectChannel"
                              />
                            </Col>
                          </Row>
                        </CardBox>
                      </Col>
                    </Row>
                  )}

                  {values.contentType === 'SERVICE'
                  && (
                    <Row>
                      <Col md={12}>
                        <CardBox
                          header={<Header title="servicePromotionCampaign" />}
                          styleName=""
                        >
                          <Row>
                            <Col md={3}>
                              <p className="mb-2">
                                <IntlMessages id="selectServicePromote" />
                              </p>
                              <div className="bg-primary p-2 text-white">
                                <IntlMessages id="model" />
                              </div>
                              <div className="pl-3 pb-2 bg-gray-1">
                                <RadioButtons
                                  field="service"
                                  translate={false}
                                  marginContainer={false}
                                  valueKey="name"
                                  options={services}
                                  displayBlock
                                  validate={required}
                                />
                              </div>
                            </Col>

                            <Col md={3} className="d-flex align-items-center">
                              <Checkbox
                                marginContainer={false}
                                field="promotePurchasing"
                                label="promotePurchasingDirectChannel"
                              />
                            </Col>
                          </Row>
                        </CardBox>
                      </Col>
                    </Row>
                  )}

                  {values.contentType === 'BUNDLE'
                  && (
                    <Row>
                      <Col md={12}>
                        <CardBox
                          header={<Header title="bundlePromotionCampaign" />}
                          styleName=""
                        >
                          <Row>
                            <Col md={3}>
                              <p className="mb-2">
                                <IntlMessages id="selectBundlePromote" />
                              </p>
                              <div className="bg-primary p-2 text-white">
                                <IntlMessages id="model" />
                              </div>
                              <div className="pl-3 pb-2 bg-gray-1">
                                <RadioButtons
                                  field="bundle"
                                  translate={false}
                                  marginContainer={false}
                                  valueKey="name"
                                  options={bundles}
                                  displayBlock
                                  validate={required}
                                />
                              </div>
                            </Col>
                            <Col md={3} className="d-flex align-items-center">
                              <Checkbox
                                marginContainer={false}
                                field="promotePurchasing"
                                label="promotePurchasingDirectChannel"
                              />
                            </Col>
                          </Row>
                        </CardBox>
                      </Col>
                    </Row>
                  )}
                  {this.getStepButtons( form )}
                </>
              )}

              {steps[activeStep].id === 3
              && (
                <>
                  <Row>
                    <Col>
                      <CardBox
                        styleName=""
                        header={(
                          <div
                            className="p-2 bg-primary text-white"
                            style={{ minHeight: '54px' }}
                          >
                            <h4 className="card-heading m-0 text-white">
                              <IntlMessages id="campaignMediaPlanMassMedia" />
                              <i
                                style={{ float: 'right' }}
                                className="fa fa-eraser fa-1x "
                                onClick={( ) => {
                                  _.map( values.mediaAttributes, ( item, index ) => {
                                    form.change( `mediaAttributes[${index}].selected`, null );
                                    form.change( `mediaAttributes[${index}].intensity`, null );
                                    mediaCostLabel[index] = 0;
                                  } );
                                }
                                }
                              />
                            </h4>
                          </div>
                        )}
                      >
                        <div className="table-responsive">
                          <table className="table table-striped custom-table-v2 mb-0">
                            <thead>
                              <tr>
                                <th style={{ width: 90 }} />
                                <th><IntlMessages id="massMedia" /></th>
                                <th>
                                  <div><IntlMessages id="intensity" /></div>
                                  <div className="mt-1">
                                    <span className="mr-5"><IntlMessages id="low" /></span>
                                    <span style={{ align: 'right', paddingLeft: '4rem' }}><IntlMessages id="high" /></span>
                                  </div>
                                </th>
                                <th>
                                  <IntlMessages id="estimatedMonthlyCost" />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <FieldArray name="mediaAttributes">
                                {( { fields } ) => (
                                  fields.map( ( mediaField, index ) => {
                                    const media = fields.value[index];
                                    const defaultValue = values.mediaAttributes[index].intensity;

                                    // eslint-disable-next-line max-len
                                    mediaCostLabel[index] = this.getMediaCost( defaultValue, media );

                                    if ( !media ) return null;
                                    return (
                                      <tr key={media.id}>
                                        <td className="td-crm">
                                          <Checkbox
                                            marginContainer={false}
                                            field={`${mediaField}.selected`}
                                            onChange={( checked ) => {
                                              if ( !checked ) {
                                                mediaCostLabel[index] = 0;
                                                fields.update( index, {
                                                  ...media,
                                                  selected: false,
                                                  regions: [],
                                                  intensity: null,
                                                } );
                                              }
                                            }}
                                          />
                                        </td>
                                        <td className="td-crm">
                                          <i className={classnames( 'fa fa-2x mr-2', {
                                            'fa-tv': media.mediaType === 'TV',
                                            'fa-broadcast-tower': media.mediaType === 'RADIO',
                                            'fa-newspaper': media.mediaType === 'INTERNET',
                                            'fa-user-tag': media.mediaType === 'OUTDOOR',
                                          } )}
                                          />
                                          {media.mediaChannel}
                                        </td>
                                        <td className="td-crm">
                                          <RadioButtons
                                            disabled={!media.selected}
                                            field={`${mediaField}.intensity`}
                                            options={[
                                              { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 },
                                            ]}
                                            onChange={( value ) => {
                                              // eslint-disable-next-line max-len
                                              mediaCostLabel[index] = this.getMediaCost( value, media );
                                            }}
                                          />
                                        </td>
                                        <td className="td-crm">
                                          {
                                            // eslint-disable-next-line max-len
                                            Util.formatCurrency( mediaCostLabel[index] ? mediaCostLabel[index] : 0 )
                                          }
                                        </td>
                                      </tr>
                                    );
                                  } ) )}

                              </FieldArray>
                              <tr>
                                <td />
                                <td />
                                <td style={{ textAlign: 'right', fontWeight: 'bold', textTransform: 'uppercase' }}>
                                  <IntlMessages id="total" />
                                </td>
                                <td className="td-crm" style={{ fontWeight: 'bold' }}>{Util.formatCurrency( _.sum( mediaCostLabel ) )}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </CardBox>
                    </Col>
                  </Row>

                  {this.getStepButtons( form )}
                </>
              )}

              {steps[activeStep].id === 4
              && (
                <>
                  <Row>
                    <Col>
                      <CardBox
                        styleName=""
                        header={<Header title="campaignMediaPlanInternetPlan" />}
                      >
                        <Row>
                          <FieldArray name="internetPlans">
                            {( { fields } ) => (
                              fields.map( ( internetPlanField, index ) => {
                                const internetPlan = fields.value[index];
                                if ( !internetPlan ) return null;

                                return (
                                  <Col md={3} key={internetPlan.id}>
                                    <CardBox
                                      styleName="card-internet"
                                      header={(
                                        <div
                                          className="p-2 bg-primary text-white"
                                          style={{ minHeight: '54px' }}
                                        >
                                          <h4 className="card-heading m-0 text-white">
                                            <IntlMessages id={internetPlan.label} />
                                            <i
                                              style={{ float: 'right' }}
                                              className="fa fa-eraser fa-1x "
                                              onClick={( ) => {
                                                form.change( `${internetPlanField}.monthlyInvestment`, null );
                                                form.change( `${internetPlanField}.gender`, null );
                                                form.change( `${internetPlanField}.ageFrom`, null );
                                                form.change( `${internetPlanField}.ageTo`, null );
                                                form.change( `${internetPlanField}.body`, null );
                                                form.change( `${internetPlanField}.body2`, null );
                                              }
                                              }
                                            />
                                          </h4>
                                        </div>
                                      )}
                                      footer={(
                                        <>
                                          <Row className="align-items-center">
                                            <Col lg={6}>
                                              <IntlMessages id="monthlyInvestment" />
                                            </Col>
                                            <Col lg={6}>
                                              <TextField
                                                field={`${internetPlanField}.monthlyInvestment`}
                                                marginContainer={false}
                                                disabled={internetPlan.id !== 'social_network_display'
                                                && !internetPlan.ageFrom
                                                && !internetPlan.ageTo
                                                && !internetPlan.gender
                                                && ( !internetPlan.body
                                                  || !internetPlan.body.length )}
                                                type="number"
                                              />
                                            </Col>
                                          </Row>
                                          <p className="mt-2">
                                            <IntlMessages id="estimatedPeopleReached" />
                                            {' '}
                                            {internetPlan.monthlyInvestment && true
                                              ? Util.formatNumber(
                                                // eslint-disable-next-line max-len
                                                this.calcMonthyInvestment( internetPlan.monthlyInvestment, internetPlan, costIndex ),
                                              ) : 0}
                                          </p>
                                        </>
                                      )}
                                    >
                                      <p className="text-center mb-2">
                                        <IntlMessages
                                          id="gender"
                                        />
                                      </p>
                                      <RadioButtons
                                        field={`${internetPlanField}.gender`}
                                        radioClassName="justify-content-around"
                                        /* validate={required} */
                                        options={[
                                          {
                                            id: 'MALE',
                                            value: 'male',
                                          }, {
                                            id: 'FEMALE',
                                            value: 'female',
                                          },
                                        ]}
                                      />

                                      <Divider className="my-3" />
                                      <p className="text-center mb-2">
                                        <IntlMessages id="age" />
                                      </p>
                                      <Row>
                                        <Col md>
                                          <TextField
                                            field={`${internetPlanField}.ageFrom`}
                                            type="number"
                                            onChange={( value ) => {
                                              if ( internetPlan.id !== 'social_network_display'
                                                && !value
                                                && !internetPlan.ageTo
                                                && !internetPlan.gender
                                                && ( !internetPlan.body
                                                  || !internetPlan.body.length ) ) {
                                                form.change( `${internetPlanField}.monthlyInvestment`, null );
                                              }
                                            }}
                                          />
                                        </Col>
                                        <Col md="auto">
                                          <IntlMessages id="ageto" />
                                        </Col>
                                        <Col md>
                                          <TextField
                                            field={`${internetPlanField}.ageTo`}
                                            type="number"
                                            validate={composeValidators( greaterThan( `${internetPlanField}.ageFrom` ) )}
                                            translateValues={{ label: 'ageFrom' }}
                                            onChange={( value ) => {
                                              if ( internetPlan.id !== 'social_network_display'
                                                && !internetPlan.ageFrom
                                                && !value
                                                && !internetPlan.gender
                                                && ( !internetPlan.body
                                                  || !internetPlan.body.length ) ) {
                                                form.change( `${internetPlanField}.monthlyInvestment`, null );
                                              }
                                            }}
                                          />
                                        </Col>
                                      </Row>

                                      <Divider className="my-3" />
                                      {internetPlan.id === 'social_network_display'
                                      && (
                                        <>
                                          <Row>
                                            <Col md>
                                              <p className="mb-2">
                                                <IntlMessages id="segmentation" />
                                              </p>
                                              <Checkboxes
                                                marginContainer={false}
                                                field={`${internetPlanField}.body`}
                                                labelClassName="col-md-6 mr-0"
                                                labelContainerClass="row"
                                                translate
                                                options={_.filter( internetPlan.bodyList, { type: 'segmentation' } )}
                                              />
                                            </Col>
                                          </Row>
                                          <div className="text-right">
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              className="jr-btn jr-btn-lg"
                                              type="button"
                                            >
                                              <i className="fa fa-calculator" />
                                              <IntlMessages id="compute" />
                                            </Button>
                                          </div>
                                          <p className="my-2">
                                            <IntlMessages id="peopleInSegmentation" />
                                            {' '}
                                            0
                                          </p>
                                        </>
                                      )}

                                      {internetPlan.id === 'artificial_intelligence'
                                      && (
                                        <>
                                          <p className="mb-2">
                                            <IntlMessages
                                              id={internetPlan.bodyLabel}
                                            />
                                          </p>
                                          <div className="pl-3 pb-2">
                                            <RadioButtons
                                              field={`${internetPlanField}.body`}
                                              options={internetPlan.bodyList}
                                              containerClass="w-50"
                                              displayBlock
                                            />
                                          </div>
                                          <p />
                                          <div className="pl-3 pb-2">
                                            <RadioButtons
                                              disabled={( !internetPlan.body
                                                  || !internetPlan.body.length )}
                                              field={`${internetPlanField}.body2`}
                                              options={internetPlan.bodyListLevel2}
                                              labelPlacement="end"
                                              displayBlock

                                            />
                                          </div>
                                        </>
                                      )}

                                      {internetPlan.id !== 'social_network_display' && internetPlan.id !== 'artificial_intelligence'
                                      && (
                                        <>
                                          <p className="mb-2">
                                            <IntlMessages
                                              id={internetPlan.bodyLabel}
                                            />
                                          </p>
                                          <Checkboxes
                                            labelClassName="col-md-6 mr-0"
                                            labelContainerClass="row"
                                            marginContainer={false}
                                            translate
                                            field={`${internetPlanField}.body`}
                                            options={internetPlan.bodyList}
                                            onChange={( value ) => {
                                              if ( internetPlan.id !== 'social_network_display'
                                                && !internetPlan.ageFrom
                                                && !internetPlan.ageTo
                                                && !internetPlan.gender
                                                && ( !value || !value.length ) ) {
                                                form.change( `${internetPlanField}.monthlyInvestment`, null );
                                              }
                                            }}
                                          />
                                        </>
                                      )}
                                    </CardBox>
                                  </Col>
                                );
                              } ) )}
                          </FieldArray>
                        </Row>
                      </CardBox>
                    </Col>
                  </Row>
                  {this.getStepButtons( form )}
                </>
              )}

              {steps[activeStep].id === 5
              && (
                <Row>
                  <Col md={4} className="m-md-auto">
                    <CardBox
                      header={<Header title="finalConfirmation" />}
                      styleName=""
                    >

                      <div className="text-center mt-2">
                        <p><IntlMessages id="approveCampaign" /></p>
                        <p>
                          <b>
                            <IntlMessages id="totalMonthlyCost" />
                            :
                          </b>
                          &nbsp;
                          {Util.formatCurrency(
                            _.sum( mediaCostLabel )
                          + _.sumBy( values.internetPlans, item => (
                            // eslint-disable-next-line no-restricted-globals
                            isNaN( item.monthlyInvestment )
                              ? 0 : parseFloat( item.monthlyInvestment ) ) ),
                          )}
                        </p>
                        <Button
                          variant="contained"
                          className="jr-btn jr-btn-lg bg-blue-grey text-white"
                          type="submit"
                          onClick={() => {
                            if ( form.getState().valid ) this.handleBack( form );
                          }}
                        >
                          <i className="fa fa-angle-double-left" />
                          <IntlMessages id="back" />
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className="jr-btn jr-btn-lg"
                          type="submit"
                        >
                          <i className="fa fa-save" />
                          <IntlMessages id="saveBtn" />
                        </Button>
                      </div>
                    </CardBox>
                  </Col>
                </Row>
              )}
            </form>
          )}
        />
      </>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( EditCampaign );
