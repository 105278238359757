import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment/moment';

import SettingsActions from 'store/reducers/Settings';
import UserLicenseService from 'services/UserLicense';
import CardBox from 'components/theme/CardBox';
import Table from 'components/custom/Table/AsyncTable';
import NewEntityBtn from 'components/custom/NewEntityBtn';
import IntlMessages from 'utils/IntlMessages';
import UserLicensesNumber from '../../../../../../../components/Admin/University/UserLicensesNumber';
import LicenseEditForm from '../../../../../../../components/LicenseEditForm';

class UserLicenses extends React.Component {
  state = {
    data: [],
    licensesNumberModal: false,
    editModal: false,
  };

  getData = ( params ) => {
    const { toggleLoading, sessionId } = this.props;
    toggleLoading( true );

    this.params = params;
    UserLicenseService.getUserLicensesBySession( sessionId, params )
      .then( ( response ) => {
        toggleLoading( false );

        if ( response.ok ) {
          this.setState( {
            data: response.data.data || [],
            pages: Math.ceil( response.data.total / params.limit ),
            total: response.data.total,
          } );
        }
      } );
  };

  getEntity = ( id ) => {
    const { toggleLoading, sessionId } = this.props;
    toggleLoading( true );

    UserLicenseService.getUserLicense( sessionId, id )
      .then( ( response ) => {
        toggleLoading( false );

        if ( response.ok ) {
          this.entityToEdit = response.data;
          this.toggleEditModal();
        }
      } );
  };

  submitForm = ( formData, form ) => {
    const { toggleLoading, toggleAlert, sessionId } = this.props;
    const dataToSend = {};

    toggleLoading( true );
    _.map( _.keys( form.getState().modified ), ( key ) => {
      dataToSend[key] = _.get( formData[key], 'id' ) ? formData[key].id : formData[key];
    } );

    UserLicenseService.updateUserLicense( sessionId, this.entityToEdit.id, dataToSend )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) return toggleAlert( response.errors );

        toggleAlert( 'dataSaved', 'info' );
        this.toggleEditModal( () => this.getData( this.params ) );
      } );
  };

  toggleLicensesNumberModal = ( entity, cb ) => {
    this.setState( previousState => (
      { licensesNumberModal: !previousState.licensesNumberModal } ), cb );
  };

  toggleEditModal = ( cb ) => {
    this.setState( previousState => ( { editModal: !previousState.editModal } ), cb );
  };

  render() {
    const { editModal, licensesNumberModal, ...rest } = this.state;
    const { sessionId } = this.props;

    return (
      <div>
        <CardBox cardStyle="p-0">
          <Table
            onFetchData={this.getData}
            {...rest}
            columns={[
              {
                maxWidth: 150,
                Header: <IntlMessages id="id" />,
                accessor: 'id',
                Cell: props => ( props.value ? `${props.value.slice( 0, 8 ).toUpperCase()}` : null ),
              },
              {
                Header: <IntlMessages id="user" />,
                accessor: 'user.email',
                id: 'user',
              },
              {
                Header: <IntlMessages id="userEnabled" />,
                accessor: 'user.enabled',
                Cell: props => ( props.value ? <i className="fa fa-check" />
                  : <i className="fa fa-times" /> ),
              },
              {
                Header: <IntlMessages id="lastLogin" />,
                accessor: 'user.lastLogin',
                Cell: props => ( props.value ? moment( Number( props.value ) ).format( 'DD/MM/YYYY HH:mm' ) : null ),
              },
              {
                Header: <IntlMessages id="enabled" />,
                accessor: 'enabled',
                maxWidth: 120,
                Cell: props => ( props.value ? <i className="fa fa-check" />
                  : <i className="fa fa-times" /> ),
              },
              {
                Header: '',
                accessor: 'id',
                maxWidth: 60,
                Cell: props => (
                  <NewEntityBtn
                    isEdit
                    onClick={() => this.getEntity( props.value )}
                  />
                ),
              },
            ]}
          />
        </CardBox>

        <UserLicensesNumber
          open={licensesNumberModal}
          onClose={() => this.toggleLicensesNumberModal()}
          sessionId={sessionId}
          onConfirm={() => {
            this.toggleLicensesNumberModal();
            this.getData( this.params );
          }}
        />

        <LicenseEditForm
          data={this.entityToEdit}
          open={editModal}
          newLabel="editUserLicense"
          editLabel="editUserLicense"
          onClose={() => this.toggleEditModal()}
          onSubmit={this.submitForm}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps,
)( UserLicenses );
