import React from 'react';
import CardBox from 'components/custom_v2/CardBox';

import { connect } from 'react-redux';
import BreadcrumbComp from 'components/custom_v2/Breadcrumb';
import { Col, Row } from 'reactstrap';
import _ from 'lodash';
import DashboardStudentService from 'services/DashboardStudent';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import ChartModal from 'routes/App/components/User/Chart';
import cell from 'assets/images/mk/cell.png';
import laptop from 'assets/images/mk/laptop.png';
import tablet from 'assets/images/mk/tablet.png';
import { Avatar } from '@material-ui/core';
import CategoryService from 'services/Category';
import MarketReportService from 'services/MarketReport';
import Util from 'utils/Util';
import classnames from 'classnames';
import IntlMessages from 'utils/IntlMessages';
import parallel from 'async/parallel';
import SettingsActions from '../../../../../../../../store/reducers/Settings';

class MarketCharts extends React.Component {
  state = {
    chart1Modal: false,
    chart2Modal: false,
    chart3Modal: false,
    chart4Modal: false,
    chart5Modal: false,
    chart6Modal: false,
    chart7Modal: false,
    chart8Modal: false,
    chart9Modal: false,
    groupsX: [],
    table1: [],
    table2: [],
    table3: [],
  };

  seriesTitle1 = [];
  seriesTitle2 = [];
  seriesTitle3 = [];
  seriesTitle4 = [];

  async componentDidMount() {
    const { user, toggleLoading } = this.props;
    toggleLoading( true );
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );

    await DashboardStudentService.getStockValueAllWorkgroup(
      user.activeSession.id,
      { limit: -1 },
    ).then( ( response ) => {
      if ( response.ok ) {
        this.workgroups = response.data;
      }
    } );
    parallel( [
      ( cb ) => {
        MarketReportService.getProductLastRoundReports( user.activeSession.id,
          { workgroup: this.workgroups[0].id } )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
      },
      ( cb ) => {
        CategoryService.getCategories( { 'filters[session]': user.activeSession.id } )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
      }], ( error, results ) => {
      toggleLoading( false );
      if ( !error ) {
        if ( results ) {
          const groupsX = [];
          _.map( results[0], ( item ) => {
            _.map( item.workgroupData, ( workgroupData ) => {
              const exist = _.findIndex( groupsX, { id: workgroupData.id } );
              if ( exist === -1 ) {
                const dataToPush = { name: workgroupData.name, id: workgroupData.id, category: [] };
                dataToPush.category.push( {
                  id: item.id,
                  name: item.name,
                  label: item.label,
                  products: workgroupData.products,
                } );
                groupsX.push( dataToPush );
              } else {
                groupsX[exist].category.push( {
                  id: item.id,
                  name: item.name,
                  label: item.label,
                  products: workgroupData.products,
                } );
                // dataToPush.category.push( { id: item.id, name: item.name, label: item.label } );
                // TO CHECK, Math.floor( ( Math.random() * 1000 ) + 1 );
              }
            } );
          } );
          this.setState( ( { groupsX } ) );


          this.categories = results[1].data;
          this.LoadGraphLaptop(
            user.activeSession.id,
            this.categories[0].id,
            this.workgroups[0].id,
          );
          this.LoadGraphTablet(
            user.activeSession.id,
            this.categories[1].id,
            this.workgroups[0].id,
          );
          this.LoadGraphCell(
            user.activeSession.id,
            this.categories[2].id,
            this.workgroups[0].id,
          );

          this.LoadGraphLaptop1(
            user.activeSession.id,
            this.categories[0].id,
            this.workgroups[0].id,
          );
          this.LoadGraphTablet1(
            user.activeSession.id,
            this.categories[1].id,
            this.workgroups[0].id,
          );
          this.LoadGraphCell1(
            user.activeSession.id,
            this.categories[2].id,
            this.workgroups[0].id,
          );
        }
      }
    } );
  }

  componentWillUnmount() {
    if ( this.chart ) {
      this.chart.dispose();
    }
    if ( this.chart2 ) {
      this.chart2.dispose();
    }
    if ( this.chart3 ) {
      this.chart3.dispose();
    }
    if ( this.chart4 ) {
      this.chart4.dispose();
    }
    if ( this.chart5 ) {
      this.chart5.dispose();
    }
    if ( this.chart6 ) {
      this.chart6.dispose();
    }
    if ( this.chart7 ) {
      this.chart7.dispose();
    }
    if ( this.chart8 ) {
      this.chart8.dispose();
    }
    if ( this.chart9 ) {
      this.chart9.dispose();
    }
  }

  // eslint-disable-next-line react/sort-comp
  LoadGraphLaptop1( activeSession, categories, workGroup ) {
    if ( categories ) {
      CategoryService.getProducts( activeSession,
        categories, { workgroup: workGroup } )
        .then( ( response ) => {
          if ( response.ok ) {
            this.Products = response.data;
            // console.log( 'Products' );
            // console.log( this.Products );
            if ( this.Products.data.length ) {
              const attributes = [];
              const titles1 = [];
              _.map( this.Products.data[0].attributes, ( attribute ) => {
                const item = { attribute: attribute.label };
                _.map( this.Products.data, ( product ) => {
                  item[product.id] = _.find( product.attributes, { id: attribute.id } ).value;
                  titles1.push( product.name );
                } );
                attributes.push( item );
              } );
              this.config1 = {
                data: attributes,
                type: am4charts.RadarChart,
                xAxes: [{
                  type: 'CategoryAxis',
                  dataFields: { category: 'attribute' },
                }],
                // cursor: { type: 'RadarCursor' },
                // legend: { type: 'Legend' },
                yAxes: [{ type: 'ValueAxis' }],
                series: _.map( this.Products.data, product => ( {
                  type: 'RadarSeries',
                  dataFields: {
                    valueY: product.id,
                    categoryX: 'attribute',
                  },
                  strokeWidth: 3,
                  tooltipText: '{valueY}',
                  name: product.name,
                  bullets: [{ type: 'CircleBullet' }],
                  // dataItems: { template: { locations: { categoryX: 0.5 } } },
                } ) ),
              };
              this.chart1 = am4core.createFromConfig( _.cloneDeep( this.config1 ), 'laptopCaracteristicas' );
              this.setState( ( { products1: response.data.data, titles1 } ) );
              // console.log( response.data.data );
            }
          }
        } );
    }
  }
  LoadGraphTablet1( activeSession, categories, workGroup ) {
    if ( categories ) {
      CategoryService.getProducts( activeSession,
        categories, { workgroup: workGroup } )
        .then( ( response ) => {
          if ( response.ok ) {
            // console.log( response.data );
            this.Products = response.data;
            if ( this.Products.data.length ) {
              const attributes = [];
              const titles2 = [];
              _.map( this.Products.data[0].attributes, ( attribute ) => {
                const item = { attribute: attribute.label };
                _.map( this.Products.data, ( product ) => {
                  item[product.id] = _.find( product.attributes, { id: attribute.id } ).value;
                  titles2.push( product.name );
                } );
                attributes.push( item );
              } );
              this.config2 = {
                data: attributes,
                type: am4charts.RadarChart,
                xAxes: [{
                  type: 'CategoryAxis',
                  dataFields: { category: 'attribute' },
                }],
                // legend: { type: 'Legend' },
                yAxes: [{ type: 'ValueAxis' }],
                series: _.map( this.Products.data, product => ( {
                  type: 'RadarSeries',
                  dataFields: {
                    valueY: product.id,
                    categoryX: 'attribute',
                  },
                  strokeWidth: 3,
                  tooltipText: '{valueY}',
                  name: product.name,
                  bullets: [{ type: 'CircleBullet' }],
                  // dataItems: { template: { locations: { categoryX: 0.5 } } },
                } ) ),
              };
              this.chart2 = am4core.createFromConfig( _.cloneDeep( this.config2 ), 'tabletsCaracteristicas' );
              this.setState( ( { products2: response.data.data, titles2 } ) );
            }
          }
        } );
    }
  }
  LoadGraphCell1( activeSession, categories, workGroup ) {
    if ( categories ) {
      CategoryService.getProducts( activeSession,
        categories, { workgroup: workGroup } )
        .then( ( response ) => {
          if ( response.ok ) {
            // console.log( response.data );
            this.Products = response.data;
            if ( this.Products.data.length ) {
              const attributes = [];
              const titles3 = [];
              _.map( this.Products.data[0].attributes, ( attribute ) => {
                const item = { attribute: attribute.label };
                _.map( this.Products.data, ( product ) => {
                  item[product.id] = _.find( product.attributes, { id: attribute.id } ).value;
                  titles3.push( product.name );
                } );
                attributes.push( item );
              } );
              this.config3 = {
                data: attributes,
                type: am4charts.RadarChart,
                xAxes: [{
                  type: 'CategoryAxis',
                  dataFields: { category: 'attribute' },
                }],
                // legend: { type: 'Legend' },
                yAxes: [{ type: 'ValueAxis' }],
                series: _.map( this.Products.data, product => ( {
                  type: 'RadarSeries',
                  dataFields: {
                    valueY: product.id,
                    categoryX: 'attribute',
                  },
                  strokeWidth: 3,
                  tooltipText: '{valueY}',
                  name: product.name,
                  bullets: [{ type: 'CircleBullet' }],
                  // dataItems: { template: { locations: { categoryX: 0.5 } } },
                } ) ),
              };
              this.chart3 = am4core.createFromConfig( _.cloneDeep( this.config3 ), 'cellCaracteristicas' );
              this.setState( ( { products3: response.data.data, titles3 } ) );
            }
          }
        } );
    }
  }

  // eslint-disable-next-line react/sort-comp
  LoadGraphLaptop( activeSession, categories, workGroup ) {
    if ( categories ) {
      DashboardStudentService.getHistoricalMarket( activeSession,
        workGroup, categories, { limit: -1 } )
        .then( ( response ) => {
          if ( response.ok ) {
            const roundsX = [];
            const rounds2X = [];
            _.map( response.data.data, ( item ) => {
              _.map( item.historical, ( round ) => {
                const exist = _.findIndex( roundsX, { id: round.round.id } );
                if ( exist === -1 ) {
                  const dataToPush = { attribute: round.round.shortName, id: round.round.id };
                  dataToPush[item.id] = round.unitSales;
                  roundsX.push( dataToPush );
                } else {
                  roundsX[exist][item.id] = round.unitSales;
                  // TO CHECK, Math.floor( ( Math.random() * 1000 ) + 1 );
                }
                const exist1 = _.findIndex( rounds2X, { id: round.round.id } );
                if ( exist1 === -1 ) {
                  const dataToPush1 = { attribute: round.round.shortName, id: round.round.id };
                  dataToPush1[item.id] = round.revenues;
                  rounds2X.push( dataToPush1 );
                } else {
                  rounds2X[exist1][item.id] = round.revenues;
                  // TO CHECK, Math.floor( ( Math.random() * 1000 ) + 1 );
                }
              } );
              this.seriesTitle1.push( item.name );
            } );

            this.config = {
              data: roundsX,
              type: am4charts.XYChart,
              xAxes: [{
                type: 'CategoryAxis',
                dataFields: { category: 'attribute' },
              }],
              yAxes: [{ type: 'ValueAxis' }],
              series: _.map( response.data.data, itemX => ( {
                type: 'LineSeries',
                dataFields: {
                  valueY: itemX.id,
                  categoryX: 'attribute',
                },
                name: itemX.name,
                strokeWidth: 2,
              } ) ),
            };
            this.chart = am4core.createFromConfig( _.cloneDeep( this.config ), 'laptopsalesbybrandu' );

            this.config2 = {
              data: rounds2X,
              type: am4charts.XYChart,
              xAxes: [{
                type: 'CategoryAxis',
                dataFields: { category: 'attribute' },
              }],
              yAxes: [{ type: 'ValueAxis' }],
              series: _.map( response.data.data, itemX => ( {
                type: 'LineSeries',
                dataFields: {
                  valueY: itemX.id,
                  categoryX: 'attribute',
                },
                name: itemX.name,
                strokeWidth: 2,
              } ) ),
            };
            this.chart2 = am4core.createFromConfig( _.cloneDeep( this.config2 ), 'laptopsalesbybrandr' );

            const dataTable = _.map( response.data.data, item => ( {
              id: item.id,
              group: item.name,
              lastsUnitSales: item.unitSales,
              totalUnitSales: item.lastsUnitSales,
            } ) );

            this.config3 = {
              data: dataTable,
              type: am4charts.PieChart,
              xAxes: [{
                type: 'CategoryAxis',
                dataFields: { category: 'group' },
              }],
              yAxes: [{ type: 'ValueAxis' }],
              series: [
                {
                  type: 'PieSeries',
                  dataFields: {
                    value: 'lastsUnitSales',
                    category: 'group',
                  },
                },
              ],
            };
            this.setState( ( { table1: dataTable } ) );

            this.chart3 = am4core.createFromConfig( _.cloneDeep( this.config3 ), 'laptopmarket' );
          }
        } );
    }
  }

  LoadGraphTablet( activeSession, categories, workGroup ) {
    if ( categories ) {
      DashboardStudentService.getHistoricalMarket( activeSession,
        workGroup, categories, { limit: -1 } )
        .then( ( response ) => {
          if ( response.ok ) {
            const roundsX = [];
            const rounds2X = [];
            _.map( response.data.data, ( item ) => {
              _.map( item.historical, ( round ) => {
                const exist = _.findIndex( roundsX, { id: round.round.id } );
                if ( exist === -1 ) {
                  const dataToPush = { attribute: round.round.shortName, id: round.round.id };
                  dataToPush[item.id] = round.unitSales;
                  roundsX.push( dataToPush );
                } else {
                  roundsX[exist][item.id] = round.unitSales;
                  // TO CHECK, Math.floor( ( Math.random() * 1000 ) + 1 );
                }
                const exist1 = _.findIndex( rounds2X, { id: round.round.id } );
                if ( exist1 === -1 ) {
                  const dataToPush1 = { attribute: round.round.shortName, id: round.round.id };
                  dataToPush1[item.id] = round.revenues;
                  rounds2X.push( dataToPush1 );
                } else {
                  rounds2X[exist1][item.id] = round.revenues;
                  // TO CHECK, Math.floor( ( Math.random() * 1000 ) + 1 );
                }
              } );
              this.seriesTitle2.push( item.name );
            } );

            this.config4 = {
              data: roundsX,
              type: am4charts.XYChart,
              xAxes: [{
                type: 'CategoryAxis',
                dataFields: { category: 'attribute' },
              }],
              yAxes: [{ type: 'ValueAxis' }],
              series: _.map( response.data.data, itemX => ( {
                type: 'LineSeries',
                dataFields: {
                  valueY: itemX.id,
                  categoryX: 'attribute',
                },
                name: itemX.name,
                strokeWidth: 2,
              } ) ),
            };
            this.chart4 = am4core.createFromConfig( _.cloneDeep( this.config4 ), 'tabletsalesbybrandu' );

            this.config5 = {
              data: rounds2X,
              type: am4charts.XYChart,
              xAxes: [{
                type: 'CategoryAxis',
                dataFields: { category: 'attribute' },
              }],
              yAxes: [{ type: 'ValueAxis' }],
              series: _.map( response.data.data, itemX => ( {
                type: 'LineSeries',
                dataFields: {
                  valueY: itemX.id,
                  categoryX: 'attribute',
                },
                name: itemX.name,
                strokeWidth: 2,
              } ) ),
            };
            this.chart5 = am4core.createFromConfig( _.cloneDeep( this.config5 ), 'tabletsalesbybrandr' );

            const dataTable = _.map( response.data.data, item => ( {
              id: item.id,
              group: item.name,
              lastsUnitSales: item.unitSales,
              totalUnitSales: item.lastsUnitSales,
            } ) );

            this.config6 = {
              data: dataTable,
              type: am4charts.PieChart,
              xAxes: [{
                type: 'CategoryAxis',
                dataFields: { category: 'group' },
              }],
              yAxes: [{ type: 'ValueAxis' }],
              series: [
                {
                  type: 'PieSeries',
                  dataFields: {
                    value: 'lastsUnitSales',
                    category: 'group',
                  },
                },
              ],
            };
            this.setState( ( { table2: dataTable } ) );

            this.chart6 = am4core.createFromConfig( _.cloneDeep( this.config6 ), 'tabletmarket' );
          }
        } );
    }
  }

  LoadGraphCell( activeSession, categories, workGroup ) {
    if ( categories ) {
      DashboardStudentService.getHistoricalMarket( activeSession,
        workGroup, categories, { limit: -1 } )
        .then( ( response ) => {
          if ( response.ok ) {
            const roundsX = [];
            const rounds2X = [];
            _.map( response.data.data, ( item ) => {
              _.map( item.historical, ( round ) => {
                const exist = _.findIndex( roundsX, { id: round.round.id } );
                if ( exist === -1 ) {
                  const dataToPush = { attribute: round.round.shortName, id: round.round.id };
                  dataToPush[item.id] = round.unitSales;
                  roundsX.push( dataToPush );
                } else {
                  roundsX[exist][item.id] = round.unitSales;
                  // TO CHECK, Math.floor( ( Math.random() * 1000 ) + 1 );
                }
                const exist1 = _.findIndex( rounds2X, { id: round.round.id } );
                if ( exist1 === -1 ) {
                  const dataToPush1 = { attribute: round.round.shortName, id: round.round.id };
                  dataToPush1[item.id] = round.revenues;
                  rounds2X.push( dataToPush1 );
                } else {
                  rounds2X[exist1][item.id] = round.revenues;
                  // TO CHECK, Math.floor( ( Math.random() * 1000 ) + 1 );
                }
              } );
              this.seriesTitle3.push( item.name );
            } );
            this.config7 = {
              data: roundsX,
              type: am4charts.XYChart,
              xAxes: [{
                type: 'CategoryAxis',
                dataFields: { category: 'attribute' },
              }],
              yAxes: [{ type: 'ValueAxis' }],
              series: _.map( response.data.data, itemX => ( {
                type: 'LineSeries',
                dataFields: {
                  valueY: itemX.id,
                  categoryX: 'attribute',
                },
                name: itemX.name,
                strokeWidth: 2,
              } ) ),
            };
            this.chart7 = am4core.createFromConfig( _.cloneDeep( this.config7 ), 'cellphonesalesbybrandu' );

            this.config8 = {
              data: rounds2X,
              type: am4charts.XYChart,
              xAxes: [{
                type: 'CategoryAxis',
                dataFields: { category: 'attribute' },
              }],
              yAxes: [{ type: 'ValueAxis' }],
              series: _.map( response.data.data, itemX => ( {
                type: 'LineSeries',
                dataFields: {
                  valueY: itemX.id,
                  categoryX: 'attribute',
                },
                name: itemX.name,
                strokeWidth: 2,
              } ) ),
            };
            this.chart8 = am4core.createFromConfig( _.cloneDeep( this.config8 ), 'cellphonesalesbybrandr' );

            const dataTable = _.map( response.data.data, item => ( {
              id: item.id,
              group: item.name,
              lastsUnitSales: item.unitSales,
              totalUnitSales: item.lastsUnitSales,
            } ) );

            this.config9 = {
              data: dataTable,
              type: am4charts.PieChart,
              xAxes: [{
                type: 'CategoryAxis',
                dataFields: { category: 'group' },
              }],
              yAxes: [{ type: 'ValueAxis' }],
              series: [
                {
                  type: 'PieSeries',
                  dataFields: {
                    value: 'lastsUnitSales',
                    category: 'group',
                  },
                },
              ],
            };
            this.setState( ( { table3: dataTable } ) );

            this.chart9 = am4core.createFromConfig( _.cloneDeep( this.config9 ), 'cellphonemarket' );
          }
        } );
    }
  }

  toggleChart1Modal = () => {
    this.setState( previousState => ( { chart1Modal: !previousState.chart1Modal } ) );
  };

  toggleChart2Modal = () => {
    this.setState( previousState => ( { chart2Modal: !previousState.chart2Modal } ) );
  };

  toggleChart3Modal = () => {
    this.setState( previousState => ( { chart3Modal: !previousState.chart3Modal } ) );
  };

  toggleChart4Modal = () => {
    this.setState( previousState => ( { chart4Modal: !previousState.chart4Modal } ) );
  };

  toggleChart5Modal = () => {
    this.setState( previousState => ( { chart5Modal: !previousState.chart5Modal } ) );
  };

  toggleChart6Modal = () => {
    this.setState( previousState => ( { chart6Modal: !previousState.chart6Modal } ) );
  };

  toggleChart7Modal = () => {
    this.setState( previousState => ( { chart7Modal: !previousState.chart7Modal } ) );
  };

  toggleChart8Modal = () => {
    this.setState( previousState => ( { chart8Modal: !previousState.chart8Modal } ) );
  };

  toggleChart9Modal = () => {
    this.setState( previousState => ( { chart9Modal: !previousState.chart9Modal } ) );
  };

  toggleChartModal = ( configModal, titleModal, seriesTitleModal ) => {
    this.setState( previousState => ( {
      chartModal: !previousState.chartModal,
      configModal,
      titleModal,
      seriesTitleModal,
    } ) );
  };

  render() {
    const {
      chart1Modal,
      chart2Modal,
      chart3Modal,
      chart4Modal,
      chart5Modal,
      chart6Modal,
      chart7Modal,
      chart8Modal,
      chart9Modal,
      table1,
      table2,
      table3,
      groupsX,

      chartModal,
      titleModal,
      seriesTitleModal,
      configModal,
      titles1,
      titles2,
      titles3,
      products1,
      products2,
      products3,
    } = this.state;

    let MonthSalesTotalTable1 = 0;
    let twelveMonthSalesTotalTable1 = 0;
    let MonthSalesTotalTable2 = 0;
    let twelveMonthSalesTotalTable2 = 0;
    let MonthSalesTotalTable3 = 0;
    let twelveMonthSalesTotalTable3 = 0;

    _.map( table1, ( item ) => {
      MonthSalesTotalTable1 += item.lastsUnitSales;
      twelveMonthSalesTotalTable1 += item.totalUnitSales;
    } );
    _.map( table2, ( item ) => {
      MonthSalesTotalTable2 += item.lastsUnitSales;
      twelveMonthSalesTotalTable2 += item.totalUnitSales;
    } );
    _.map( table3, ( item ) => {
      MonthSalesTotalTable3 += item.lastsUnitSales;
      twelveMonthSalesTotalTable3 += item.totalUnitSales;
    } );

    return (
      <div>
        <BreadcrumbComp links={[
          {
            url: '/',
            name: 'informationDashboard',
          },
          { name: 'market' },
        ]}
        />
        <Row>
          <Col lg={6}>
            <CardBox
              heading="LAPTOPSalesByBrandU"
              childrenStyle=""
              headerBg="bg-product-laptop"
              icon="laptop"
              onClick={this.toggleChart1Modal}
            >
              <div className="jr-link  mt-3" id="laptopsalesbybrandu" style={{ width: '100%', height: '250px' }} />
            </CardBox>
            <CardBox heading="LAPTOPSalesByBrandRevenue" childrenStyle="" headerBg="bg-product-laptop" icon="laptop" onClick={this.toggleChart2Modal}>
              <div className="jr-link mt-3" id="laptopsalesbybrandr" style={{ width: '100%', height: '250px' }} />
            </CardBox>
          </Col>
          <Col lg={6}>
            <CardBox heading="LAPTOPMARKET" childrenStyle="" headerBg="bg-product-laptop" icon="laptop" onClick={this.toggleChart3Modal}>
              <div className="jr-link mt-3" id="laptopmarket" style={{ width: '100%', height: '315px' }} />
              <div>
                <table
                  className="table table-striped table-products mb-0 table-products-laptop mt-3 table-header"
                >
                  <thead>
                    <tr>
                      <th className="thead-report"><IntlMessages id="this_month_sales" /></th>
                      <th className="thead-report" />
                      <th className="thead-report"><IntlMessages id="previousRound" /></th>
                      <th className="thead-report" />
                    </tr>
                  </thead>
                  <tbody>
                    {_.map( table1, item => (
                      <tr key={item.id}>
                        <td className="td-report">{item.group}</td>
                        <td className="td-report">{Util.formatNumber( item.lastsUnitSales )}</td>
                        <td className="td-report">{item.group}</td>
                        <td className="td-report">{Util.formatNumber( item.totalUnitSales )}</td>
                      </tr>
                    ) )}
                    {!!table1 && !!table1.length
                    && (
                      <tr>
                        <td className="td-report font-weight-bold"><IntlMessages id="total" /></td>
                        <td className="td-report font-weight-bold">{Util.formatNumber( MonthSalesTotalTable1 )}</td>
                        <td className="td-report" />
                        <td className="td-report font-weight-bold">{Util.formatNumber( twelveMonthSalesTotalTable1 )}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </CardBox>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <CardBox heading="TABLETSalesByBrandU" childrenStyle="" headerBg="bg-product-tablet" icon="tablet-alt" onClick={this.toggleChart4Modal}>
              <div className="jr-link mt-3" id="tabletsalesbybrandu" style={{ width: '100%', height: '250px' }} />
            </CardBox>
            <CardBox heading="TABLETSalesByBrandRevenue" childrenStyle="" headerBg="bg-product-tablet" icon="tablet-alt" onClick={this.toggleChart5Modal}>
              <div className="jr-link mt-3" id="tabletsalesbybrandr" style={{ width: '100%', height: '250px' }} />
            </CardBox>
          </Col>
          <Col lg={6}>
            <CardBox heading="TABLETMARKET" childrenStyle="" headerBg="bg-product-tablet" icon="tablet-alt" onClick={this.toggleChart6Modal}>
              <div className="jr-link" id="tabletmarket" style={{ width: '100%', height: '315px' }} />

              <div>
                <table
                  className="table table-striped table-products mb-0 table-products-tablet mt-3 table-header"
                >
                  <thead>
                    <tr>
                      <th className="thead-report"><IntlMessages id="this_month_sales" /></th>
                      <th className="thead-report" />
                      <th className="thead-report"><IntlMessages id="previousRound" /></th>
                      <th className="thead-report" />
                    </tr>
                  </thead>
                  <tbody>
                    {_.map( table2, item => (
                      <tr key={item.id}>
                        <td className="td-report">{item.group}</td>
                        <td className="td-report">{Util.formatNumber( item.lastsUnitSales )}</td>
                        <td className="td-report">{item.group}</td>
                        <td className="td-report">{Util.formatNumber( item.totalUnitSales )}</td>
                      </tr>
                    ) )}
                    {!!table2 && !!table2.length
                    && (
                      <tr>
                        <td className="td-report font-weight-bold"><IntlMessages id="total" /></td>
                        <td className="td-report font-weight-bold">{Util.formatNumber( MonthSalesTotalTable2 )}</td>
                        <td className="td-report" />
                        <td className="td-report font-weight-bold">{Util.formatNumber( twelveMonthSalesTotalTable2 )}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

            </CardBox>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <CardBox heading="CELLPHONESalesByBrandU" childrenStyle="" headerBg="bg-product-cell" icon="mobile-alt" onClick={this.toggleChart7Modal}>
              <div className="jr-link mt-3" id="cellphonesalesbybrandu" style={{ width: '100%', height: '250px' }} />
            </CardBox>
            <CardBox heading="CELLPHONESalesByBrandRevenue" childrenStyle="" headerBg="bg-product-cell" icon="mobile-alt" onClick={this.toggleChart8Modal}>
              <div className="jr-link mt-3" id="cellphonesalesbybrandr" style={{ width: '100%', height: '250px' }} />
            </CardBox>
          </Col>
          <Col lg={6}>
            <CardBox heading="CELL PHONE MARKET" childrenStyle="" headerBg="bg-product-cell" icon="mobile-alt" onClick={this.toggleChart9Modal}>
              <div className="jr-link" id="cellphonemarket" style={{ width: '100%', height: '315px' }} />
              <div>
                <table
                  className="table table-striped table-products mb-0 table-products-cell mt-3 table-header"
                >
                  <thead>
                    <tr>
                      <th className="thead-report"><IntlMessages id="this_month_sales" /></th>
                      <th className="thead-report" />
                      <th className="thead-report"><IntlMessages id="previousRound" /></th>
                      <th className="thead-report" />
                    </tr>
                  </thead>
                  <tbody>
                    {_.map( table3, item => (
                      <tr key={item.id}>
                        <td className="td-report">{item.group}</td>
                        <td className="td-report">{Util.formatNumber( item.lastsUnitSales )}</td>
                        <td className="td-report">{item.group}</td>
                        <td className="td-report">{Util.formatNumber( item.totalUnitSales )}</td>
                      </tr>
                    ) )}
                    {!!table3 && !!table3.length
                    && (
                      <tr>
                        <td className="td-report font-weight-bold"><IntlMessages id="total" /></td>
                        <td className="td-report font-weight-bold">{Util.formatNumber( MonthSalesTotalTable3 )}</td>
                        <td className="td-report" />
                        <td className="td-report font-weight-bold">{Util.formatNumber( twelveMonthSalesTotalTable3 )}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </CardBox>
          </Col>
        </Row>
        <CardBox
          header={<div />}
        >
          <Row>
            <Col lg={4}>
              <Avatar
                className="size-100 border-radius-0 m-auto"
                alt="..."
                src={laptop}
              />
            </Col>
            <Col lg={4}>
              <Avatar
                className="size-100 border-radius-0 m-auto"
                alt="..."
                src={tablet}
              />
            </Col>
            <Col lg={4}>
              <Avatar
                className="size-100 border-radius-0 m-auto"
                alt="..."
                src={cell}
              />
            </Col>
          </Row>
        </CardBox>
        {_.map( groupsX, ( item ) => {
          const CATEGORY_1 = _.find( item.category, { label: 'CATEGORY_1' } );
          const CATEGORY_2 = _.find( item.category, { label: 'CATEGORY_2' } );
          const CATEGORY_3 = _.find( item.category, { label: 'CATEGORY_3' } );
          let unitSalesCat1 = 0;
          let revenuesCat1 = 0;
          let unitSalesCat2 = 0;
          let revenuesCat2 = 0;
          let unitSalesCat3 = 0;
          let revenuesCat3 = 0;
          _.map( CATEGORY_1.products, ( cat ) => {
            unitSalesCat1 += cat.unitSales;
            revenuesCat1 += cat.revenues;
          } );
          _.map( CATEGORY_2.products, ( cat ) => {
            unitSalesCat2 += cat.unitSales;
            revenuesCat2 += cat.revenues;
          } );
          _.map( CATEGORY_3.products, ( cat ) => {
            unitSalesCat3 += cat.unitSales;
            revenuesCat3 += cat.revenues;
          } );
          return (
            <CardBox key={item.id} heading={item.name}>
              <div>
                <Row>
                  <Col lg={4} md>
                    <div className="table-responsive">
                      <table
                        className={classnames( 'table table-striped table-products mb-0, table-products-laptop table-header' )}
                      >
                        <thead>
                          <tr>
                            <th className="thead-report"><IntlMessages id="model" /></th>
                            <th className="thead-report"><IntlMessages id="share_u" /></th>
                            <th className="thead-report"><IntlMessages id="share_u2" /></th>
                            <th className="thead-report"><IntlMessages id="price" /></th>
                          </tr>
                        </thead>
                        <tbody>
                          {!!CATEGORY_1 && !!CATEGORY_1.products.length
                          && _.map( CATEGORY_1.products, cat => (
                            <tr>
                              <td className="td-report">{cat.name}</td>
                              <td className="td-report">{Util.formatNumber( cat.unitSales )}</td>
                              <td className="td-report">{Util.formatCurrency( cat.revenues )}</td>
                              <td className="td-report">{Util.formatCurrency( cat.price )}</td>
                            </tr>
                          ) )}
                          {!!CATEGORY_1 && !!CATEGORY_1.products.length
                          && (
                            <tr>
                              <td className="td-report font-weight-bold"><IntlMessages id="total" /></td>
                              <td className="td-report font-weight-bold">{Util.formatNumber( unitSalesCat1 )}</td>
                              <td className="td-report font-weight-bold">{Util.formatCurrency( revenuesCat1 )}</td>
                              <td className="td-report" />
                            </tr>
                          )}
                          {!!CATEGORY_1 && !CATEGORY_1.products.length
                          && (
                            <tr>
                              <td colSpan={5}><IntlMessages id="NoDataFound" /></td>
                            </tr>
                          ) }
                        </tbody>
                      </table>
                    </div>
                  </Col>
                  <Col lg={4} md>
                    <div className="table-responsive">
                      <table
                        className={classnames( 'table table-striped table-products mb-0, table-products-tablet table-header' )}
                      >
                        <thead>
                          <tr>
                            <th className="thead-report"><IntlMessages id="model" /></th>
                            <th className="thead-report"><IntlMessages id="share_u" /></th>
                            <th className="thead-report"><IntlMessages id="share_u2" /></th>
                            <th className="thead-report"><IntlMessages id="price" /></th>
                          </tr>
                        </thead>
                        <tbody>
                          {!!CATEGORY_2 && !!CATEGORY_2.products.length
                        && _.map( CATEGORY_2.products, cat => (
                          <tr>
                            <td className="td-report">{cat.name}</td>
                            <td className="td-report">{Util.formatNumber( cat.unitSales )}</td>
                            <td className="td-report">{Util.formatCurrency( cat.revenues )}</td>
                            <td className="td-report">{Util.formatCurrency( cat.price )}</td>
                          </tr>
                        ) )}
                          {!!CATEGORY_2 && !!CATEGORY_2.products.length
                          && (
                            <tr>
                              <td className="td-report font-weight-bold"><IntlMessages id="total" /></td>
                              <td className="td-report font-weight-bold">{Util.formatNumber( unitSalesCat2 )}</td>
                              <td className="td-report font-weight-bold">{Util.formatCurrency( revenuesCat2 )}</td>
                              <td className="td-report" />
                            </tr>
                          )}
                          {!!CATEGORY_2 && !CATEGORY_2.products.length
                          && (
                            <tr>
                              <td colSpan={5}><IntlMessages id="NoDataFound" /></td>
                            </tr>
                          ) }
                        </tbody>
                      </table>
                    </div>
                  </Col>
                  <Col lg={4} md>
                    <div className="table-responsive">
                      <table
                        className={classnames( 'table table-striped table-products mb-0, table-products-cell table-header' )}
                      >
                        <thead>
                          <tr>
                            <th className="thead-report"><IntlMessages id="model" /></th>
                            <th className="thead-report"><IntlMessages id="share_u" /></th>
                            <th className="thead-report"><IntlMessages id="share_u2" /></th>
                            <th className="thead-report"><IntlMessages id="price" /></th>
                          </tr>
                        </thead>
                        <tbody>
                          {!!CATEGORY_3 && !!CATEGORY_3.products.length
                        && _.map( CATEGORY_3.products, cat => (
                          <tr>
                            <td className="td-report">{cat.name}</td>
                            <td className="td-report">{ Util.formatNumber( cat.unitSales )}</td>
                            <td className="td-report">{Util.formatCurrency( cat.revenues )}</td>
                            <td className="td-report">{Util.formatCurrency( cat.price )}</td>
                          </tr>
                        ) )}
                          {!!CATEGORY_3 && !!CATEGORY_3.products.length
                          && (
                            <tr>
                              <td className="td-report font-weight-bold"><IntlMessages id="total" /></td>
                              <td className="td-report font-weight-bold">{Util.formatNumber( unitSalesCat3 )}</td>
                              <td className="td-report font-weight-bold">{Util.formatCurrency( revenuesCat3 )}</td>
                              <td className="td-report" />
                            </tr>
                          )}
                          {!!CATEGORY_3 && !CATEGORY_3.products.length
                        && (
                          <tr>
                            <td colSpan={5}><IntlMessages id="NoDataFound" /></td>
                          </tr>
                        ) }
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBox>
          );
        } )}
        <Row>
          <Col lg={4}>
            <CardBox
              heading="laptops"
              childrenStyle=""
              headerBg="bg-product-laptop"
              icon="laptop"
              onClick={() => this.toggleChartModal( this.config1, 'laptops', titles1 )}
            >
              <div className="jr-link" id="laptopCaracteristicas" style={{ width: '100%', height: '350px' }} />
              <div className="table-responsive  mt-3">
                <table
                  className="table table-striped table-products mb-0 table-products-laptop table-header"
                >
                  <thead>
                    <tr>
                      <th className="thead-report"><IntlMessages id="model" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-pencil-ruler text-purple" title="Design" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-arrows-alt text-blue" title="Size" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-cookie text-warning" title="Reliability" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-cog text-sepia" title="Weight" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-tachometer-alt text-danger" title="Speed" /></th>
                      <th className="thead-report"><IntlMessages id="price" /></th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map( products1, ( item ) => {
                      const design = _.find( item.attributes, { label: 'Design' } );
                      const size = _.find( item.attributes, { label: 'Size' } );
                      const reliability = _.find( item.attributes, { label: 'Reliability' } );
                      const weight = _.find( item.attributes, { label: 'Weight' } );
                      const speed = _.find( item.attributes, { label: 'Speed' } );
                      return (
                        <tr key={item.id}>
                          <td className="td-report">{item.name}</td>
                          <td className="td-report">{( design ) ? design.valueText : null}</td>
                          <td className="td-report">{( size ) ? size.valueText : null}</td>
                          <td className="td-report">{( reliability ) ? reliability.valueText : null}</td>
                          <td className="td-report">{( weight ) ? weight.valueText : null}</td>
                          <td className="td-report">{( speed ) ? speed.valueText : null}</td>
                          <td className="td-report">{Util.formatCurrency( item.salePrice )}</td>
                        </tr>
                      );
                    } )}
                  </tbody>
                </table>
              </div>
            </CardBox>
          </Col>
          <Col lg={4}>
            <CardBox
              heading="tablets"
              childrenStyle=""
              headerBg="bg-product-tablet"
              icon="tablet-alt"
              onClick={() => this.toggleChartModal( this.config2, 'tablets', titles2 )}
            >
              <div className="jr-link" id="tabletsCaracteristicas" style={{ width: '100%', height: '350px' }} />

              <div className="table-responsive  mt-3">
                <table
                  className="table table-striped table-products mb-0 table-products-tablet table-header"
                >
                  <thead>
                    <tr>
                      <th className="thead-report"><IntlMessages id="model" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-pencil-ruler text-purple" title="Design" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-arrows-alt text-blue" title="Size" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-cookie text-warning" title="Reliability" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-cog text-sepia" title="Weight" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-tachometer-alt text-danger" title="Speed" /></th>
                      <th className="thead-report"><IntlMessages id="price" /></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!products2 && _.map( products2, ( item ) => {
                      const design = _.find( item.attributes, { label: 'Design' } );
                      const size = _.find( item.attributes, { label: 'Size' } );
                      const reliability = _.find( item.attributes, { label: 'Reliability' } );
                      const weight = _.find( item.attributes, { label: 'Weight' } );
                      const speed = _.find( item.attributes, { label: 'Speed' } );
                      return (
                        <tr key={item.id}>
                          <td className="td-report">{item.name}</td>
                          <td className="td-report">{( design ) ? design.valueText : null}</td>
                          <td className="td-report">{( size ) ? size.valueText : null}</td>
                          <td className="td-report">{( reliability ) ? reliability.valueText : null}</td>
                          <td className="td-report">{( weight ) ? weight.valueText : null}</td>
                          <td className="td-report">{( speed ) ? speed.valueText : null}</td>
                          <td className="td-report">{Util.formatCurrency( item.salePrice )}</td>
                        </tr>
                      );
                    } )}
                  </tbody>
                </table>
              </div>
            </CardBox>
          </Col>
          <Col lg={4}>
            <CardBox
              heading="cellulars"
              childrenStyle=""
              headerBg="bg-product-cell"
              icon="mobile-alt"
              onClick={() => this.toggleChartModal( this.config3, 'cellulars', titles3 )}
            >
              <div className="jr-link" id="cellCaracteristicas" style={{ width: '100%', height: '350px' }} />
              <div className="table-responsive  mt-3">
                <table
                  className="table table-striped table-products mb-0 table-products-cell table-header"
                >
                  <thead>
                    <tr>
                      <th className="thead-report"><IntlMessages id="model" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-pencil-ruler text-purple" title="Design" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-arrows-alt text-blue" title="Size" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-cookie text-warning" title="Reliability" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-cog text-sepia" title="Weight" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-tachometer-alt text-danger" title="Speed" /></th>
                      <th className="thead-report"><IntlMessages id="price" /></th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map( products3, ( item ) => {
                      const design = _.find( item.attributes, { label: 'Design' } );
                      const size = _.find( item.attributes, { label: 'Size' } );
                      const reliability = _.find( item.attributes, { label: 'Reliability' } );
                      const weight = _.find( item.attributes, { label: 'Weight' } );
                      const speed = _.find( item.attributes, { label: 'Speed' } );
                      return (
                        <tr key={item.id}>
                          <td className="td-report">{item.name}</td>
                          <td className="td-report">{( design ) ? design.valueText : null}</td>
                          <td className="td-report">{( size ) ? size.valueText : null}</td>
                          <td className="td-report">{( reliability ) ? reliability.valueText : null}</td>
                          <td className="td-report">{( weight ) ? weight.valueText : null}</td>
                          <td className="td-report">{( speed ) ? speed.valueText : null}</td>
                          <td className="td-report">{Util.formatCurrency( item.salePrice )}</td>
                        </tr>
                      );
                    } )}
                  </tbody>
                </table>
              </div>
            </CardBox>
          </Col>
        </Row>

        <ChartModal
          open={chart1Modal}
          title="LAPTOP Sales by Brand ( u )"
          onClose={this.toggleChart1Modal}
          seriesTitle={this.seriesTitle1}
          config={this.config}
        />

        <ChartModal
          open={chart2Modal}
          title="LAPTOP Sales by Brand ( Revenue )"
          onClose={this.toggleChart2Modal}
          seriesTitle={this.seriesTitle1}
          config={this.config2}
        />

        <ChartModal
          open={chart3Modal}
          title="LAPTOP MARKET"
          onClose={this.toggleChart3Modal}
          seriesTitle={this.seriesTitle1}
          config={this.config3}
        />

        <ChartModal
          open={chart4Modal}
          title="Tablet Sales by Brand ( u )"
          onClose={this.toggleChart4Modal}
          seriesTitle={this.seriesTitle2}
          config={this.config4}
        />

        <ChartModal
          open={chart5Modal}
          title="Tablet Sales by Brand ( Revenue )"
          onClose={this.toggleChart5Modal}
          seriesTitle={this.seriesTitle2}
          config={this.config5}
        />

        <ChartModal
          open={chart6Modal}
          title="TABLET MARKET"
          onClose={this.toggleChart6Modal}
          seriesTitle={this.seriesTitle2}
          config={this.config6}
        />

        <ChartModal
          open={chart7Modal}
          title="Cell Sales by Brand ( u )"
          onClose={this.toggleChart7Modal}
          seriesTitle={this.seriesTitle3}
          config={this.config7}
        />
        <ChartModal
          open={chart8Modal}
          title="Cell Sales by Brand ( Revenue )"
          onClose={this.toggleChart8Modal}
          seriesTitle={this.seriesTitle3}
          config={this.config8}
        />
        <ChartModal
          open={chart9Modal}
          title="CELL MARKET"
          onClose={this.toggleChart9Modal}
          seriesTitle={this.seriesTitle3}
          config={this.config9}
        />

        <ChartModal
          open={chartModal}
          title={titleModal}
          onClose={this.toggleChartModal}
          seriesTitle={seriesTitleModal}
          config={configModal}
        />

      </div>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = { toggleLoading: SettingsActions.toggleLoading };

export default connect( mapStateToProps, mapDispatchToProps )( MarketCharts );
