import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import classnames from 'classnames';

import IntlMessages from 'utils/IntlMessages';
import { TextField, Checkbox, AsyncSelect, Buttons } from 'components/custom/FormElements';
import { email } from 'config/InputErrors';
import UserService from 'services/User';

const LicenseEditForm = ( { open, onClose, onSubmit, data, newLabel, editLabel } ) => {
  function loadUserOptions( value, callback ) {
    UserService.getSupervisorUsers( {
      offset: 0,
      limit: 10,
      'filters[criteria]': value,
    } )
      .then( ( response ) => {
        if ( response.ok ) {
          callback( response.data.data );
        } else {
          callback();
        }
      } );
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <Form
        initialValues={data}
        onSubmit={onSubmit}
        validate={( values ) => {
          const errors = {};
          if ( ( !data || !data.id ) && !values.userEmail && !values.user ) {
            errors.enterValue = 'Required';
          }
          return errors;
        }}
        render={( { handleSubmit, errors, form, submitFailed } ) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle className="bg-dark text-white">
              <IntlMessages id={data && data.id ? editLabel : newLabel} />
            </DialogTitle>
            <DialogContent className="p-3 overflow-visible">
              {data && data.id
                ? (
                  <div className="row">
                    <div className="col-12">
                      <Checkbox field="enabled" label="enabled" />
                    </div>
                  </div>
                )
                : (
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <p
                          className={classnames( { 'Mui-error': submitFailed && errors.enterValue } )}
                        >
                          <IntlMessages id="editLicenseMsg" />
                        </p>
                      </div>
                    </div>
                    <div className="row">

                      <div className="col-md-6">
                        <AsyncSelect
                          field="user"
                          label="user"
                          labelKey="email"
                          loadOptions={loadUserOptions}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField field="userEmail" validate={email} label="email" />
                      </div>
                    </div>
                  </div>
                )
              }
            </DialogContent>
            <DialogActions>
              <Buttons onClickCancel={onClose} />
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

export default LicenseEditForm;
