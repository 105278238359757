import React from 'react';
import _ from 'lodash';
import IntlMessages from 'utils/IntlMessages';

const CardBox = ( { heading, subHeading, children, styleName, cardStyle, childrenStyle, headerOutside, headingStyle, onClick } ) => {

  return (
    <div className={`${styleName}`} onClick={onClick}>
      {headerOutside &&
      <div className="jr-entry-header">
        <h3 className="entry-heading">{heading ? <IntlMessages id={heading}/> : ''}</h3>
        {!!subHeading && <div className="entry-description">{subHeading}</div>}
      </div>
      }

      <div className={`jr-card ${cardStyle}`}>
        {!headerOutside &&
        ( heading &&
          <div className={`jr-card-header ${headingStyle}`}>
            <h3 className="card-heading">{heading ? _.isString( heading ) ?
              <IntlMessages id={heading}/> : heading : ''}</h3>
            {!!subHeading && <div className="sub-heading">{subHeading}</div>}
          </div>
        )}
        <div className={`jr-card-body ${childrenStyle}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default CardBox;

CardBox.defaultProps = {
  cardStyle: 'px-4 py-3 mb-0',
  headingStyle: 'mb-3',
  childrenStyle: '',
  styleName: 'col-12 p-0 mt-3'
};
