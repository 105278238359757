import api from '../config/Api';

const endpointV2 = 'v2/session';

export default {
  getProducts( sessionId, params ) {
    return api.get( `${endpointV2}/${sessionId}/product-distribution`, params );
  },
  getServices( sessionId, params ) {
    return api.get( `${endpointV2}/${sessionId}/service-distribution`, params );
  },
  getBundles( sessionId, params ) {
    return api.get( `${endpointV2}/${sessionId}/bundle-distribution`, params );
  },
};
