import React from 'react';
import { Badge } from 'reactstrap';
import { AppBar } from '@material-ui/core';
import moment from 'moment';

import IntlMessages from 'utils/IntlMessages';
import Util from 'utils/Util';

const SessionInfoBar = React.memo( ( { data, workgroup } ) => (
  <div className="app-header-horizontal">
    <AppBar position="relative" className="cbg-primary-dark student-subheader">
      {data.currentSupervisor
        ? (
          <div className="d-flex justify-content-between align-items-center">
            <span className="ml-2 text-uppercase">
                  Prof:
              {' '}
              {data.currentSupervisor.name}
              {', '}
              <IntlMessages id="round" />
              {': '}
              {data.currentRound ? data.currentRound.largeName : '-'}
              {' '}
              <IntlMessages id="EndsAt" />
              {': '}
              {data.currentRound.endDate ? moment.utc( Number( data.currentRound.endDate ) ).format( 'DD-MM-YYYY HH:mm' ) : '-'}
              {' '}
              {data.currentRound.endDate ? data.timeZone : ' '}
              {workgroup.name ? `, Group: ${workgroup.name}` : '.'}
            </span>
            <div>
              <Badge className="mr-4 mt-2 text-uppercase bg-white text-muted pointer" pill>
                <IntlMessages id="cash" />
                {': '}
                {Util.formatCurrencyNoDecimals( workgroup.cash || 0 )}
              </Badge>
              <Badge className="mr-4 mt-2 text-uppercase bg-white text-muted pointer" pill>
                <IntlMessages id="points" />
                {': '}
                {Util.formatNumber( workgroup.points || 0 )}
              </Badge>
            </div>
          </div>
        )
        : <span />
      }
    </AppBar>
  </div>
) );

export default SessionInfoBar;
