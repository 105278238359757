import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import IntlMessages from 'utils/IntlMessages';

export default ( { onClickCancel, cancelTo, cancelBtn = true, disabledSubmit = false } ) => (
  [
    <Button key="save" variant="contained" color="primary" className="jr-btn jr-btn-lg"
            disabled={disabledSubmit}
            type="submit">
      <i className="fa fa-save"/>
      <IntlMessages id="saveBtn"/>
    </Button>,

    cancelBtn ?
      cancelTo
        ? (
        <Button key="cancel" variant="contained"
                className="jr-btn jr-btn-lg bg-blue-grey text-white" component={Link} to={cancelTo}
                onClick={onClickCancel}
        >
          <i className="fa fa-times"/>
          <IntlMessages id="cancelBtn"/>
        </Button>
        )
        : (
          <Button key="cancel" variant="contained"
                  className="jr-btn jr-btn-lg bg-blue-grey text-white" onClick={onClickCancel}>
            <i className="fa fa-times"/>
            <IntlMessages id="cancelBtn"/>
          </Button>
        ) :
      null
  ]
);
