import React from 'react';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withRouter } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import classnames from 'classnames';

import CustomScrollbars from 'components/theme/CustomScrollbars';
import CardBox from 'components/theme/CardBox';
import IntlMessages from 'utils/IntlMessages';

export default withRouter( ( { items, cardClasses, history } ) => (
  <CardBox
    styleName={classnames( 'fixed-sidebar custom-fixed-sidebar', cardClasses )}
    cardStyle="py-2 px-0 h-100 shadow-none"
    childrenStyle="h-100"
  >
    <List className="pinned-list h-100" subheader={<div/>}>
      <CustomScrollbars className="scrollbar h-100">
        {items.map( ( listItem, index ) => (
          listItem.headerId ?
            <div key={`section-${listItem.headerId}`}>
              <ListSubheader
                className="text-gray-2 text-uppercase"
              >
                {index > 0 && <Divider className="bg-gray-3"/>}
                {<IntlMessages id={listItem.heading}/>}
              </ListSubheader>
              {listItem.items.map( item => (
                [
                  <ListItem
                    button
                    disabled={item.disabled}
                    key={`section-${listItem.headerId}-${item.id}-1`}
                    onClick={() => history.push( item.url )}
                    selected={history.location.pathname === item.url}
                  >
                    <ListItemText disableTypography primary={<IntlMessages id={item.name}/>}/>
                    <i className={`zmdi zmdi-${item.icon} text-${item.color} font-size-20 ml-2`}/>
                  </ListItem>,
                ]
              ) )}
            </div> :
            [
              <ListItem
                button
                disabled={listItem.disabled}
                key={`section-${listItem.id}-1`}
                onClick={() => history.push( listItem.url )}
                selected={history.location.pathname === listItem.url}
              >
                <ListItemText disableTypography primary={<IntlMessages id={listItem.name}/>}/>
                <i
                  className={`zmdi zmdi-${listItem.icon} text-${listItem.color} font-size-20 ml-2`}/>
              </ListItem>,
            ]
        ) )}
      </CustomScrollbars>
    </List>
  </CardBox>
) );
