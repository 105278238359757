import api from '../config/Api';

const endpoint = 'v1/attribute';

export default {

  getLastVersion( params ) {
    return api.get( `${endpoint}/last/version`, params );
  },

  getAttributes( params ) {
    return api.get( endpoint, params );
  },

  saveAttributes( data ) {
    return api.put( endpoint, data );
  },
};
