import React from 'react';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import _ from 'lodash';

import IntlMessages from 'utils/IntlMessages';

class SubHeader extends React.Component {
  onTabSelect = ( e, tab ) => {
    this.props.history.push( this.props.tabs[tab].link );
  };

  render() {
    const activeTab = _.findIndex( _.map( this.props.tabs, 'match' ), item => this.props.location.pathname.indexOf( item )  !== -1);

    return (
      <AppBar position="relative" color="default" classes={{ root: 'app-subheader' }}>
        <Tabs
          value={activeTab !== -1 ? activeTab : 0}
          onChange={this.onTabSelect}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="on"
        >
          {_.map( this.props.tabs, tab => <Tab className="tab" key={tab.name}
                                               label={<IntlMessages id={tab.name}/>}/> )}
        </Tabs>
      </AppBar>
    );
  }
}

export default withRouter( SubHeader );
