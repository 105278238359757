import pink from '@material-ui/core/colors/pink';

export default {
  palette: {
    primary: {
      light: '#05dacd',
      main: '#04A89E',
      dark: '#00665F',
      contrastText: '#fff',
    },
    secondary: {
      light: pink[300],
      main: pink.A200,
      dark: pink[700],
      contrastText: '#fff',
    },
  },
  status: { danger: 'orange' },
  typography: {
    button: {
      fontWeight: 400,
      textAlign: 'capitalize',
    },
  },
};
