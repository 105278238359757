import React from 'react';
import ReactTable from 'react-table';
import _ from 'lodash';
import classnames from 'classnames';

import TablePagination from '../Pagination';

class CustomTable extends React.Component {
  render() {
    const { showPageSizeOptions, className, ...rest } = this.props;

    return (
      <ReactTable
        className={classnames( 'app-table-V2 -striped', className )}
        defaultPageSize={10}
        minRows={0}
        pageSizeOptions={[10, 20, 30]}
        resizable={false}
        showPaginationBottom={false}
        showPaginationTop
        showPageSizeOptions={_.isUndefined( showPageSizeOptions ) ? true : showPageSizeOptions}
        PaginationComponent={props => <TablePagination {...props} />}
        getTrProps={() => {
          return {
            className: 'pointer'
          };
        }}
        {...rest}
      />
    );
  }
}

export default CustomTable;
