import api from '../config/Api';

const endpoint = 'v1/user';
const endpoint2 = 'v2/user';

export default {
  getCurrentUser( data ) {
    if ( data && data.access_token ) return api.get( `${endpoint}/me?app_movil=true`, null, { headers: { Authorization: `Bearer ${data.access_token}` } } );
    return api.get( `${endpoint}/me?app_movil=true` );
  },

  getUsers( params ) {
    return api.get( endpoint, params );
  },

  getSupervisorUsers( params ) {
    return api.get( 'v1/user-supervisor', params );
  },

  getUser( id ) {
    return api.get( `${endpoint}/${id}` );
  },

  updateUser( id, data ) {
    return api.put( `${endpoint}/${id}`, data );
  },

  saveUser( data ) {
    return api.post( endpoint, data );
  },

  validateCode( data ) {
    return api.post( `${endpoint2}/validate/registration-code`, data );
  },

  deleteUser( id ) {
    return api.delete( `${endpoint}/${id}` );
  },

  changePassword( data ) {
    return api.post( `${endpoint}/change-password`, data );
  },

  recoverPassword( data ) {
    return api.post( `${endpoint}/recover-password`, data );
  },

  recoverPasswordConfirmation( token, data ) {
    return api.post( `${endpoint}/recover-password/${token}`, data );
  },

  updateUserProfile( data ) {
    return api.put( `${endpoint2}/setting`, data );
  },

  registerByCode( data ) {
    return api.post( `${endpoint2}/register-by-code`, data );
  },
};
