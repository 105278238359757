import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Error404 from 'components/theme/Error404';
import Dashboard from './Dashboard';
import Detail from './Detail';

const Sessions = ( { entityId, match } ) => (
  <Switch>
    <Route
      path={`${match.url}/:id`}
      render={props => <Detail {...props} entityId={entityId} />}
    />
    <Route path={match.url} exact render={props => <Dashboard {...props} entityId={entityId} />} />
    <Route component={Error404} />
  </Switch>
);

export default Sessions;
