import React from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  BarChart,
  Bar,
} from 'recharts';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import async from 'async';

import IntlMessages from 'utils/IntlMessages';
import { TextField } from 'components/custom/FormElements';
import CardBox from 'components/theme/CardBox';
import Table from 'components/custom/Table/Custom';
import { required } from 'config/InputErrors';
import SettingsActions from 'store/reducers/Settings';
import ProductService from 'services/Product';
import CommodityService from 'services/Commodity';
import ServiceService from 'services/Service';
import BundleService from 'services/Bundle';
import AxisTick from 'components/custom/Charts/AxisTick';
import ContainerHeader from 'components/theme/ContainerHeader';
import Util from 'utils/Util';

class Products extends React.Component {
  state = {
    commodities: [],
    selectedCommodity: {},
    globalAchievement: [],
  };

  componentDidMount() {
    const { toggleAlert, user } = this.props;
    if ( !user.activeSession ) {
      this.setState( { corruptedData: true } );
      return toggleAlert( 'ERROR' );
    }

    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      this.setState( { corruptedData: true } );
      return toggleAlert( 'ERROR' );
    }
    this.activeSession = user.activeSession;
    this.getData();
  }

  getData = () => {
    const { toggleAlert, toggleLoading } = this.props;

    toggleLoading( true );
    async.parallel( {
      commodities: ( cb ) => {
        async.waterfall( [
          this.getCommodities,
          ( data, wCb ) => {
            const { selectedCommodity } = this.state;
            const commodity = selectedCommodity.id
              ? selectedCommodity : data.commodities.length ? data.commodities[0] : null;

            if ( !commodity ) {
              return wCb ? wCb( null, {
                ...data,
                selectedCommodity: {},
              } ) : null;
            }
            this.getCommodity( commodity, ( error, response ) => {
              if ( error ) return wCb( true );
              wCb( null, { ...data, ...response } );
            } );
          },
        ], cb );
      },
      globalAchievement: this.getGlobalAchievement,
    }, ( error, results ) => {
      toggleLoading( false );

      if ( error ) {
        this.setState( { corruptedData: true } );
        return toggleAlert( 'ERROR' );
      }
      this.setState( { globalAchievement: results.globalAchievement, ...results.commodities } );
    } );
  };

  getGlobalAchievement = ( cb ) => {
    const filters = { workgroup: this.license.workGroup.id };
    CommodityService.getGlobalAchievement( this.activeSession.id, filters )
      .then( ( response ) => {
        if ( !response.ok ) return cb( true );
        cb( null, response.data );
      } );
  };

  getCommodities = ( cb ) => {
    const filters = {
      workgroup: this.license.workGroup.id,
      limit: -1,
    };
    CommodityService.getCommodities( this.activeSession.id, filters )
      .then( ( response ) => {
        if ( !response.ok ) return cb( true );
        cb( null, { commodities: response.data.data } );
      } );
  };

  getCommodity = ( commodity, cb ) => {
    const { toggleLoading, toggleAlert } = this.props;
    if ( !cb ) toggleLoading( true );

    const filters = { workgroup: this.license.workGroup.id };
    let promise = null;
    switch ( commodity.owner ) {
    case 'SERVICE':
      promise = ServiceService.getService( this.activeSession.id, {
        ...filters,
        service: commodity.id,
      } );
      this.roundAttr = 'serviceRound';
      break;
    case 'PRODUCT':
      promise = ProductService.getProduct( this.activeSession.id, {
        ...filters,
        product: commodity.id,
      } );
      this.roundAttr = 'productions';
      break;
    case 'BUNDLE':
      promise = BundleService.getBundle( this.activeSession.id, {
        ...filters,
        bundle: commodity.id,
      } );
      this.roundAttr = 'bundleRound';
      break;
    default:
      promise = null;
    }

    if ( !promise ) return cb ? cb() : null;

    promise.then( ( response ) => {
      if ( !response.ok ) return cb ? cb( true ) : toggleAlert( 'ERROR' );

      const formattedData = {
        ...response.data,
        owner: commodity.owner,
      };

      if ( cb ) {
        cb( null, { selectedCommodity: formattedData } );
      } else {
        toggleLoading( false );
        this.setState( { selectedCommodity: formattedData } );
      }
    } );
  };

  submitForm = ( formData ) => {
    const { selectedCommodity } = this.state;
    const { toggleAlert, toggleLoading } = this.props;

    toggleLoading( true );
    const dataToSend = {
      workgroup: this.license.workGroup.id,
      targets: [{
        commodity: selectedCommodity.id,
        value: formData.target,
      }],
    };

    CommodityService.updateCommodityTarget( this.activeSession.id, dataToSend )
      .then( ( response ) => {
        if ( !response.ok ) {
          toggleLoading( false );
          return toggleAlert( response.errors );
        }

        toggleAlert( 'dataSaved', 'info' );

        async.parallel( [
          this.getCommodities,
          cb => this.getCommodity( selectedCommodity, cb ),
        ], ( error, results ) => {
          toggleLoading( false );

          if ( error ) return toggleAlert( error );
          this.setState( {
            commodities: _.get( results, '0.commodities', [] ),
            selectedCommodity: _.get( results, '1.selectedCommodity', {} ),
          } );
        } );
      } );
  };

  render() {
    const { commodities, selectedCommodity, globalAchievement, corruptedData } = this.state;

    return (
      <Form
        initialValues={selectedCommodity}
        onSubmit={this.submitForm}
        render={( { handleSubmit } ) => (
          <form onSubmit={handleSubmit}>
            <ContainerHeader
              title="target"
              rightContent={(
                <Button
                  disabled={corruptedData}
                  variant="contained"
                  color="primary"
                  className="jr-btn jr-btn-lg"
                  type="submit"
                >
                  <i className="fa fa-save" />
                  <IntlMessages id="saveBtn" />
                </Button>
              )}
            />

            <div className="row">
              <div className="col-12">
                <ExpansionPanel defaultExpanded className="mb-3 expansion-panel">
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><IntlMessages id="commoditiesList" /></Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className="p-0 d-block">
                    <div className="col-12 p-0">
                      {commodities.length === 0
                        ? (
                          <div
                            className="text-muted h-100 d-flex align-items-center justify-content-center p-3"
                          >
                            No commodities found
                          </div>
                        )
                        : (
                          <Table
                            data={commodities}
                            pageSize={commodities.length}
                            rowsText=""
                            showPaginationTop={false}
                            columns={[
                              {
                                Header: <IntlMessages id="category" />,
                                accessor: 'category',
                              },
                              {
                                Header: <IntlMessages id="code" />,
                                accessor: 'code',
                                maxWidth: 100,
                              },
                              {
                                Header: <IntlMessages id="name" />,
                                accessor: 'name',
                              },
                              {
                                Header: <IntlMessages id="description" />,
                                accessor: 'description',
                              },
                              {
                                Header: <IntlMessages id="cost" />,
                                accessor: 'cost',
                                Cell: ( { value } ) => Util.formatCurrency( value ),
                              },
                              {
                                Header: <IntlMessages id="lastRoundPrice" />,
                                accessor: 'lastRoundPrice',
                                Cell: ( { value } ) => Util.formatCurrency( value ),
                              },
                              {
                                Header: <IntlMessages id="production" />,
                                accessor: 'lastRoundProduction',
                                Cell: ( { value } ) => Util.formatNumber( value ),
                              },
                              {
                                Header: <IntlMessages id="sales" />,
                                accessor: 'lastRoundSales',
                                Cell: ( { value } ) => Util.formatNumber( value ),
                              },
                              {
                                Header: <IntlMessages id="target" />,
                                accessor: 'target',
                                Cell: ( { value } ) => Util.formatNumber( value ),
                              },
                              {
                                Header: <IntlMessages id="percentTarget" />,
                                accessor: 'percentTarget',
                              },
                              {
                                Header: <IntlMessages id="nextRoundTarget" />,
                                accessor: 'currentTarget',
                                Cell: ( { value } ) => Util.formatNumber( value ),
                              },
                            ]}
                            getTrProps={( state, rowInfo ) => (
                              {
                                className: selectedCommodity.id === rowInfo.original.id ? 'font-weight-bold pointer' : 'pointer',
                                onClick: () => this.getCommodity( rowInfo.original ),
                              } )}
                          />
                        )}
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            </div>

            <div className="row">
              {selectedCommodity.id
                ? (
                  <div className="col-lg-6">
                    <CardBox styleName="mt-3 mt-lg-0" heading="information">
                      <div className="row">
                        <div className="col-md-6">
                          <p>
                            <strong>
                              <IntlMessages id="commodity" />
                              :
                              {' '}
                            </strong>
                            {' '}
                            {selectedCommodity.name}
                          </p>
                          <p>
                            <strong>
                              <IntlMessages id="description" />
                              {' '}
                            </strong>
                            :
                            {' '}
                            {selectedCommodity.description}
                          </p>
                        </div>
                        <div className="col-md-6">
                          <TextField
                            field="target"
                            label="nextRoundTarget"
                            type="number"
                            validate={required}
                          />
                        </div>
                      </div>
                    </CardBox>

                    <CardBox heading="targetVsSales" styleName="mt-3">
                      <div className="pt-3">
                        <ResponsiveContainer width="100%" height={200}>
                          <BarChart
                            data={selectedCommodity[this.roundAttr]}
                            margin={{
                              left: -10,
                              right: 20,
                            }}
                          >
                            <XAxis
                              dataKey="round.name"
                              height={60}
                              tick={<AxisTick />}
                            />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Legend verticalAlign="top" />
                            <Bar dataKey="target" name="Target" fill="#3367d6" />
                            <Bar dataKey="unitSales" name="Sales" fill="#ffc658" />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </CardBox>
                  </div>
                ) : null }

              <div className="col-lg-6">
                <CardBox heading="productHistory" styleName="mt-0">
                  <div className="pt-3">
                    <ResponsiveContainer width="100%" height={200}>
                      <BarChart
                        data={commodities}
                        margin={{
                          left: -10,
                          right: 20,
                        }}
                      >
                        <XAxis
                          dataKey={item => `${item.name.slice( 0, 8 )}${item.name.length > 8 ? '...' : ''}`}
                          height={60}
                          tick={<AxisTick />}
                        />
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Legend verticalAlign="top" />
                        <Bar dataKey="target" name="Target" fill="#ffc658" />
                        <Bar dataKey="lastRoundSales" name="Sales" fill="#3367d6" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </CardBox>

                <CardBox styleName="mt-3" heading="globalTargetAchievement">
                  <div className="pt-3">
                    <ResponsiveContainer width="100%" height={200}>
                      <LineChart
                        data={globalAchievement}
                        margin={{
                          left: -10,
                          right: 20,
                        }}
                      >
                        <XAxis
                          dataKey="round"
                          height={60}
                          tick={<AxisTick />}
                        />
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Legend verticalAlign="top" />
                        <Line type="monotone" dataKey="totalPercentTarget" name="% Target" stroke="#ffc658" />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </CardBox>
              </div>
            </div>
          </form>
        )}
      />
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( Products );
