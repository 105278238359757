import { createReducer, createActions } from 'reduxsauce';
import {
  FIXED_DRAWER,
  VERTICAL_NAVIGATION,
  INSIDE_THE_HEADER,
} from 'constants/ActionTypes';
import { DARK_INDIGO } from 'constants/ThemeColors';

const rltLocale = ['ar'];
export const INITIAL_STATE = {
  navCollapsed: true,
  drawerType: FIXED_DRAWER,
  themeColor: DARK_INDIGO,
  darkTheme: false,
  width: window.innerWidth,
  isDirectionRTL: false,
  navigationStyle: VERTICAL_NAVIGATION,
  horizontalNavPosition: INSIDE_THE_HEADER,
  isLoading: false,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
};

const { Types, Creators } = createActions( {
  toggleCollapsedNav: ['isNavCollapsed'],
  setDrawerType: ['drawerType'],
  updateWindowWidth: ['width'],
  setThemeColor: ['color'],
  setDarkTheme: null,
  switchLanguage: ['locale'],
  changeDirection: null,
  changeNavigationStyle: ['payload'],
  setHorizontalMenuPosition: ['payload'],
  toggleAlert: ['msg', 'alertType', 'translate'],
  openNoSessionAlert: null,
  closeNoSessionAlert: null,
  toggleLoading: ['isLoading'],
}, {} );

const toggleCollapsedNav = state => (
  { ...state, navCollapsed: !state.navCollapsed }
);
const setDrawerType = ( state, { drawerType } ) => ( { ...state, drawerType } );
const updateWindowWidth = ( state, { width } ) => ( { ...state, width } );
const setThemeColor = ( state, { color } ) => (
  { ...state, darkTheme: false, themeColor: color }
);
const setDarkTheme = state => ( { ...state, darkTheme: !state.darkTheme } );
const switchLanguage = ( state, { locale } ) => ( {
  ...state,
  locale,
  isDirectionRTL: rltLocale.includes( locale.locale ),
} );
const changeDirection = state => ( { ...state, isDirectionRTL: !state.isDirectionRTL } );
const changeNavigationStyle = ( state, { payload } ) => ( { ...state, navigationStyle: payload } );
const setHorizontalMenuPosition = ( state, { payload } ) => (
  { ...state, horizontalNavPosition: payload }
);
const toggleAlert = ( state, { msg, alertType, translate } ) => ( {
  ...state,
  alert: { date: new Date().valueOf(), msg, alertType: alertType || 'error', translate },
} );
const openNoSessionAlert = ( state ) => {
  if ( state.alertNoSessionOpened ) return state;
  return { ...state, alertNoSessionOpened: true };
};
const closeNoSessionAlert = state => ( { ...state, alertNoSessionOpened: false } );
const toggleLoading = ( state, { isLoading } ) => ( { ...state, isLoading } );
const locationChange = state => ( { ...state, navCollapsed: false } );

export const reducer = createReducer( INITIAL_STATE, {
  [Types.TOGGLE_COLLAPSED_NAV]: toggleCollapsedNav,
  [Types.SET_DRAWER_TYPE]: setDrawerType,
  [Types.UPDATE_WINDOW_WIDTH]: updateWindowWidth,
  [Types.SET_THEME_COLOR]: setThemeColor,
  [Types.SET_DARK_THEME]: setDarkTheme,
  [Types.SWITCH_LANGUAGE]: switchLanguage,
  [Types.CHANGE_DIRECTION]: changeDirection,
  [Types.CHANGE_NAVIGATION_STYLE]: changeNavigationStyle,
  [Types.SET_HORIZONTAL_MENU_POSITION]: setHorizontalMenuPosition,
  [Types.TOGGLE_ALERT]: toggleAlert,
  [Types.OPEN_NO_SESSION_ALERT]: openNoSessionAlert,
  [Types.CLOSE_NO_SESSION_ALERT]: closeNoSessionAlert,
  [Types.TOGGLE_LOADING]: toggleLoading,
  '@@router/LOCATION_CHANGE': locationChange,
} );

export default Creators;
