import api from '../config/Api';

const endpoint = 'v1/session';
const endpoint2 = 'v2/session';

export default {

  getGeneralData( id, params ) {
    return api.get( `${endpoint}/${id}/dashboard/student/general-data`, params );
  },

  getRankingRounds( id, params ) {
    return api.get( `${endpoint}/${id}/dashboard/student/ranking-round`, params );
  },

  getRankingChart( id, params ) {
    return api.get( `${endpoint}/${id}/dashboard/student/ranking-graph`, params );
  },

  getMyProducts( id, params ) {
    return api.get( `${endpoint}/${id}/dashboard/student/my-products`, params );
  },

  getMyServices( id, params ) {
    return api.get( `${endpoint}/${id}/dashboard/student/my-services`, params );
  },

  getMyBundles( id, params ) {
    return api.get( `${endpoint}/${id}/dashboard/student/my-bundles`, params );
  },

  getHistoricalChart( id, workgroupId, params ) {
    return api.get( `${endpoint2}/${id}/workgroup/${workgroupId}/profit-loss`, params );
  },

  getHistoricalMarket( id, workgroupId, categoryId, params ) {
    return api.get( `${endpoint2}/${id}/market/products/?workgroup=${workgroupId}&category=${categoryId}`, params );
  },

  getHistoricalProducts( id, workgroupId, params ) {
    return api.get( `${endpoint2}/${id}/product/information?workgroup=${workgroupId}`, params );
  },

  getHistoricalBundle( id, params ) {
    return api.get( `${endpoint2}/${id}/bundle/information`, params );
  },

  getCustomerInformation( id, params ) {
    return api.get( `${endpoint2}/${id}/customer/information`, params );
  },
  getCustomerEvolution( id, params ) {
    return api.get( `${endpoint2}/${id}/customer/evolution`, params );
  },
  getCustomerRetention( id, params ) {
    return api.get( `${endpoint2}/${id}/customer/retention/rate`, params );
  },
  getPLComparison( id, params ) {
    return api.get( `${endpoint2}/${id}/profit-loss/all-workgroup`, params );
  },
  getStockValueAllWorkgroup( id, params ) {
    return api.get( `${endpoint2}/${id}/stock-value/all-workgroup`, params );
  },

};
