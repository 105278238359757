import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import classnames from 'classnames';
import _ from 'lodash';
import moment from 'moment';

import ContainerHeader from 'components/theme/ContainerHeader';
import CardBox from 'components/theme/CardBox';
import { TextField, Buttons, DatePicker, Checkbox } from 'components/custom/FormElements';
import SettingsActions from 'store/reducers/Settings';
import UserService from 'services/User';
import IntlMessages from 'utils/IntlMessages';
import { composeValidators, email, required, equalTo } from 'config/InputErrors';

class Edit extends React.Component {
  state = { data: {} };

  entityId = null;

  componentDidMount() {
    const { match } = this.props;
    this.entityId = match.params.id;

    if ( this.entityId ) {
      this.getEntity();
    }
  }

  getEntity = () => {
    const { toggleLoading } = this.props;
    toggleLoading( true );
    UserService.getUser( this.entityId )
      .then( ( response ) => {
        toggleLoading( false );

        if ( response.ok ) {
          this.setState( { data: response.data } );
        }
      } );
  };

  submitForm = ( data, form ) => {
    const { toggleLoading, toggleAlert, history } = this.props;
    const dataToSend = {};

    toggleLoading( true );
    _.map( _.keys( form.getState().modified ), ( key ) => {
      if ( key !== 'passwordConfirmation' ) dataToSend[key] = data[key];
    } );

    if ( dataToSend.birthDate ) dataToSend.birthDate = moment( Number( dataToSend.birthDate ) ).format( 'DD/MM/YYYY' );
    const promise = this.entityId ? UserService.updateUser( this.entityId, dataToSend )
      : UserService.saveUser( dataToSend );
    promise.then( ( response ) => {
      toggleLoading( false );

      if ( !response.ok ) return toggleAlert( response.errors );

      toggleAlert( 'dataSaved', 'info' );
      history.push( '/users' );
    } );
  };

  render() {
    const { match } = this.props;
    const { data } = this.state;

    return (
      <div className="app-wrapper">
        <ContainerHeader match={match} title={this.entityId ? 'editUser' : 'newUser'} />

        <CardBox>
          <Form
            initialValues={data}
            onSubmit={this.submitForm}
            render={( { handleSubmit, form } ) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className={classnames( {
                    'col-md-5': !this.entityId,
                    'col-md-6': this.entityId,
                  } )}
                  >
                    <TextField field="name" label="name" validate={required} />
                    <TextField field="lastName" label="lastName" />
                    <TextField field="phone" label="phone" />
                    <DatePicker field="birthDate" label="birthdate" />
                  </div>

                  <div className={classnames( {
                    'col-md-4': !this.entityId,
                    'col-md-6': this.entityId,
                  } )}
                  >
                    <TextField
                      field="email"
                      label="email"
                      validate={composeValidators( required, email )}
                    />
                    <TextField field="username" label="username" validate={required} />
                    <Checkbox field="enabled" label="enabled" />
                  </div>
                  {!this.entityId
                  && (
                    <div className="col-md-3">
                      <TextField field="plainPassword" label="password" type="password" validate={required} />
                      <TextField
                        field="passwordConfirmation"
                        label="passwordConfirmation"
                        type="password"
                        translateValues={{ label: <IntlMessages id="password" /> }}
                        validate={composeValidators( required, equalTo( 'plainPassword' ) )}
                      />
                    </div>
                  )}
                </div>

                <div className="row">
                  <div className="col-md-12 text-right">
                    <Buttons cancelTo="/users" />
                  </div>
                </div>
              </form>
            )}
          />
        </CardBox>
      </div>
    );
  }
}

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps,
)( Edit );
