import React from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import moment from 'moment';

import CardBox from 'components/theme/CardBox';
import { TextField, Buttons } from 'components/custom/FormElements';
import IntlMessages from 'utils/IntlMessages';
import { required } from 'config/InputErrors';
import SessionService from 'services/Session';
import SettingsActions from 'store/reducers/Settings';
import Util from 'utils/Util';

class Info extends React.Component {
  state = { data: {} };

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { toggleLoading, sessionId } = this.props;
    toggleLoading( true );

    SessionService.getSession( sessionId )
      .then( ( response ) => {
        toggleLoading( false );

        this.setState( { data: response.data } );
      } );
  }

  submitForm = ( formData ) => {
    const { toggleLoading, toggleAlert, sessionId } = this.props;
    toggleLoading( true );

    SessionService.updateSession( sessionId, {
      name: formData.name,
      maxUserGroup: formData.maxUserGroup,
    } )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) return toggleAlert( response.errors );
        this.setState( { data: response.data } );
        toggleAlert( 'dataSaved', 'info' );
      } );
  };

  render() {
    const { data } = this.state;
    return (
      <Form
        initialValues={data}
        onSubmit={this.submitForm}
        render={( { handleSubmit, form } ) => (
          <form onSubmit={handleSubmit}>
            <CardBox styleName="col-12 p-0" heading="session">
              <div className="row mt-3">
                <div className="col-md-4">
                  <p>
                    <strong>
                      <IntlMessages id="numberGroup" />
                    </strong>
                    {': '}
                    {data.numberGroup || '---'}
                  </p>
                  <p>
                    <strong>
                      <IntlMessages id="startDate" />
                    </strong>
                    {': '}
                    {data.startDate ? moment( Number( data.startDate ) )
                      .format( 'DD/MM/YYYY' ) : '---'}
                  </p>
                  <p className="m-0">
                    <strong>
                      <IntlMessages id="endDate" />
                    </strong>
                    {': '}
                    {data.endDate ? moment( Number( data.endDate ) )
                      .format( 'DD/MM/YYYY' ) : '---'}
                  </p>
                </div>
                <div className="col-md-4">
                  <p>
                    <strong>
                      <IntlMessages id="licensesTotal" />
                    </strong>
                    {': '}
                    {Util.formatNumber( data.licensesTotal || '---' )}
                  </p>
                  <p>
                    <strong>
                      <IntlMessages id="availableLicenses" />
                    </strong>
                    {': '}
                    {Util.formatNumber(
                      data && data.id ? data.licensesTotal - data.licensesWithUserActive : '---',
                    )}
                  </p>
                  <p>
                    <strong>
                      <IntlMessages id="initialMoneyPerGroup" />
                    </strong>
                    {': '}
                    {Util.formatCurrency( data.initialMoneyPerGroup || '---' )}
                  </p>
                  <p className="m-0">
                    <strong>
                      <IntlMessages id="fixCostPerGroup" />
                    </strong>
                    {': '}
                    {Util.formatCurrency( data.fixCostPerGroup || '---' )}
                  </p>
                </div>
                <div className="col-sm-4">
                  <p>
                    <strong>
                      <IntlMessages id="maxUserGroup" />
                    </strong>
                    {': '}
                    {Util.formatNumber( data.maxUserGroup || '---' )}
                  </p>
                  <p>
                    <strong>
                      <IntlMessages id="stockFinalValue" />
                    </strong>
                    {': '}
                    {Util.formatNumber( data.stockFinalValue || '---' )}
                  </p>
                  <p>
                    <strong>
                      <IntlMessages id="taxes" />
                      :
                      {' '}
                    </strong>
                    {Util.formatNumber( data.taxes || '---' )}
                  </p>
                  <p className="m-0">
                    <strong>
                      <IntlMessages id="registrationCode" />
                      :
                      {' '}
                    </strong>
                    { data.registrationCode }
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <TextField field="name" label="name" validate={required} />
                </div>
                <div className="col-md-6">
                  <TextField
                    field="maxUserGroup"
                    label="maxUserGroup"
                    validate={required}
                  />
                </div>
              </div>
            </CardBox>

            <div className="row mt-3">
              <div className="col-md-12 text-right">
                <Buttons cancelTo="/universities" />
              </div>
            </div>
          </form>
        )}
      />
    );
  }
}

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps,
)( Info );
