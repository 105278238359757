import api from '../config/Api';

const endpoint = 'v1/service-type';

export default {

  getServiceTypes( params ) {
    return api.get( endpoint, params );
  },

  updateServiceTypes( data ) {
    return api.put( endpoint, data );
  },
};
