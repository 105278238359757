import api from '../config/Api';

const endpoint = 'v1/session';
const endpointV2 = 'v2/session';

export default {

  updateProduction( sessionId, data ) {
    return api.put( `${endpoint}/${sessionId}/production`, data );
  },

  updateProductionV2( sessionId, data ) {
    return api.put( `${endpointV2}/${sessionId}/production`, data );
  },

  updateProductionPriceV2( sessionId, data ) {
    return api.put( `${endpointV2}/${sessionId}/production/price`, data );
  },

  updateProductionDistributionV2( sessionId, data ) {
    return api.put( `${endpointV2}/${sessionId}/production/distribution`, data );
  },

  updateServiceDistributionV2( sessionId, data ) {
    return api.put( `${endpointV2}/${sessionId}/service/distribution`, data );
  },

  updateBundleDistributionV2( sessionId, data ) {
    return api.put( `${endpointV2}/${sessionId}/bundle/distribution`, data );
  },

  updatePriceDistribution( sessionId, data ) {
    return api.put( `${endpoint}/${sessionId}/production/price-distribution`, data );
  },
};
