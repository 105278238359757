import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { reducer as Settings } from './Settings';
import { reducer as User } from './User';

export default history => combineReducers( {
  router: connectRouter( history ),
  settings: Settings,
  user: User,
} );
