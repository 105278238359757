import React from 'react';
import {
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as am4core from '@amcharts/amcharts4/core';
import _ from 'lodash';
import IntlMessages from 'utils/IntlMessages';

class ChartModal extends React.Component {
  componentDidUpdate( prevProps ) {
    const { config, seriesTitle, open } = this.props;

    if ( !prevProps.open && open ) {
      setTimeout( () => {
        this.chartModal = am4core.createFromConfig( {
          ..._.cloneDeep( config ),
          series: _.map( config.series, ( serie, index ) => ( {
            ...serie,
            tooltipText: `${seriesTitle[index]} - {categoryX}: {valueY.value}`,
          } ) ),
          cursor: {},
          legend: { type: 'Legend' },
          scrollbarX: {
            type: 'XYChartScrollbar',
            background: {
              fill: am4core.color( '#04A89E' ),
              fillOpacity: 0.5,
            },
            minHeight: 10,
          },
        }, 'chartdivModal' );
      }, 100 );
    } else if ( prevProps.open && !open ) {
      this.chartModal.dispose();
    }
  }

  render() {
    const { title, open, onClose } = this.props;
    let title2 = title;
    if ( !title ) title2 = 'nodefinido';
    return (
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'up' }}
      >
        <AppBar className="position-relative">
          <Toolbar>
            <IconButton onClick={onClose} aria-label="Close" className="text-white">
              <CloseIcon />
            </IconButton>
            <Typography
              color="inherit"
              style={{ flex: 1 }}
            >
              <IntlMessages id={title2} />
            </Typography>
          </Toolbar>
        </AppBar>

        <div id="chartdivModal" style={{ width: '100%', height: '90vh' }} />
      </Dialog>
    );
  }
}

export default ChartModal;
