import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import Error404 from 'components/theme/Error404';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import UserActions from 'store/reducers/User';
import Dashboard from './Dashboard';
import General from './General';
import Message from './Message';
import Information from './Information';


const Session = ( { match } ) => (
  <div className="row m-0">
    <div className="col-12">
      <Switch>
        <Route
          path={`${match.url}/info`}
          component={Information}
        />
        <Route
          path={`${match.url}/dashboard`}
          component={Dashboard}
        />
        <Route
          path={`${match.url}/general`}
          render={props => <General {...props} sessionId={match.params.id} />}
        />
        <Route
          path={`${match.url}/messages`}
          render={props => <Message {...props} />}
        />
        <Route component={Error404} />
      </Switch>
    </div>
  </div>
);

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
  changeActiveSession: UserActions.changeActiveSession,
};

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( Session ) );
