import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import ProfileAvatarEditor from 'components/custom_v2/ProfileAvatarEditor';

const ProfilePicture = ( { newSrc, isOpen, src, editAvatar, avatarEditor, onClose } ) => (
  <div>
    <div>
      <div className="profile-picture mx-auto mt-2">
        <div className="img__overlay img__overlay--green" onClick={avatarEditor}>
          <i className="zmdi zmdi-hc-2x zmdi-camera" />
        </div>
        <img src={newSrc || src} alt="" className=" mx-auto d-block profile-picture" />
      </div>
    </div>

    <Dialog open={isOpen} onClose={onClose} fullScreen>
      <DialogTitle className="bg-dark text-white">
                Avatar
        <IconButton
          aria-label="close"
          className="closeButton float-right"
          onClick={onClose}
        >
          <i className="zmdi zmdi-close text-white" />
        </IconButton>
      </DialogTitle>
      <DialogContent className="p-5 overflow-visible">
        <ProfileAvatarEditor onSave={editAvatar} />
      </DialogContent>
    </Dialog>
  </div>
);

export default ProfilePicture;
