import React, { useEffect, useState } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import Error404 from 'components/theme/Error404';
import SettingsActions from 'store/reducers/Settings';
import SessionService from 'services/Session';
import UserActions from 'store/reducers/User';
import SessionInfoBar from 'routes/App/components/User/SessionInfoBar';
import Dashboard from './Dashboard';
import Information from './Information';
import General from './General';
import Message from './Message';

const Session = ( { match, toggleLoading, toggleAlert, changeActiveSession, user } ) => {
  const [data, setData] = useState( {} );
  const [workgroup, setWorkgroup] = useState( {} );
  const [limitedAccess, setLimitedAccess] = useState( true );

  useEffect( () => {
    toggleLoading( true );

    SessionService.getSessionCurrentWorkgroupRound( user.activeSession.id )
      .then( ( response ) => {
        if ( response.ok ) {
          setWorkgroup( response.data );
        }
      } );
    SessionService.getSession( user.activeSession.id )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) return toggleAlert( response.errors );
        changeActiveSession( response.data );
        setData( response.data );

        if ( !user.activeSession ) {
          setLimitedAccess( true );
          return toggleAlert( 'sessionNotConfigured', 'danger', true );
        }

        if ( !user.activeSession.currentRound ) {
          setLimitedAccess( true );
          return toggleAlert( 'sessionNoRounds', 'danger', true );
        }

        const license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
        if ( !license || !license.workGroup ) {
          setLimitedAccess( true );
          return toggleAlert( 'sessionNotConfigured', 'danger', true );
        }

        if ( response.data.status === 'READY_NO_ROUND' ) {
          setLimitedAccess( true );
          return toggleAlert( 'sessionNoRounds', 'danger', true );
        }

        if ( !response.data.status ) {
          setLimitedAccess( true );
          return toggleAlert( 'sessionNotConfigured', 'danger', true );
        }

        setLimitedAccess( false );
      } );
  }, [user.activeSession.id] ); // eslint-disable-line

  return (
    <div>
      <SessionInfoBar data={data} workgroup={workgroup} />

      <div className="row m-0">
        <div className="col-12">
          {data.id
            ? (
              <Switch>
                <Route
                  path={`${match.url}/dashboard`}
                  component={Dashboard}
                />
                <Route
                  path={`${match.url}/info`}
                  component={Information}
                />
                {!limitedAccess
                && (
                  <Route
                    path={`${match.url}/general`}
                    render={props => <General {...props} />}
                  />
                )}
                <Route
                  path={`${match.url}/messages`}
                  render={props => <Message {...props} />}
                />
                <Route
                  path={match.url}
                  component={Dashboard}
                  exact
                />
                <Route component={Error404} />
              </Switch>
            )
            : null
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
  changeActiveSession: UserActions.changeActiveSession,
};

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( Session ) );
