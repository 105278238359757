import React from 'react';
import { Route, Switch } from 'react-router-dom';

import FixedSidebar from 'components/custom/FixedSidebar';
import Error404 from 'components/theme/Error404';
import Info from './Info';
import UserLicenses from './UserLicenses';
import WorkGroups from './WorkGroups';
import Rounds from './Rounds';
import Logs from './Logs';

const General = ( { match, sessionId } ) => {
  const lists = [
    {
      headerId: 1,
      heading: 'decisionDashboard',
      items: [
        {
          id: 2,
          icon: 'view-module',
          name: 'roundmngmnt',
          color: 'warning',
          url: `${match.url}/rounds`,
        },
        {
          id: 3,
          icon: 'layers',
          name: 'workgroups',
          color: 'info',
          url: `${match.url}/user-licenses`,
        },


      ],
    },
  ];

  return (
    <div className="row">
      <FixedSidebar
        items={lists}
        cardClasses="col-lg-2 col-md-6 col-12 pl-0"
      />

      <div className="col-12 col-md-6 col-lg-10 pt-4 mt-2">
        <Switch>
          <Route
            path={`${match.url}/user-licenses`}
            render={props => <UserLicenses {...props} sessionId={sessionId} />}
          />
          <Route
            path={`${match.url}/work-groups`}
            render={props => <WorkGroups {...props} sessionId={sessionId} />}
          />
          <Route
            path={`${match.url}/rounds`}
            render={props => <Rounds {...props} sessionId={sessionId} />}
          />
          <Route
            path={`${match.url}/logs`}
            render={props => <Logs {...props} sessionId={sessionId} />}
          />
          <Route
            path={match.url}
            render={props => <Info {...props} sessionId={sessionId} />}
            exact
          />
          <Route component={Error404} />
        </Switch>
      </div>
    </div>
  );
};

export default General;
