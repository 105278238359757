import React from 'react';
import { Row, Col } from 'reactstrap';

import CardBox from 'components/custom_v2/CardBox';
import {
  TextField,
  RadioButtons,
  Checkboxes,
} from 'components/custom/FormElements';
import IntlMessages from 'utils/IntlMessages';

export default ( {
  behavioralSegmentation, behavioralSegmentationField,
  commodities, index, change, clearAll,
} ) => (
  <CardBox

    header={(
      <div
        className="p-2 bg-primary"
      >
        <h4 className="card-heading m-0 text-white">
          <IntlMessages id="behavioralSegmentation" />
          {' '}
          {index}
          <i
            style={{ float: 'right' }}
            className="fa fa-eraser fa-1x "
            onClick={( ) => {
              change( `${behavioralSegmentationField}.behavioral`, null );
              change( `${behavioralSegmentationField}.fromMonth`, null );
              change( `${behavioralSegmentationField}.toMonth`, null );
              change( `${behavioralSegmentationField}.commodity`, null );
            }
            }
          />
        </h4>
      </div>
    )}
  >
    <div className="mb-2">
      {behavioralSegmentation.type === 'radio'
      && (
        <RadioButtons
          field={`${behavioralSegmentationField}.behavioral`}
          options={behavioralSegmentation.options}
          valueKey="name"
        />
      )}

      {behavioralSegmentation.type === 'checkbox'
      && (
        <Checkboxes
          field={`${behavioralSegmentationField}.behavioral`}
          options={behavioralSegmentation.options}
          valueKey="name"
          translate
          marginContainer={false}
          onChange={( value ) => {
            if ( !value || !value.length ) {
              change( `${behavioralSegmentationField}.fromMonth`, null );
              change( `${behavioralSegmentationField}.toMonth`, null );
            }
          }}
        />
      )}
    </div>

    {behavioralSegmentation.selectCommodity
    && (
      <>
        <div className="bg-primary p-2 text-white">
          <IntlMessages id="model" />
        </div>
        <div className="pl-3 py-2 mb-3 bg-gray-1 h-75">
          <RadioButtons
            disabled={!behavioralSegmentation.behavioral}
            field={`${behavioralSegmentationField}.commodity`}
            translate={false}
            marginContainer={false}
            valueKey="name"
            options={commodities}
            displayBlock
            clearAll={clearAll}
          />
        </div>
      </>
    )}

    <IntlMessages id="Between" />
    <Row>

      <Col md={4}>
        <div className="d-flex align-items-center">
          <div className="mr-0">
            <TextField
              placeholder="month"
              field={`${behavioralSegmentationField}.fromMonth`}
              disabled={!behavioralSegmentation.behavioral
              || !behavioralSegmentation.behavioral.length}
              type="number"
              marginContainer={false}
            />
          </div>
        </div>
      </Col>
      <Col md={2} className="align-items-center">
        <IntlMessages id="and" />
      </Col>
      <Col md={4}>
        <div className="d-flex align-items-center">
          <div className="mr-0">
            <TextField
              placeholder="month"
              field={`${behavioralSegmentationField}.toMonth`}
              disabled={!behavioralSegmentation.behavioral
              || !behavioralSegmentation.behavioral.length}
              type="number"
              marginContainer={false}
            />
          </div>
        </div>
      </Col>
      <Col md={6}>
        <IntlMessages id="monthsAgo" />
      </Col>
    </Row>

  </CardBox>
);
