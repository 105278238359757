import React from 'react';

import ContainerHeader from 'components/theme/ContainerHeader';
import EditForm from '../../../../components/Admin/University/EditForm';

const NewUniversity = ( { match } ) => (
  <div className="app-wrapper">
    <ContainerHeader match={match} title="newUniversity" />
    <EditForm />
  </div>
);

export default NewUniversity;
