import api from '../config/Api';

const baseEndpoint = 'v1/session';
const baseEndpointV2 = 'v2/session';

export default {

  getCems( id, params ) {
    return api.get( `${baseEndpoint}/${id}/cem`, params );
  },

  getCemsV2( id, params ) {
    return api.get( `${baseEndpointV2}/${id}/cem`, params );
  },

  getCem( id, params ) {
    return api.get( `${baseEndpoint}/${id}/cem/detail`, params );
  },

  getAttributeMaxValue( id, params ) {
    return api.get( `${baseEndpoint}/${id}/cem/attributes-max-value`, params );
  },

  getAttributeMaxValueV2( id, params ) {
    return api.get( `${baseEndpointV2}/${id}/cem/attributes-max-value`, params );
  },

  getAttributeCost( id, params ) {
    return api.get( `${baseEndpoint}/${id}/cem/attribute-cost`, params );
  },

  getAttributeCostV2( id, params ) {
    return api.get( `${baseEndpointV2}/${id}/cem/attribute-cost`, params );
  },

  updateCem( id, data ) {
    return api.put( `${baseEndpoint}/${id}/cem`, data );
  },

  updateCemV2( id, data ) {
    return api.put( `${baseEndpointV2}/${id}/cem`, data );
  },
};
