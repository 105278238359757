import React from 'react';
import {Link} from 'react-router-dom';
import IntlMessages from 'utils/IntlMessages';

const IconCard = ( { icon, title, bgColor, link, onClick } ) => (
  <Link to={link} className="icon-card-link" onClick={onClick}>
    <div className="jr-card jr-card-full mb-0 m-auto icon-card borderless">
      <div className={`jr-fillchart ${bgColor}`}>
        <div className="jr-fillchart-content d-flex align-items-center justify-content-center">
          <i className={`fa fa-${icon} fa-4x`}/>
        </div>
      </div>
    </div>
    <div className="text-center text-muted mt-2">
      <h1><IntlMessages id={title}/></h1>
    </div>
  </Link>
);

export default IconCard;
