import React from 'react';
import { connect } from 'react-redux';
import Tree, { TreeNode } from 'rc-tree';
import 'assets/vendors/rc-tree.css';

import SettingsActions from 'store/reducers/Settings';
import WorkGroupService from 'services/WorkGroup';
import UserLicenseService from 'services/UserLicense';
import CardBox from 'components/theme/CardBox';
import Table from 'components/custom/Table/Custom';
import IntlMessages from 'utils/IntlMessages';
import Util from 'utils/Util';
import WorkGroupEditForm from '../../../../../../components/Professor/Session/WorkGroupEditForm';
import WorkGroupChangeUser from '../../../../../../components/Professor/Session/WorkGroupChangeUser';

class WorkGroups extends React.Component {
  state = {
    data: [],
    editModal: false,
    changeUserModal: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData = ( params ) => {
    const { toggleLoading, sessionId } = this.props;
    toggleLoading( true );

    this.params = params;
    WorkGroupService.getWorkGroupsBySession( sessionId, params )
      .then( ( response ) => {
        toggleLoading( false );

        if ( response.ok ) {
          this.setState( { data: response.data.data || [] } );
        }
      } );
  };

  getEntity = ( id ) => {
    const { toggleLoading, sessionId } = this.props;
    toggleLoading( true );

    WorkGroupService.getWorkGroup( sessionId, id )
      .then( ( response ) => {
        toggleLoading( false );

        if ( response.ok ) {
          this.entityToEdit = response.data;
          this.toggleEditModal();
        }
      } );
  };

  getLicense = ( id ) => {
    const { toggleLoading, sessionId } = this.props;
    toggleLoading( true );

    UserLicenseService.getUserLicense( sessionId, id )
      .then( ( response ) => {
        toggleLoading( false );

        if ( response.ok ) {
          this.userToEdit = response.data;
          this.toggleChangeUserModal();
        }
      } );
  };

  toggleEditModal = ( cb ) => {
    this.setState( previousState => ( { editModal: !previousState.editModal } ), cb );
  };

  toggleChangeUserModal = ( cb ) => {
    this.setState( previousState => ( { changeUserModal: !previousState.changeUserModal } ), cb );
  };

  render() {
    const { editModal, changeUserModal, ...rest } = this.state;
    const { sessionId } = this.props;

    const loop = data => data.map( item => (
      !item.licenses
        ? (
          <TreeNode
            key={item.id}
            title={`${item.user.name || ''} ${item.user.lastName || ''}`}
            isLeaf
          />
        )
        : (
          <TreeNode
            key={item.id}
            title={item.name}
            isLeaf={false}
            selectable
          >
            {loop( item.licenses )}
          </TreeNode>
        )
    ) );
    const treeNodes = loop( rest.data );

    return (
      <div>
        <CardBox styleName="p-0" heading="workGroups">
          <div className="row">
            <div className="col-md-5">
              {treeNodes.length
                ? (
                  <Tree
                    selectedKeys={[]}
                    showLine
                    defaultExpandAll
                    onSelect={( selectedKeys, data ) => (
                      !data.node.isLeaf() ? this.getEntity( selectedKeys[0] )
                        : this.getLicense( selectedKeys[0] ) )}
                    switcherIcon={props => ( props.isLeaf ? false : !props.expanded
                      ? <i className="fa fa-plus-circle text-primary" />
                      : <i className="fa fa-minus-circle text-secondary" /> )}
                  >
                    {treeNodes}
                  </Tree>
                )
                : []
              }
            </div>
            <div className="col-md-7">
              <Table
                data={rest.data}
                showPaginationTop={false}
                columns={[
                  {
                    Header: <IntlMessages id="group" />,
                    accessor: 'name',
                  },
                  {
                    Header: <IntlMessages id="position" />,
                    accessor: 'position',
                    Cell: ( { value } ) => Util.formatNumber( value ),
                  },
                  {
                    Header: <IntlMessages id="points" />,
                    accessor: 'points',
                    Cell: ( { value } ) => Util.formatNumber( value ),
                  },
                ]}
              />
            </div>
          </div>
        </CardBox>

        <WorkGroupEditForm
          data={this.entityToEdit}
          sessionId={sessionId}
          open={editModal}
          onClose={() => this.toggleEditModal()}
          onConfirm={() => {
            this.toggleEditModal();
            this.getData( this.params );
          }}
        />

        <WorkGroupChangeUser
          data={this.userToEdit}
          sessionId={sessionId}
          workGroups={rest.data}
          open={changeUserModal}
          onClose={() => this.toggleChangeUserModal()}
          onConfirm={() => {
            this.toggleChangeUserModal();
            this.getData( this.params );
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps,
)( WorkGroups );
