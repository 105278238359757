import React from 'react';
import { Field } from 'react-final-form';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import classNames from 'classnames';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import IntlMessages from 'utils/IntlMessages';

const TextFieldInput = ( {
                           field, containerClass, fullWidth = true, onChange, allowZero = false,
                           translateValues, validate, intl, marginContainer = true, ...rest
                         } ) => (
  field ? (
      <Field name={field} validate={validate}>
        {( { meta, input } ) => (
          <FormControl
            error={meta.touched && !!meta.error}
            className={classNames( 'form-control-app-sm', {'mb-4': marginContainer } )}
            fullWidth={fullWidth}
          >
            {rest.label ? <label><IntlMessages id={rest.label}/></label> : ''}
            {(() => {
             if (rest.type==="number") {
               rest.type="string";
               rest.setmasknumber="true";
             }
            })()}
            { rest.setmasknumber==="true" ?
            
            <NumberFormat
              variant="outlined"
              customInput = { TextField }
              thousandSeparator={true}
              isNumericString = {true}              
              {...input}
              {...rest}
              label={null}
              error={meta.touched && !!meta.error}
              placeholder={rest.placeholder ? intl.formatMessage( { id: rest.placeholder } ) : null}
              defaultValue={allowZero ? input.value : input.value|| ''}
              onValueChange={values => (rest.value = values.value)}
              onChange={(e) => {
                input.onChange( rest.value || '')
                if (onChange) onChange( e.target.value, e );
              }}
            />
            :
            <TextField
              variant="outlined"
              {...input}
              {...rest}
              label={null}
              error={meta.touched && !!meta.error}
              placeholder={rest.placeholder ? intl.formatMessage( { id: rest.placeholder } ) : null}
              value={allowZero ? input.value : input.value || ''}
              onChange={( e ) => {
                if (rest.type === 'number') {
                  input.onChange( !_.isEmpty( e.target.value ) ? +e.target.value : '' );
                  if (onChange) onChange( !_.isEmpty( e.target.value ) ? +e.target.value : '', e );
                } else {
                  input.onChange( e.target.value );
                  if (onChange) onChange( e.target.value, e );
                }
              }}
            />            

            }
            {meta.touched && meta.error
            && (
              <FormHelperText>
                <IntlMessages id={meta.error} values={translateValues}/>
              </FormHelperText>
            )}
          </FormControl>
        )}
      </Field>
    )
    : (
      <FormControl
        className={classNames( { 'mb-4': marginContainer } )}
        fullWidth={fullWidth}
      >
        <TextField
          variant="outlined"
          {...rest}
          placeholder={rest.placeholder ? intl.formatMessage( { id: rest.placeholder } ) : null}
          value={rest.value || ''}
          onChange={( e ) => {
            if (onChange) onChange( e.target.value, e );
          }}
        />
      </FormControl>
    )

);

export default injectIntl( TextFieldInput );
