import React from 'react';
import { FieldArray } from 'react-final-form-arrays';

import { required } from 'config/InputErrors';
import CardBox from 'components/theme/CardBox';
import { TextField } from 'components/custom/FormElements';
import IntlMessages from 'utils/IntlMessages';

export default ( { title, fieldName, variables, iconClass } ) => (
  <CardBox
    styleName="col-12 p-0"
    cardStyle="jr-full-card m-0"
    headingStyle="mb-0"
    heading={[
      <i
        key={1}
        className={`zmdi zmdi-hc-lg mr-2 ${iconClass}`}
      />,
      <IntlMessages id={title} key={2} />,
    ]}
  >
    <div className="table-responsive-material pb-2" style={{ maxHeight: 400 }}>
      <table className="table custom-table remove-table-border table-sm mb-0">
        <thead className="">
          <tr>
            <td>Variable</td>
            <td>Value</td>
          </tr>
        </thead>
        <tbody>
          <FieldArray name={fieldName}>
            {( { fields } ) => (
              fields.map( ( variable, index ) => (
                <tr
                  tabIndex={-1}
                  key={variables[index].id}
                >
                  <td>
                    <div className=" py-2 d-flex flex-row align-items-center">
                      <div className="">
                        <h5 className="m-0">
                          {variables[index].name}
                        </h5>
                      </div>
                    </div>
                  </td>
                  <td>
                    <TextField
                      marginContainer={false}
                      field={`${variable}.value`}
                      type="number"
                      disabled={variables[index].label === 'GROUP_SESSION'}
                      validate={required}
                    />
                  </td>
                </tr>
              ) )
            )}
          </FieldArray>
        </tbody>
      </table>
    </div>
  </CardBox>
);
