import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import utilTimeZone from 'utils/TimeZone';
import _ from 'lodash';
import moment from 'moment';

import IntlMessages from 'utils/IntlMessages';
import { TextField, Checkbox, Select, DatePicker, Buttons } from 'components/custom/FormElements';
import { required } from 'config/InputErrors';
import SessionService from 'services/Session';
import SettingsActions from 'store/reducers/Settings';

const EditForm = ( { open, onClose, onConfirm, data, toggleLoading, toggleAlert, entityId } ) => {
  function submitForm( formData, form ) {
    const dataToSend = {};

    toggleLoading( true );
    _.map( _.keys( form.getState().modified ), ( key ) => {
      if ( formData[key] && ( key === 'startDate' || key === 'endDate' ) ) {
        dataToSend[key] = moment( Number( formData[key] ) ).format( 'YYYY-MM-DD' );
      } else dataToSend[key] = formData[key];
    } );

    dataToSend.entity = entityId;
    const promise = data && data.id ? SessionService.updateSession( data.id, dataToSend )
      : SessionService.saveSession( dataToSend );
    promise.then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) return toggleAlert( response.errors );

      toggleAlert( 'dataSaved', 'info' );
      onConfirm();
    } );
  }
  const timeZones = _.map( utilTimeZone.getTimeZones(), item => ( { id: item, name: item } ) );
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <Form
        initialValues={data}
        onSubmit={submitForm}
        render={( { handleSubmit, form } ) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle className="bg-dark text-white">
              <IntlMessages id={data && data.id ? 'editSession' : 'newSession'} />
            </DialogTitle>
            <DialogContent className="p-3">
              <div className="row">
                <div className="col-md-6">
                  <TextField field="name" label="name" validate={required} />
                  <TextField disabled field="numberGroup" label="numberGroup" type="number" validate={required} />
                  <TextField field="maxUserGroup" label="maxUserGroup" type="number" validate={required} />
                  <DatePicker field="startDate" label="startDate" validate={required} />
                  <DatePicker field="endDate" label="endDate" validate={required} />
                  <Checkbox field="active" label="active" />
                </div>
                <div className="col-md-6">
                  <Select
                    field="currency"
                    label="currency"
                    options={[{ id: 'eur', name: 'EUR' }]}
                    translateOptions={false}
                    validate={required}
                  />
                  <Select
                    field="timeZone"
                    label="timeZone"
                    options={timeZones}
                    translateOptions={false}
                    validate={required}
                  />
                  <TextField field="licensesTotal" label="licensesTotal" type="number" validate={required} />
                  <TextField field="maxProductPerGroup" label="maxProductPerGroup" type="number" validate={required} />
                  <TextField field="initialMoneyPerGroup" label="initialMoneyPerGroup" type="number" validate={required} />
                  <TextField field="fixCostPerGroup" label="fixCostPerGroup" type="number" validate={required} />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Buttons onClickCancel={onClose} />
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps,
)( EditForm );
