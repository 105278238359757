import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import async from 'async';
import { Row, Col } from 'reactstrap';
import classnames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
// import Button from '@material-ui/core/Button';

import IntlMessages from 'utils/IntlMessages';
import CategoryService from 'services/Category';
import SettingsActions from 'store/reducers/Settings';
import Util from 'utils/Util';
import BreadcrumbComp from 'components/custom_v2/Breadcrumb';
import CategoryAvatar from 'components/custom_v2/CategoryAvatar';


import { Avatar } from '@material-ui/core';
import bundle from 'assets/images/mk/bundle.png';
import service from 'assets/images/mk/service.png';

import { TextField } from 'components/custom/FormElements';
import ConfirmationModal from 'components/custom/ConfirmationModal';
import ProductionService from 'services/Production';
import DistributionService from 'services/Distribution';
import {
  composeValidators,
  greaterOrEqualValue,
  required,
} from 'config/InputErrors';
import Help from 'routes/App/components/User/Help';
import HelpSnackBar from 'routes/App/components/User/HelpSnackBar';

class Distribution extends React.Component {
  state = {
    categories: [],
    confirmSaveModal: false,
  };

  componentDidMount() {
    const { toggleAlert, user } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.activeSession = user.activeSession;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }

    this.getData();
    document.addEventListener( 'keydown', e => this.handleKeyPress( e ), true );
  }

  handleKeyPress = ( event ) => {
    if ( event.key === 'Enter' ) {
      if ( event.target.type === 'button' ) {
        event.target.click();
      } else {
        event.preventDefault();
        this.setState( { confirmSaveModal: true } );
      }
    }
  }


  getData = () => {
    const { toggleAlert, toggleLoading } = this.props;

    toggleLoading( true );

    async.parallel( {
      categories: ( cb ) => {
        CategoryService.getCategories( { 'filters[session]': this.activeSession.id } )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
      },
      products: ( cb ) => {
        DistributionService.getProducts( this.activeSession.id, {
          workgroup: this.license.workGroup.id,
          'filters[active]': 1,
        } ).then( ( response ) => {
          if ( !response.ok ) return cb( response.errors );
          cb( null, response.data.data );
        } );
      },
      services: ( cb ) => {
        DistributionService.getServices( this.activeSession.id, {
          workgroup: this.license.workGroup.id,
          'filters[active]': 1,
        } ).then( ( response ) => {
          if ( !response.ok ) return cb( response.errors );
          cb( null, response.data.data );
        } );
      },
      bundles: ( cb ) => {
        DistributionService.getBundles( this.activeSession.id, {
          workgroup: this.license.workGroup.id,
          'filters[active]': 1,
        } ).then( ( response ) => {
          if ( !response.ok ) return cb( response.errors );
          cb( null, response.data.data );
        } );
      },
    }, ( error, results ) => {
      toggleLoading( false );
      if ( error ) toggleAlert( error );
      const { services } = results;
      const { bundles } = results;
      const categories = _.map( results.categories, category => ( {
        ...category,
        products: _.filter( results.products, { category: { id: category.id } } ),
      } ) );
      this.setState( { categories, services, bundles } );
    } );
  };

  submitForm = ( formData ) => {
    const { toggleAlert, toggleLoading } = this.props;

    const distributions = [];
    _.map( formData.categories, ( category ) => {
      _.map( category.products, ( product ) => {
        const data = {
          product: product.id,
          tradeSalePrice: product.tradeSalePrice,
          pop: product.pop,
        };
        distributions.push( data );
      } );
    } );

    const ServiceDistributions = [];

    _.map( formData.services, ( servicex ) => {
      const data = {
        service: servicex.id,
        tradeSalePrice: servicex.tradeSalePrice,
        pop: servicex.pop,
      };
      ServiceDistributions.push( data );
    } );

    const BundleDistributions = [];

    _.map( formData.bundles, ( bundlex ) => {
      const data = {
        bundle: bundlex.id,
        tradeSalePrice: bundlex.tradeSalePrice,
        pop: bundlex.pop,
        monthlyTradeSaleFee: bundlex.monthlyTradeSaleFee,
      };
      BundleDistributions.push( data );
    } );


    toggleLoading( true );
    const dataToSend = {
      workgroup: this.license.workGroup.id,
      distributions,
    };

    const dataServiceToSend = {
      workgroup: this.license.workGroup.id,
      distributions: ServiceDistributions,
    };

    const dataBundleToSend = {
      workgroup: this.license.workGroup.id,
      distributions: BundleDistributions,
    };
    if ( distributions.length !== 0 ) {
      ProductionService.updateProductionDistributionV2( this.activeSession.id, dataToSend )
        .then( ( response ) => {
          if ( !response.ok ) {
            toggleLoading( false );
            return toggleAlert( response.errors );
          }

          toggleAlert( 'dataSaved', 'info' );
          this.getData();
        } );
    }
    if ( ServiceDistributions.length !== 0 ) {
      ProductionService.updateServiceDistributionV2( this.activeSession.id, dataServiceToSend )
        .then( ( response ) => {
          if ( !response.ok ) {
            toggleLoading( false );
            return toggleAlert( response.errors );
          }

          toggleAlert( 'dataSaved', 'info' );
          this.getData();
        } );
    }
    if ( BundleDistributions.length !== 0 ) {
      ProductionService.updateBundleDistributionV2( this.activeSession.id, dataBundleToSend )
        .then( ( response ) => {
          if ( !response.ok ) {
            toggleLoading( false );
            return toggleAlert( response.errors );
          }

          toggleAlert( 'dataSaved', 'info' );
          this.getData();
        } );
    }
  };

  toggleConfirmSaveModal = () => {
    const { confirmSaveModal } = this.state;
    this.setState( { confirmSaveModal: !confirmSaveModal } );
  };

  calcPorcMargin = ( priceProduct, tradeSalePrice ) => {
    if ( _.isNaN( priceProduct ) || _.isNaN( tradeSalePrice ) || tradeSalePrice === 0 ) return 0;
    const porcMargin = ( ( priceProduct - tradeSalePrice ) / tradeSalePrice ) * 100;
    return porcMargin;
  };

  render() {
    const { categories, confirmSaveModal, services, bundles } = this.state;

    return (
      <>
        <Form
          initialValues={{ categories }}
          onSubmit={this.submitForm}
          mutators={{ ...arrayMutators }}
          render={( { handleSubmit } ) => (
            <form onSubmit={handleSubmit}>
              <Row className="mb-2 align-items-center">
                <Col>
                  <BreadcrumbComp links={[
                    {
                      url: '/',
                      name: 'decisionDashboard',
                    },
                    { name: 'distribution' },
                  ]}
                  />
                </Col>
                <Col md={4} className="d-flex justify-content-end align-items-center">
                  <Help
                    title="help.distribution.title"
                    content="help.distribution.content"
                    multimedia="help.distribution.multimedia"
                  />

                </Col>
              </Row>

              <FieldArray name="categories">
                {data => (
                  data.fields.map( ( itemField, itemIndex ) => {
                    const item = data.fields.value[itemIndex];

                    return (
                      <Row key={item.id} className="mb-4">
                        <Col md="auto" className="text-center">
                          <h4 className="text-uppercase font-weight-bold">{item.name}</h4>
                          <CategoryAvatar category={item} />
                        </Col>

                        <Col md>
                          <div className="table-responsive">
                            <table
                              className={classnames( 'table table-striped table-products mb-0 table-header', {
                                'table-products-laptop': item.label === 'CATEGORY_1',
                                'table-products-tablet': item.label === 'CATEGORY_2',
                                'table-products-cell': item.label === 'CATEGORY_3',
                              } )}
                            >
                              <thead>
                                <tr>
                                  <th style={{ width: 100 }}><IntlMessages id="model" /></th>
                                  <th style={{ width: 50 }}><IntlMessages id="unitCost" /></th>
                                  <th style={{ width: 100 }}><IntlMessages id="price" /></th>
                                  <th style={{ width: 120 }}>
                                    <IntlMessages id="retailerPrice" />
                                  </th>
                                  <th>
                                    <div className="d-inline-block text-center">
                                      <IntlMessages id="marginToRetailerS" />
                                      <span className="d-block">($)</span>
                                    </div>
                                  </th>
                                  <th>
                                    <div className="d-inline-block text-center">
                                      <IntlMessages id="marginToRetailerPercent" />
                                      <span className="d-block">(%)</span>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <FieldArray name={`${itemField}.products`}>
                                  {productsData => (
                                    productsData.fields.map( ( productField, productIndex ) => {
                                      const product = categories[itemIndex].products[productIndex];
                                      if ( !product ) return null;
                                      return ( [
                                        <tr key={product.id}>
                                          <td>
                                            {product.name}
                                          </td>
                                          <td>
                                            {Util.formatCurrency( product.unitCost )}
                                          </td>
                                          <td>
                                            {Util.formatCurrency( product.price )}
                                          </td>
                                          <td>
                                            <TextField
                                              field={`${productField}.tradeSalePrice`}
                                              marginContainer={false}
                                              type="number"
                                              allowZero
                                              inputProps={{ className: 'text-right' }}
                                              validate={composeValidators(
                                                required,
                                                greaterOrEqualValue( 0 ),
                                              )}
                                              translateValues={{ value: 0 }}
                                            />
                                          </td>
                                          <td>
                                            {Util.formatCurrency( product.price - _.get( productsData.fields, `value[${productIndex}].tradeSalePrice`, 0 ) )}
                                          </td>
                                          <td>
                                            {Util.formatNumber( this.calcPorcMargin( product.price, _.get( productsData.fields, `value[${productIndex}].tradeSalePrice` ) ) )}
                                          </td>
                                        </tr>,
                                      ] );
                                    } ) )}
                                </FieldArray>
                              </tbody>
                            </table>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="table-responsive">
                            <table
                              className={classnames( 'table table-striped table-products mb-0 table-header', {
                                'table-products-laptop': item.label === 'CATEGORY_1',
                                'table-products-tablet': item.label === 'CATEGORY_2',
                                'table-products-cell': item.label === 'CATEGORY_3',
                              } )}
                            >
                              <thead>
                                <tr>
                                  <th>
                                    <div className="d-inline-block text-center">
                                      <IntlMessages id="percentDirectSales" />
                                      <span className="d-block"><IntlMessages id="lastMonth" /></span>
                                    </div>
                                  </th>
                                  <th>
                                    <div className="d-inline-block text-center">
                                      <IntlMessages id="percentChannelSales" />
                                      <span className="d-block"><IntlMessages id="lastMonth" /></span>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <FieldArray name={`${itemField}.products`}>
                                  {productsData => (
                                    productsData.fields.map( ( productField, productIndex ) => {
                                      const product = categories[itemIndex].products[productIndex];
                                      if ( !product ) return null;
                                      return ( [
                                        <tr key={product.id} style={{ height: 64 }}>
                                          <td>
                                            {/* eslint-disable-next-line max-len */}
                                            {Util.formatNumberNoDecimals( product.percentDirectSales )}
                                          </td>
                                          <td>
                                            {/* eslint-disable-next-line max-len */}
                                            {Util.formatNumberNoDecimals( product.percentChannelSales )}
                                          </td>
                                        </tr>,
                                      ] );
                                    } ) )}
                                </FieldArray>
                              </tbody>
                            </table>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="table-responsive">
                            <table
                              className={classnames( 'table table-striped table-products mb-0 table-header', {
                                'table-products-laptop': item.label === 'CATEGORY_1',
                                'table-products-tablet': item.label === 'CATEGORY_2',
                                'table-products-cell': item.label === 'CATEGORY_3',
                              } )}
                            >
                              <thead>
                                <tr>
                                  <th>
                                    <div className="d-inline-block text-center">
                                      <IntlMessages id="pontPurchase" />
                                      <span className="d-block"><IntlMessages id="investment" /></span>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <FieldArray name={`${itemField}.products`}>
                                  {productsData => (
                                    productsData.fields.map( ( productField, productIndex ) => {
                                      const product = categories[itemIndex].products[productIndex];
                                      if ( !product ) return null;
                                      return ( [
                                        <tr key={product.id}>
                                          <td>
                                            <div style={{ width: 100 }}>
                                              <TextField
                                                field={`${productField}.pop`}
                                                marginContainer={false}
                                                type="number"
                                                allowZero
                                                inputProps={{ className: 'text-right' }}
                                                validate={composeValidators(
                                                  required,
                                                  greaterOrEqualValue( 0 ),
                                                )}
                                                translateValues={{ value: 0 }}
                                              />
                                            </div>
                                          </td>
                                        </tr>,
                                      ] );
                                    } ) )}
                                </FieldArray>
                              </tbody>
                            </table>
                          </div>
                        </Col>
                      </Row>
                    );
                  } ) )}
              </FieldArray>

              <ConfirmationModal
                open={confirmSaveModal}
                title="confirmSaveProductProduction"
                confirmBtnText="yesModalBtn"
                onSubmit={() => {
                  this.toggleConfirmSaveModal();
                  handleSubmit();
                }}
                onClose={this.toggleConfirmSaveModal}
              />
            </form>
          )}
        />
        <Form
          initialValues={{ services }}
          onSubmit={this.submitForm}
          mutators={{ ...arrayMutators }}
          render={( { handleSubmit } ) => (
            <form onSubmit={handleSubmit}>


              <Row className="mb-4">
                <Col md="auto" className="text-center">
                  <h4 className="text-uppercase font-weight-bold"><IntlMessages id="services" /></h4>
                  <Avatar className="size-100 border-radius-0 m-auto" alt="..." src={service} />
                </Col>
                <Col md>
                  <div className="table-responsive">
                    <table
                      className={classnames( 'table table-striped table-products mb-0 table-header', {
                        'table-products-laptop': '' !== 'CATEGORY_1',
                        'table-products-tablet': '' === 'CATEGORY_2',
                        'table-products-cell': '' === 'CATEGORY_3',
                      } )}
                    >
                      <thead>
                        <tr>
                          <th style={{ width: 100 }}>
                            <IntlMessages id="service" />
                          </th>
                          <th style={{ width: 50 }}><IntlMessages id="unitCost" /></th>
                          <th style={{ width: 100 }}><IntlMessages id="price" /></th>
                          <th style={{ width: 120 }}><IntlMessages id="retailerPrice" /></th>
                          <th>
                            <div className="d-inline-block text-center">
                              <IntlMessages id="marginToRetailerS" />
                              <span className="d-block">($)</span>
                            </div>
                          </th>
                          <th>
                            <div className="d-inline-block text-center">
                              <IntlMessages id="marginToRetailerPercent" />
                              <span className="d-block">(%)</span>
                            </div>
                          </th>

                        </tr>
                      </thead>
                      <tbody>
                        <FieldArray name="services">
                          {( { fields } ) => (
                            fields.map( ( serviceField, productIndex ) => {
                              const servicex = services[productIndex];
                              if ( !servicex ) return null;
                              return ( [
                                <tr key={servicex.id}>
                                  <td className="d-flex align-items-center">
                                    {servicex.name}
                                  </td>

                                  <td>
                                    {Util.formatCurrency( servicex.unitCost )}
                                  </td>
                                  <td>
                                    {Util.formatCurrency( servicex.price )}
                                  </td>
                                  <td>
                                    <TextField
                                      field={`${serviceField}.tradeSalePrice`}
                                      marginContainer={false}
                                      type="number"
                                      allowZero
                                      inputProps={{ className: 'text-right' }}
                                      validate={composeValidators(
                                        required,
                                        greaterOrEqualValue( 0 ),
                                      )}
                                      translateValues={{ value: 0 }}
                                    />
                                  </td>
                                  <td>
                                    {Util.formatCurrency( servicex.price - _.get( fields, `value[${productIndex}].tradeSalePrice`, 0 ) )}
                                  </td>
                                  <td>
                                    {Util.formatNumber( this.calcPorcMargin( servicex.price, _.get( fields, `value[${productIndex}].tradeSalePrice` ) ) )}
                                  </td>
                                </tr>,
                              ] );
                            } ) )}
                        </FieldArray>
                      </tbody>
                    </table>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="table-responsive">
                    <table
                      className={classnames( 'table table-striped table-products mb-0 table-header', {
                        'table-products-laptop': '' !== 'CATEGORY_1',
                        'table-products-tablet': '' === 'CATEGORY_2',
                        'table-products-cell': '' === 'CATEGORY_3',
                      } )}
                    >
                      <thead>
                        <tr>
                          <th>
                            <div className="d-inline-block text-center">
                              <IntlMessages id="percentDirectSales" />
                              <span className="d-block"><IntlMessages id="lastMonth" /></span>
                            </div>
                          </th>
                          <th>
                            <div className="d-inline-block text-center">
                              <IntlMessages id="percentChannelSales" />
                              <span className="d-block"><IntlMessages id="lastMonth" /></span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <FieldArray name="services">
                          {( { fields } ) => (
                            fields.map( ( serviceField, productIndex ) => {
                              const servicex = services[productIndex];
                              if ( !service ) return null;
                              return ( [
                                <tr key={servicex.id} style={{ height: 64 }}>
                                  <td>
                                    {/* eslint-disable-next-line max-len */}
                                    {Util.formatNumberNoDecimals( servicex.percentDirectSales )}
                                  </td>
                                  <td>
                                    {/* eslint-disable-next-line max-len */}
                                    {Util.formatNumberNoDecimals( servicex.percentChannelSales )}
                                  </td>

                                </tr>,
                              ] );
                            } ) )}
                        </FieldArray>
                      </tbody>
                    </table>
                  </div>
                </Col>
                <Col md={2}>
                  <div className="table-responsive">
                    <table
                      className={classnames( 'table table-striped table-products mb-0 table-header', {
                        'table-products-laptop': '' !== 'CATEGORY_1',
                        'table-products-tablet': '' === 'CATEGORY_2',
                        'table-products-cell': '' === 'CATEGORY_3',
                      } )}
                    >
                      <thead>
                        <tr>
                          <th>
                            <div className="d-inline-block text-center">
                              <IntlMessages id="pontPurchase" />
                              <span className="d-block"><IntlMessages id="investment" /></span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <FieldArray name="services">
                          {( { fields } ) => (
                            fields.map( ( serviceField, productIndex ) => {
                              const servicex = services[productIndex];
                              if ( !servicex ) return null;
                              return ( [
                                <tr key={servicex.id}>
                                  <td>
                                    <div style={{ width: 100 }}>
                                      <TextField
                                        field={`${serviceField}.pop`}
                                        marginContainer={false}
                                        type="number"
                                        allowZero
                                        inputProps={{ className: 'text-right' }}
                                        validate={composeValidators(
                                          required,
                                          greaterOrEqualValue( 0 ),
                                        )}
                                        translateValues={{ value: 0 }}
                                      />
                                    </div>
                                  </td>


                                </tr>,
                              ] );
                            } ) )}
                        </FieldArray>
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>


              <ConfirmationModal
                open={confirmSaveModal}
                title="confirmSave"
                confirmBtnText="yesModalBtn"
                onSubmit={() => {
                  this.toggleConfirmSaveModal();
                  handleSubmit();
                }}
                onClose={this.toggleConfirmSaveModal}
              />
            </form>
          )}
        />

        <Form
          initialValues={{ bundles }}
          onSubmit={this.submitForm}
          mutators={{ ...arrayMutators }}
          render={( { handleSubmit } ) => (
            <form onSubmit={handleSubmit}>


              <Row className="mb-4">
                <Col md="auto" className="text-center">
                  <h4 className="text-uppercase font-weight-bold"><IntlMessages id="bundles" /></h4>
                  <Avatar className="size-100 border-radius-0 m-auto" alt="..." src={bundle} />
                </Col>
                <Col md>
                  <div className="table-responsive">
                    <table
                      className={classnames( 'table table-striped table-products mb-0 table-header', {
                        'table-products-laptop': '' !== 'CATEGORY_1',
                        'table-products-tablet': '' === 'CATEGORY_2',
                        'table-products-cell': '' === 'CATEGORY_3',
                      } )}
                    >
                      <thead>
                        <tr style={{ height: 54 }}>
                          <th style={{ width: 100 }}>
                            <IntlMessages id="bundle" />
                          </th>
                          <th style={{ width: 50 }}><IntlMessages id="unitCost" /></th>
                          <th style={{ width: 50 }}><IntlMessages id="monthlyFee" /></th>
                          <th style={{ width: 100 }}><IntlMessages id="monthlyTradeSaleFee" /></th>
                          <th style={{ width: 50 }}><IntlMessages id="price" /></th>
                          <th style={{ width: 100 }}><IntlMessages id="retailerPrice" /></th>
                          <th style={{ width: 30 }}>
                            <div className="d-inline-block text-center">
                              <IntlMessages id="marginToRetailerS" />
                              <span className="d-block">($)</span>
                            </div>
                          </th>
                          <th style={{ width: 30 }}>
                            <div className="d-inline-block text-center">
                              <IntlMessages id="marginToRetailerPercent" />
                              <span className="d-block">(%)</span>
                            </div>
                          </th>

                        </tr>
                      </thead>
                      <tbody>
                        <FieldArray name="bundles">
                          {( { fields } ) => (
                            fields.map( ( bundleField, productIndex ) => {
                              const bundlex = bundles[productIndex];
                              if ( !bundlex ) return null;
                              return ( [
                                <tr key={bundlex.id} style={{ height: 54 }}>
                                  <td className="d-flex align-items-center">
                                    {bundlex.name}
                                  </td>

                                  <td>
                                    {Util.formatCurrency( bundlex.unitCost )}
                                  </td>
                                  <td>
                                    {Util.formatCurrency( bundlex.monthlyFee )}
                                  </td>
                                  <td>
                                    <TextField
                                      field={`${bundleField}.monthlyTradeSaleFee`}
                                      marginContainer={false}
                                      type="number"
                                      allowZero
                                      inputProps={{ className: 'text-right' }}
                                      validate={composeValidators(
                                        required,
                                        greaterOrEqualValue( 0 ),
                                      )}
                                      translateValues={{ value: 0 }}
                                    />
                                  </td>
                                  <td>
                                    {Util.formatCurrency( bundlex.price )}
                                  </td>
                                  <td>
                                    <TextField
                                      field={`${bundleField}.tradeSalePrice`}
                                      marginContainer={false}
                                      type="number"
                                      allowZero
                                      inputProps={{ className: 'text-right' }}
                                      validate={composeValidators(
                                        required,
                                        greaterOrEqualValue( 0 ),
                                      )}
                                      translateValues={{ value: 0 }}
                                    />
                                  </td>
                                  <td>
                                    {Util.formatCurrency( bundlex.price - _.get( fields, `value[${productIndex}].tradeSalePrice`, 0 ) )}
                                  </td>
                                  <td>
                                    {Util.formatNumber( this.calcPorcMargin( bundlex.price, _.get( fields, `value[${productIndex}].tradeSalePrice` ) ) )}
                                  </td>
                                </tr>,
                              ] );
                            } ) )}
                        </FieldArray>
                      </tbody>
                    </table>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="table-responsive">
                    <table
                      className={classnames( 'table table-striped table-products mb-0 table-header', {
                        'table-products-laptop': '' !== 'CATEGORY_1',
                        'table-products-tablet': '' === 'CATEGORY_2',
                        'table-products-cell': '' === 'CATEGORY_3',
                      } )}
                    >
                      <thead>
                        <tr style={{ height: 54 }}>
                          <th>
                            <div className="d-inline-block text-center">
                              <IntlMessages id="percentDirectSales" />
                              <span className="d-block"><IntlMessages id="lastMonth" /></span>
                            </div>
                          </th>
                          <th>
                            <div className="d-inline-block text-center">
                              <IntlMessages id="percentChannelSales" />
                              <span className="d-block"><IntlMessages id="lastMonth" /></span>
                            </div>
                          </th>
                          <th>
                            <div className="d-inline-block text-center">
                              <IntlMessages id="pontPurchase" />
                              <span className="d-block"><IntlMessages id="investment" /></span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <FieldArray name="bundles">
                          {( { fields } ) => (
                            fields.map( ( bundleField, productIndex ) => {
                              const bundlex = bundles[productIndex];
                              if ( !bundlex ) return null;
                              return ( [
                                <tr key={bundlex.id} style={{ height: 54 }}>
                                  <td>
                                    {/* eslint-disable-next-line max-len */}
                                    {Util.formatNumberNoDecimals( bundlex.percentDirectSales )}
                                  </td>
                                  <td>
                                    {/* eslint-disable-next-line max-len */}
                                    {Util.formatNumberNoDecimals( bundlex.percentChannelSales )}
                                  </td>
                                  <td>
                                    <div style={{ width: 100 }}>
                                      <TextField
                                        field={`${bundleField}.pop`}
                                        marginContainer={false}
                                        type="number"
                                        allowZero
                                        inputProps={{ className: 'text-right' }}
                                        validate={composeValidators(
                                          required,
                                          greaterOrEqualValue( 0 ),
                                        )}
                                        translateValues={{ value: 0 }}
                                      />
                                    </div>
                                  </td>


                                </tr>,
                              ] );
                            } ) )}
                        </FieldArray>
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
              <HelpSnackBar message="help.distribution.snackbar" />
              <ConfirmationModal
                open={confirmSaveModal}
                title="confirmSave"
                confirmBtnText="yesModalBtn"
                onSubmit={() => {
                  this.toggleConfirmSaveModal();
                  handleSubmit();
                }}
                onClose={this.toggleConfirmSaveModal}
              />
            </form>
          )}
        />
      </>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( Distribution );
