/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { connect } from 'react-redux';
import {
  Button,
  AppBar,
  Tabs,
  Tab,
} from '@material-ui/core';
import async from 'async';
import _ from 'lodash';
import {
  CartesianGrid,
  Legend, Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import IntlMessages from 'utils/IntlMessages';
import { Select, TextField } from 'components/custom/FormElements';
import CardBox from 'components/theme/CardBox';
import SettingsActions from 'store/reducers/Settings';
import ContainerHeader from 'components/theme/ContainerHeader';
import Util from 'utils/Util';
import CategoryService from 'services/Category';
import ServiceTypeService from 'services/ServiceType';
import PublicMarketService from 'services/PublicMarket';
import ConfirmationModal from 'components/custom/ConfirmationModal';
import AxisTick from 'components/custom/Charts/AxisTick';
import CompareCommodityModal from '../../../../../components/User/CompareCommodityModal';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

class PublicMarket extends React.Component {
  state = {
    categories: [],
    serviceTypes: [],
    products: [],
    myProducts: [],
    productForm: {},
    activeTab: 0,
    bundles: [],
    myBundles: [],
    bundleForm: {},
    services: [],
    myServices: [],
    serviceForm: {},
    confirmMyCommodityModal: false,
    compareCommodityModal: false,
    selectedCommodities: [],
    priceHistory: [],
  };

  componentDidMount() {
    const { toggleAlert, user } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.activeSession = user.activeSession;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }

    this.getData();
  }

  getData = () => {
    const { toggleAlert, toggleLoading } = this.props;

    toggleLoading( true );
    async.parallel( {
      serviceTypes: this.getServicesTypes,
      products: ( cb ) => {
        async.waterfall( [
          this.getCategories,
          ( categories, wCb ) => {
            if ( !categories || !categories[0] ) return wCb();
            this.getProducts( { category: categories[0].id }, ( error, results ) => {
              if ( error ) return wCb( error );
              wCb( null, {
                categories,
                products: results,
                productForm: { category: categories[0].id },
              } );
            } );
          },
          ( data, wCb ) => {
            if ( !_.get( data, 'categories', [] ).length ) return wCb();
            this.getMyProducts( data.categories[0].id, ( error, results ) => {
              if ( error ) return cb( error );
              cb( null, {
                ...data,
                myProducts: results,
              } );
            } );
          },
        ], cb );
      },
    }, ( error, results ) => {
      toggleLoading( false );
      if ( error ) {
        return toggleAlert( error );
      }

      this.setState( {
        serviceTypes: results.serviceTypes,
        categories: results.products.categories,
        products: results.products.products,
        myProducts: results.products.myProducts,
        productForm: results.products.productForm,
      } );
    } );
  };

  getCategories = ( cb ) => {
    CategoryService.getCategories( {
      'filters[session]': this.activeSession.id,
      limit: -1,
    } )
      .then( response => cb( response.errors, response.data ? response.data.data : [] ) );
  };

  getServicesTypes = ( cb ) => {
    ServiceTypeService.getServiceTypes( {
      'filters[session]': this.activeSession.id,
      limit: -1,
    } )
      .then( response => cb( response.errors, response.data ? response.data.data : [] ) );
  };

  getProductsData = () => {
    const { toggleAlert, toggleLoading } = this.props;
    const { categories } = this.state;
    toggleLoading( true );

    async.parallel( {
      products: ( cb ) => {
        if ( !categories || !categories[0] ) return cb();
        this.getProducts( { category: categories[0].id }, ( error, results ) => {
          if ( error ) return cb( error );
          cb( null, {
            products: results,
            productForm: { category: categories[0].id },
          } );
        } );
      },
      myProducts: ( cb ) => {
        if ( !categories || !categories[0] ) return cb();
        this.getMyProducts( categories[0].id, cb );
      },
    }, ( error, results ) => {
      toggleLoading( false );
      if ( error ) return toggleAlert( error );

      this.setState( {
        products: results.products.products,
        productForm: results.products.productForm,
        myProducts: results.myProducts,
      } );
    } );
  };

  getProducts = ( filters, cb ) => {
    const params = {
      limit: -1,
      workgroup: this.license.workGroup.id,
    };
    if ( filters.category ) {
      params.category = filters.category;
    }
    if ( filters.criteria ) {
      params['filters[criteria]'] = filters.criteria;
    }
    PublicMarketService.getProducts( this.activeSession.id, params )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        cb( null, response.data.data );
      } );
  };

  getMyProducts = ( category, cb ) => {
    const params = {
      limit: -1,
      workgroup: this.license.workGroup.id,
      'filters[category]': category,
    };
    PublicMarketService.getMyProducts( this.activeSession.id, params )
      .then( response => cb( response.errors, response.data ? response.data.data : [] ) );
  };

  getServicesData = () => {
    const { toggleAlert, toggleLoading } = this.props;
    const { serviceTypes } = this.state;
    toggleLoading( true );

    async.parallel( {
      services: ( cb ) => {
        if ( !serviceTypes || !serviceTypes[0] ) return cb();
        this.getServices( { serviceType: serviceTypes[0].id }, ( error, results ) => {
          if ( error ) return cb( error );
          cb( null, {
            services: results,
            serviceForm: { serviceType: serviceTypes[0].id },
          } );
        } );
      },
      myServices: ( cb ) => {
        if ( !serviceTypes || !serviceTypes[0] ) return cb();
        this.getMyServices( serviceTypes[0].id, cb );
      },
    }, ( error, results ) => {
      toggleLoading( false );
      if ( error ) return toggleAlert( error );

      this.setState( {
        services: results.services.services,
        serviceForm: results.services.serviceForm,
        myServices: results.myServices,
      } );
    } );
  };

  getServices = ( filters, cb ) => {
    const params = {
      limit: -1,
      workgroup: this.license.workGroup.id,
    };
    if ( filters.serviceType ) {
      params.serviceType = filters.serviceType;
    }
    if ( filters.criteria ) {
      params['filters[criteria]'] = filters.criteria;
    }
    PublicMarketService.getServices( this.activeSession.id, params )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        cb( null, response.data.data );
      } );
  };

  getMyServices = ( serviceType, cb ) => {
    const params = {
      limit: -1,
      workgroup: this.license.workGroup.id,
      'filters[serviceType]': serviceType,
    };
    PublicMarketService.getMyServices( this.activeSession.id, params )
      .then( response => cb( response.errors, response.data ? response.data.data : [] ) );
  };

  getBundlesData = () => {
    const { toggleAlert, toggleLoading } = this.props;
    toggleLoading( true );

    async.parallel( {
      bundles: cb => this.getBundles( {}, cb ),
      myBundles: this.getMyBundles,
    }, ( error, results ) => {
      toggleLoading( false );
      if ( error ) return toggleAlert( error );

      this.setState( results );
    } );
  };

  getBundles = ( filters, cb ) => {
    const params = {
      limit: -1,
      workgroup: this.license.workGroup.id,
    };
    if ( filters.criteria ) {
      params['filters[criteria]'] = filters.criteria;
    }
    PublicMarketService.getBundles( this.activeSession.id, params )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        cb( null, response.data.data );
      } );
  };

  getMyBundles = ( cb ) => {
    const params = {
      limit: -1,
      workgroup: this.license.workGroup.id,
    };
    PublicMarketService.getMyBundles( this.activeSession.id, params )
      .then( response => cb( response.errors, response.data ? response.data.data : [] ) );
  };

  filterProducts = ( data ) => {
    const { toggleAlert, toggleLoading } = this.props;
    const { myProducts } = this.state;
    toggleLoading( true );

    async.parallel( {
      products: cb => this.getProducts( data, cb ),
      myProducts: ( cb ) => {
        if ( !this.productCategoryChange ) return cb( null, myProducts );
        return this.getMyProducts( data.category, cb );
      },
    }, ( error, results ) => {
      toggleLoading( false );
      if ( error ) return toggleAlert( error );

      this.dataToCompare = [];
      this.setState( { ...results, selectedCommodities: [] } );
    } );
  };

  filterServices = ( data ) => {
    const { toggleAlert, toggleLoading } = this.props;
    const { myServices } = this.state;
    toggleLoading( true );

    async.parallel( {
      services: cb => this.getServices( data, cb ),
      myServices: ( cb ) => {
        if ( !this.serviceServiceTypeChange ) return cb( null, myServices );
        return this.getMyServices( data.serviceType, cb );
      },
    }, ( error, results ) => {
      toggleLoading( false );
      if ( error ) return toggleAlert( error );

      this.dataToCompare = [];
      this.setState( { ...results, selectedCommodities: [] } );
    } );
  };

  filterBundles = ( data ) => {
    const { toggleAlert, toggleLoading } = this.props;
    toggleLoading( true );

    this.getBundles( data, ( error, bundles ) => {
      toggleLoading( false );
      if ( error ) return toggleAlert( error );

      this.setState( { bundles } );
    } );
  };

  submitMyCommodity = () => {
    const { toggleAlert, toggleLoading } = this.props;

    toggleLoading( true );
    PublicMarketService.traceCommodity(
      this.activeSession.id, {
        workgroup: this.license.workGroup.id,
        ...this.changedCommodity,
      },
    ).then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) {
        this.onCancelCb();
        return toggleAlert( response.errors );
      }
      this.closeConfirmMyCommodityModal();
      toggleAlert( 'dataSaved', 'info' );
    } );
  };

  closeConfirmMyCommodityModal = ( ) => {
    this.setState( { confirmMyCommodityModal: false } );
  };

  cancelConfirmMyCommodityModal = ( ) => {
    this.onCancelCb();
    this.closeConfirmMyCommodityModal();
  };

  openConfirmMyCommodityModal = ( commodityId, myCommodityId, onCancelCb ) => {
    this.changedCommodity = {
      commodity: commodityId,
      myCommodity: myCommodityId,
    };
    this.onCancelCb = onCancelCb;

    this.setState( { confirmMyCommodityModal: true } );
  };

  changeTab = ( event, value ) => {
    this.dataToCompare = [];
    this.setState( { activeTab: value, selectedCommodities: [] } );

    if ( value === 0 ) {
      this.getProductsData();
    }
    if ( value === 1 ) {
      this.getBundlesData();
    }
    if ( value === 2 ) {
      this.getServicesData();
    }
  };

  findAttribute = ( attributes, label ) => {
    const attibute = _.find( attributes, { label } );
    return attibute ? attibute.value : '';
  };

  toggleCommodity = ( id, name ) => {
    const { toggleAlert, toggleLoading } = this.props;
    const { selectedCommodities } = this.state;
    const commodities = [...selectedCommodities];
    const selectedIndex = _.findIndex( commodities, { id } );

    if ( selectedIndex !== -1 ) {
      commodities.splice( selectedIndex, 1 );
    } else {
      commodities.push( { id, name } );
    }

    if ( selectedIndex === -1 ) {
      toggleLoading( true );
      PublicMarketService.getPriceHistory(
        this.activeSession.id,
        {
          workgroup: this.license.workGroup.id,
          commodities: _.map( commodities, 'id' ),
        },
      ).then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) {
          return toggleAlert( response.errors );
        }
        this.setState( { selectedCommodities: commodities, priceHistory: response.data } );
      } );
    } else {
      this.setState( { selectedCommodities: commodities } );
    }
  };

  closeCompareCommodityModal = ( ) => {
    this.setState( { compareCommodityModal: false } );
  };

  openCompareCommodityModal = ( ) => {
    const { toggleAlert, toggleLoading } = this.props;
    const { selectedCommodities } = this.state;
    if ( !selectedCommodities.length ) return toggleAlert( 'specifyCommodity', 'danger', true );

    toggleLoading( true );
    PublicMarketService.getCompare(
      this.activeSession.id,
      {
        workgroup: this.license.workGroup.id,
        commodities: _.map( selectedCommodities, 'id' ),
      },
    ).then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) {
        return toggleAlert( response.errors );
      }
      this.dataToCompare = response.data.data;
      this.setState( { compareCommodityModal: true } );
    } );
  };

  render() {
    const {
      categories, products, myProducts, productForm, bundles, myBundles, bundleForm,
      serviceTypes, services, myServices, serviceForm, activeTab,
      confirmMyCommodityModal, selectedCommodities, priceHistory, compareCommodityModal,
    } = this.state;

    return (
      <>
        <ContainerHeader title="publicMarket" />

        <div className="row mt-3">
          <CardBox cardStyle="p-0" styleName="col-12">
            <AppBar className="bg-primary public-market-tabs" position="static">
              <Tabs
                value={activeTab}
                onChange={this.changeTab}
                variant="fullWidth"
                indicatorColor="primary"
              >
                <Tab className="tab" label={<IntlMessages id="products" />} />
                <Tab className="tab" label={<IntlMessages id="bundles" />} />
                <Tab className="tab" label={<IntlMessages id="services" />} />
              </Tabs>
            </AppBar>

            {activeTab === 0
            && (
              <div>
                <Form
                  initialValues={productForm}
                  onSubmit={this.filterProducts}
                  render={( { handleSubmit, form } ) => {
                    this.productFormInstance = form;
                    return (
                      <form onSubmit={handleSubmit} className="row mx-0 my-1">
                        <div className="col-sm-3">
                          <Select
                            placeholder="categories"
                            field="category"
                            options={categories}
                            isClearable={false}
                            marginContainer={false}
                            translateOptions={false}
                            onChange={() => {
                              this.productCategoryChange = true;
                              handleSubmit();
                            }}
                          />
                        </div>

                        <div className="col-sm-3">
                          <TextField
                            placeholder="search"
                            field="criteria"
                            marginContainer={false}
                            onKeyPress={( e ) => {
                              if ( e.key === 'Enter' ) {
                                this.productCategoryChange = false;
                                handleSubmit();
                              }
                            }}
                          />
                        </div>
                      </form>
                    );
                  }}
                />

                <div className="row">
                  <div className="col-12">
                    {products.length === 0
                      ? (
                        <div
                          className="text-muted h-100 d-flex align-items-center justify-content-center p-3"
                        >
                          <IntlMessages id="noProductsFound" />
                        </div>
                      )
                      : (
                        <Form
                          initialValues={{ products }}
                          onSubmit={() => {
                          }}
                          mutators={{ ...arrayMutators }}
                          render={( { handleSubmit, values, form } ) => (
                            <form onSubmit={handleSubmit}>
                              <div
                                className="table-responsive-material table-overflow-x-visible pb-2"
                              >
                                <table className="table custom-table mb-0">
                                  <thead>
                                    <tr>
                                      <td><IntlMessages id="group" /></td>
                                      <td><IntlMessages id="name" /></td>
                                      <td><IntlMessages id="price" /></td>
                                      <td><IntlMessages id="design" /></td>
                                      <td><IntlMessages id="speed" /></td>
                                      <td><IntlMessages id="reliability" /></td>
                                      <td><IntlMessages id="size" /></td>
                                      <td><IntlMessages id="weight" /></td>
                                      <td><IntlMessages id="bundle" /></td>
                                      <td><IntlMessages id="isMine" /></td>
                                      <td style={{ minWidth: '200px' }}><IntlMessages id="myProduct" /></td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <FieldArray name="products">
                                      {( { fields } ) => (
                                        fields.map( ( product, index ) => {
                                          const { id, name } = values.products[index];
                                          const tdClassnames = _.findIndex( selectedCommodities,
                                            { id } ) !== -1 ? 'font-weight-bold' : '';
                                          return (
                                            <tr key={values.products[index].id}>
                                              <td
                                                className={tdClassnames}
                                                onClick={() => this.toggleCommodity( id, name )}
                                              >
                                                {_.get( values.products[index], 'workgroup.name' )}
                                              </td>
                                              <td
                                                className={tdClassnames}
                                                onClick={() => this.toggleCommodity( id, name )}
                                              >
                                                {values.products[index].name}
                                              </td>
                                              <td
                                                className={tdClassnames}
                                                onClick={() => this.toggleCommodity( id, name )}
                                              >
                                                {Util.formatCurrency(
                                                  values.products[index].price,
                                                )}
                                              </td>
                                              <td
                                                className={tdClassnames}
                                                onClick={() => this.toggleCommodity( id, name )}
                                              >
                                                {this.findAttribute( values.products[index].attributesParam, 'Design' )}
                                              </td>
                                              <td
                                                className={tdClassnames}
                                                onClick={() => this.toggleCommodity( id, name )}
                                              >
                                                {this.findAttribute( values.products[index].attributesParam, 'Speed' )}
                                              </td>
                                              <td
                                                className={tdClassnames}
                                                onClick={() => this.toggleCommodity( id, name )}
                                              >
                                                {this.findAttribute( values.products[index].attributesParam, 'Reliability' )}
                                              </td>
                                              <td
                                                className={tdClassnames}
                                                onClick={() => this.toggleCommodity( id, name )}
                                              >
                                                {this.findAttribute( values.products[index].attributesParam, 'Size' )}
                                              </td>
                                              <td
                                                className={tdClassnames}
                                                onClick={() => this.toggleCommodity( id, name )}
                                              >
                                                {this.findAttribute( values.products[index].attributesParam, 'Weight' )}
                                              </td>
                                              <td
                                                className={tdClassnames}
                                                onClick={() => this.toggleCommodity( id, name )}
                                              >
                                                {values.products[index].bundle}
                                              </td>
                                              <td onClick={() => this.toggleCommodity( id, name )}>
                                                {values.products[index].isMine
                                                  ? <i className="fa fa-star text-primary" /> : ''}
                                              </td>
                                              <td>
                                                <Select
                                                  options={!values.products[index].myProduct
                                                    ? myProducts
                                                    : values.products[index].myProduct.active
                                                      ? myProducts
                                                      : [values.products[index].myProduct,
                                                        ...myProducts]
                                                  }
                                                  translateOptions={false}
                                                  marginContainer={false}
                                                  field={`${product}.myProduct.id`}
                                                  onChange={( val, oldValue ) => {
                                                    if ( val !== oldValue ) {
                                                      this.openConfirmMyCommodityModal(
                                                        values.products[index].id,
                                                        val,
                                                        () => form.change( `${product}.myProduct`, values.products[index].myProduct ),
                                                      );
                                                    }
                                                  }}
                                                />
                                              </td>
                                            </tr>
                                          );
                                        } )
                                      )}
                                    </FieldArray>
                                  </tbody>
                                </table>
                              </div>
                            </form>
                          )}
                        />
                      )}
                  </div>
                </div>

                {products.length
                  ? (
                    <div className="row mx-0">
                      <div className="col-12 text-right">
                        <Button
                          variant="contained"
                          color="primary"
                          className="jr-btn jr-btn-lg my-2"
                          type="button"
                          onClick={this.openCompareCommodityModal}
                        >
                          <IntlMessages id="compareProducts" />
                        </Button>
                      </div>
                    </div>
                  )
                  : null}
              </div>
            )}

            {activeTab === 1
            && (
              <div>
                <Form
                  initialValues={bundleForm}
                  onSubmit={this.filterBundles}
                  render={( { handleSubmit, form } ) => {
                    this.bundleFormInstance = form;
                    return (
                      <form onSubmit={handleSubmit} className="row mx-0 my-1">
                        <div className="col-sm-3">
                          <TextField
                            placeholder="search"
                            field="criteria"
                            marginContainer={false}
                          />
                        </div>
                      </form>
                    );
                  }}
                />

                <div className="row">
                  <div className="col-12">
                    {bundles.length === 0
                      ? (
                        <div
                          className="text-muted h-100 d-flex align-items-center justify-content-center p-3"
                        >
                          <IntlMessages id="noBundlesFound" />
                        </div>
                      )
                      : (
                        <Form
                          initialValues={{ bundles }}
                          onSubmit={() => {
                          }}
                          mutators={{ ...arrayMutators }}
                          render={( { handleSubmit, values, form } ) => (
                            <form onSubmit={handleSubmit}>
                              <div
                                className="table-responsive-material table-overflow-x-visible pb-2"
                              >
                                <table className="table custom-table mb-0">
                                  <thead>
                                    <tr>
                                      <td><IntlMessages id="group" /></td>
                                      <td><IntlMessages id="price" /></td>
                                      <td><IntlMessages id="name" /></td>
                                      <td><IntlMessages id="productCategory" /></td>
                                      <td><IntlMessages id="productName" /></td>
                                      <td><IntlMessages id="serviceType" /></td>
                                      <td><IntlMessages id="serviceName" /></td>
                                      <td><IntlMessages id="isMine" /></td>
                                      <td style={{ minWidth: '200px' }}><IntlMessages id="myBundle" /></td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <FieldArray name="bundles">
                                      {( { fields } ) => (
                                        fields.map( ( bundle, index ) => {
                                          const { id, name } = values.bundles[index];
                                          const tdClassnames = _.findIndex( selectedCommodities,
                                            { id } ) !== -1 ? 'font-weight-bold' : '';
                                          return (
                                            <tr key={values.bundles[index].id}>
                                              <td
                                                className={tdClassnames}
                                                onClick={() => this.toggleCommodity( id, name )}
                                              >
                                                {_.get( values.bundles[index], 'workgroup.name' )}
                                              </td>
                                              <td
                                                className={tdClassnames}
                                                onClick={() => this.toggleCommodity( id, name )}
                                              >
                                                {Util.formatCurrency( values.bundles[index].price )}
                                              </td>
                                              <td
                                                className={tdClassnames}
                                                onClick={() => this.toggleCommodity( id, name )}
                                              >
                                                {values.bundles[index].name}
                                              </td>
                                              <td
                                                className={tdClassnames}
                                                onClick={() => this.toggleCommodity( id, name )}
                                              >
                                                {values.bundles[index].productCategory}
                                              </td>
                                              <td
                                                className={tdClassnames}
                                                onClick={() => this.toggleCommodity( id, name )}
                                              >
                                                {values.bundles[index].productName}
                                              </td>
                                              <td
                                                className={tdClassnames}
                                                onClick={() => this.toggleCommodity( id, name )}
                                              >
                                                {values.bundles[index].serviceType}
                                              </td>
                                              <td
                                                className={tdClassnames}
                                                onClick={() => this.toggleCommodity( id, name )}
                                              >
                                                {values.bundles[index].serviceName}
                                              </td>
                                              <td onClick={() => this.toggleCommodity( id, name )}>
                                                {values.bundles[index].isMine
                                                  ? <i className="fa fa-star text-primary" /> : ''}
                                              </td>
                                              <td>
                                                <Select
                                                  options={myBundles}
                                                  translateOptions={false}
                                                  marginContainer={false}
                                                  field={`${bundle}.myBundle.id`}
                                                  onChange={( val, oldValue ) => {
                                                    if ( val !== oldValue ) {
                                                      this.openConfirmMyCommodityModal(
                                                        values.bundles[index].id,
                                                        val,
                                                        () => form.change( `${bundle}.myBundle`, values.bundles[index].myBundle ),
                                                      );
                                                    }
                                                  }}
                                                />
                                              </td>
                                            </tr>
                                          );
                                        } ) )}
                                    </FieldArray>
                                  </tbody>
                                </table>
                              </div>
                            </form>
                          )}
                        />
                      )}
                  </div>
                </div>
              </div>
            )}

            {activeTab === 2
            && (
              <div>
                <Form
                  initialValues={serviceForm}
                  onSubmit={this.filterServices}
                  render={( { handleSubmit, form } ) => {
                    this.serviceFormInstance = form;
                    return (
                      <form onSubmit={handleSubmit} className="row mx-0 my-1">
                        <div className="col-sm-3">
                          <Select
                            placeholder="serviceType"
                            field="serviceType"
                            options={serviceTypes}
                            isClearable={false}
                            marginContainer={false}
                            translateOptions={false}
                            onChange={() => {
                              this.serviceServiceTypeChange = true;
                              handleSubmit();
                            }}
                          />
                        </div>

                        <div className="col-sm-3">
                          <TextField
                            placeholder="search"
                            field="criteria"
                            marginContainer={false}
                            onKeyPress={( e ) => {
                              if ( e.key === 'Enter' ) {
                                this.serviceServiceTypeChange = false;
                                handleSubmit();
                              }
                            }}
                          />
                        </div>
                      </form>
                    );
                  }}
                />

                <div className="row">
                  <div className="col-12">
                    {services.length === 0
                      ? (
                        <div
                          className="text-muted h-100 d-flex align-items-center justify-content-center p-3"
                        >
                          <IntlMessages id="noServicesFound" />
                        </div>
                      )
                      : (
                        <Form
                          initialValues={{ services }}
                          onSubmit={() => {
                          }}
                          mutators={{ ...arrayMutators }}
                          render={( { handleSubmit, values, form } ) => (
                            <form onSubmit={handleSubmit}>
                              <div
                                className="table-responsive-material table-overflow-x-visible pb-2"
                              >
                                <table className="table custom-table mb-0">
                                  <thead>
                                    <tr>
                                      <td><IntlMessages id="group" /></td>
                                      <td><IntlMessages id="price" /></td>
                                      <td><IntlMessages id="name" /></td>
                                      <td><IntlMessages id="content" /></td>
                                      <td><IntlMessages id="easyToUse" /></td>
                                      <td><IntlMessages id="speed" /></td>
                                      <td><IntlMessages id="reliability" /></td>
                                      <td><IntlMessages id="bundle" /></td>
                                      <td><IntlMessages id="isMine" /></td>
                                      <td style={{ minWidth: '200px' }}><IntlMessages id="myService" /></td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <FieldArray name="services">
                                      {( { fields } ) => (
                                        fields.map( ( service, index ) => {
                                          const { id, name } = values.services[index];
                                          const tdClassnames = _.findIndex( selectedCommodities,
                                            { id } ) !== -1 ? 'font-weight-bold' : '';
                                          return (
                                            <tr key={values.services[index].id}>
                                              <td
                                                className={tdClassnames}
                                                onClick={() => this.toggleCommodity( id, name )}
                                              >
                                                {_.get( values.services[index], 'workgroup.name' )}
                                              </td>
                                              <td
                                                className={tdClassnames}
                                                onClick={() => this.toggleCommodity( id, name )}
                                              >
                                                {Util.formatCurrency(
                                                  values.services[index].price,
                                                )}
                                              </td>
                                              <td
                                                className={tdClassnames}
                                                onClick={() => this.toggleCommodity( id, name )}
                                              >
                                                {values.services[index].name}
                                              </td>
                                              <td
                                                className={tdClassnames}
                                                onClick={() => this.toggleCommodity( id, name )}
                                              >
                                                {this.findAttribute( values.services[index].attributesParam, 'CONTENT' )}
                                              </td>
                                              <td
                                                className={tdClassnames}
                                                onClick={() => this.toggleCommodity( id, name )}
                                              >
                                                {this.findAttribute( values.services[index].attributesParam, 'EASY_TO_USE' )}
                                              </td>
                                              <td
                                                className={tdClassnames}
                                                onClick={() => this.toggleCommodity( id, name )}
                                              >
                                                {this.findAttribute( values.services[index].attributesParam, 'SPEED' )}
                                              </td>
                                              <td
                                                className={tdClassnames}
                                                onClick={() => this.toggleCommodity( id, name )}
                                              >
                                                {this.findAttribute( values.services[index].attributesParam, 'RELIABILITY' )}
                                              </td>
                                              <td
                                                className={tdClassnames}
                                                onClick={() => this.toggleCommodity( id, name )}
                                              >
                                                {values.services[index].bundle}
                                              </td>
                                              <td onClick={() => this.toggleCommodity( id, name )}>
                                                {values.services[index].isMine
                                                  ? <i className="fa fa-star text-primary" /> : ''}
                                              </td>
                                              <td>
                                                <Select
                                                  options={!values.services[index].myService
                                                    ? myServices
                                                    : values.services[index].myService.active
                                                      ? myServices
                                                      : [values.services[index].myService,
                                                        ...myServices]
                                                  }
                                                  translateOptions={false}
                                                  marginContainer={false}
                                                  field={`${service}.myService.id`}
                                                  onChange={( val, oldValue ) => {
                                                    if ( val !== oldValue ) {
                                                      this.openConfirmMyCommodityModal(
                                                        values.services[index].id,
                                                        val,
                                                        () => form.change( `${service}.myService`, values.services[index].myService ),
                                                      );
                                                    }
                                                  }}
                                                />
                                              </td>
                                            </tr>
                                          );
                                        } )
                                      )}
                                    </FieldArray>
                                  </tbody>
                                </table>
                              </div>
                            </form>
                          )}
                        />
                      )}
                  </div>
                </div>

                {services.length
                  ? (
                    <div className="row mx-0">
                      <div className="col-12 text-right">
                        <Button
                          variant="contained"
                          color="primary"
                          className="jr-btn jr-btn-lg my-2"
                          type="button"
                          onClick={this.openCompareCommodityModal}
                        >
                          <IntlMessages id="compareServices" />
                        </Button>
                      </div>
                    </div>
                  )
                  : null}
              </div>
            )}
          </CardBox>
        </div>

        {selectedCommodities.length
          ? (
            <div className="row mt-3">
              <CardBox styleName="col-12" heading="priceHistory">
                <div className="pt-3">
                  <ResponsiveContainer width="100%" height={200}>
                    <LineChart
                      data={priceHistory}
                      margin={{
                        left: -10,
                        right: 20,
                      }}
                    >
                      <XAxis
                        dataKey="name"
                        height={60}
                        tick={<AxisTick />}
                      />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend verticalAlign="top" />
                      {_.map( selectedCommodities, ( item, index ) => (
                        <Line
                          key={item.id}
                          type="monotone"
                          dataKey={item.name}
                          name={item.name}
                          stroke={COLORS[index % COLORS.length]}
                        />
                      ) )}
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </CardBox>
            </div>
          ) : null
        }

        <ConfirmationModal
          open={confirmMyCommodityModal}
          title="confirmChangeMyCommodity"
          confirmBtnText="yesModalBtn"
          onSubmit={this.submitMyCommodity}
          onClose={this.cancelConfirmMyCommodityModal}
        />

        <CompareCommodityModal
          activeTab={activeTab}
          data={this.dataToCompare}
          open={compareCommodityModal}
          onClose={this.closeCompareCommodityModal}
        />
      </>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( PublicMarket );
