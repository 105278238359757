import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import IntlMessage from 'utils/IntlMessages';

const getDisplayString = ( sub ) => {
  const arr = sub.split( '-' );
  if (arr.length > 1) {
    return arr[0].charAt( 0 )
      .toUpperCase() + arr[0].slice( 1 ) + ' ' + arr[1].charAt( 0 )
      .toUpperCase() + arr[1].slice( 1 );
  } else {
    return sub.charAt( 0 )
      .toUpperCase() + sub.slice( 1 );
  }

};
const getUrlString = ( path, sub, index ) => {
  return '/' + path.split( sub )[0] + sub;
};

const ContainerHeader = ( { title, translateTitle = true, match, name, rightContent } ) => {
  const path = match ? match.path.substr( 1 ) : '';
  const subPath = path.split( '/' )
    .filter( item => item !== ':id' );

  return (
    <div
      className="page-heading py-3 d-md-flex justify-content-md-between align-items-md-center shadow-none border-primary border">
      <h3 className="title font-weight-normal mb-3 mb-sm-0">{translateTitle ? <IntlMessage id={title}/> : title} {name}</h3>

      {rightContent ? rightContent : null}

      {match ?
        <Breadcrumb className="mb-0" tag="nav">
          {subPath.map( ( sub, index ) => {
              return subPath.length === index + 1 ?
                <BreadcrumbItem active={subPath.length === index + 1}
                                tag={subPath.length === index + 1 ? 'span' : 'a'} key={index}
                                href={getUrlString( path, sub, index )}>{getDisplayString( sub )}</BreadcrumbItem>
                :
                <Link key={index} to={getUrlString( path, sub, index )} className="breadcrumb-item">
                  {getDisplayString( sub )}
                </Link>;
            }
          )}
        </Breadcrumb> : null}
    </div>
  );
};

export default ContainerHeader;

