import React from 'react';
import { Col, Row } from 'reactstrap';
import CardBox from 'components/custom_v2/CardBox';
import Util from 'utils/Util';
import IntlMessages from 'utils/IntlMessages';

const CompCashAndDept = ( { data } ) => (
  <>
    {!!data && data.length > 0 && (
      <Row style={{ paddingLeft: '15px', paddingRight: '15px' }}>
        <Col style={{ width: 'auto', margin: 0, paddingLeft: 2, paddingRight: 2 }}>

          <CardBox heading="cashAndDebt" headerBg="bg-product-laptop">
            <div className="jr-link" id="PLossCompdiv" />
            <Row>
              <Col lg={2}>&nbsp;</Col>
              <Col lg={2} className="text-center"><b>{data[0].name}</b></Col>
              <Col lg={2} className="text-center"><b>{data[1].name}</b></Col>
              <Col lg={2} className="text-center"><b>{data[2].name}</b></Col>
              <Col lg={2} className="text-center"><b>{data[3].name}</b></Col>
              <Col lg={2} className="text-center"><b>{data[4].name}</b></Col>
            </Row>

            <Row>
              <Col>&nbsp;</Col>
            </Row>
            <Row>
              <Col lg={2} style={{ fontWeight: 'bold', fontSize: '16px' }}><IntlMessages id="cashavailability" /></Col>
              <Col lg={2} className="text-right">{Util.formatCurrency( data[0].cash )}</Col>
              <Col lg={2} className="text-right">{Util.formatCurrency( data[1].cash )}</Col>
              <Col lg={2} className="text-right">{Util.formatCurrency( data[2].cash )}</Col>
              <Col lg={2} className="text-right">{Util.formatCurrency( data[3].cash )}</Col>
              <Col lg={2} className="text-right">{Util.formatCurrency( data[4].cash )}</Col>
            </Row>
            <Row>
              <Col lg={2} style={{ fontWeight: 'bold', fontSize: '16px' }}><IntlMessages id="outstandingDebt" /></Col>
              <Col lg={2} className="text-right">{Util.formatCurrency( data[0].outstandingDebt )}</Col>
              <Col lg={2} className="text-right">{Util.formatCurrency( data[1].outstandingDebt )}</Col>
              <Col lg={2} className="text-right">{Util.formatCurrency( data[2].outstandingDebt )}</Col>
              <Col lg={2} className="text-right">{Util.formatCurrency( data[3].outstandingDebt )}</Col>
              <Col lg={2} className="text-right">{Util.formatCurrency( data[4].outstandingDebt )}</Col>
            </Row>
            <Row>
              <hr />
            </Row>

          </CardBox>
        </Col>
      </Row>
    )}
  </>

);

export default CompCashAndDept;
