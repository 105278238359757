import React from 'react';
import { Field } from 'react-final-form';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import ChipInput from 'material-ui-chip-input';
import _ from 'lodash';

import IntlMessages from 'utils/IntlMessages';

const TextFieldInput = ( {
                           field, containerClass, fullWidth = true, onChange,
                           translateValues, validate, intl, marginContainer = true, splitCharacter, ...rest
                         } ) => (
  field ? (
      <Field name={field} validate={validate}>
        {( { meta, input } ) => (
          <FormControl
            error={meta.touched && !!meta.error}
            className={classNames( { 'mb-4': marginContainer } )}
            fullWidth={fullWidth}
          >
            <ChipInput
              {...input}
              {...rest}
              label={<IntlMessages id={rest.label}/>}
              error={meta.touched && !!meta.error}
              value={input.value || []}
              onAdd={( chip ) => {
                if (splitCharacter) {
                  const value = chip.split( ' ' );
                  input.onChange( [...input.value, ...value] );
                } else {
                  input.onChange( [...input.value, chip] );
                }
              }}
              onDelete={( chip, index ) => {
                const value = _.clone( input.value );
                value.splice( index, 1 );
                input.onChange( value );
              }}
            />

            {meta.touched && meta.error
            && (
              <FormHelperText>
                <IntlMessages id={meta.error} values={translateValues}/>
              </FormHelperText>
            )}
          </FormControl>
        )}
      </Field>
    )
    : (
      <FormControl
        className={classNames( { 'mb-4': marginContainer } )}
        fullWidth={fullWidth}
      >
        <TextField
          InputLabelProps={{ className: 'label-shrink' }}
          {...rest}
          placeholder={rest.placeholder ? intl.formatMessage( { id: rest.placeholder } ) : null}
          value={rest.value || ''}
          onChange={( e ) => {
            if (onChange) onChange( e.target.value, e );
          }}
        />
      </FormControl>
    )

);

export default injectIntl( TextFieldInput );
