import React from 'react';
import { Route, Switch } from 'react-router-dom';

import FixedSidebar from 'components/custom/FixedSidebar';
import Error404 from 'components/theme/Error404';
import EditForm from '../../../../../components/Admin/University/EditForm';
import UserLicenses from './UserLicenses';
import ProfLicenses from './ProfLicenses';

import PurchasedLicenses from './PurchasedLicenses';

const General = ( { entityId, match } ) => {
  const menus = [
    {
      headerId: 1,
      heading: 'general',
      items: [
        {
          id: 1,
          icon: 'label',
          name: 'information',
          color: 'primary',
          url: match.url,
        },
        {
          id: 2,
          icon: 'money-box',
          name: 'purchasedLicenses',
          color: 'warning',
          url: `${match.url}/purchased-licenses`,
        },
        {
          id: 3,
          icon: 'layers',
          name: 'userLicenses',
          color: 'info',
          url: `${match.url}/user-licenses`,
        },
        {
          id: 3,
          icon: 'layers',
          name: 'supervisorLicenses',
          color: 'info',
          url: `${match.url}/supervisor-licenses`,
        },
      ],
    },
  ];

  return (
    <div className="d-flex flex-wrap">
      <FixedSidebar
        items={menus}
        cardClasses="col-lg-2 col-md-6 col-12 pl-0"
      />
      <div className="col-12 col-md-6 col-lg-10 mt-3">
        <Switch>
          <Route
            path={`${match.url}/supervisor-licenses`}
            render={props => <ProfLicenses {...props} entityId={entityId} />}
          />
          <Route
            path={`${match.url}/user-licenses`}
            render={props => <UserLicenses {...props} entityId={entityId} />}
          />
          <Route
            path={`${match.url}/purchased-licenses`}
            render={props => <PurchasedLicenses {...props} entityId={entityId} />}
          />
          <Route
            path={match.url}
            render={() => <EditForm entityId={entityId} />}
            exact
          />
          <Route component={Error404} />
        </Switch>
      </div>
    </div>
  );
};

export default General;
