import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { Button } from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
import _ from 'lodash';
import async from 'async';

import SettingsActions from 'store/reducers/Settings';
import AttributeIDCostParamService from 'services/AttributeIDCostParam';
import AttributeIDAchievableParamService from 'services/AttributeIDAchievableParam';
import AttributeIDFormulaParamService from 'services/AttributeIDFormulaParam';
import CardBox from 'components/theme/CardBox';
import { InputRange, Select } from 'components/custom/FormElements';
import IntlMessages from 'utils/IntlMessages';
import AttributeCostMatrix from 'routes/App/components/Admin/Params/AttributeCostMatrix';
import { FieldArray } from 'react-final-form-arrays';

const AttributeCostParamsID = ( {
  data, options, selectedAttribute, selectedCategory,
  getAttributeDetail, toggleLoading, toggleAlert,
} ) => {
  const saveAttributesIDCost = ( formData, cb ) => {
    if ( !formData.attributesID || !formData.attributesID.data ) return toggleAlert( 'Error' );

    const attributes = _.map( formData.attributesID.data, item => ( {
      id: item.id,
      round: item.round,
      value1: item.value1,
      value2: item.value2,
      value3: item.value3,
      value4: item.value4,
      value5: item.value5,
      value6: item.value6,
      value7: item.value7,
      value8: item.value8,
      value9: item.value9,
      value10: item.value10,
    } ) );

    AttributeIDCostParamService.updateAttributeIDCostParam( selectedAttribute, {
      attributesIPlusDCost: attributes,
      category: selectedCategory,
    } )
      .then( response => cb( response.errors, response.data ) );
  };

  const saveAttributesIDAchievable = ( formData, cb ) => {
    if ( !formData.attributesIDAchievable || !formData.attributesIDAchievable.data ) return toggleAlert( 'Error' );

    const attributes = _.map( formData.attributesIDAchievable.data, item => ( {
      id: item.id,
      round: item.round,
      value1: item.value1,
      value2: item.value2,
      value3: item.value3,
      value4: item.value4,
      value5: item.value5,
      value6: item.value6,
      value7: item.value7,
      value8: item.value8,
      value9: item.value9,
      value10: item.value10,
    } ) );

    AttributeIDAchievableParamService.updateAttributeIDAchievableParam( selectedAttribute, {
      attributesIPlusDAchievable: attributes,
      category: selectedCategory,
    } )
      .then( response => cb( response.errors, response.data ) );
  };

  const saveAttributesIDFormula = ( formData, cb ) => {
    if ( !formData.attributesIDFormula || !formData.attributesIDFormula.data ) return toggleAlert( 'Error' );

    const attributes = _.map( formData.attributesIDFormula.data, item => ( {
      id: item.id,
      step: item.step,
      percentage: item.percentage,
    } ) );

    AttributeIDFormulaParamService.updateAttributeIDFormulaParam( selectedAttribute, {
      attributesIPlusDFormula: attributes,
      category: selectedCategory,
    } )
      .then( response => cb( response.errors, response.data ) );
  };

  const saveAttributes = ( formData ) => {
    if ( !formData ) return toggleAlert( 'Error' );
    toggleLoading( true );


    async.parallel( [
      cb => saveAttributesIDCost( formData, cb ),
      cb => saveAttributesIDAchievable( formData, cb ),
      cb => saveAttributesIDFormula( formData, cb ),
    ], ( error ) => {
      toggleLoading( false );
      if ( error ) return toggleAlert( error );
      toggleAlert( 'dataSaved', 'info' );
    } );
  };

  const dataFormula = data.attributesIDFormula ? data.attributesIDFormula.data : [];
  return (
    <Form
      initialValues={data}
      onSubmit={saveAttributes}
      mutators={{ ...arrayMutators }}
      render={( { handleSubmit, form, values } ) => (
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              <CardBox
                cardStyle="jr-full-card m-0"
                headingStyle="mb-0 pb-0"
                heading={[
                  <i
                    key={1}
                    className="zmdi zmdi-globe-alt zmdi-hc-lg text-secondary mr-2"
                  />,
                  <IntlMessages id="attributeCostIDParams" key={2} />,
                ]}
              >
                <div className="row px-5 pt-2 py-3">
                  <div className="col-md-3">
                    <Select
                      isClearable={false}
                      translateOptions={false}
                      marginContainer={false}
                      label="category"
                      containerClass="mb-3 mb-md-0"
                      options={options.categories}
                      value={selectedCategory}
                      onChange={value => getAttributeDetail(
                        selectedAttribute, value,
                      )}
                    />
                  </div>

                  <div className="col-md-3">
                    <Select
                      isClearable={false}
                      translateOptions={false}
                      marginContainer={false}
                      label="attribute"
                      containerClass="mb-3 mb-md-0"
                      options={options.productAttributes}
                      value={selectedAttribute}
                      onChange={value => getAttributeDetail(
                        value, selectedCategory,
                      )}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <h4 className="font-weight-bold px-3"><IntlMessages id="cost" /></h4>
                  </div>
                </div>

                <AttributeCostMatrix
                  fieldName="attributesID.data"
                  data={values.attributesID ? values.attributesID.data : []}
                  values={values}
                />

                <div className="row mt-4">
                  <div className="col-md-8">
                    <h4 className="font-weight-bold px-3"><IntlMessages id="maxAchievableValue" /></h4>
                    <AttributeCostMatrix
                      fieldName="attributesIDAchievable.data"
                      data={values.attributesIDAchievable ? values.attributesIDAchievable.data : []}
                      values={values}
                      renderField={field => (
                        <InputRange
                          containerClass="table-input-range pl-2"
                          minValue={2}
                          maxValue={9}
                          marginContainer={false}
                          field={field}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-4">
                    <h4 className="font-weight-bold"><IntlMessages id="combinedValueFormula" /></h4>
                    <div className="table-responsive-material pb-2" style={{ maxHeight: 450 }}>
                      <table className="table custom-table custom-table-border table-sm mb-0">
                        <tbody>
                          <FieldArray name="attributesIDFormula.data">
                            {( { fields } ) => (
                              fields.map( ( round, index ) => (
                                <tr
                                  tabIndex={-1}
                                  key={dataFormula[index].id}
                                >
                                  <td width={100}>
                                    <h5 className="m-0">
                                      Step
                                      {' '}
                                      {dataFormula[index].step}
                                    </h5>
                                  </td>
                                  <td className="px-2">
                                    <InputRange
                                      fullWidth={false}
                                      containerClass="table-input-range w-75 pl-2"
                                      minValue={1}
                                      maxValue={100}
                                      marginContainer={false}
                                      field={`${round}.percentage`}
                                    />
                                  </td>
                                </tr>
                              ) )
                            )}
                          </FieldArray>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="row pt-2 pb-4 px-3">
                  <div className="col-md-12 text-right">
                    <Button
                      variant="contained"
                      color="primary"
                      className="jr-btn jr-btn-lg"
                      type="submit"
                    >
                      <i className="fa fa-save" />
                      <IntlMessages id="saveBtn" />
                    </Button>
                  </div>
                </div>
              </CardBox>
            </div>
          </div>
        </form>
      )}
    />
  );
};

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps,
)( AttributeCostParamsID );
