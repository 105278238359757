import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { Row, Col } from 'reactstrap';

import CardBox from 'components/custom_v2/CardBox';
import IntlMessages from 'utils/IntlMessages';
import Util from 'utils/Util';

export default ( { data, defaultOpened = false } ) => {
  const mediaAttributes = _.filter( data.mediaAttributes, { selected: true } );
  const internetPlans = _.filter( data.internetPlans,
    item => ( item.regions && item.regions.length ) || item.gender || item.ageFrom || item.ageTo
      || ( item.body && item.body.length ) || item.monthlyInvestment );
  const [opened, setOpened] = useState( defaultOpened );

  const promotePurchasing = (
    <i
      className={classnames( 'fa', {
        'fa-check': data.promotePurchasing,
        'fa-times': !data.promotePurchasing,
      } )}
    />
  );

  const toggleOpened = useCallback( () => {
    setOpened( !opened );
  }, [opened] );

  const getPropertyRender = ( label, key, value ) => (
    <p className="mb-1">
      <strong><IntlMessages id={label} /></strong>
      {': '}
      {value || ( _.get( data, key ) ? _.get( data, key ) : '-' )}
    </p>
  );

  const getTranslatePropertyRender = ( label, key, value ) => (
    <p className="mb-1">
      <strong><IntlMessages id={label} /></strong>
      {': '}
      {value ? <IntlMessages id={value} />
        : ( _.get( data, key ) ? <IntlMessages id={_.get( data, key )} /> : '-' )}
    </p>
  );

  const getTranslateArrayRender = ( label, value ) => (
    <p className="mb-1">
      <strong><IntlMessages id={label} /></strong>
      {': '}
      {_.map( value, ( item, index ) => (
        <span key={item.id}>
          <IntlMessages id={item.id} />
          {index < value.length - 1 ? ', ' : ''}
        </span>
      ) )}
    </p>
  );

  return (
    <CardBox header={(
      <div
        className="p-2 bg-primary cursor-pointer d-flex justify-content-between"
        onClick={toggleOpened}
      >
        <h4 className="card-heading m-0 text-white">
          <IntlMessages id="overview" />
        </h4>
        <i className={classnames( 'fa text-white', {
          'fa-chevron-up': !opened,
          'fa-chevron-down': opened,
        } )}
        />
      </div>
    )}
    >
      {opened
      && (
        <Row>
          <Col md={4}>
            <p className="mb-1  border-bottom border-grey">
              <span className="text-uppercase font-weight-bold"><IntlMessages id="campaign" /></span>
            </p>
            <div className={classnames( 'mb-2 p-2 crm-detail-bg-1' )}>
              {getPropertyRender( 'name', 'name' )}
              {getPropertyRender( 'startDate', 'fromRound' )}
              {getPropertyRender( 'endDate', 'toRound' )}
              {getTranslatePropertyRender( 'mainObjective', 'contentType' )}
            </div>
            {data.contentType === 'PRODUCT'
            && (
              <div className={classnames( 'mb-2 p-2' )}>
                {getPropertyRender( 'product', 'product' )}
                {getPropertyRender( 'attributes', null, _.map( data.productFeature, 'id' )
                  .join( ', ' ) )}
                {getPropertyRender( 'promotePurchasingDirectChannel', null, promotePurchasing )}
              </div>
            )}

            {data.contentType === 'SERVICE'
            && (
              <div className={classnames( 'mb-2 p-2' )}>
                {getPropertyRender( 'service', 'service' )}
                {getPropertyRender( 'attributes', null, _.map( data.serviceFeature, 'id' )
                  .join( ', ' ) )}
                {getPropertyRender( 'promotePurchasingDirectChannel', null, promotePurchasing )}
              </div>
            )}

            {data.contentType === 'BUNDLE'
            && (
              <div className={classnames( 'mb-2 p-2' )}>
                {getPropertyRender( 'bundle', 'bundle' )}
                {getPropertyRender( 'promotePurchasingDirectChannel', null, promotePurchasing )}
              </div>
            )}
          </Col>

          <Col md={4}>
            <p className="mb-1  border-bottom border-grey">
              <span className="text-uppercase font-weight-bold"><IntlMessages id="media" /></span>
            </p>
            {_.map( mediaAttributes, ( item, index ) => (
              <div
                key={item.id}
                className={classnames( 'mb-2 p-2', { 'crm-detail-bg-1': index % 2 === 0 } )}
              >
                <Row>
                  <Col>
                    {getPropertyRender( 'channel', null, item.mediaChannel )}
                  </Col>
                  <Col>
                    {getPropertyRender( 'intensity', null, item.intensity )}
                  </Col>
                </Row>
              </div>
            ) )}
          </Col>

          <Col md={4}>
            <p className="mb-1 border-bottom border-grey">
              <span className="text-uppercase font-weight-bold"><IntlMessages id="internet" /></span>
            </p>
            {_.map( internetPlans, ( item, index ) => (
              <div
                key={item.id}
                className={classnames( 'mb-2 p-2', { 'crm-detail-bg-1': index % 2 === 0 } )}
              >
                <p className="mb-1">
                  <span className="text-uppercase font-weight-bold font-italic">
                    <IntlMessages
                      id={item.name}
                    />
                  </span>
                </p>
                <Row>
                  <Col>
                    {getTranslatePropertyRender( 'gender', null, item.gender )}
                  </Col>
                  <Col>
                    {getPropertyRender( 'age', null, item.ageFrom || item.ageTo
                      ? `${item.ageFrom || ''} ${item.ageTo && item.ageFrom ? 'to' : ''} ${item.ageTo || ''}` : false )}
                  </Col>
                </Row>
                {item.id === 'social_network_display'
                && (
                  <>
                    {getTranslateArrayRender( 'education', _.filter( item.body, { type: 'education' } ) )}
                    {getTranslateArrayRender( 'occupation', _.filter( item.body, { type: 'occupation' } ) )}
                  </>
                )}

                {item.id !== 'social_network_display' && item.id !== 'artificial_intelligence'
                && (
                  getTranslateArrayRender( item.bodyLabel, item.body )
                )}

                {item.id === 'artificial_intelligence'
                && ( getTranslatePropertyRender( item.bodyLabel, null, item.body ) )}

                {getPropertyRender( 'monthlyInvestment', null, Util.formatCurrency( item.monthlyInvestment ) )}
              </div>
            ) )}
          </Col>
        </Row>
      )}
    </CardBox>
  );
};
