import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import SettingsActions from 'store/reducers/Settings';
import LogService from 'services/Log';
import CardBox from 'components/theme/CardBox';
import Table from 'components/custom/Table/AsyncTable';
import IntlMessages from 'utils/IntlMessages';

class UserLicenses extends React.Component {
  state = { data: [] };

  getData = ( params ) => {
    const { toggleLoading, sessionId } = this.props;
    toggleLoading( true );

    LogService.getLogs( sessionId, params )
      .then( ( response ) => {
        toggleLoading( false );

        if ( response.ok ) {
          this.setState( {
            data: response.data.data || [],
            pages: Math.ceil( response.data.total / params.limit ),
            total: response.data.total,
          } );
        }
      } );
  };

  render() {
    return (
      <CardBox cardStyle="p-0">
        <Table
          onFetchData={this.getData}
          {...this.state}
          columns={[
            {
              Header: <IntlMessages id="name" />,
              accessor: 'user.name',
            },
            {
              Header: <IntlMessages id="workGroup" />,
              accessor: 'data.workgroup.name',
            },
            {
              Header: <IntlMessages id="commodity" />,
              accessor: 'data.commodity.name',
            },
            {
              Header: <IntlMessages id="createdAt" />,
              accessor: 'createdAt',
              Cell: props => ( props.value ? moment( Number( props.value ) ).format( 'DD/MM/YYYY HH:mm' ) : null ),
            },

            {
              Header: <IntlMessages id="action" />,
              accessor: 'action',
            },
          ]}
        />
      </CardBox>
    );
  }
}

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps,
)( UserLicenses );
