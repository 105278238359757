import React from 'react';
import CardBox from 'components/custom_v2/CardBox';

import { connect } from 'react-redux';
import BreadcrumbComp from 'components/custom_v2/Breadcrumb';
import { Col, Row } from 'reactstrap';
import _ from 'lodash';
// import CategoryService from 'services/Category';
import DashboardStudentService from 'services/DashboardStudent';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import ChartModal from 'routes/App/components/User/Chart';
import Util from 'utils/Util';
import { Avatar } from '@material-ui/core';
import cell from 'assets/images/mk/cell.png';
import laptop from 'assets/images/mk/laptop.png';
import tablet from 'assets/images/mk/tablet.png';
import IntlMessages from 'utils/IntlMessages';


class ProductCharts extends React.Component {
  state = {
    chartModal: false,
    seriesTitleBundle1: [],
    seriesTitle1: [],
    seriesTitle2: [],
    seriesTitle3: [],
  };


  componentDidMount() {
    const { user } = this.props;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );

    DashboardStudentService.getHistoricalBundle( user.activeSession.id,
      { workgroup: this.license.workGroup.id, limit: -1 } )
      .then( ( response ) => {
        if ( response.ok ) {
          const allData = _.cloneDeep( response.data.data );
          const roundsBundle1 = [];
          const seriesTitleBundle1 = [];

          this.getDataSerie( allData, roundsBundle1, seriesTitleBundle1, 'unitSales' );
          const result1 = this.createCharSerie( 'bundleunitsale', roundsBundle1, allData, 'unitSales', 'lastsUnitSales' );
          this.configBundle1 = _.cloneDeep( result1.config );
          this.chartBundle1 = result1.chart;
          const tableBundle1 = result1.table;

          this.getDataSerie( allData, roundsBundle1, seriesTitleBundle1, 'revenues' );
          const result2 = this.createCharSerie( 'bundlerevenuesale', roundsBundle1, allData, 'revenues', 'lastsRevenues' );
          this.configBundle2 = _.cloneDeep( result2.config );
          this.chartBundle2 = result2.chart;
          const tableBundle2 = result2.table;

          this.setState( ( {
            tableBundle1,
            seriesTitleBundle1,
            tableBundle2,
          } ) );
        }
      } );

    DashboardStudentService.getHistoricalProducts( user.activeSession.id,
      this.license.workGroup.id, { limit: -1 } )
      .then( ( response ) => {
        if ( response.ok ) {
          const allData = _.cloneDeep( response.data.data );
          const categories1 = _.filter( allData, { category: { label: 'CATEGORY_1' } } );
          const categories2 = _.filter( allData, { category: { label: 'CATEGORY_2' } } );
          const categories3 = _.filter( allData, { category: { label: 'CATEGORY_3' } } );
          const roundsCat1 = [];
          const roundsCat2 = [];
          const roundsCat3 = [];
          const seriesTitle1 = [];
          const seriesTitle2 = [];
          const seriesTitle3 = [];

          this.getDataSerie( categories1, roundsCat1, seriesTitle1, 'unitSales' );
          const result1 = this.createCharSerie( 'laptopsalesbybrandu', roundsCat1, categories1, 'unitSales', 'lastsUnitSales' );
          this.config1 = _.cloneDeep( result1.config );
          this.chart = result1.chart;
          const table1 = result1.table;

          this.getDataSerie( categories1, roundsCat1, seriesTitle1, 'revenues' );
          const result11 = this.createCharSerie( 'laptopsalesbybrandrevenuew', roundsCat1, categories1, 'revenues', 'lastsRevenues' );
          this.config11 = _.cloneDeep( result11.config );
          this.chart11 = result11.chart;
          const table11 = result11.table;

          this.getDataSerie( categories1, roundsCat1, seriesTitle1, 'profits' );
          const result12 = this.createCharSerie( 'laptopsalesbybrandprofits', roundsCat1, categories1, 'profits', 'lastsProfits' );
          this.config12 = _.cloneDeep( result12.config );
          this.chart12 = result12.chart;
          const table12 = result12.table;

          this.getDataSerie( categories1, roundsCat1, seriesTitle1, 'percentDirectSales' );
          const result13 = this.createCharSerie( 'laptopsalesbybrandsale', roundsCat1, categories1, 'percentDirectSales', 'percentDirectSales' );
          this.config13 = _.cloneDeep( result13.config );
          this.chart13 = result13.chart;
          const table13 = result13.table;

          this.getDataSerie( categories2, roundsCat2, seriesTitle2, 'unitSales' );
          let result2 = this.createCharSerie( 'tabletsalesbybrandu', roundsCat2, categories2, 'unitSales', 'lastsUnitSales' );
          this.config2 = _.cloneDeep( result2.config );
          this.chart2 = result2.chart;
          const table2 = result2.table;

          this.getDataSerie( categories2, roundsCat2, seriesTitle2, 'revenues' );
          result2 = this.createCharSerie( 'tabletsalesbybrandrevenue', roundsCat2, categories2, 'revenues', 'lastsRevenues' );
          this.config21 = _.cloneDeep( result2.config );
          this.chart21 = result2.chart;
          const table21 = result2.table;

          this.getDataSerie( categories2, roundsCat2, seriesTitle2, 'profits' );
          result2 = this.createCharSerie( 'tabletsalesbybrandprofits', roundsCat2, categories2, 'profits', 'lastsProfits' );
          this.config22 = _.cloneDeep( result2.config );
          this.chart22 = result2.chart;
          const table22 = result2.table;

          this.getDataSerie( categories2, roundsCat2, seriesTitle2, 'percentDirectSales' );
          result2 = this.createCharSerie( 'tabletsalesbybrandsale', roundsCat2, categories2, 'percentDirectSales', 'percentDirectSales' );
          this.config23 = _.cloneDeep( result2.config );
          this.chart23 = result2.chart;
          const table23 = result2.table;

          this.getDataSerie( categories3, roundsCat3, seriesTitle3, 'unitSales' );
          let result3 = this.createCharSerie( 'cellsalesbybrandu', roundsCat3, categories3, 'unitSales', 'lastsUnitSales' );
          this.config3 = _.cloneDeep( result3.config );
          this.chart3 = result3.chart;
          const table3 = result3.table;

          this.getDataSerie( categories3, roundsCat3, seriesTitle3, 'revenues' );
          result3 = this.createCharSerie( 'cellsalesbybrandrevenue', roundsCat3, categories3, 'revenues', 'lastsRevenues' );
          this.config31 = _.cloneDeep( result3.config );
          this.chart31 = result3.chart;
          const table31 = result3.table;

          this.getDataSerie( categories3, roundsCat3, seriesTitle3, 'profits' );
          result3 = this.createCharSerie( 'cellsalesbybrandprofits', roundsCat3, categories3, 'profits', 'profits' );
          this.config32 = _.cloneDeep( result3.config );
          this.chart32 = result3.chart;
          const table32 = result3.table;

          this.getDataSerie( categories3, roundsCat3, seriesTitle3, 'percentDirectSales' );
          result3 = this.createCharSerie( 'cellsalesbybrandsale', roundsCat3, categories3, 'percentDirectSales', 'percentDirectSales' );
          this.config33 = _.cloneDeep( result3.config );
          this.chart33 = result3.chart;
          const table33 = result3.table;


          this.setState( ( {
            table1,
            table2,
            table3,
            table11,
            table12,
            table13,
            table21,
            table22,
            table23,
            table33,
            table32,
            table31,
            seriesTitle1,
            seriesTitle2,
            seriesTitle3,
          } ) );
        }
      } );
  }


  componentWillUnmount() {
    if ( this.chart ) {
      this.chart.dispose();
    }
    if ( this.chart11 ) {
      this.chart11.dispose();
    }
    if ( this.chart12 ) {
      this.chart12.dispose();
    }
    if ( this.chart13 ) {
      this.chart13.dispose();
    }
    if ( this.chart2 ) {
      this.chart2.dispose();
    }
    if ( this.chart3 ) {
      this.chart3.dispose();
    }
    if ( this.chart4 ) {
      this.chart4.dispose();
    }
    if ( this.chart5 ) {
      this.chart5.dispose();
    }
    if ( this.chart6 ) {
      this.chart6.dispose();
    }
    if ( this.chart7 ) {
      this.chart7.dispose();
    }
    if ( this.chart8 ) {
      this.chart8.dispose();
    }
    if ( this.chart9 ) {
      this.chart9.dispose();
    }
  }

  getDataSerie=( categories, roundsCat = [], seriesTitle, param ) => {
    _.map( categories, ( item ) => {
      _.map( item.historical, ( round ) => {
        const exist = _.findIndex( roundsCat, { id: round.round.id } );
        if ( exist === -1 ) {
          const dataToPush = { attribute: round.round.shortName, id: round.round.id };
          dataToPush[item.id] = round[param];
          roundsCat.push( dataToPush );
        } else {
          // eslint-disable-next-line no-param-reassign
          roundsCat[exist][item.id] = round[param];
        }
      } );
      seriesTitle.push( item.name );
    } );
  };

  createCharSerie=( key, roundsCat, categories, param, param1 ) => {
    const config = {
      data: roundsCat,
      type: am4charts.XYChart,
      xAxes: [{
        type: 'CategoryAxis',
        dataFields: { category: 'attribute' },
      }],
      yAxes: [{ type: 'ValueAxis' }],
      series: _.map( categories, itemX => ( {
        type: 'LineSeries',
        dataFields: {
          valueY: itemX.id,
          categoryX: 'attribute',
        },
        name: itemX.name,
        strokeWidth: 2,
      } ) ),
    };
    const chart = am4core.createFromConfig( _.cloneDeep( config ), key );
    const table = _.map( categories, item => ( {
      id: item.id,
      name: item.name,
      unitSales: item[param],
      lastsUnitSales: item[param1],
    } ) );
    return { config, chart, table };
  };


  toggleChartModal = ( configModal, titleModal, seriesTitleModal ) => {
    this.setState( previousState => ( {
      chartModal: !previousState.chartModal,
      configModal,
      titleModal,
      seriesTitleModal,
    } ) );
  };

  render() {
    const {
      chartModal,
      configModal,
      titleModal,
      seriesTitleModal,
      table1,
      table2,
      table3,
      table11,
      table12,
      table13,
      table21,
      table22,
      table23,
      table33,
      table32,
      table31,
      seriesTitle1,
      seriesTitle2,
      seriesTitle3,
      tableBundle1,
      tableBundle2,
      seriesTitleBundle1,
    } = this.state;

    const StyleLineRight = { borderRight: '1px solid' };

    return (
      <div>
        <BreadcrumbComp links={[
          {
            url: '/',
            name: 'informationDashboard',
          },
          { name: 'products' },
        ]}
        />
        <CardBox
          header={<div />}
        >
          <Row>
            <Col lg={4}>
              <Avatar
                className="size-100 border-radius-0 m-auto"
                alt="..."
                src={laptop}
              />
            </Col>
            <Col lg={4}>
              <Avatar
                className="size-100 border-radius-0 m-auto"
                alt="..."
                src={cell}
              />
            </Col>
            <Col lg={4}>
              <Avatar
                className="size-100 border-radius-0 m-auto"
                alt="..."
                src={tablet}
              />
            </Col>
          </Row>
        </CardBox>
        <Row>
          <Col lg={4} style={StyleLineRight}>
            <CardBox
              heading="LaptopSalesU"
              headerBg="bg-product-laptop"
              icon="laptop"
              childrenStyle=""
              onClick={() => this.toggleChartModal( this.config1, 'LaptopSalesU', seriesTitle1 )}
            >
              <div className="jr-link mt-3" id="laptopsalesbybrandu" style={{ width: '100%', height: '250px' }} />
              <div>
                <table
                  className="table table-striped table-products mb-0 table-products-laptop mt-3 table-header"
                >
                  <thead>
                    <tr>
                      <th className="thead-report"><IntlMessages id="this_month_sales" /></th>
                      <th className="thead-report" />
                      <th className="thead-report"><IntlMessages id="12-month_sales" /></th>
                      <th className="thead-report" />
                    </tr>
                  </thead>
                  <tbody>
                    {_.map( table1, item => (
                      <tr key={item.id}>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumber( item.unitSales )}</td>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumber( item.lastsUnitSales )}</td>
                      </tr>
                    ) )}
                  </tbody>
                </table>
              </div>
            </CardBox>
          </Col>

          <Col lg={4} style={StyleLineRight}>
            <CardBox
              heading="CellPhoneSalesU"
              headerBg="bg-product-cell"
              icon="mobile-alt"
              childrenStyle=""
              onClick={() => this.toggleChartModal( this.config3, 'CellPhoneSalesU', seriesTitle3 )}
            >
              <div className="jr-link mt-3" id="cellsalesbybrandu" style={{ width: '100%', height: '250px' }} />
              <div>
                <table
                  className="table table-striped table-products mb-0 table-products-cell mt-3 table-header"
                >
                  <thead>
                    <tr>
                      <th className="thead-report"><IntlMessages id="this_month_sales" /></th>
                      <th className="thead-report" />
                      <th className="thead-report"><IntlMessages id="12-month_sales" /></th>
                      <th className="thead-report" />
                    </tr>
                  </thead>
                  <tbody>
                    {_.map( table3, item => (
                      <tr key={item.id}>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumber( item.unitSales )}</td>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumber( item.lastsUnitSales )}</td>
                      </tr>
                    ) )}
                  </tbody>
                </table>
              </div>
            </CardBox>
          </Col>
          <Col lg={4}>
            <CardBox
              heading="TabletSalesU"
              headerBg="bg-product-tablet"
              icon="tablet-alt"
              childrenStyle=""
              onClick={() => this.toggleChartModal( this.config2, 'TabletSalesU', seriesTitle2 )}
            >
              <div className="jr-link mt-3" id="tabletsalesbybrandu" style={{ width: '100%', height: '250px' }} />
              <div>
                <table
                  className="table table-striped table-products mb-0 table-products-tablet mt-3 table-header"
                >
                  <thead>
                    <tr>
                      <th className="thead-report"><IntlMessages id="this_month_sales" /></th>
                      <th className="thead-report" />
                      <th className="thead-report"><IntlMessages id="12-month_sales" /></th>
                      <th className="thead-report" />
                    </tr>
                  </thead>
                  <tbody>
                    {_.map( table2, item => (
                      <tr key={item.id}>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumber( item.unitSales )}</td>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumber( item.lastsUnitSales )}</td>
                      </tr>
                    ) )}
                  </tbody>
                </table>
              </div>
            </CardBox>
          </Col>
        </Row>
        <Row>
          <Col lg={4} style={StyleLineRight}>
            <CardBox
              heading="LaptopSalesRevenue"
              headerBg="bg-product-laptop"
              icon="laptop"
              childrenStyle=""
              onClick={() => this.toggleChartModal( this.config11, 'LaptopSalesRevenue', seriesTitle1 )}
            >
              <div className="jr-link mt-3" id="laptopsalesbybrandrevenuew" style={{ width: '100%', height: '250px' }} />
              <div>
                <table
                  className="table table-striped table-products mb-0 table-products-laptop mt-3 table-header"
                >
                  <thead>
                    <tr>
                      <th className="thead-report"><IntlMessages id="this_month_sales" /></th>
                      <th className="thead-report" />
                      <th className="thead-report"><IntlMessages id="12-month_sales" /></th>
                      <th className="thead-report" />
                    </tr>
                  </thead>
                  <tbody>
                    {_.map( table11, item => (
                      <tr key={item.id}>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumber( item.unitSales )}</td>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumber( item.lastsUnitSales )}</td>
                      </tr>
                    ) )}
                  </tbody>
                </table>
              </div>
            </CardBox>
          </Col>

          <Col lg={4} style={StyleLineRight}>
            <CardBox
              heading="CellPhoneSalesRevenue"
              headerBg="bg-product-cell"
              icon="mobile-alt"
              childrenStyle=""
              onClick={() => this.toggleChartModal( this.config31, 'CellPhoneSalesRevenue', seriesTitle3 )}
            >
              <div className="jr-link mt-3" id="cellsalesbybrandrevenue" style={{ width: '100%', height: '250px' }} />
              <div>
                <table
                  className="table table-striped table-products mb-0 table-products-cell mt-3 table-header"
                >
                  <thead>
                    <tr>
                      <th className="thead-report"><IntlMessages id="this_month_sales" /></th>
                      <th className="thead-report" />
                      <th className="thead-report"><IntlMessages id="12-month_sales" /></th>
                      <th className="thead-report" />
                    </tr>
                  </thead>
                  <tbody>
                    {_.map( table31, item => (
                      <tr key={item.id}>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumber( item.unitSales )}</td>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumber( item.lastsUnitSales )}</td>
                      </tr>
                    ) )}
                  </tbody>
                </table>
              </div>
            </CardBox>
          </Col>
          <Col lg={4}>
            <CardBox
              heading="TabletSalesRevenue"
              headerBg="bg-product-tablet"
              icon="tablet-alt"
              childrenStyle=""
              onClick={() => this.toggleChartModal( this.config21, 'TabletSalesRevenue', seriesTitle2 )}
            >
              <div className="jr-link mt-3" id="tabletsalesbybrandrevenue" style={{ width: '100%', height: '250px' }} />
              <div>
                <table
                  className="table table-striped table-products mb-0 table-products-tablet mt-3 table-header"
                >
                  <thead>
                    <tr>
                      <th className="thead-report"><IntlMessages id="this_month_sales" /></th>
                      <th className="thead-report" />
                      <th className="thead-report"><IntlMessages id="12-month_sales" /></th>
                      <th className="thead-report" />
                    </tr>
                  </thead>
                  <tbody>
                    {_.map( table21, item => (
                      <tr key={item.id}>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumber( item.unitSales )}</td>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumber( item.lastsUnitSales )}</td>
                      </tr>
                    ) )}
                  </tbody>
                </table>
              </div>
            </CardBox>
          </Col>
        </Row>
        <Row>
          <Col lg={4} style={StyleLineRight}>
            <CardBox
              heading="LaptopSalesProfits"
              headerBg="bg-product-laptop"
              icon="laptop"
              childrenStyle=""
              onClick={() => this.toggleChartModal( this.config12, 'LaptopProfits', seriesTitle1 )}
            >
              <div className="jr-link mt-3" id="laptopsalesbybrandprofits" style={{ width: '100%', height: '250px' }} />
              <div>
                <table
                  className="table table-striped table-products mb-0 table-products-laptop mt-3 table-header"
                >
                  <thead>
                    <tr>
                      <th className="thead-report"><IntlMessages id="this_month_sales" /></th>
                      <th className="thead-report" />
                      <th className="thead-report"><IntlMessages id="12-month_sales" /></th>
                      <th className="thead-report" />
                    </tr>
                  </thead>
                  <tbody>
                    {_.map( table12, item => (
                      <tr key={item.id}>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumberNoDecimals( item.unitSales )}</td>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumberNoDecimals( item.lastsUnitSales )}</td>
                      </tr>
                    ) )}
                  </tbody>
                </table>
              </div>
            </CardBox>
          </Col>

          <Col lg={4} style={StyleLineRight}>
            <CardBox
              heading="CellphoneSalesProfits"
              headerBg="bg-product-cell"
              icon="mobile-alt"
              childrenStyle=""
              onClick={() => this.toggleChartModal( this.config32, 'CellphoneSalesProfits', seriesTitle3 )}
            >
              <div className="jr-link mt-3" id="cellsalesbybrandprofits" style={{ width: '100%', height: '250px' }} />
              <div>
                <table
                  className="table table-striped table-products mb-0 table-products-cell mt-3 table-header"
                >
                  <thead>
                    <tr>
                      <th className="thead-report"><IntlMessages id="this_month_sales" /></th>
                      <th className="thead-report" />
                      <th className="thead-report"><IntlMessages id="12-month_sales" /></th>
                      <th className="thead-report" />
                    </tr>
                  </thead>
                  <tbody>
                    {_.map( table32, item => (
                      <tr key={item.id}>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumberNoDecimals( item.unitSales )}</td>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumberNoDecimals( item.lastsUnitSales )}</td>
                      </tr>
                    ) )}
                  </tbody>
                </table>
              </div>
            </CardBox>
          </Col>
          <Col lg={4}>
            <CardBox
              heading="TabletSalesProfits"
              headerBg="bg-product-tablet"
              icon="tablet-alt"
              childrenStyle=""
              onClick={() => this.toggleChartModal( this.config22, 'TabletSalesProfits', seriesTitle2 )}
            >
              <div className="jr-link mt-3" id="tabletsalesbybrandprofits" style={{ width: '100%', height: '250px' }} />
              <div>
                <table
                  className="table table-striped table-products mb-0 table-products-tablet mt-3 table-header"
                >
                  <thead>
                    <tr>
                      <th className="thead-report"><IntlMessages id="this_month_sales" /></th>
                      <th className="thead-report" />
                      <th className="thead-report"><IntlMessages id="12-month_sales" /></th>
                      <th className="thead-report" />
                    </tr>
                  </thead>
                  <tbody>
                    {_.map( table22, item => (
                      <tr key={item.id}>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumberNoDecimals( item.unitSales )}</td>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumberNoDecimals( item.lastsUnitSales )}</td>
                      </tr>
                    ) )}
                  </tbody>
                </table>
              </div>
            </CardBox>
          </Col>
        </Row>
        <Row>
          <Col lg={4} style={StyleLineRight}>
            <CardBox
              heading="LaptopsDirectSales"
              headerBg="bg-product-laptop"
              icon="laptop"
              childrenStyle=""
              onClick={() => this.toggleChartModal( this.config13, 'LaptopsDirectSales', seriesTitle1 )}
            >
              <div className="jr-link mt-3" id="laptopsalesbybrandsale" style={{ width: '100%', height: '250px' }} />
              <div>
                <table
                  className="table table-striped table-products mb-0 table-products-laptop mt-3 table-header"
                >
                  <thead>
                    <tr>
                      <th className="thead-report"><IntlMessages id="this_month_sales" /></th>
                      <th className="thead-report" />
                      <th className="thead-report"><IntlMessages id="12-month_sales" /></th>
                      <th className="thead-report" />
                    </tr>
                  </thead>
                  <tbody>
                    {_.map( table13, item => (
                      <tr key={item.id}>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumber( item.unitSales )}</td>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumber( item.lastsUnitSales )}</td>
                      </tr>
                    ) )}
                  </tbody>
                </table>
              </div>
            </CardBox>
          </Col>

          <Col lg={4} style={StyleLineRight}>
            <CardBox
              heading="CellPhoneDirectSales"
              headerBg="bg-product-cell"
              icon="mobile-alt"
              childrenStyle=""
              onClick={() => this.toggleChartModal( this.config33, 'CellPhoneDirectSales', seriesTitle3 )}
            >
              <div className="jr-link mt-3" id="cellsalesbybrandsale" style={{ width: '100%', height: '250px' }} />
              <div>
                <table
                  className="table table-striped table-products mb-0 table-products-cell mt-3 table-header"
                >
                  <thead>
                    <tr>
                      <th className="thead-report"><IntlMessages id="this_month_sales" /></th>
                      <th className="thead-report" />
                      <th className="thead-report"><IntlMessages id="12-month_sales" /></th>
                      <th className="thead-report" />
                    </tr>
                  </thead>
                  <tbody>
                    {_.map( table33, item => (
                      <tr key={item.id}>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumber( item.unitSales )}</td>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumber( item.lastsUnitSales )}</td>
                      </tr>
                    ) )}
                  </tbody>
                </table>
              </div>
            </CardBox>
          </Col>
          <Col lg={4}>
            <CardBox
              heading="TabletDirectSales"
              headerBg="bg-product-tablet"
              icon="tablet-alt"
              childrenStyle=""
              onClick={() => this.toggleChartModal( this.config22, 'TabletDirectSales', seriesTitle2 )}
            >
              <div className="jr-link mt-3" id="tabletsalesbybrandsale" style={{ width: '100%', height: '250px' }} />
              <div>
                <table
                  className="table table-striped table-products mb-0 table-products-tablet mt-3 table-header"
                >
                  <thead>
                    <tr>
                      <th className="thead-report"><IntlMessages id="this_month_sales" /></th>
                      <th className="thead-report" />
                      <th className="thead-report"><IntlMessages id="12-month_sales" /></th>
                      <th className="thead-report" />
                    </tr>
                  </thead>
                  <tbody>
                    {_.map( table23, item => (
                      <tr key={item.id}>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumber( item.unitSales )}</td>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumber( item.lastsUnitSales )}</td>
                      </tr>
                    ) )}
                  </tbody>
                </table>
              </div>
            </CardBox>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <CardBox
              heading="BundlesU"
              childrenStyle=""
              headerBg="bg-bundles"
              onClick={() => this.toggleChartModal( this.configBundle1, 'BundlesU', seriesTitleBundle1 )}
            >
              <div className="jr-link mt-3" id="bundleunitsale" style={{ width: '100%', height: '250px' }} />
              <div>
                <table
                  className="table table-striped table-products table-bundle mb-0 mt-3 table-header"
                >
                  <thead>
                    <tr>
                      <th className="thead-report"><IntlMessages id="this_month_sales" /></th>
                      <th className="thead-report" />
                      <th className="thead-report"><IntlMessages id="12-month_sales" /></th>
                      <th className="thead-report" />
                    </tr>
                  </thead>
                  <tbody>
                    {_.map( tableBundle1, item => (
                      <tr key={item.id}>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumber( item.unitSales )}</td>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumber( item.lastsUnitSales )}</td>
                      </tr>
                    ) )}
                  </tbody>
                </table>
              </div>
            </CardBox>
          </Col>
          <Col lg={6}>
            <CardBox
              heading="BundlesRevenue"
              childrenStyle=""
              headerBg="bg-bundles"
              onClick={() => this.toggleChartModal( this.configBundle2, 'BundlesRevenue', seriesTitleBundle1 )}
            >
              <div className="jr-link mt-3" id="bundlerevenuesale" style={{ width: '100%', height: '250px' }} />
              <div>
                <table
                  className="table table-striped table-products table-bundle mb-0 mt-3 table-header"
                >
                  <thead>
                    <tr>
                      <th className="thead-report"><IntlMessages id="this_month_sales" /></th>
                      <th className="thead-report" />
                      <th className="thead-report"><IntlMessages id="12-month_sales" /></th>
                      <th className="thead-report" />
                    </tr>
                  </thead>
                  <tbody>
                    {_.map( tableBundle2, item => (
                      <tr key={item.id}>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumber( item.unitSales )}</td>
                        <td className="td-report">{item.name}</td>
                        <td className="td-report">{Util.formatNumber( item.lastsUnitSales )}</td>
                      </tr>
                    ) )}
                  </tbody>
                </table>
              </div>
            </CardBox>
          </Col>
        </Row>

        <ChartModal
          open={chartModal}
          title={titleModal}
          onClose={this.toggleChartModal}
          seriesTitle={seriesTitleModal}
          config={configModal}
        />

      </div>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

export default connect( mapStateToProps )( ProductCharts );
