import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import _ from 'lodash';

import rootSaga from 'sagas';
import reducers from './reducers';
import { loadState, saveState } from './localStorage';

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware( history );
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, routeMiddleware];

export default function configureStore() {
  const persistedState = loadState();
  const store = createStore( reducers( history ), persistedState,
    compose( applyMiddleware( ...middlewares ) ) );

  sagaMiddleware.run( rootSaga );

  if ( module.hot ) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept( './reducers/index', () => {
      const nextRootReducer = require( './reducers/index' ); // eslint-disable-line  global-require
      store.replaceReducer( nextRootReducer );
    } );
  }

  store.subscribe( _.throttle( () => {
    saveState( store.getState() );
  } ), 1000 );

  return store;
}
export { history };
