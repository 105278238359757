import api from '../config/Api';

const endpoint = 'v1';

export default {

  getReport( sessionId ) {
    return api.get( `${endpoint}/session/${sessionId}/report/parametrization` );
  },

  getAdminReport( id ) {
    return api.get( `${endpoint}/report/${id}/parametrization` );
  },
};
