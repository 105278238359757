import React from 'react';
import { connect } from 'react-redux';

import Sidebar from 'components/custom_v2/SideNav';
import SettingsActions from 'store/reducers/Settings';
import { Route } from 'react-router';
import Chart from './Chart';
import Company from './Company';
import Products from './Products';
import Customer from './Customer';
import Market from './Market';
import PublicMarket from './PublicMarket';
import Research from './Research';
import Activity from './Activity';

const General = ( { navCollapsed, toggleCollapsedNav, match } ) => {
  const menus = [
    {
      icon: 'widgets',
      name: 'company',
      color: 'sepia',
      url: `${match.url}/company`,
    },
    {
      icon: 'view-module',
      name: 'products',
      color: 'primary',
      url: `${match.url}/products`,
    },
    {
      icon: 'bookmark-outline',
      name: 'market',
      color: 'info',
      url: `${match.url}/market`,
    },
    {
      icon: 'sort-amount-asc',
      name: 'research',
      color: 'lime',
      url: `${match.url}/research`,
    },
    {
      icon: 'bookmark-outline',
      name: 'customers',
      color: 'danger',
      url: `${match.url}/customer`,
    },
    {
      icon: 'sort-amount-asc',
      name: 'activity',
      color: 'info',
      url: `${match.url}/activity`,
    },
  ];

  return (
    <div className="row">
      <Sidebar
        menus={menus}
        open={navCollapsed}
        onToggle={toggleCollapsedNav}
      />
      <div className="col-12 mt-3">
        <Route
          path={`${match.url}/chart`}
          component={Chart}
        />
        <Route
          path={`${match.url}/company`}
          component={Company}
        />
        <Route
          path={`${match.url}/products`}
          component={Products}
        />
        <Route
          path={`${match.url}/customer`}
          component={Customer}
        />
        <Route
          path={`${match.url}/market`}
          component={Market}
        />
        <Route
          path={`${match.url}/publicmarket`}
          component={PublicMarket}
        />
        <Route
          path={`${match.url}/research`}
          component={Research}
        />
        <Route
          path={`${match.url}/activity`}
          component={Activity}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ( { settings } ) => (
  { navCollapsed: settings.navCollapsed }
);

const mapDispatchToProps = {
  toggleCollapsedNav:
  SettingsActions.toggleCollapsedNav,
};

export default connect( mapStateToProps, mapDispatchToProps )( General );
