import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Row, Col } from 'reactstrap';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import async from 'async';
import { FormHelperText } from '@material-ui/core';

import IntlMessages from 'utils/IntlMessages';
import SettingsActions from 'store/reducers/Settings';
import Util from 'utils/Util';
import BreadcrumbComp from 'components/custom_v2/Breadcrumb';
import { RadioButtons } from 'components/custom/FormElements';
import ConfirmationModal from 'components/custom/ConfirmationModal';
import { required } from 'config/InputErrors';
import CemService from 'services/Cem';
import Help from 'routes/App/components/User/Help';
import HelpSnackBar from 'routes/App/components/User/HelpSnackBar';

class Service extends React.Component {
  state = {
    data: {},
    confirmSaveModal: false,
  };

  componentDidMount() {
    const { toggleAlert, user } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.activeSession = user.activeSession;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }

    this.getData();
    document.addEventListener( 'keydown', e => this.handleKeyPress( e ), true );
  }

  handleKeyPress = ( event ) => {
    if ( event.key === 'Enter' ) {
      if ( event.target.type === 'button' ) {
        event.target.click();
      } else {
        event.preventDefault();
        this.setState( { confirmSaveModal: true } );
      }
    }
  }


  getData = () => {
    const { toggleAlert, toggleLoading } = this.props;

    toggleLoading( true );
    async.parallel( {
      data: ( cb ) => {
        CemService.getCemsV2( this.activeSession.id,
          { workgroup: this.license.workGroup.id } )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            const { data } = response;
            data.attributesParam = _.map( data.attributesParam, attribute => ( {
              ...attribute,
              value: attribute.value.toString(),
            } ) );
            cb( null, data );
          } );
      },
      maxValues: ( cb ) => {
        CemService.getAttributeMaxValueV2( this.activeSession.id,
          { workgroup: this.license.workGroup.id } )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
      },
    }, ( error, results ) => {
      toggleLoading( false );
      if ( error ) {
        return toggleAlert( error );
      }
      const { data } = results;
      _.map( results.maxValues, ( item ) => {
        const attributeIndex = _.findIndex(
          results.data.attributesParam, { attribute: { id: item.id } },
        );
        if ( attributeIndex !== -1 ) {
          data.attributesParam[attributeIndex].values = _.map(
            new Array( 10 ), ( value, index ) => ( {
              id: index + 1,
              value: index + 1,
              disabled: index >= item.maxValue,
            } ),
          );
        }
      } );
      this.setState( { data } );
    } );
  };

  getAttributeCost = ( id, value, previousValue, index ) => {
    const { toggleLoading } = this.props;
    if ( value === previousValue ) return;

    toggleLoading( true );
    CemService.getAttributeCostV2( this.activeSession.id, {
      workgroup: this.license.workGroup.id,
      attributeCem: id,
      value,
    } )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) {
          this.form.change( `attributes[${index}].value`, previousValue );
          this.setState( {
            attributeError: response.errors,
            attributeIdError: id,
          } );
        } else {
          this.form.change( `attributes[${index}].cost`, response.data.cost );
          this.setState( {
            attributeError: null,
            attributeIdError: null,
          } );
          this.setState( { confirmSaveModal: true } );
        }
      } );
  };

  submitForm = ( formData ) => {
    const { toggleLoading, toggleAlert } = this.props;
    const { data } = this.state;

    const dataToSend = {
      workgroup: this.license.workGroup.id,
      cemRound: data.id,
      attributeData: _.map( formData.attributes, item => ( {
        attributeCem: item.attribute.id,
        value: Number( item.value ),
      } ) ),
    };

    toggleLoading( true );
    CemService.updateCemV2( this.activeSession.id, dataToSend )
      .then( ( response ) => {
        if ( !response.ok ) {
          toggleLoading( false );
          return toggleAlert( response.errors );
        }

        toggleAlert( 'dataSaved', 'info' );
        this.getData();
      } );
  };

  toggleConfirmSaveModal = () => {
    const { confirmSaveModal } = this.state;
    this.setState( { confirmSaveModal: !confirmSaveModal } );
  };

  render() {
    const { data, confirmSaveModal, attributeIdError, attributeError } = this.state;

    return (
      <Form
        initialValues={{ attributes: data.attributesParam }}
        onSubmit={this.submitForm}
        mutators={{ ...arrayMutators }}
        render={( { handleSubmit, form } ) => {
          this.form = form;
          return (
            <form onSubmit={handleSubmit}>
              <Row className="mb-2 align-items-center">
                <Col>
                  <BreadcrumbComp links={[
                    {
                      url: '/',
                      name: 'decisionDashboard',
                    },
                    { name: 'cem' },
                  ]}
                  />
                </Col>
                <Col md={4} className="d-flex justify-content-end align-items-center">
                  <Help
                    title="help.cem.title"
                    content="help.cem.content"
                    multimedia="help.cem.multimedia"
                  />

                </Col>
              </Row>

              <Row>
                <Col md={10} className="m-auto">
                  <div className="table-responsive">
                    <table className="table table-striped custom-table-v2 mb-0">
                      <thead>
                        <tr>
                          <th style={{ width: 300 }} />
                          <th><IntlMessages id="quality" /></th>
                          <th>
                            <div><IntlMessages id="estimatedIncremental" /></div>
                            <div><IntlMessages id="monthlyCost" /></div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <FieldArray name="attributes">
                          {( { fields } ) => (
                            fields.map( ( attributeField, index ) => {
                              const attribute = fields.value[index];
                              if ( !attribute ) return null;
                              return ( [
                                <tr key={attribute.id}>
                                  <td>
                                    <h5 className="text-uppercase font-weight-bold my-0">
                                      {attribute.attribute.name}
                                    </h5>
                                  </td>
                                  <td>
                                    <RadioButtons
                                      extraContent={(
                                        <Row>
                                          <Col xs={2}><IntlMessages id="low" /></Col>
                                          <Col xs className="text-right mr-3"><IntlMessages id="high" /></Col>
                                        </Row>
                                      )}
                                      translate={false}
                                      containerClass="w-auto"
                                      id={attribute.id}
                                      field={`${attributeField}.value`}
                                      validate={required}
                                      options={attribute.values}
                                      onChange={( value, previousValue ) => {
                                        this.getAttributeCost(
                                          attribute.attribute.id, value, previousValue, index,
                                        );
                                      }}
                                    />
                                    {
                                      attributeIdError === attribute.attribute.id
                                    && (
                                      <FormHelperText className="text-danger">
                                        {attributeError}
                                      </FormHelperText>
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {Util.formatCurrency( attribute.cost )}
                                  </td>
                                </tr>,
                              ] );
                            } ) )}
                        </FieldArray>
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
              <HelpSnackBar message="help.cem.snackbar" />
              <ConfirmationModal
                open={confirmSaveModal}
                title="confirmSave"
                confirmBtnText="yesModalBtn"
                onSubmit={() => {
                  this.toggleConfirmSaveModal();
                  handleSubmit();
                }}
                onClose={this.toggleConfirmSaveModal}
              />
            </form>
          );
        }}
      />
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( Service );
