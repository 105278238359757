import React from 'react';
import { connect } from 'react-redux';
import {
  Button, ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import async from 'async';
import _ from 'lodash';

import IntlMessages from 'utils/IntlMessages';
import { Select, TextField } from 'components/custom/FormElements';
import CardBox from 'components/theme/CardBox';
import Table from 'components/custom/Table/Custom';
import SessionInfoService from 'services/SessionInfo';
import RoundService from 'services/Round';
import SettingsActions from 'store/reducers/Settings';
import ContainerHeader from 'components/theme/ContainerHeader';
import Util from 'utils/Util';
import moment from 'moment';
import { Form } from 'react-final-form';
import { required } from 'config/InputErrors';
import AttributeMediaService from 'services/AttributeMedia';
import ReportService from 'services/Report';
import GlobalVariableService from 'services/GlobalVariable';
import WorkGroupService from 'services/WorkGroup';
import ConfirmationModal from 'components/custom/ConfirmationModal';

class SessionInfo extends React.Component {
  state = {
    workgroupInfo: {},
    rounds: [],
    roundGroups: [],
    groupForm: {},
    mediaAttributes: {},
    costVariables: [],
    confirmChangeNameModal: false,
    confirmChangeGroupModal: false,
    selectedWorkgroupId: null,
  };

  componentDidMount() {
    const { toggleAlert, user } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.activeSession = user.activeSession;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }

    this.getData();
  }

  getData = () => {
    const { toggleAlert, toggleLoading } = this.props;

    toggleLoading( true );
    async.parallel( {
      workgroupInfo: this.getWorkGroupDetail,
      rounds: ( cb ) => {
        async.waterfall( [
          this.getRounds,
          ( rounds ) => {
            if ( !rounds || !rounds[0] ) return cb();
            this.getGroups( { round: rounds[0].id }, ( error, results ) => {
              if ( error ) return cb( error );
              cb( null, {
                rounds,
                roundGroups: results,
                groupForm: { round: rounds[0].id },
              } );
            } );
          },
        ], cb );
      },
      mediaAttributes: this.getMediaAttributes,
      costVariables: this.getCostVariables,
    }, ( error, results ) => {
      toggleLoading( false );
      if ( error ) {
        return toggleAlert( error );
      }

      this.setState( {
        workgroupInfo: results.workgroupInfo,
        rounds: results.rounds.rounds,
        roundGroups: results.rounds.roundGroups,
        groupForm: results.rounds.groupForm,
        costVariables: results.costVariables,
        mediaAttributes: {
          radio: _.filter( results.mediaAttributes, { mediaType: 'RADIO' } ),
          internet: _.filter( results.mediaAttributes, { mediaType: 'INTERNET' } ),
          tv: _.filter( results.mediaAttributes, { mediaType: 'TV' } ),
          outdoor: _.filter( results.mediaAttributes, { mediaType: 'OUTDOOR' } ),
        },
      } );
    } );
  };

  getWorkGroupDetail = ( cb ) => {
    SessionInfoService.getWorkgroupDetail(
      this.activeSession.id, { workgroup: this.license.workGroup.id },
    )
      .then( ( response ) => {
        if ( !response.ok ) return cb( true );
        cb( null, response.data );
      } );
  };

  getRounds = ( cb ) => {
    if ( !this.activeSession.currentRound ) return cb( <IntlMessages id="noActiveRound" /> );

    RoundService.getRoundsBySession( this.activeSession.id, {
      limit: -1,
      'filters[date]': moment( Number( this.activeSession.currentRound.endDate ) )
        .format( 'YYYY-MM-DD' ),
    } )
      .then( ( response ) => {
        if ( !response.ok ) return cb( true );
        cb( null, response.data.data );
      } );
  };

  getGroups = ( filters, cb ) => {
    const params = {
      limit: -1,
      workgroup: this.license.workGroup.id,
    };
    if ( filters.round ) {
      params['filters[round]'] = filters.round;
    }
    if ( filters.criteria ) {
      params['filters[criteria]'] = filters.criteria;
    }
    SessionInfoService.getWorkgroups( this.activeSession.id, params )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        cb( null, response.data.data );
      } );
  };

  getMediaAttributes = ( cb ) => {
    AttributeMediaService.getAttributes( {
      'filters[session]': this.activeSession.id,
      limit: -1,
    } )
      .then( response => cb( response.errors, response.data ? response.data.data : [] ) );
  };

  getCostVariables = ( cb ) => {
    GlobalVariableService.getVariables( { 'filters[typeVariable]': 'Cost' } )
      .then( response => cb( response.errors, response.data ? response.data.data : [] ) );
  };

  filterRoundGroups = ( data ) => {
    const { toggleAlert, toggleLoading } = this.props;
    toggleLoading( true );

    this.getGroups( data, ( error, roundGroups ) => {
      toggleLoading( false );
      if ( error ) return toggleAlert( error );

      this.setState( { roundGroups } );
    } );
  };

  closeConfirmChangeNameModal = () => {
    this.setState( { confirmChangeNameModal: false } );
  };

  openConfirmChangeNameModal = ( formData ) => {
    const { workgroupInfo } = this.state;
    if ( formData.name && formData.name.trim() === workgroupInfo.name ) return;

    this.formChangeNameData = { name: formData.name.trim() };
    this.setState( { confirmChangeNameModal: true } );
  };

  submitChangeName = () => {
    const { toggleAlert, toggleLoading } = this.props;
    const { workgroupInfo } = this.state;

    toggleLoading( true );
    WorkGroupService.updateWorkGroup(
      this.activeSession.id, workgroupInfo.id, { name: this.formChangeNameData.name },
    )
      .then( ( response ) => {
        if ( !response.ok ) {
          toggleLoading( false );
          return toggleAlert( response.errors );
        }

        this.closeConfirmChangeNameModal();
        this.setState( {
          workgroupInfo: {
            ...workgroupInfo,
            name: this.formChangeNameData.name,
          },
        } );
        toggleAlert( 'dataSaved', 'info' );

        if ( !this.groupFormInstance || !this.groupFormInstance.getFieldState( 'round' ) ) {
          return toggleLoading( false );
        }

        this.getGroups( {
          round: this.groupFormInstance.getFieldState( 'round' ).value,
          criteria: this.groupFormInstance.getFieldState( 'criteria' ).value,
        },
        ( error, roundGroups ) => {
          toggleLoading( false );
          if ( error ) return toggleAlert( error );

          this.setState( { roundGroups } );
        } );
      } );
  };

  closeConfirmChangeGroupModal = () => {
    this.setState( {
      selectedWorkgroupId: null,
      confirmChangeGroupModal: false,
    } );
  };

  openConfirmChangeGroupModal = () => {
    this.setState( { confirmChangeGroupModal: true } );
  };

  submitChangeGroup = () => {
    const { toggleAlert, toggleLoading } = this.props;
    const { workgroupInfo, selectedWorkgroupId } = this.state;

    toggleLoading( true );
    SessionInfoService.changeUserWorkgroup(
      this.activeSession.id, {
        workgroup: workgroupInfo.id,
        newWorkgroup: selectedWorkgroupId,
      },
    )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) return toggleAlert( response.errors );
        this.closeConfirmChangeGroupModal();
        toggleAlert( 'dataSaved', 'info' );
      } );
  };

  getCostReport = () => {
    const { toggleAlert, toggleLoading } = this.props;

    toggleLoading( true );

    ReportService.getReport( this.activeSession.id ).then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) return toggleAlert( response.errors );
      window.location.assign( response.data.url );
    } );
  };

  render() {
    const {
      workgroupInfo, rounds, roundGroups, groupForm, selectedWorkgroupId,
      mediaAttributes, costVariables, confirmChangeNameModal, confirmChangeGroupModal,
    } = this.state;

    return (
      <>
        <ContainerHeader
          translateTitle={false}
          title={(
            <>
              <span>{_.get( workgroupInfo, 'entity.name' )}</span>
              {workgroupInfo.professors && workgroupInfo.professors.length ? ', ' : null}
              <span>
                {_.map( workgroupInfo.professors, item => `Prof ${item.name || ''} ${item.lastName || ''}` )
                  .join( ', ' )}
              </span>
              {_.get( workgroupInfo, 'session.name' ) ? (
                <span>
                  {', '}
                  {workgroupInfo.session.name}
                </span>
              ) : null}
              {_.get( workgroupInfo, 'round.name' ) ? (
                <span>
                  {', '}
                  {workgroupInfo.round.name}
                  {' ('}
                  {moment( Number( workgroupInfo.round.startDate ) )
                    .format( 'MMMM YYYY' )}
                  )
                </span>
              ) : null}
            </>
          )}
        />

        <Form
          initialValues={{ name: workgroupInfo.name }}
          onSubmit={this.openConfirmChangeNameModal}
          render={( { handleSubmit } ) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12">
                  <div className="jr-card px-4 py-3 mb-0">
                    <div className="jr-card-header mb-3">
                      <div className="d-flex justify-content-between">
                        <h3 className="card-heading">
                          <IntlMessages id="group" />
                        </h3>
                        <Button
                          variant="contained"
                          className="jr-btn jr-btn-lg bg-blue-grey text-white"
                          type="button"
                          onClick={this.getCostReport}
                        >
                          <IntlMessages id="costsReport" />
                        </Button>
                      </div>
                    </div>
                    <div className="jr-card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <TextField
                            field="name"
                            label="name"
                            validate={required}
                            marginContainer={false}
                          />
                        </div>

                        <div className="col-md-6">
                          <strong><IntlMessages id="students" /></strong>
                          {': '}
                          {_.map( workgroupInfo.students, item => `${item.name || ''} ${item.lastName || ''}` )
                            .join( ', ' )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        />

        <div className="row mt-3">
          <div className="col-12">
            <ExpansionPanel defaultExpanded className="mb-3 expansion-panel">
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography><IntlMessages id="groups" /></Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className="p-0 d-block">
                <Form
                  initialValues={groupForm}
                  onSubmit={this.filterRoundGroups}
                  render={( { handleSubmit, form } ) => {
                    this.groupFormInstance = form;
                    return (
                      <form onSubmit={handleSubmit} className="row mx-0 my-1">
                        <div className="col-sm-3">
                          <Select
                            placeholder="round"
                            field="round"
                            options={rounds}
                            isClearable={false}
                            marginContainer={false}
                            translateOptions={false}
                            onChange={handleSubmit}
                          />
                        </div>

                        <div className="col-sm-3">
                          <TextField
                            placeholder="search"
                            field="criteria"
                            marginContainer={false}
                            onKeyPress={( e ) => {
                              if ( e.key === 'Enter' ) handleSubmit();
                            }}
                          />
                        </div>
                      </form>
                    );
                  }}
                />

                <div className="col-12 p-0">
                  {roundGroups.length === 0
                    ? (
                      <div
                        className="text-muted h-100 d-flex align-items-center justify-content-center p-3"
                      >
                        <IntlMessages id="noGroupFound" />
                      </div>
                    )
                    : (
                      <Table
                        data={roundGroups}
                        pageSize={roundGroups.length}
                        rowsText=""
                        showPaginationTop={false}
                        columns={[
                          {
                            Header: <IntlMessages id="name" />,
                            accessor: 'workgroup.name',
                          },
                          {
                            Header: <IntlMessages id="position" />,
                            accessor: 'position',
                            Cell: ( { value } ) => Util.formatNumber( value ),
                          },
                          {
                            Header: <IntlMessages id="points" />,
                            accessor: 'points',
                            Cell: ( { value } ) => Util.formatNumber( value ),
                          },
                          {
                            Header: <IntlMessages id="accumulated" />,
                            accessor: 'accumulated',
                            Cell: ( { value } ) => Util.formatNumber( value ),
                          },
                          {
                            Header: <IntlMessages id="members" />,
                            accessor: 'members',
                            Cell: ( { value } ) => ( _.map( value, item => `${item.name || ''} ${item.lastName || ''}` )
                              .join( ', ' ) ),
                          },
                        ]}
                        getTrProps={( state, rowInfo ) => ( {
                          className: selectedWorkgroupId === rowInfo.original.workgroup.id ? 'font-weight-bold pointer' : 'pointer',
                          onClick: () => this.setState(
                            { selectedWorkgroupId: rowInfo.original.workgroup.id },
                          ),
                        } )}
                      />
                    )}
                </div>

                {roundGroups.length
                  ? (
                    <div className="col-12 text-right my-2">
                      <Button
                        variant="contained"
                        color="primary"
                        className="jr-btn jr-btn-lg"
                        type="button"
                        onClick={this.openConfirmChangeGroupModal}
                      >
                        <IntlMessages id="changeGroup" />
                      </Button>
                    </div>
                  )
                  : null}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        </div>

        <div className="row mt-3">
          <CardBox styleName="col-md-6" heading="radio">
            <Table
              data={mediaAttributes.radio || []}
              pageSize={mediaAttributes.radio ? mediaAttributes.radio.length : 0}
              showPaginationTop={false}
              columns={[
                {
                  Header: <IntlMessages id="label" />,
                  accessor: 'label',
                },
                {
                  Header: <IntlMessages id="channel" />,
                  accessor: 'mediaChannel',
                },
                {
                  Header: <IntlMessages id="description" />,
                  accessor: 'description',
                },
              ]}
            />
          </CardBox>

          <CardBox styleName="col-md-6" heading="tv">
            <Table
              data={mediaAttributes.tv || []}
              pageSize={mediaAttributes.tv ? mediaAttributes.tv.length : 0}
              showPaginationTop={false}
              columns={[
                {
                  Header: <IntlMessages id="label" />,
                  accessor: 'label',
                },
                {
                  Header: <IntlMessages id="channel" />,
                  accessor: 'mediaChannel',
                },
                {
                  Header: <IntlMessages id="description" />,
                  accessor: 'description',
                },
              ]}
            />
          </CardBox>
        </div>

        <div className="row mt-3">
          <CardBox styleName="col-md-6" heading="internet">
            <Table
              data={mediaAttributes.internet || []}
              pageSize={mediaAttributes.internet ? mediaAttributes.internet.length : 0}
              showPaginationTop={false}
              columns={[
                {
                  Header: <IntlMessages id="label" />,
                  accessor: 'label',
                },
                {
                  Header: <IntlMessages id="channel" />,
                  accessor: 'mediaChannel',
                },
                {
                  Header: <IntlMessages id="description" />,
                  accessor: 'description',
                },
              ]}
            />
          </CardBox>

          <CardBox styleName="col-md-6" heading="outdoor">
            <Table
              data={mediaAttributes.outdoor || []}
              pageSize={mediaAttributes.outdoor ? mediaAttributes.outdoor.length : 0}
              showPaginationTop={false}
              columns={[
                {
                  Header: <IntlMessages id="label" />,
                  accessor: 'label',
                },
                {
                  Header: <IntlMessages id="channel" />,
                  accessor: 'mediaChannel',
                },
                {
                  Header: <IntlMessages id="description" />,
                  accessor: 'description',
                },
              ]}
            />
          </CardBox>
        </div>

        <div className="row mt-3">
          <CardBox styleName="col-md-4" heading="variables">
            <Table
              data={costVariables}
              pageSize={costVariables.length}
              showPaginationTop={false}
              columns={[
                {
                  Header: <IntlMessages id="variable" />,
                  accessor: 'name',
                },
                {
                  Header: <IntlMessages id="value" />,
                  accessor: 'value',
                  maxWidth: 100,
                  Cell: ( { value } ) => Util.formatNumber( value ),
                },
              ]}
            />
          </CardBox>

          <CardBox styleName="col-md-8" heading="segments">
            <Table
              data={[
                {
                  segment: 'Students',
                  description: 'Receptive to prices, not relevant the design the design: reliability is revelant',
                },
                {
                  segment: 'Professionals',
                  description: '',
                },
              ]}
              sortable={false}
              pageSize={2}
              showPaginationTop={false}
              columns={[
                {
                  Header: <IntlMessages id="segment" />,
                  accessor: 'segment',
                },
                {
                  Header: <IntlMessages id="description" />,
                  accessor: 'description',
                },
              ]}
            />
          </CardBox>
        </div>

        <ConfirmationModal
          open={confirmChangeNameModal}
          title="confirmChangeGroupName"
          confirmBtnText="yesModalBtn"
          onSubmit={this.submitChangeName}
          onClose={this.closeConfirmChangeNameModal}
        />

        <ConfirmationModal
          open={confirmChangeGroupModal}
          title="confirmChangeWorkgroup"
          confirmBtnText="yesModalBtn"
          onSubmit={this.submitChangeGroup}
          onClose={this.closeConfirmChangeGroupModal}
        />
      </>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( SessionInfo );
