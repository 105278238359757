import React from 'react';
import { connect } from 'react-redux';

import UserService from 'services/User';
import SettingsActions from 'store/reducers/Settings';
import picture from 'assets/images/profile/empty-picture.png';
import ChangePassword from './ChangePassword';
import AccountProfile from './AccountProfile';
import PictureProfile from '../PictureProfile';

class ProfileSettings extends React.Component {
  state = {
    readOnly: true,
    isOpen: false,
    uploadAvatar: null,
    data: {},
  };

  static getDerivedStateFromProps( props ) {
    return {
      data: {
        name: props.user.name,
        lastName: props.user.lastName,
        avatar: props.user.avatar,
        email: props.user.email,
      },
    };
  }

  handleEdit = () => {
    this.setState( previousState => ( { readOnly: !previousState.readOnly } ) );
  };

  handleClose = () => this.setState( { isOpen: false } );

  handleAvatarEditor = () => this.setState( { isOpen: true } );

  handleEditAvatar = ( src ) => {
    const { toggleLoading, toggleAlert } = this.props;

    toggleLoading( true );

    UserService.updateUserProfile( { uploadAvatar: src } ).then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) return toggleAlert( response.errors );
      this.setState( { isOpen: false, uploadAvatar: src } );
      toggleAlert( 'profileUpdated', 'info' );
    } );
  };

  render() {
    const { isOpen, uploadAvatar, data, readOnly } = this.state;
    return (
      <div>
        {data && data.name && (
          <div className="container mt-5">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <PictureProfile
                  src={data.avatar || picture}
                  newSrc={uploadAvatar}
                  isOpen={isOpen}
                  editAvatar={this.handleEditAvatar}
                  avatarEditor={this.handleAvatarEditor}
                  onClose={this.handleClose}
                />
                <div className="mt-3 mb-3 module-user-info d-flex align-items-center justify-content-center">
                  {data.name && data.lastName && (
                    <div className="module-title ">
                      {`${data.name} ${data.lastName}`}
                    </div>
                  )}
                  {data.email && (
                    <div className="module-user-detail">{data.email}</div>
                  )}
                </div>
              </div>

              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                <AccountProfile
                  readOnly={readOnly}
                  onEdit={this.handleEdit}
                  user={data}
                />
                <div className="mt-5">
                  <ChangePassword />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( null, mapDispatchToProps )( ProfileSettings );
