import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Button } from '@material-ui/core';
import _ from 'lodash';
import async from 'async';

import CardBox from 'components/theme/CardBox';
import Table from 'components/custom/Table/AsyncTable';
import NewEntityBtn from 'components/custom/NewEntityBtn';
import { TextField } from 'components/custom/FormElements';
import SettingsActions from 'store/reducers/Settings';
import SessionService from 'services/Session';
import SearchParams from 'utils/SearchParams';
import IntlMessages from 'utils/IntlMessages';
import FixedSidebar from 'components/custom/FixedSidebar';
import FilterForm from 'components/custom/FilterForm';
import GlobalVariableService from 'services/GlobalVariable';
import VersionVariableService from 'services/VersionVariable';
import Util from 'utils/Util';
import SessionEditForm from '../../../../../../components/Admin/University/SessionEditForm';

class Dashboard extends React.Component {
  state = {
    data: [],
    editModal: false,
  };

  search = {};
  lists = [];

  componentWillMount() {
    const { entityId } = this.props;
    this.lists = [
      {
        headerId: 1,
        heading: 'sessions',
        items: [
          {
            id: 2,
            icon: 'format-indent-increase',
            name: 'list',
            color: 'primary',
            url: `/universities/detail/${entityId}/sessions`,
          },
        ],
      },
    ];
  }

  getData = ( params ) => {
    const { entityId, toggleLoading } = this.props;
    toggleLoading( true );

    this.params = _.cloneDeep( params );

    if ( this.search.endDate ) {
      this.search.endDate = moment( this.search.endDate )
        .format( 'YYYY-MM-DD' );
    }
    if ( this.search.initDate ) {
      this.search.initDate = moment( this.search.initDate )
        .format( 'YYYY-MM-DD' );
    }
    const formattedParams = SearchParams.getSearchParams( this.search, params );
    SessionService.getSessions( entityId, formattedParams )
      .then( ( response ) => {
        toggleLoading( false );

        if ( response.ok ) {
          this.setState( {
            data: response.data.data || [],
            pages: Math.ceil( response.data.total / params.limit ),
            total: response.data.total,
          } );
        }
      } );
  };

  submitForm = ( data ) => {
    this.search = data;
    this.getData( this.params );
  };

  getEntity = ( id ) => {
    const { toggleLoading } = this.props;
    toggleLoading( true );

    SessionService.getSession( id )
      .then( ( response ) => {
        toggleLoading( false );

        if ( response.ok ) {
          this.entityToEdit = response.data;
          this.toggleEditModal();
        }
      } );
  };

  getVariables = () => {
    const { toggleLoading } = this.props;
    toggleLoading( true );

    async.waterfall( [
      ( cb ) => {
        VersionVariableService.getLastVersion()
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
      },
      ( version, cb ) => {
        GlobalVariableService.getVariables( {
          'filters[version]': version.id,
          limit: -1,
        } )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
      },
    ], ( error, results ) => {
      toggleLoading( false );
      if ( !error ) {
        const numberGroup = _.find( results.data, { label: 'GROUP_SESSION' } );
        const maxUserGroup = _.find( results.data, { label: 'USER_SESSION' } );
        const maxProductPerGroup = _.find( results.data, { label: 'PRODUCT_GROUP' } );
        this.entityToEdit = {
          numberGroup: numberGroup ? numberGroup.value : null,
          maxUserGroup: maxUserGroup ? maxUserGroup.value : null,
          maxProductPerGroup: maxProductPerGroup ? maxProductPerGroup.value : null,
          active: true,
        };
        this.toggleEditModal();
      }
    } );
  };

  toggleEditModal = ( cb ) => {
    this.setState( previousState => ( { editModal: !previousState.editModal } ), cb );
  };

  render() {
    const { editModal, ...rest } = this.state;
    const { entityId, match } = this.props;

    return (
      <div className="d-flex flex-wrap">
        <FixedSidebar
          items={this.lists}
          cardClasses="col-lg-3 col-md-6 col-12 pl-0"
        />

        <div className="col-12 col-md-6 col-lg-9 mt-3">
          <CardBox cardStyle="p-0">
            <FilterForm
              newBtn={(
                <NewEntityBtn
                  label="newSession"
                  onClick={this.getVariables}
                />
              )}
              onSubmit={this.submitForm}
              onClear={() => {
                this.search = {};
                this.getData( this.params );
              }}
            >
              <div className="col-lg-3">
                <TextField
                  field="name"
                  label="name"
                />
              </div>
            </FilterForm>

            <Table
              onFetchData={this.getData}
              {...rest}
              columns={[
                {
                  Header: <IntlMessages id="name" />,
                  accessor: 'name',
                },
                {
                  Header: <IntlMessages id="numberGroup" />,
                  accessor: 'numberGroup',
                },
                {
                  Header: <IntlMessages id="licensesTotal" />,
                  accessor: 'licensesTotal',
                  Cell: ( { value } ) => Util.formatNumber( value ),
                },
                {
                  Header: <IntlMessages id="licensesAvailable" />,
                  accessor: 'licensesAvailable',
                  Cell: ( { value } ) => Util.formatNumber( value ),
                },
                {
                  Header: <IntlMessages id="maxProductPerGroup" />,
                  accessor: 'maxProductPerGroup',
                  Cell: ( { value } ) => Util.formatNumber( value ),
                },
                {
                  Header: <IntlMessages id="active" />,
                  accessor: 'active',
                  maxWidth: 120,
                  Cell: props => ( props.value ? <i className="fa fa-check" />
                    : <i className="fa fa-times" /> ),
                },
                {
                  Header: '',
                  accessor: 'id',
                  maxWidth: 120,
                  Cell: props => (
                    <div>
                      <NewEntityBtn
                        isEdit
                        onClick={() => this.getEntity( props.value )}
                      />
                      <Link
                        to={`${match.url}/${props.value}/professor-licenses`}
                        className="text-decoration-none"
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          className="jr-btn"
                        >
                          <i className="fa fa-eye" />
                        </Button>
                      </Link>
                    </div>
                  ),
                },
              ]}
            />
          </CardBox>

          <SessionEditForm
            open={editModal}
            onClose={() => this.toggleEditModal()}
            onConfirm={() => {
              this.toggleEditModal();
              this.getData( this.params );
            }}
            entityId={entityId}
            data={this.entityToEdit}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps,
)( Dashboard );
