import React from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';

import { Button } from '@material-ui/core';
import moment from 'moment';
// import _ from 'lodash';
import async from 'async';

import CardBox from 'components/theme/CardBox';
import { TimePicker, RadioButtons } from 'components/custom/FormElements';
import ConfirmationModal from 'components/custom/ConfirmationModal';

import IntlMessages from 'utils/IntlMessages';
import { required } from 'config/InputErrors';
import SettingsActions from 'store/reducers/Settings';
import Table from 'components/custom/Table/Custom';
import RoundService from 'services/Round';
import SessionService from 'services/Session';
// import EntityService from 'services/Entity';
import GlobalVariableService from 'services/GlobalVariable';
import VersionVariableService from 'services/VersionVariable';
// import ContainerHeader from 'components/theme/ContainerHeader';

class Info extends React.Component {
  activeClosingTime=false;
  activeClosingTypeSection=false;
  activeManualCloseSection=false;
  state = {
    data: [],
    nuRounds: 0,
    openSessionDialog: false,
    openMultiDialog: false,
    openSingleDialog: false,
    dropdownOpen: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { toggleLoading, sessionId } = this.props;
    toggleLoading( true );
    RoundService.getRoundsBySession( sessionId, { limit: -1 } )
      .then( ( response ) => {
        toggleLoading( false );
        if ( response.ok ) this.setState( { data: response.data.data } );
      } );

    async.waterfall( [
      ( cb ) => {
        VersionVariableService.getLastVersion()
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
      },
      ( version, cb ) => {
        GlobalVariableService.getVariables( {
          'filters[version]': version.id,
          'filters[label]': 'CLOSE_MULTIPLE_ROUNDS',
          limit: -1,
        } )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
      },
    ], ( error, results ) => {
      if ( !error ) {
        this.setState( { nuRounds: results.data[0].value } );
      }
    } );
  }

  // eslint-disable-next-line no-unused-vars
  submitForm = ( formData, form ) => {
    const { toggleLoading, toggleAlert, sessionId } = this.props;
    const dataToSend = {};

    if ( formData.closingType === 'CLOSING_TIME' ) {
      toggleLoading( true );
      dataToSend.endTime = moment( Number( formData.closingTime ) ).format( 'HH:mm' );
      // dataToSend.manualClosing = false;
      RoundService.changeClosingTime( sessionId, dataToSend )
        .then( ( response ) => {
          toggleLoading( false );
          if ( !response.ok ) {
            toggleAlert( response.errors );
          } else {
            toggleAlert( 'dataSaved', 'info' );
            this.getData();
          }
        } );
    }

    if ( formData.closingType === 'MANUAL_CLOSING' ) {
      toggleLoading( true );
      dataToSend.manualClosing = true;
      RoundService.changeManualClosing( sessionId, dataToSend )
        .then( ( response ) => {
          toggleLoading( false );
          if ( !response.ok ) {
            toggleAlert( response.errors );
          } else {
            toggleAlert( 'dataSaved', 'info' );
            this.getData();
          }
        } );
    }
  };

  closeRound = ( ) => {
    this.setState( previousState => ( { openSingleDialog: !previousState.openSingleDialog } ) );
    const { toggleLoading, toggleAlert, sessionId } = this.props;
    toggleLoading( true );
    RoundService.closeRound( sessionId )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) {
          toggleAlert( response.errors );
        } else {
          toggleAlert( 'roundClosed', 'info' );
          this.getData();
        }
      } );
  }

  closeSession = ( ) => {
    this.setState( previousState => ( { openSessionDialog: !previousState.openSessionDialog } ) );
    const { toggleLoading, toggleAlert, sessionId } = this.props;
    toggleLoading( true );
    SessionService.closeSession( sessionId )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) {
          toggleAlert( response.errors );
        } else {
          toggleAlert( 'sessionClosed', 'info' );
          this.getData();
        }
      } );
  }

  closeMultipleRound = ( ) => {
    this.setState( previousState => ( { openMultiDialog: !previousState.openMultiDialog } ) );
    const { toggleLoading, toggleAlert, sessionId } = this.props;
    toggleLoading( true );
    RoundService.closeMultipleRound( sessionId )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) {
          toggleAlert( response.errors );
        } else {
          toggleAlert( 'roundsClosed', 'info' );
          this.getData();
        }
      } );
  }

  handleClickOpenSessionDialog = () => {
    this.setState( previousState => ( { openSessionDialog: !previousState.openSessionDialog } ) );
  }

  handleCloseSessionDialog= () => {
    this.setState( previousState => ( { openSessionDialog: !previousState.openSessionDialog } ) );
  }

  handleClickOpenMultiDialog = () => {
    this.setState( previousState => ( { openMultiDialog: !previousState.openMultiDialog } ) );
  }

  handleCloseMultiDialog= () => {
    this.setState( previousState => ( { openMultiDialog: !previousState.openMultiDialog } ) );
  }

  handleClickOpenSingleDialog = () => {
    this.setState( previousState => ( { openSingleDialog: !previousState.openSingleDialog } ) );
  }

  handleCloseSingleDialog= () => {
    this.setState( previousState => ( { openSingleDialog: !previousState.openSingleDialog } ) );
  }

  toggleDrop= () => {
    this.setState( previousState => ( { dropdownOpen: !previousState.dropdownOpen } ) );
  }

  render() {
    const {
      data,
      nuRounds,
      openSessionDialog,
      openSingleDialog,
      openMultiDialog,
    } = this.state;

    return (
      <Form
        onSubmit={this.submitForm}
        initialValues={{
          rounds: 1,
          typeDuration: 'days',
        }}
        validate={( values ) => {
          const errors = {};
          if ( ( values.closingType === 'CLOSING_TIME' ) && ( !values.closingTime ) ) {
            errors.closingTime = required( values.closingTime );
          }

          return errors;
        }}
        render={( { handleSubmit, form } ) => (
          <form onSubmit={handleSubmit}>

            <CardBox styleName="col-12 p-0">
              <div className="row">
                <div className="col-md-6">
                  <b><IntlMessages id="labelSectionRoundClose" /></b>
                </div>
                <div className="col-md-3" />
                <div className="col-md-3 d-flex align-items-end">
                  <Button
                    variant="contained"
                    color="primary"
                    className="jr-btn jr-btn-lg"
                    onClick={this.handleClickOpenSessionDialog}
                  >
                    <i className="fa fa-times-circle" />
                    <IntlMessages id="closeSession" />
                  </Button>
                </div>
              </div>

              <div className="row">
                <div className="col-md-7">
                  <Button
                    variant="contained"
                    color="primary"
                    className="jr-btn jr-btn-lg"
                    onClick={this.handleClickOpenSingleDialog}
                  >
                    <i className="fa fa-times-circle" />
                    <IntlMessages id="yesCloseCurrentRound" />
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    className="jr-btn jr-btn-lg"
                    onClick={this.handleClickOpenMultiDialog}
                  >
                    <i className="fa fa-times-circle" />
                    <IntlMessages id="yesCloseNRound" values={{ count: nuRounds }} />
                  </Button>
                </div>
                <div className="col-md-3" />
              </div>

              <div className="row mt-3">
                <div className="col-md-3 d-flex align-items-end" />
              </div>

              <div className="row mt-3">
                <div className="col-md-3 d-flex align-items-end">
                  <b><IntlMessages id="labelSectionRoundClosingType" /></b>
                </div>
                <div className="col-md-4 d-flex align-items-end" />

              </div>

              <div className="row jr-border-radius">

                <div className="col-md-2">

                  <RadioButtons
                    field="closingType"
                    valueKey="name"
                    options={[
                      {
                        id: 'CLOSING_TIME',
                        name: 'CLOSING_TIME',
                      },
                      {
                        id: 'MANUAL_CLOSING',
                        name: 'MANUAL_CLOSING',
                      },
                    ]}
                    onChange={( value ) => {
                      form.change( 'closeRound', null );
                      if ( value === 'MANUAL_CLOSING' ) {
                        form.change( 'closingTime', null );
                        this.activeClosingTime = false;
                      }
                      if ( value === 'CLOSING_TIME' ) {
                        this.activeClosingTime = true;
                      }
                    }}
                    displayBlock
                  />
                </div>
                <div className="col-md-2">

                  <TimePicker
                    label="closingTime"
                    field="closingTime"
                    disabled={!this.activeClosingTime}
                    marginContainer={false}
                  />
                </div>

                <div className="col-md-3">
                  <Button
                    variant="contained"
                    color="primary"
                    className="jr-btn jr-btn-lg"
                    type="submit"
                  >
                    <i className="fa fa-save" />
                    <IntlMessages id="alterRounds" />
                  </Button>
                </div>

              </div>

            </CardBox>

            <div className="row mt-3">
              <CardBox styleName="col-12" heading="currentRounds">
                {data.length
                  ? (
                    <Table
                      data={data}
                      columns={[
                        {
                          Header: <IntlMessages id="round" />,
                          accessor: 'name',
                          Cell: ( { value, original } ) => `${value} ${original.startDateInMonth || ''}`,
                        },
                        {
                          Header: <IntlMessages id="start" />,
                          accessor: 'startDate',
                          Cell: props => moment.utc( Number( props.value ) )
                            .format( 'DD/MM/YYYY HH:mm' ),
                        },
                        {
                          Header: <IntlMessages id="end" />,
                          accessor: 'endDate',
                          Cell: ( { value, original } ) => (
                            original.manualClosing === true ? '---'
                              : moment.utc( Number( value ) ).format( 'DD/MM/YYYY HH:mm' )
                          ),
                        },
                        {
                          Header: <IntlMessages id="status" />,
                          accessor: 'status',
                        },
                        {
                          Header: <IntlMessages id="roundReport" />,
                          accessor: 'roundReport',
                          Cell: ( { value } ) => ( value
                            ? (
                              <a
                                rel="noopener noreferrer"
                                className="text-blue"
                                target="_blank"
                                href={value}
                              >
                                <IntlMessages id="report" />
                              </a>
                            ) : '' ),
                        },
                      ]}
                    />
                  )
                  : (
                    <div className="p-5 text-center text-muted">
                      <h3 className="m-0">No rounds</h3>
                    </div>
                  )
                }
              </CardBox>
            </div>
            <ConfirmationModal
              open={openSingleDialog}
              title="confirmCloseRound"
              confirmBtnText="button.yes"
              onSubmit={this.closeRound}
              onClose={this.handleCloseSingleDialog}
            />

            <ConfirmationModal
              open={openMultiDialog}
              title="confirmCloseRound"
              confirmBtnText={<IntlMessages id="button.yes" />}
              onSubmit={this.closeMultipleRound}
              onClose={this.handleCloseMultiDialog}
            />

            <ConfirmationModal
              open={openSessionDialog}
              title="confirmCloseSession"
              confirmBtnText={<IntlMessages id="button.yes" />}
              onSubmit={this.closeSession}
              onClose={this.handleCloseSessionDialog}
            />

          </form>
        )}
      />

    );
  }
}


const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( Info );
