import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Error404 from 'components/theme/Error404';
import Dashboard from './Dashboard';
import Session from './Session';
import Tutorial from './Tutorial';
import GroupSettings from '../../GroupSettings';


const App = ( { match, user } ) => (
  <Switch>
    <Route
      path={`${match.url}dashboard`}
      component={Dashboard}
    />
    <Route
      path={`${match.url}tutorial`}
      component={Tutorial}
    />
    <Route
      path={`${match.url}sessions`}
      component={Session}
    />
    <Route
      path={`${match.url}group-settings`}
      render={props => <GroupSettings {...props} user={user} />}
    />
    <Route
      path={`${match.url}`}
      component={Dashboard}
    />
    <Route component={Error404} />
  </Switch>
);

export default App;
