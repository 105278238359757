import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Error404 from 'components/theme/Error404';
import Dashboard from './Dashboard';
import Session from './Session';
import NewSession from './NewSession';

const App = ( { match } ) => (
  <Switch>
    <Route
      path={`${match.url}dashboard`}
      component={Dashboard}
    />
    <Route
      path={`${match.url}sessions/add`}
      component={NewSession}
    />
    <Route
      path={`${match.url}sessions/:id`}
      component={Session}
    />
    <Route
      path={`${match.url}`}
      component={Dashboard}
    />

    <Route component={Error404} />
  </Switch>
);

export default App;
