import React from 'react';
import _ from 'lodash';
import async from 'async';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form } from 'react-final-form';
import {
  Bar,
  BarChart,
  CartesianGrid, Cell,
  Legend, Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import CardBox from 'components/theme/CardBox';
import StatsPanel from 'components/theme/StatsPanel';
import IntlMessages from 'utils/IntlMessages';
import Table from 'components/custom/Table/Custom';
import DashboardStudentService from 'services/DashboardStudent';
import Util from 'utils/Util';
import SettingsActions from 'store/reducers/Settings';
import AxisTick from 'components/custom/Charts/AxisTick';
import CommodityService from 'services/Commodity';
import { Select } from 'components/custom/FormElements';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8d8c82'];

class Dashboard extends React.Component {
  state = {
    generalData: {},
    rankingRounds: [],
    rankingChart: {},
    myProducts: [],
    myServices: [],
    myBundles: [],
    unitsSalesMargin: [],
    unitsForm: { unitsType: 'TYPE_PRODUCT' },
  };

  componentDidMount() {
    const { toggleAlert, user } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.activeSession = user.activeSession;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }

    this.getData();
  }

  componentDidUpdate( prevProps ) {
    if ( _.get( prevProps, 'user.activeSession.id' ) !== _.get( this.props, 'user.activeSession.id' ) ) {
      const { toggleAlert, user } = this.props;

      if ( !user.activeSession ) {
        return toggleAlert( 'ERROR' );
      }

      this.activeSession = user.activeSession;
      this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
      if ( !this.license || !this.license.workGroup ) {
        return toggleAlert( 'ERROR' );
      }

      this.getData();
    }
  }

  getData = () => {
    const { toggleAlert, toggleLoading } = this.props;
    toggleLoading( true );

    async.parallel( {
      generalData: this.getGeneralData,
      rankingChart: this.getRankingChart,
      rankingRounds: this.getRankingRound,
      myProducts: this.getMyProducts,
      myServices: this.getMyServices,
      myBundles: this.getMyBundles,
      unitsSalesMargin: cb => this.getUnitsSalesMargin( {}, cb ),
    }, ( error, results ) => {
      toggleLoading( false );
      if ( error ) return toggleAlert( error );

      const chartNames = _.map( results.rankingChart, 'name' );
      const chartData = [];
      _.map( results.rankingRounds, ( round ) => {
        const formattedData = {
          id: round.round.id,
          name: round.round.name,
        };
        _.map( results.rankingChart, ( chart ) => {
          const roundChart = _.find( chart.workgroupRound, { round: { id: round.round.id } } );
          if ( roundChart ) formattedData[chart.name] = roundChart.ranking;
        } );
        chartData.push( formattedData );
      } );

      this.setState( { ...results, rankingChart: { chartNames, chartData } } );
    } );
  };

  getGeneralData = ( cb ) => {
    const params = { workgroup: this.license.workGroup.id };
    DashboardStudentService.getGeneralData( this.activeSession.id, params )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        cb( null, response.data );
      } );
  };

  getRankingRound = ( cb ) => {
    const params = { workgroup: this.license.workGroup.id, limit: -1 };
    DashboardStudentService.getRankingRounds( this.activeSession.id, params )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        cb( null, response.data.data );
      } );
  };

  getRankingChart = ( cb ) => {
    const params = { workgroup: this.license.workGroup.id, limit: -1 };
    DashboardStudentService.getRankingChart( this.activeSession.id, params )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        cb( null, response.data.data );
      } );
  };

  getUnitsSalesMargin = ( values, cb ) => {
    const { toggleAlert, toggleLoading } = this.props;

    const params = { workgroup: this.license.workGroup.id, limit: -1 };
    if ( values.unitsRound ) {
      params.round = values.unitsRound;
    }
    if ( !cb ) toggleLoading( true );
    CommodityService.getUnitSalesMargin( this.activeSession.id, params )
      .then( ( response ) => {
        if ( cb ) {
          if ( !response.ok ) return cb( response.errors );
          cb( null, response.data );
        } else {
          toggleLoading( false );
          if ( !response.ok ) return toggleAlert( response.errors );
          this.setState( { unitsSalesMargin: response.data } );
        }
      } );
  };

  getMyProducts = ( cb ) => {
    const params = { workgroup: this.license.workGroup.id, limit: -1 };
    DashboardStudentService.getMyProducts( this.activeSession.id, params )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        cb( null, response.data.data );
      } );
  };

  getMyServices = ( cb ) => {
    const params = { workgroup: this.license.workGroup.id, limit: -1 };
    DashboardStudentService.getMyServices( this.activeSession.id, params )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        cb( null, response.data.data );
      } );
  };

  getMyBundles = ( cb ) => {
    const params = { workgroup: this.license.workGroup.id, limit: -1 };
    DashboardStudentService.getMyBundles( this.activeSession.id, params )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        cb( null, response.data.data );
      } );
  };

  render() {
    const {
      generalData, rankingRounds, myProducts,
      myServices, myBundles, rankingChart, unitsForm, unitsSalesMargin,
    } = this.state;

    return (
      <div className="pt-4">
        <div className="row">
          <div className="col-lg-6">
            <div className="page-heading m-0 py-3 shadow-none border-primary border">
              <h3 className="title font-weight-normal mb-1">
                <IntlMessages id="group" />
                {': '}
                {generalData.name}
              </h3>
              <p className="m-0">
                <IntlMessages id="students" />
                {': '}
                {_.map( generalData.students, item => `${item.name || ''} ${item.lastName || ''}` )
                  .join( ', ' )}
              </p>
              <p className="m-0">
                <IntlMessages id="currentRound" />
                {': '}
                {_.get( generalData, 'round.numberRound' )}
                /
                {_.get( generalData, 'round.totalRound' )}
              </p>
              <p className="m-0">
                <IntlMessages id="currentRanking" />
                {': '}
                {generalData.position}
              </p>
            </div>
          </div>

          <div className="col-sm-2">
            <StatsPanel data={{
              icon: 'star-circle',
              title: Util.formatNumber( generalData.points ),
              subTitle: <IntlMessages id="points" />,
              color: '#3f51b5',
            }}
            />
          </div>
          <div className="col-sm-2 pl-0">
            <StatsPanel data={{
              icon: 'money',
              title: Util.formatCurrency( generalData.cash ),
              subTitle: <IntlMessages id="cash" />,
              color: '#3f51b5',
            }}
            />
          </div>
          <div className="col-sm-2 pl-0">
            <StatsPanel data={{
              icon: 'alert-polygon',
              title: (
                <span>
                  {Util.formatNumber( _.get( generalData, 'stock.stock' ) )}
                  {' ('}
                  {Util.formatCurrency( _.get( generalData, 'stock.value' ) )}
                  )
                </span>
              ),
              subTitle: <IntlMessages id="stock" />,
              color: '#3f51b5',
            }}
            />
          </div>
        </div>

        <div className="row mt-3">
          <CardBox styleName="col-12" heading="historic">
            <ResponsiveContainer width="100%" height={200}>
              <LineChart
                data={rankingChart.chartData}
                margin={{
                  left: -10,
                  right: 20,
                }}
              >
                <XAxis
                  interval={0}
                  dataKey="name"
                  height={60}
                  tick={<AxisTick />}
                />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend verticalAlign="top" />
                {_.map( rankingChart.chartNames, ( item, index ) => (
                  <Line
                    key={item}
                    type="monotone"
                    dataKey={item}
                    name={item}
                    stroke={COLORS[index % COLORS.length]}
                  />
                ) )}
              </LineChart>
            </ResponsiveContainer>
          </CardBox>
        </div>

        <div className="row mt-3">
          <CardBox styleName="col-12" heading="rounds">
            <div className="overflow-auto" style={{ maxHeight: 350 }}>
              <Table
                sortable={false}
                data={rankingRounds}
                pageSize={rankingRounds.length}
                showPaginationTop={false}
                columns={[
                  {
                    Header: <IntlMessages id="round" />,
                    accessor: 'round.name',
                    Cell: ( { value, original } ) => `${value} ${_.get( original, 'round.startDateInMonth', '' )}`,
                  },
                  {
                    Header: <IntlMessages id="ranking" />,
                    accessor: 'ranking',
                  },
                  {
                    Header: <IntlMessages id="position" />,
                    accessor: 'position',
                  },
                  {
                    Header: <IntlMessages id="points" />,
                    accessor: 'points',
                    Cell: ( { value } ) => Util.formatNumber( value ),
                  },
                  {
                    Header: <IntlMessages id="end" />,
                    accessor: 'round.endDate',
                    Cell: ( { value } ) => (
                      value ? moment( Number( value ) ).format( 'DD/MM/YYYY' ) : ''
                    ),
                  },
                  {
                    Header: <IntlMessages id="duration" />,
                    accessor: 'duration',
                    Cell: ( { value } ) => `${value || 0}d`,
                  },
                  {
                    Header: <IntlMessages id="status" />,
                    accessor: 'status',
                  },
                  {
                    Header: <IntlMessages id="roundReport" />,
                    accessor: 'roundReport',
                    Cell: ( { value } ) => ( value
                      ? (
                        <a
                          rel="noopener noreferrer"
                          className="text-blue"
                          target="_blank"
                          href={value}
                        >
                          <IntlMessages id="report" />
                        </a>
                      ) : '' ),
                  },
                ]}
              />
            </div>
          </CardBox>
        </div>

        <div className="row mt-3">
          <CardBox styleName="col-12" heading="activeProducts">
            <Table
              sortable={false}
              data={myProducts}
              pageSize={myProducts.length}
              showPaginationTop={false}
              columns={[
                {
                  Header: <IntlMessages id="category" />,
                  accessor: 'category.name',
                },
                {
                  Header: <IntlMessages id="code" />,
                  accessor: 'code',
                },
                {
                  Header: <IntlMessages id="description" />,
                  accessor: 'description',
                },
                {
                  Header: <IntlMessages id="stock" />,
                  accessor: 'stock',
                  Cell: ( { value } ) => Util.formatNumber( value ),
                },
                {
                  Header: <IntlMessages id="unitCost" />,
                  accessor: 'unitaryCost',
                  Cell: ( { value } ) => Util.formatCurrency( value ),
                },
                {
                  Header: <IntlMessages id="price" />,
                  accessor: 'price',
                  Cell: ( { value } ) => Util.formatCurrency( value ),
                },
                {
                  Header: <IntlMessages id="currentPrice" />,
                  accessor: 'currentPrice',
                  Cell: ( { value } ) => Util.formatCurrency( value ),
                },
                {
                  Header: <IntlMessages id="totalSales" />,
                  accessor: 'totalUnitSales',
                  Cell: ( { value } ) => Util.formatNumber( value ),
                },
                {
                  Header: <IntlMessages id="lastRoundSalesUnits" />,
                  accessor: 'lastRoundUnitSales',
                  Cell: ( { value } ) => Util.formatNumber( value ),
                },
                {
                  Header: <IntlMessages id="lastRoundSalesCost" />,
                  accessor: 'lastRoundSales',
                  Cell: ( { value } ) => Util.formatCurrency( value ),
                },
                {
                  Header: <IntlMessages id="marginCost" />,
                  accessor: 'margin',
                  Cell: ( { value } ) => Util.formatCurrency( value ),
                },
                {
                  Header: <IntlMessages id="salesPercent" />,
                  accessor: 'salesPercent',
                },
                {
                  Header: <IntlMessages id="marginPercent" />,
                  accessor: 'marginPercent',
                },
              ]}
            />
          </CardBox>
        </div>

        <div className="row mt-3">
          <CardBox styleName="col-12" heading="services">
            <Table
              sortable={false}
              data={myServices}
              pageSize={myServices.length}
              showPaginationTop={false}
              columns={[
                {
                  Header: <IntlMessages id="description" />,
                  accessor: 'description',
                },
                {
                  Header: <IntlMessages id="unitaryCost" />,
                  accessor: 'unitaryCost',
                  Cell: ( { value } ) => Util.formatCurrency( value ),
                },
                {
                  Header: <IntlMessages id="price" />,
                  accessor: 'price',
                  Cell: ( { value } ) => Util.formatCurrency( value ),
                },
                {
                  Header: <IntlMessages id="currentPrice" />,
                  accessor: 'currentPrice',
                  Cell: ( { value } ) => Util.formatCurrency( value ),
                },
                {
                  Header: <IntlMessages id="totalSales" />,
                  accessor: 'totalUnitSales',
                  Cell: ( { value } ) => Util.formatNumber( value ),
                },
                {
                  Header: <IntlMessages id="lastRoundSalesUnits" />,
                  accessor: 'lastRoundUnitSales',
                  Cell: ( { value } ) => Util.formatNumber( value ),
                },
                {
                  Header: <IntlMessages id="lastRoundSalesCost" />,
                  accessor: 'lastRoundSales',
                  Cell: ( { value } ) => Util.formatCurrency( value ),
                },
                {
                  Header: <IntlMessages id="marginCost" />,
                  accessor: 'margin',
                  Cell: ( { value } ) => Util.formatCurrency( value ),
                },
                {
                  Header: <IntlMessages id="salesPercent" />,
                  accessor: 'salesPercent',
                },
                {
                  Header: <IntlMessages id="marginPercent" />,
                  accessor: 'marginPercent',
                },
              ]}
            />
          </CardBox>
        </div>

        <div className="row mt-3">
          <CardBox styleName="col-12" heading="activeBundles">
            <Table
              sortable={false}
              data={myBundles}
              pageSize={myBundles.length}
              showPaginationTop={false}
              columns={[
                {
                  Header: <IntlMessages id="name" />,
                  accessor: 'name',
                },
                {
                  Header: <IntlMessages id="product" />,
                  accessor: 'product.name',
                },
                {
                  Header: <IntlMessages id="service" />,
                  accessor: 'service.name',
                },
                {
                  Header: <IntlMessages id="from" />,
                  accessor: 'from.name',
                },
                {
                  Header: <IntlMessages id="to" />,
                  accessor: 'to.name',
                },
                {
                  Header: <IntlMessages id="price" />,
                  accessor: 'price',
                  Cell: ( { value } ) => Util.formatCurrency( value ),
                },
                {
                  Header: <IntlMessages id="currentPrice" />,
                  accessor: 'currentPrice',
                  Cell: ( { value } ) => Util.formatCurrency( value ),
                },
                {
                  Header: <IntlMessages id="totalSales" />,
                  accessor: 'totalUnitSales',
                  Cell: ( { value } ) => Util.formatNumber( value ),
                },
                {
                  Header: <IntlMessages id="lastRoundSalesUnits" />,
                  accessor: 'lastRoundUnitSales',
                  Cell: ( { value } ) => Util.formatNumber( value ),
                },
              ]}
            />
          </CardBox>
        </div>

        <CardBox styleName="mt-3">
          <Form
            initialValues={unitsForm}
            onSubmit={values => this.getUnitsSalesMargin( values )}
            render={( { handleSubmit } ) => (
              <form onSubmit={handleSubmit} className="row">
                <div className="col-sm-3">
                  <Select
                    placeholder="round"
                    labelKey="round.name"
                    idKey="round.id"
                    field="unitsRound"
                    options={rankingRounds}
                    marginContainer={false}
                    translateOptions={false}
                    onChange={() => {
                      handleSubmit();
                    }}
                  />
                </div>
              </form>
            )}
          />

          <div className="row mt-3">
            <div className="col-md-4">
              <h4 className="m-0"><IntlMessages id="units" /></h4>
              <ResponsiveContainer width="100%" height={250}>
                <PieChart>
                  <Pie
                    data={unitsSalesMargin}
                    dataKey="margin"
                    label={item => Util.formatNumber( item.value )}
                    cy={120}
                    outerRadius={80}
                    isAnimationActive={false}
                  >
                    {_.map( unitsSalesMargin, ( entry, index ) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ) )}
                  </Pie>
                  <Tooltip separator=": " formatter={item => Util.formatCurrency( item )} />
                </PieChart>
              </ResponsiveContainer>
            </div>

            <div className="col-md-4">
              <h4 className="m-0"><IntlMessages id="sales" /></h4>
              <ResponsiveContainer width="100%" height={250}>
                <PieChart>
                  <Pie
                    data={unitsSalesMargin}
                    dataKey="sales"
                    label={item => Util.formatCurrency( item.value )}
                    cy={120}
                    outerRadius={80}
                    isAnimationActive={false}
                  >
                    {_.map( unitsSalesMargin, ( entry, index ) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ) )}
                  </Pie>
                  <Tooltip separator=": " formatter={item => Util.formatCurrency( item )} />
                </PieChart>
              </ResponsiveContainer>
            </div>

            <div className="col-md-4">
              <h4 className="m-0"><IntlMessages id="margin" /></h4>
              <ResponsiveContainer width="100%" height={250}>
                <BarChart
                  data={unitsSalesMargin}
                >
                  <XAxis
                    interval={0}
                    dataKey="name"
                    height={60}
                    tick={<AxisTick />}
                  />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip separator=": " formatter={item => Util.formatCurrency( item )} />
                  <Legend verticalAlign="top" />
                  <Bar dataKey="margin" name="Margin" fill="#3367d6" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </CardBox>
      </div>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( Dashboard );
