import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import IntlMessages from 'utils/IntlMessages';

class WriteBox extends Component {
  state = {
    content: '',
  };

  handleAddPost() {
    this.props.addPost(this.state.content);
    this.setState({
      content: '',
    });
  }

  onChange(e) {
    this.setState({ content: e.target.value });
  }

  render() {
    return (
      <Card className="jr-card">
        <div className="media mb-2">
          {this.props.user.avatar ? (
            <Avatar
              alt="..."
              className="size-50 mr-3"
              src={this.props.user.avatar}
            />
          ) : (
            <Avatar alt="..." className="size-50 mr-3">
              {this.props.user.name.charAt()}
            </Avatar>
          )}
          <div className="media-body">
            <TextField
              id="exampleTextarea"
              label={<IntlMessages id="post-placeholder" />}
              value={this.state.content}
              onChange={event => this.onChange(event)}
              multiline
              fullWidth
              className="jr-wall-textarea"
              margin="none"
              variant="outlined"
              rows="4"
            />
          </div>
        </div>

        <div className="jr-clearfix">
          <Divider />
        </div>

        <div className="d-flex flex-row align-items-center mt-2">
          <Button
            color="primary"
            size="small"
            className="ml-auto mb-0"
            disabled={this.state.content === '' ? true : false}
            onClick={this.handleAddPost.bind(this)}
          >
            <IntlMessages id="post-send" />
          </Button>
        </div>
      </Card>
    );
  }
}

export default WriteBox;
