import React from 'react';
import { connect } from 'react-redux';
import { FormHelperText } from '@material-ui/core';
import { Form } from 'react-final-form';
import { Col, Row } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import arrayMutators from 'final-form-arrays';
import _ from 'lodash';
import async from 'async';

import SettingsActions from 'store/reducers/Settings';
import IntlMessages from 'utils/IntlMessages';
import { TextField, Buttons, RadioButtons } from 'components/custom/FormElements';
import BreadcrumbComp from 'components/custom_v2/Breadcrumb';
import CardBox from 'components/custom_v2/CardBox';
import { composeValidators, greaterOrEqualValue, required } from 'config/InputErrors';
import ProductService from 'services/Product';
import ServiceService from 'services/Service';
import BundleService from 'services/Bundle';
import Util from 'utils/Util';

class NewBundle extends React.Component {
  state = {
    products: [],
    services: [],
    confirmationOpen: false,
  };

  componentDidMount() {
    const { toggleAlert, user } = this.props;

    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.activeSession = user.activeSession;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }

    this.getData();
  }

  getData = () => {
    const { toggleAlert, toggleLoading } = this.props;

    toggleLoading( true );
    async.parallel( {
      services: ( cb ) => {
        ServiceService.getServicesV2( this.activeSession.id, {
          workgroup: this.license.workGroup.id,
          'filters[active]': 1,
        } )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
      },
      products: ( cb ) => {
        ProductService.getProducts( this.activeSession.id, {
          workgroup: this.license.workGroup.id,
          'filters[inStatus]': ['READY'],
          'filters[active]': 1,
        } )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
      },
    }, ( error, results ) => {
      toggleLoading( false );
      if ( error ) return toggleAlert( error );
      this.setState( results );
    } );
  };

  submitForm = ( data ) => {
    const { toggleAlert, toggleLoading, history } = this.props;
    const productId = data.productService ? data.productService.split( '_' )[0] : null;
    const serviceId = data.productService ? data.productService.split( '_' )[1] : null;

    toggleLoading( true );
    const dataToSend = {
      workgroup: this.license.workGroup.id,
      name: data.name,
      price: data.price,
      monthlyFee: data.monthlyFee,
      product: productId,
      service: serviceId,
    };

    BundleService.saveBundleV2( this.activeSession.id, dataToSend )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) return toggleAlert( response.errors );

        toggleAlert( 'dataSaved', 'info' );
        history.push( '/sessions/general/services' );
      } );
  };

  toggleConfirmationModal = () => {
    const { confirmationOpen } = this.state;
    this.setState( { confirmationOpen: !confirmationOpen } );
  };

  render() {
    const { products, services, confirmationOpen } = this.state;

    return (
      <Form
        validate={( values ) => {
          const errors = {};
          if ( !values.productService ) {
            errors.productServiceError = required( values.productService );
          }
          return errors;
        }}
        onSubmit={this.toggleConfirmationModal}
        mutators={{ ...arrayMutators }}
        render={( { handleSubmit, values, submitFailed, errors } ) => {
          const productId = values && values.productService ? values.productService.split( '_' )[0] : null;
          const productObj = _.find( products, { id: productId } );
          const serviceId = values && values.productService ? values.productService.split( '_' )[1] : null;
          const serviceObj = _.find( services, { id: serviceId } );

          return (
            <form onSubmit={handleSubmit}>
              <BreadcrumbComp links={[
                {
                  url: '/',
                  name: 'decisionDashboard',
                },
                {
                  url: '/sessions/general/services',
                  name: 'services',
                },
                { name: 'newBundle' },
              ]}
              />
              <Row>
                <Col className="text-right">
                  <Buttons cancelTo="/sessions/general/services" />
                </Col>
              </Row>
              <Row>
                <Col md={5}>
                  <TextField
                    field="name"
                    label="bundleName"
                    validate={required}
                  />

                  <TextField
                    field="price"
                    label="bundlePrice"
                    type="number"
                    inputProps={{ className: 'text-right' }}
                    validate={composeValidators(
                      required,
                      greaterOrEqualValue( 0 ),
                    )}
                  />
                  <TextField
                    field="monthlyFee"
                    label="bundleMonthlyFee"
                    type="number"
                    inputProps={{ className: 'text-right' }}
                    validate={composeValidators(
                      required,
                      greaterOrEqualValue( 0 ),
                    )}
                  />
                </Col>
                <Col md={7}>
                  <CardBox
                    header={(
                      <div className="p-2 bg-primary text-white">
                        <h4 className="card-heading m-0">
                          <IntlMessages id="selectProductServiceCombination" />
                        </h4>
                      </div>
                    )}
                  >
                    <div className="table-responsive">
                      <table className="table table-borderless mb-0">
                        <thead>
                          <tr>
                            <th className="bg-transparent" style={{ width: 120 }} />
                            {_.map( services, service => (
                              <th
                                style={{ width: 120, verticalAlign: 'top' }}
                                className="bg-transparent text-dark font-weight-normal"
                                key={service.id}
                              >
                                <Row>
                                  <Col className="text-center">
                                    {service.serviceType.label === 'SERVICE_1'
                              && (
                                <i className="fa fa-video fa-4x text-primary" />
                              )}
                                    {service.serviceType.label === 'SERVICE_2'
                              && (
                                <i className="fa fa-newspaper fa-4x text-primary" />
                              )}
                                    {service.serviceType.label === 'SERVICE_3'
                              && (
                                <i className="fa fa-music fa-4x text-primary" />
                              )}
                                  </Col>
                                </Row>
                                <Row className="text-center mt-2">
                                  <Col>
                                    {service.name}
                                  </Col>
                                </Row>
                              </th>
                            ) )}
                          </tr>
                        </thead>
                        <tbody>
                          {_.map( products, product => (
                            <tr key={product.id}>
                              <td><div className="mt-3">{product.name}</div></td>
                              {_.map( services, service => (
                                <td key={service.id}>
                                  <RadioButtons
                                    field="productService"
                                    radioClassName="justify-content-center"
                                    options={[{ id: `${product.id}_${service.id}` }]}
                                  />
                                </td>
                              ) )}
                            </tr>
                          ) )}
                          <tr />
                        </tbody>
                      </table>
                    </div>
                    {submitFailed && errors && errors.productServiceError
                      ? (
                        <div className="row">
                          <div className="col">
                            <FormHelperText className="Mui-error">
                              <IntlMessages id={errors.productServiceError} />
                            </FormHelperText>
                          </div>
                        </div>
                      )
                      : null}
                  </CardBox>
                </Col>
              </Row>

              <SweetAlert
                show={confirmationOpen}
                warning
                showCancel
                confirmBtnText={<IntlMessages id="yes" />}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title={(
                  <>
                    <p>
                      <IntlMessages id="activateBundleQ" />
                    </p>
                    <p className="label-font-sm mb-1">
                      <IntlMessages id="name" />
                      {': '}
                      {values.name}
                    </p>
                    <p className="label-font-sm mb-1">
                      <IntlMessages id="product" />
                      {': '}
                      {productObj ? productObj.name : ''}
                    </p>
                    <p className="label-font-sm mb-1">
                      <IntlMessages id="service" />
                      {': '}
                      {serviceObj ? serviceObj.name : ''}
                    </p>
                    <p className="label-font-sm mb-1">
                      <IntlMessages id="price" />
                      {': '}
                      {Util.formatCurrency( values.price )}
                    </p>
                    <p className="label-font-sm">
                      <IntlMessages id="fee" />
                      {': '}
                      {Util.formatCurrency( values.monthlyFee )}
                    </p>
                  </>
                )}
                onConfirm={() => this.submitForm( values )}
                onCancel={this.toggleConfirmationModal}
              />
            </form>
          );
        }}
      />
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( NewBundle );
