import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { Button } from '@material-ui/core';

import { composeValidators, email, required } from 'config/InputErrors';
import TextField from 'components/custom/FormElements/TextField';
import IntlMessage from 'utils/IntlMessages';
import UserActions from 'store/reducers/User';
import SettingsActions from 'store/reducers/Settings';
import UserService from 'services/User';
import logo from 'assets/images/logo.png';
import IntlMessages from '../../utils/IntlMessages';

class ForgotPassword extends React.Component {
  componentDidMount() {
    const { accessToken, logoutUser } = this.props;
    if ( accessToken ) logoutUser();
  }

  submitForm = ( data ) => {
    const { toggleLoading, toggleAlert, history } = this.props;
    toggleLoading( true );

    UserService.recoverPassword( { email: data.username } )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) return toggleAlert( response.errors );

        history.push( '/login' );
        toggleAlert( 'forgotPasswordEmail', 'info' );
      } );
  };

  render() {
    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3"
      >
        <div className="app-login-main-content">

          <div className="app-logo-content bg-primary d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="MK">
              <img src={logo} alt="MK" title="MK" width={200} />
            </Link>
          </div>

          <div className="app-login-content" style={{ minWidth: 400 }}>
            <div className="app-login-header mb-4">
              <h1><IntlMessage id="recoverYourPassword" /></h1>
            </div>

            <div className="app-login-form">
              <Form
                onSubmit={this.submitForm}
                render={( { handleSubmit } ) => (
                  <form onSubmit={handleSubmit} noValidate className="w-100">
                    <TextField field="username" label="email" validate={composeValidators( required, email )} />

                    <div className="d-flex justify-content-between align-items-center">
                      <Link to="login">
                        <IntlMessages id="login" />
                      </Link>

                      <Button variant="contained" color="primary" className="jr-btn jr-btn-lg" type="submit">
                        <i className="fa fa-key" />
                        <IntlMessages id="recoverPassword" />
                      </Button>
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ( { user, settings } ) => ( {
  accessToken: user.access_token,
  isLoading: settings.isLoading,
} );

const mapDispatchToProps = ( {
  loginUser: UserActions.loginUser,
  logoutUser: UserActions.logoutUser,
  toggleLoading: SettingsActions.toggleLoading,
  toggleAlert: SettingsActions.toggleAlert,
  changeActiveSession: UserActions.changeActiveSession,
} );

export default connect( mapStateToProps, mapDispatchToProps )( ForgotPassword );
