import api from '../config/Api';

const endpoint = 'v1/session';
const endpointV2 = 'v2/session';

export default {

  getBundles( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/bundle`, params );
  },

  getBundlesV2( sessionId, params ) {
    return api.get( `${endpointV2}/${sessionId}/bundle`, params );
  },

  getBundle( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/bundle/detail`, params );
  },

  saveBundle( sessionId, data ) {
    return api.post( `${endpoint}/${sessionId}/bundle`, data );
  },

  saveBundleV2( sessionId, data ) {
    return api.post( `${endpointV2}/${sessionId}/bundle`, data );
  },

  updateBundle( sessionId, data ) {
    return api.put( `${endpoint}/${sessionId}/bundle`, data );
  },

  updateBundlesV2( sessionId, data ) {
    return api.put( `${endpointV2}/${sessionId}/bundle/price-status`, data );
  },

  updateBundleRoundPrice( sessionId, data ) {
    return api.put( `${endpoint}/${sessionId}/bundle-round/price`, data );
  },
};
