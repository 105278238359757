import React from 'react';
import { FieldArray } from 'react-final-form-arrays';

import CardBox from 'components/theme/CardBox';
import { TextField } from 'components/custom/FormElements';
import IntlMessages from 'utils/IntlMessages';

export default ( { title, fieldName, values, iconClass } ) => (
  <CardBox
    cardStyle="jr-full-card m-0"
    headingStyle="mb-0 pb-3"
    styleName="col-12 p-0"
    heading={[
      <i
        key={1}
        className={`zmdi zmdi-hc-lg mr-2 ${iconClass}`}
      />,
      <IntlMessages id={title} key={2} />,
    ]}
  >
    <div className="table-responsive-material pb-2" style={{ maxHeight: 400 }}>
      <table className="table custom-table remove-table-border table-sm mb-0">
        <tbody>
          <FieldArray name={fieldName}>
            {( { fields } ) => (
              fields.map( ( product, index ) => (
                <tr
                  tabIndex={-1}
                  key={values[index].id}
                >
                  <td width="15%" className="pl-2">
                    <div className="py-2 d-flex flex-row align-items-center">
                      <div className="">
                        <h5 className="m-0">
                          {values[index].label}
                        </h5>
                      </div>
                    </div>
                  </td>
                  <td>
                    <TextField
                      marginContainer={false}
                      field={`${product}.mediaChannel`}
                    />
                    <TextField
                      marginContainer={false}
                      field={`${product}.description`}
                      multiline
                      rowsMax={2}
                    />
                  </td>
                </tr>
              ) )
            )}
          </FieldArray>
        </tbody>
      </table>
    </div>
  </CardBox>
);
