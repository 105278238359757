import api from '../config/Api';

const endpoint = 'v1/category';
const endpointV2 = 'v2/category';

export default {

  getCategories( params ) {
    return api.get( endpoint, params );
  },

  getCategory( id, params ) {
    return api.get( `${endpointV2}/${id}`, params );
  },

  getLastVersion( params ) {
    return api.get( `${endpoint}/last/version`, params );
  },

  getProducts( sessionId, categoryId, params ) {
    return api.get( `v2/session/${sessionId}/category/${categoryId}/product`, params );
  },

  updateCategories( data ) {
    return api.put( endpoint, data );
  },
};
