import React from 'react';
import {Link} from 'react-router-dom';
import IntlMessages from 'utils/IntlMessages';

const IconCard = ( { icon, title, bgColor, link, onClick } ) => (
  <Link to={link} className="icon-card-link" onClick={onClick}>
    <div className={`jr-card jr-card-full mb-0 m-auto icon-card cbg-light borderless`}>
      <div className="jr-fillchart cbg-light mb-4 border">
        <div className="jr-fillchart-content align-items-center justify-content-center">
          <i className={`fa fa-${icon} fa-4x text-app`}/>
          <h2 className="mb-0 mt-2 text-app" ><IntlMessages id={title}/></h2>
        </div>
      </div>
    </div>
  </Link>
);

export default IconCard;
