import React from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';

import IntlMessages from 'utils/IntlMessages';
import { TextField } from 'components/custom/FormElements';
import CardBox from 'components/theme/CardBox';
import { required } from 'config/InputErrors';
import SettingsActions from 'store/reducers/Settings';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import Table from 'components/custom/Table/Custom';
import CommodityService from 'services/Commodity';
import ContainerHeader from 'components/theme/ContainerHeader';
import PieLabel from 'components/custom/Charts/PieLabel';
import Util from 'utils/Util';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const COLORS1 = ['#233539', '#8898aa', '#FFBB28', '#FF8042'];

class Simulation extends React.Component {
  state = {
    simulationData: null,
    commodities: [],
  };

  componentDidMount() {
    const { toggleAlert, user } = this.props;
    if ( !user.activeSession ) {
      this.setState( { corruptedData: true } );
      return toggleAlert( 'ERROR' );
    }

    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      this.setState( { corruptedData: true } );
      return toggleAlert( 'ERROR' );
    }
    this.activeSession = user.activeSession;
    this.getCommodities();
  }

  getCommodities = () => {
    const { toggleAlert, toggleLoading } = this.props;
    toggleLoading( true );

    const filters = {
      workgroup: this.license.workGroup.id,
      limit: -1,
    };
    CommodityService.getCommodities( this.activeSession.id, filters )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) {
          this.setState( { corruptedData: true } );
          return toggleAlert( 'ERROR' );
        }
        this.setState( { commodities: response.data.data } );
      } );
  };

  simulateData = ( formData ) => {
    const { toggleAlert, toggleLoading } = this.props;
    toggleLoading( true );

    const dataToSend = {
      workgroup: this.license.workGroup.id,
      simulations: _.map( formData.commodities, ( item ) => {
        const data = {
          commodity: item.id,
          price: item.lastRoundPrice,
          sales: item.totalSales,
        };
        if ( item.owner === 'PRODUCT' ) {
          data.tradeSalePrice = item.lastRoundTradeSalesPrice;
          data.tradeSales = item.lastRoundTradeSales;
        }
        return data;
      } ),
    };
    CommodityService.simulateData( this.activeSession.id, dataToSend )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) {
          return toggleAlert( response.errors );
        }
        this.setState( { simulationData: response.data } );
      } );
  };

  submitTarget = ( formData ) => {
    const { toggleAlert, toggleLoading } = this.props;
    toggleLoading( true );

    const dataToSend = {
      workgroup: this.license.workGroup.id,
      targets: _.map( formData.commodities, ( item ) => {
        const data = {
          commodity: item.id,
          value: item.totalSales,
        };
        if ( item.owner === 'PRODUCT' ) {
          data.value = item.totalSales + item.lastRoundTradeSales;
        }
        return data;
      } ),
    };
    CommodityService.updateCommodityTarget( this.activeSession.id, dataToSend )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) {
          this.setState( { corruptedData: true } );
          return toggleAlert( response.errors );
        }
        toggleAlert( 'dataSaved', 'info' );
      } );
  };

  submitPrice = ( formData ) => {
    const { toggleAlert, toggleLoading } = this.props;
    toggleLoading( true );

    const dataToSend = {
      workgroup: this.license.workGroup.id,
      prices: _.map( formData.commodities, ( item ) => {
        const data = {
          commodity: item.id,
          price: item.lastRoundPrice,
        };
        if ( item.owner === 'PRODUCT' ) {
          data.tradeSalePrice = item.totalSales + item.lastRoundTradeSalesPrice;
        }
        return data;
      } ),
    };
    CommodityService.updatePrices( this.activeSession.id, dataToSend )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) {
          this.setState( { corruptedData: true } );
          return toggleAlert( response.errors );
        }
        toggleAlert( 'dataSaved', 'info' );
      } );
  };

  render() {
    const { commodities, corruptedData, simulationData } = this.state;
    const simulationCommodities = simulationData ? [
      ...simulationData.services, ...simulationData.products, ...simulationData.bundles] : [];

    return (
      <Form
        initialValues={{ commodities }}
        onSubmit={this.simulateData}
        mutators={{ ...arrayMutators }}
        render={( { values, handleSubmit, form, ...rest } ) => (
          <form onSubmit={handleSubmit}>
            <ContainerHeader
              title="resultsSimulation"
              rightContent={(
                <div>
                  <Button
                    disabled={corruptedData}
                    variant="contained"
                    color="primary"
                    className="jr-btn jr-btn-lg"
                    type="submit"
                  >
                    <i className="fa fa-play" />
                    <IntlMessages id="runSimulation" />
                  </Button>

                  <Button
                    disabled={corruptedData}
                    variant="contained"
                    className="jr-btn jr-btn-lg bg-blue-grey text-white"
                    type="button"
                    onClick={() => {
                      if ( !rest.valid ) {
                        form.submit();
                      } else {
                        this.submitTarget( values );
                      }
                    }}
                  >
                    <i className="fa fa-save" />
                    <IntlMessages id="confirmTarget" />
                  </Button>

                  <Button
                    disabled={corruptedData}
                    variant="contained"
                    className="jr-btn jr-btn-lg bg-blue-grey text-white"
                    type="button"
                    onClick={() => {
                      if ( !rest.valid ) {
                        form.submit();
                      } else {
                        this.submitPrice( values );
                      }
                    }}
                  >
                    <i className="fa fa-save" />
                    <IntlMessages id="updatePrices" />
                  </Button>
                </div>
              )}
            />

            <div className="row">
              <div className="col-12">
                <ExpansionPanel defaultExpanded className="mb-3 expansion-panel">
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><IntlMessages id="commoditiesList" /></Typography>
                  </ExpansionPanelSummary>

                  <ExpansionPanelDetails className="p-0 d-block">
                    <div className="table-responsive-material">
                      <table className="table custom-table mb-0">
                        <thead>
                          <tr>
                            <th><IntlMessages id="category" /></th>
                            <th><IntlMessages id="code" /></th>
                            <th><IntlMessages id="name" /></th>
                            <th><IntlMessages id="description" /></th>
                            <th><IntlMessages id="cost" /></th>
                            <th><IntlMessages id="price" /></th>
                            <th><IntlMessages id="stockPlusProd" /></th>
                            <th><IntlMessages id="lastRoundSales" /></th>
                            <th><IntlMessages id="totalQtySales" /></th>
                            <th><IntlMessages id="tradeSalePrice" /></th>
                            <th><IntlMessages id="tradeSale" /></th>
                          </tr>
                        </thead>
                        <tbody>
                          <FieldArray name="commodities">
                            {( { fields } ) => (
                              fields.map( ( commodity, index ) => (
                                <tr
                                  tabIndex={-1}
                                  key={values.commodities[index].id}
                                >
                                  <td>{values.commodities[index].category}</td>
                                  <td>{values.commodities[index].code}</td>
                                  <td>{values.commodities[index].name}</td>
                                  <td>{values.commodities[index].description}</td>
                                  <td>{Util.formatCurrency( values.commodities[index].cost )}</td>
                                  <td>
                                    <TextField
                                      marginContainer={false}
                                      field={`${commodity}.lastRoundPrice`}
                                      type="number"
                                      validate={required}
                                      allowZero
                                    />
                                  </td>
                                  <td>
                                    {Util.formatNumber(
                                      values.commodities[index].lastRoundProduction,
                                    )}
                                  </td>
                                  <td>
                                    {Util.formatNumber( values.commodities[index].lastRoundSales )}
                                  </td>
                                  <td>
                                    <TextField
                                      marginContainer={false}
                                      field={`${commodity}.totalSales`}
                                      type="number"
                                      validate={required}
                                      allowZero
                                    />
                                  </td>
                                  <td>
                                    {values.commodities[index].owner === 'PRODUCT'
                                    && (
                                      <TextField
                                        marginContainer={false}
                                        field={`${commodity}.lastRoundTradeSalesPrice`}
                                        type="number"
                                        validate={required}
                                        allowZero
                                      />
                                    )}
                                  </td>
                                  <td>
                                    {values.commodities[index].owner === 'PRODUCT'
                                    && (
                                      <TextField
                                        marginContainer={false}
                                        field={`${commodity}.lastRoundTradeSales`}
                                        type="number"
                                        validate={required}
                                        allowZero
                                      />
                                    )}
                                  </td>
                                </tr>
                              ) )
                            )}
                          </FieldArray>
                        </tbody>
                      </table>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            </div>

            {simulationData
            && (
              <>
                <h3><IntlMessages id="generalSimulationResults" /></h3>
                <hr />

                <div className="row">
                  <div className="col-lg-6">
                    <CardBox styleName="mt-3 mt-lg-0" heading="information">
                      <div className="row">
                        <div className="col-md-6">
                          <p className="mb-1">
                            <strong>
                              <IntlMessages id="totalProductionCost" />
                            </strong>
                            {': '}
                            {Util.formatCurrency( simulationData.total.totalProductionCost || 0 )}
                          </p>
                          <p className="mb-1">
                            <strong>
                              <IntlMessages id="totalPopCost" />
                            </strong>
                            {': '}
                            {Util.formatCurrency( simulationData.total.pop || 0 )}
                          </p>
                          <p className="mb-1">
                            <strong>
                              <IntlMessages id="fixedCost" />
                            </strong>
                            {': '}
                            {Util.formatCurrency( simulationData.total.fixedCost || 0 )}
                          </p>
                          <p>
                            <strong>
                              <IntlMessages id="cemCost" />
                            </strong>
                            {': '}
                            {Util.formatCurrency( simulationData.total.cemCost || 0 )}
                          </p>
                          <p className="mb-1">
                            <strong>
                              <IntlMessages id="totalCost" />
                            </strong>
                            {': '}
                            {Util.formatCurrency( simulationData.total.totalCost || 0 )}
                          </p>
                          <p>
                            <strong>
                              <IntlMessages id="totalSales" />
                            </strong>
                            {': '}
                            {Util.formatCurrency( simulationData.total.totalSales || 0 )}
                          </p>
                          <p>
                            <strong>
                              <IntlMessages id="simulationBalance" />
                            </strong>
                            {': '}
                            {Util.formatCurrency( simulationData.total.simulationBalance || 0 )}
                          </p>
                        </div>
                        <div className="col-md-6">
                          <p>
                            <strong>
                              <IntlMessages id="operationalMargin" />
                            </strong>
                            {': '}
                            {Util.formatCurrency( simulationData.total.operationalMargin || 0 )}
                          </p>
                          <p>
                            <strong>
                              <IntlMessages id="totalSoldUnits" />
                            </strong>
                            {': '}
                            {Util.formatNumber( simulationData.total.totalSoldUnits || 0 )}
                          </p>
                        </div>
                      </div>
                    </CardBox>
                  </div>

                  <div className="col-lg-6">
                    <CardBox styleName="mt-0">
                      <div className="row">
                        <div className="col-md-4">
                          <h4 className="m-0"><IntlMessages id="units" /></h4>
                          <ResponsiveContainer width="100%" height={150}>
                            <PieChart>
                              <Pie
                                dataKey="unitSales"
                                data={simulationCommodities}
                                labelLine={false}
                                label={PieLabel}
                                outerRadius={50}
                                isAnimationActive={false}
                              >
                                {_.map( simulationCommodities, ( entry, index ) => (
                                  <Cell
                                    key={entry.commodityId}
                                    fill={COLORS[index % COLORS.length]}
                                  />
                                ) )}
                              </Pie>
                              <Tooltip separator=": " />
                            </PieChart>
                          </ResponsiveContainer>
                        </div>

                        <div className="col-md-4">
                          <h4 className="m-0"><IntlMessages id="sales" /></h4>
                          <ResponsiveContainer width="100%" height={150}>
                            <PieChart>
                              <Pie
                                dataKey="sales"
                                data={simulationCommodities}
                                labelLine={false}
                                label={PieLabel}
                                outerRadius={50}
                                isAnimationActive={false}
                              >
                                {_.map( simulationCommodities, ( entry, index ) => (
                                  <Cell
                                    key={entry.commodityId}
                                    fill={COLORS1[index % COLORS1.length]}
                                  />
                                ) )}
                              </Pie>
                              <Tooltip separator=": " />
                            </PieChart>
                          </ResponsiveContainer>
                        </div>

                        <div className="col-md-4">
                          <h4 className="m-0"><IntlMessages id="margin" /></h4>
                          <ResponsiveContainer width="100%" height={150}>
                            <PieChart stackOffset="sign">
                              <Pie
                                dataKey="margin"
                                data={simulationCommodities}
                                labelLine={false}
                                label={PieLabel}
                                outerRadius={50}
                                isAnimationActive={false}
                              >
                                {_.map( simulationCommodities, ( entry, index ) => (
                                  <Cell
                                    key={entry.commodityId}
                                    fill={COLORS[index % COLORS.length]}
                                  />
                                ) )}
                              </Pie>
                              <Tooltip separator=": " />
                            </PieChart>
                          </ResponsiveContainer>
                        </div>
                      </div>
                    </CardBox>
                  </div>
                </div>

                <div className="row">
                  <CardBox
                    styleName="col-12 mt-3"
                    cardStyle="p-0 m-0"
                    headingStyle="px-4 py-3 m-0"
                    heading="products"
                  >
                    <Table
                      data={simulationData.products}
                      showPaginationTop={false}
                      columns={[
                        {
                          Header: <IntlMessages id="category" />,
                          accessor: 'category',
                        },
                        {
                          Header: <IntlMessages id="code" />,
                          accessor: 'codProduct',
                          maxWidth: 100,
                        },
                        {
                          Header: <IntlMessages id="description" />,
                          accessor: 'description',
                        },
                        {
                          Header: <IntlMessages id="stock" />,
                          accessor: 'stock',
                          Cell: ( { value } ) => Util.formatNumber( value ),
                        },
                        {
                          Header: <IntlMessages id="unitaryCost" />,
                          accessor: 'unitaryCost',
                          Cell: ( { value } ) => Util.formatCurrency( value ),
                        },
                        {
                          Header: <IntlMessages id="price" />,
                          accessor: 'price',
                          Cell: ( { value } ) => Util.formatCurrency( value ),
                        },
                        {
                          Header: <IntlMessages id="salesUnit" />,
                          accessor: 'unitSales',
                          Cell: ( { value } ) => Util.formatNumber( value ),
                        },
                        {
                          Header: <IntlMessages id="salesCost" />,
                          accessor: 'sales',
                          Cell: ( { value } ) => Util.formatCurrency( value ),
                        },
                        {
                          Header: <IntlMessages id="marginCost" />,
                          accessor: 'margin',
                          Cell: ( { value } ) => Util.formatCurrency( value ),
                        },
                        {
                          Header: <IntlMessages id="salesPercent" />,
                          accessor: 'percentSales',
                        },
                        {
                          Header: <IntlMessages id="marginPercent" />,
                          accessor: 'percentMargin',
                        },
                      ]}
                    />
                  </CardBox>
                </div>

                <div className="row">
                  <CardBox
                    styleName="col-12 mt-3"
                    cardStyle="p-0 m-0"
                    headingStyle="px-4 py-3 m-0"
                    heading="services"
                  >
                    <Table
                      data={simulationData.services}
                      showPaginationTop={false}
                      columns={[
                        {
                          Header: <IntlMessages id="description" />,
                          accessor: 'description',
                        },
                        {
                          Header: <IntlMessages id="unitaryCost" />,
                          accessor: 'unitaryCost',
                          Cell: ( { value } ) => Util.formatCurrency( value ),
                        },
                        {
                          Header: <IntlMessages id="price" />,
                          accessor: 'price',
                          Cell: ( { value } ) => Util.formatCurrency( value ),
                        },
                        {
                          Header: <IntlMessages id="salesUnit" />,
                          accessor: 'unitSales',
                          Cell: ( { value } ) => Util.formatNumber( value ),
                        },
                        {
                          Header: <IntlMessages id="salesCost" />,
                          accessor: 'sales',
                          Cell: ( { value } ) => Util.formatCurrency( value ),
                        },
                        {
                          Header: <IntlMessages id="marginCost" />,
                          accessor: 'margin',
                          Cell: ( { value } ) => Util.formatCurrency( value ),
                        },
                        {
                          Header: <IntlMessages id="salesPercent" />,
                          accessor: 'percentSales',
                        },
                        {
                          Header: <IntlMessages id="marginPercent" />,
                          accessor: 'percentMargin',
                        },
                      ]}
                    />
                  </CardBox>
                </div>

                <div className="row">
                  <CardBox
                    styleName="col-12 mt-3"
                    cardStyle="p-0 m-0"
                    headingStyle="px-4 py-3 m-0"
                    heading="bundles"
                  >
                    <Table
                      data={simulationData.bundles}
                      showPaginationTop={false}
                      columns={[
                        {
                          Header: <IntlMessages id="bundle" />,
                          accessor: 'bundle',
                        },
                        {
                          Header: <IntlMessages id="product" />,
                          accessor: 'product',
                        },
                        {
                          Header: <IntlMessages id="service" />,
                          accessor: 'service',
                        },
                        {
                          Header: <IntlMessages id="from" />,
                          accessor: 'from.name',
                        },
                        {
                          Header: <IntlMessages id="to" />,
                          accessor: 'to.name',
                        },
                        {
                          Header: <IntlMessages id="price" />,
                          accessor: 'price',
                          Cell: ( { value } ) => Util.formatCurrency( value ),
                        },
                        {
                          Header: <IntlMessages id="salesUnit" />,
                          accessor: 'unitSales',
                          Cell: ( { value } ) => Util.formatNumber( value ),
                        },
                      ]}
                    />
                  </CardBox>
                </div>
              </>
            )
            }
          </form>
        )}
      />
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( Simulation );
