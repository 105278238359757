import React from 'react';
import { connect } from 'react-redux';
import { FormHelperText, Button } from '@material-ui/core';
import { Form } from 'react-final-form';
import { Col, Row } from 'reactstrap';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import classnames from 'classnames';
import _ from 'lodash';
import async from 'async';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';

import SettingsActions from 'store/reducers/Settings';
import IntlMessages from 'utils/IntlMessages';
import { TextField, ButtonsNP } from 'components/custom/FormElements';
import BreadcrumbComp from 'components/custom_v2/Breadcrumb';
import CardBox from 'components/custom_v2/CardBox';
import CategoryAvatar from 'components/custom_v2/CategoryAvatar';
import { required } from 'config/InputErrors';
import CategoryService from 'services/Category';
import AttributeService from 'services/Attribute';
import AttributeCostService from 'services/AttributeCost';
import Util from 'utils/Util';
import ProductService from 'services/Product';

const attributesDetail = [
  {
    category: 'CATEGORY_1',
    label: 'Design',
    values: [{ key: 1, text: 'Poor', val: '$50' }, { key: 2, text: 'Basic', val: '$100' }, {
      key: 3,
      text: 'Regular',
      val: '$150',
    }, { key: 4, text: 'Advanced', val: '$200' }, { key: 5, text: 'Premium', val: '$250' }],
  },
  {
    category: 'CATEGORY_1',
    label: 'Size',
    values: [{ key: 1, text: 'Regular', val: '$0' }, { key: 2, text: 'Slim', val: '$50' }, {
      key: 3,
      text: 'Large',
      val: '$50',
    }, { key: 4, text: 'X-Large', val: '$70' }],
  },
  {
    category: 'CATEGORY_1',
    label: 'Reliability',
    values: [{ key: 1, text: 'Poor', val: '$30' }, { key: 2, text: 'Basic', val: '$60' }, {
      key: 3,
      text: 'Normal',
      val: '$90',
    }, { key: 4, text: 'Good', val: '$120' }, { key: 5, text: 'Excellent', val: '$150' }],
  },
  {
    category: 'CATEGORY_1',
    label: 'Weight',
    values: [{ key: 1, text: 'Heavy', val: '$±20' }, { key: 2, text: 'Regular', val: '$±20' }, {
      key: 3,
      text: 'Light',
      val: '$±20',
    }, { key: 4, text: 'Ultra-Light', val: '$±20' }],
  },
  {
    category: 'CATEGORY_1',
    label: 'Speed',
    values: [{ key: 1, text: 'Browsing', val: '$35' }, { key: 2, text: 'Browsing + Video', val: '$110' }, {
      key: 3,
      text: 'Standard',
      val: '$320',
    }, { key: 4, text: 'High performance', val: '$410' }],
  },
  {
    category: 'CATEGORY_2',
    label: 'Design',
    values: [{ key: 1, text: 'Poor', val: '$20' }, { key: 2, text: 'Basic', val: '$40' }, {
      key: 3,
      text: 'Regular',
      val: '$60',
    }],
  },
  {
    category: 'CATEGORY_2',
    label: 'Size',
    values: [{ key: 1, text: 'Regular', val: '$0' }, { key: 2, text: 'Large', val: '$30' }, {
      key: 3,
      text: 'X-Large',
      val: '$50',
    }],
  },
  {
    category: 'CATEGORY_2',
    label: 'Reliability',
    values: [{ key: 1, text: 'Poor', val: '$15' }, { key: 2, text: 'Normal', val: '$30' }, {
      key: 3,
      text: 'Good',
      val: '$45',
    }, { key: 4, text: 'Excellent', val: '$60' }],
  },
  {
    category: 'CATEGORY_2',
    label: 'Weight',
    values: [{ key: 1, text: 'Regular', val: '$±20' }, { key: 2, text: 'Light', val: '$±20' }, {
      key: 3,
      text: 'Ultra-Light',
      val: '$±20',
    }],
  },
  {
    category: 'CATEGORY_2',
    label: 'Speed',
    values: [{ key: 1, text: 'Regular', val: '$15' }, { key: 2, text: 'High performance', val: '$35' }],
  },
  {
    category: 'CATEGORY_3',
    label: 'Design',
    values: [{ key: 1, text: 'Poor', val: '$10' }, { key: 2, text: 'Basic', val: '$40' }, {
      key: 3,
      text: 'Regular',
      val: '$80',
    }, { key: 4, text: 'Advanced', val: '$150' }, { key: 5, text: 'Premium', val: '$300' }],
  },
  {
    category: 'CATEGORY_3',
    label: 'Size',
    values: [{ key: 1, text: 'Regular', val: '$0' }, { key: 2, text: 'Large', val: '$50' }],
  },
  {
    category: 'CATEGORY_3',
    label: 'Reliability',
    values: [{ key: 1, text: 'Poor', val: '$15' }, { key: 2, text: 'Normal', val: '$30' }, {
      key: 3,
      text: 'Good',
      val: '$45',
    }, { key: 4, text: 'Excellent', val: '$60' }],
  },
  {
    category: 'CATEGORY_3',
    label: 'Weight',
    values: [{ key: 1, text: 'Regular', val: '$±20' }, { key: 2, text: 'Light', val: '$±20' }],
  },
  {
    category: 'CATEGORY_3',
    label: 'Speed',
    values: [{ key: 1, text: 'Basic', val: '$15' }, { key: 2, text: 'Regular', val: '$35' }, {
      key: 3,
      text: 'High performance',
      val: '$70',
    }],
  },
];

const COLORS = ['danger', 'purple', 'blue', 'warning', 'sepia'];

class NewProduct extends React.Component {
  state = {
    category: {},
    attributes: [],
  };
  backLabel = 'products';

  componentDidMount() {
    const { toggleAlert, user, history } = this.props;

    if ( history.location.pathname.includes( 'price' ) ) {
      this.backLabel = 'price';
    } else if ( history.location.pathname.includes( 'distribution' ) ) {
      this.backLabel = 'distribution';
    }

    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.activeSession = user.activeSession;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }

    this.getData();
  }

  componentWillUnmount() {
    if ( this.chart ) {
      this.chart.dispose();
    }
  }

  getData = () => {
    const { toggleAlert, toggleLoading, match } = this.props;

    toggleLoading( true );
    async.parallel( {
      category: ( cb ) => {
        CategoryService.getCategory( match.params.id )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
      },
      attributes: ( cb ) => {
        AttributeService.getAttributes( {
          'filters[session]': this.activeSession.id,
          workgroup: this.license.workGroup.id,
          category: match.params.id,
        } )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
      },
      defaultValues: ( cb ) => {
        AttributeService.getAttributes( {
          'filters[session]': this.activeSession.id,
          workgroup: this.license.workGroup.id,
          category: match.params.id,
        } )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.default );
          } );
      },
      chartData: ( cb ) => {
        CategoryService.getProducts(
          this.activeSession.id,
          match.params.id,
          { workgroup: this.license.workGroup.id },
        )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
      },
    }, ( error, results ) => {
      toggleLoading( false );
      if ( error ) return toggleAlert( error );

      this.attributesChart = [];
      if ( results.chartData.length ) {
        _.map( results.chartData[0].attributes, ( attribute ) => {
          const item = { attribute: attribute.label, id: attribute.id };
          _.map( results.chartData, ( product ) => {
            item[product.id] = _.find( product.attributes, { id: attribute.id } ).value;
          } );
          item.NEW = 0;
          this.attributesChart.push( item );
        } );
        this.chartData = _.cloneDeep( results.chartData );
        this.chartData.push( { id: 'NEW', name: 'NEW' } );
        this.renderGraph( this.attributesChart, this.chartData );
      } else if ( results.attributes.length ) {
        _.map( results.attributes, ( attribute ) => {
          const item = { attribute: attribute.label, id: attribute.id };
          item.NEW = 0;
          this.attributesChart.push( item );
        } );
        this.chartData = [];
        this.chartData.push( { id: 'NEW', name: 'NEW' } );
        this.renderGraph( this.attributesChart, this.chartData );
      }

      this.setState( {
        defaultValues: results.defaultValues,
        category: results.category,
        attributes: _.chain( results.attributes )
          .orderBy( 'orderAttribute' )
          .map( item => ( {
            ...item,
            maxValueArray: _.map(
              new Array( item.maxValue ), ( value, index ) => ( { id: index + 1 } ),
            ),
          } ) )
          .value(),
      } );
    } );
  };

  renderGraph = ( attributes, chartData ) => {
    if ( this.chart ) {
      this.chart.dispose();
    }
    const config = {
      data: attributes,
      type: am4charts.RadarChart,
      xAxes: [{
        type: 'CategoryAxis',
        dataFields: { category: 'attribute' },
      }],
      cursor: { type: 'RadarCursor' },
      legend: { type: 'Legend' },
      yAxes: [{ type: 'ValueAxis' }],
      series: _.map( chartData, product => ( {
        type: 'RadarSeries',
        dataFields: {
          valueY: product.id,
          categoryX: 'attribute',
        },
        strokeWidth: 3,
        tooltipText: '{valueY}',
        name: product.name,
        fill: product.id !== 'NEW' ? am4core.color( '#A8A6A6' ) : am4core.color( '#00a89e' ),
        stroke: product.id !== 'NEW' ? am4core.color( '#A8A6A6' ) : am4core.color( '#00a89e' ),
        bullets: [{ type: 'CircleBullet' }],
      } ) ),
    };
    this.chart = am4core.createFromConfig( config, 'chartdiv' );
  };

  calcAttribute = ( id, value, previousValue, index, formData, sizeValue ) => {
    const { attributes } = this.state;
    const { toggleLoading } = this.props;

    toggleLoading( true );

    async.waterfall( [
      cb => this.getAttributeCost( id, value, previousValue, index, formData, cb, sizeValue ),
      ( cb ) => {
        const current = _.find( attributes, { id } );
        const weight = _.find( attributes, { label: 'Weight' } );
        const Size = _.find( attributes, { label: 'Size' } );
        const { newWeight } = this.state;
        if ( weight ) {
          let weightValue;
          if ( newWeight !== '' ) {
            weightValue = newWeight;
          } else {
            weightValue = weight.value;
          }
          const weightIndex = _.findIndex( attributes, { id: weight.id } );

          let currentSizeValue = sizeValue;
          if ( Size.value ) {
            currentSizeValue = Size.value;
          }
          this.getAttributeCost(
            weight.id, weightValue, weight.value, weightIndex, formData, cb, currentSizeValue,
          );
          const findIndexWeight = _.findIndex( this.attributesChart, { attribute: 'Weight' } );
          if ( findIndexWeight !== -1 ) this.attributesChart[findIndexWeight].NEW = weightValue;
        }
        const findIndex = _.findIndex( this.attributesChart, { attribute: current.label } );
        if ( findIndex !== -1 ) {
          this.attributesChart[findIndex].NEW = value;
          this.renderGraph( this.attributesChart, this.chartData );
        }
      },
    ], () => {
      toggleLoading( false );
    } );
  };

  getAttributeCost = ( id, value, previousValue, index, formData, cb, sizeValue ) => {
    const { category, attributes, defaultValues } = this.state;

    const params = {
      category: category.id,
      workgroup: this.license.workGroup.id,
      attribute: id,
      value,
    };
    if ( sizeValue > 0 ) {
      params.size = sizeValue;
    }
    AttributeCostService.getAttributeCostV2( this.activeSession.id, params )
      .then( ( response ) => {
        if ( !response.ok ) {
          const newAttributes = [...attributes];
          newAttributes[index].value = previousValue;
          this.setState( {
            attributeError: response.errors,
            attributeIdError: id,
            attributes: newAttributes,
            formData,
          }, () => cb( true ) );
        } else {
          const newAttributes = [...attributes];
          newAttributes[index].cost = response.data.cost;
          newAttributes[index].costIPlusD = response.data.costIPlusD;
          newAttributes[index].value = value;
          newAttributes[index].requiredTime = response.data.requiredTime;

          this.setState( {
            attributeError: null,
            attributeIdError: null,
            attributes: newAttributes,
            newWeight: response.data.weight,
            formData: {
              ...formData,
              developCost: Util.formatCurrency( _.sumBy( newAttributes, 'costIPlusD' ) === 0 ? defaultValues.fixedCostMoney : _.sumBy( newAttributes, 'costIPlusD' ) ),
              unitProductionCost: Util.formatCurrency( _.sumBy( newAttributes, 'cost' ) ),
              developTime: _.sumBy( newAttributes, 'requiredTime' ) === 0 ? defaultValues.fixedCostTime : _.sumBy( newAttributes, 'requiredTime' ),
            },
          }, cb );
        }
      } );
  };

  submitForm = ( data ) => {
    const { toggleAlert, toggleLoading, history } = this.props;
    const { category } = this.state;

    if ( _.find( data.variables, item => !item.value ) ) {
      return toggleAlert( 'fillAttributesValue', 'danger', true );
    }
    toggleLoading( true );
    const dataToSend = {
      workgroup: this.license.workGroup.id,
      category: category.id,
      name: data.formData.name,
      description: data.formData.description,
      attributeData: _.map( data.attributes, attribute => ( {
        attribute: attribute.id,
        value: attribute.value,
      } ) ),
    };

    ProductService.saveProductV2( this.activeSession.id, dataToSend )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) return toggleAlert( response.errors );

        toggleAlert( 'dataSaved', 'info' );
        history.push( `/sessions/general/${this.backLabel}` );
      } );
  };

  findLabelAttr = ( categoryId, label, key ) => {
    const values = _.find( attributesDetail, { category: categoryId, label } );
    if ( values ) {
      const objt = _.find( values.values, { key } );
      if ( objt ) return `${objt.text} (${objt.val})`;
    }
    return '.';
  };

  render() {
    const { category, attributes, attributeIdError, attributeError, formData } = this.state;

    return (
      <Form
        initialValues={{
          formData,
          attributes,
        }}
        validate={( values ) => {
          const errors = {};
          _.map( values.attributes, ( attribute ) => {
            if ( !errors.attributes ) errors.attributes = {};
            if ( !attribute.value ) errors.attributes[attribute.id] = required( attribute.value );
          } );
          return errors;
        }}
        onSubmit={this.submitForm}
        mutators={{ ...arrayMutators }}
        render={( { handleSubmit, values, submitFailed, errors } ) => (
          <form onSubmit={handleSubmit}>
            <BreadcrumbComp links={[
              {
                url: '/',
                name: 'decisionDashboard',
              },
              {
                url: `/sessions/general/${this.backLabel}`,
                name: this.backLabel,
              },
              { name: 'newProduct' },
            ]}
            />

            <Row>
              <Col md="auto" className="text-center">
                <h4 className="text-uppercase font-weight-bold mb-2">{category.name}</h4>
                <CategoryAvatar category={category} />
              </Col>

              <Col md={6}>

                <Row>
                  <Col md={6}>
                    <TextField
                      field="formData.name"
                      label="productName"
                      validate={required}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <TextField
                      field="formData.description"
                      label="productDescription"
                      validate={required}
                    />
                  </Col>
                </Row>

                <FieldArray name="attributes">
                  {( { fields } ) => (
                    fields.map( ( attributeField, index ) => {
                      const attribute = attributes[index];
                      return (
                        <Row key={attribute.id} className="align-items-center mb-2">
                          <Col md={2} className="text-md-left font-weight-bold">
                            <i
                              className={`fa mr-2 fa-${attribute.description || 'cog'} text-${COLORS[index % COLORS.length]}`}
                            />
                            {attribute.label}
                          </Col>
                          <Col md="auto">
                            <div>
                              {_.map( attribute.maxValueArray, item => (
                                <Button
                                  className={classnames( 'jr-btn jr-btn-sm bg-gray-3 ml-0 sizecustom', {
                                    'bg-product-laptop': category.label === 'CATEGORY_1' && attribute.value === item.id,
                                    'bg-product-tablet': category.label === 'CATEGORY_2' && attribute.value === item.id,
                                    'bg-product-cell': category.label === 'CATEGORY_3' && attribute.value === item.id,
                                  } )}
                                  key={item.id}
                                  onClick={() => {
                                    // if ( attribute.label === 'Weight' ) return;
                                    let sizeValue = fields.value[1].value;
                                    if ( attribute.label !== 'Weight' ) sizeValue = -1;
                                    this.calcAttribute(
                                      attribute.id, item.id, fields.value[index].value,
                                      index, values.formData, sizeValue,
                                    );
                                  }}
                                >
                                  {this.findLabelAttr( category.label, attribute.label, item.id )}
                                </Button>
                              ) )}
                            </div>
                            {submitFailed && errors.attributes && errors.attributes[attribute.id]
                            && attributeIdError !== attribute.id
                              ? (
                                <FormHelperText className="text-danger">
                                  <IntlMessages id={errors.attributes[attribute.id]} />
                                </FormHelperText>
                              ) : null
                            }
                            {attributeIdError === attribute.id
                            && (
                              <FormHelperText className="text-danger">
                                {attributeError}
                              </FormHelperText>
                            )}
                          </Col>
                        </Row>
                      );
                    } ) )}
                </FieldArray>

                <p />

                <CardBox
                  heading="productionResults"
                  headerBg={classnames( {
                    'bg-product-laptop': category.label === 'CATEGORY_1',
                    'bg-product-tablet': category.label === 'CATEGORY_2',
                    'bg-product-cell': category.label === 'CATEGORY_3',
                  } )}
                >
                  <div className="d-flex align-items-center mb-4">
                    <Col lg={6}>
                      <IntlMessages id="estimatedTimeToDevelop" />
                      {' ( '}
                      <IntlMessages id="months" />
                      {' )'}
                    </Col>
                    <Col lg={6} md={3}>
                      <TextField
                        allowZero
                        field="formData.developTime"
                        disabled
                        inputProps={{ style: { textAlign: 'right' } }}
                        marginContainer={false}
                      />
                    </Col>

                  </div>
                  <div className="d-flex align-items-center mb-4">
                    <Col lg={6}><IntlMessages id="estimatedCostToDevelop" /></Col>
                    <Col lg={6} md={3}>
                      <TextField
                        field="formData.developCost"
                        disabled
                        inputProps={{ style: { textAlign: 'right' } }}
                        marginContainer={false}
                      />
                    </Col>
                  </div>
                  <div className="d-flex align-items-center">
                    <Col lg={6}><IntlMessages id="estimatedUnitProductionCost" /></Col>
                    <Col lg={6} md={3}>
                      <TextField
                        field="formData.unitProductionCost"
                        disabled
                        inputProps={{ style: { textAlign: 'right' } }}
                        marginContainer={false}
                      />
                    </Col>
                  </div>
                </CardBox>
              </Col>

              <Col lg className="pr-0 text-right">
                <Row>
                  <Col>
                    <div>
                      <ButtonsNP cancelTo={`/sessions/general/${this.backLabel}`} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      id="chartdiv"
                      style={{
                        width: '100%',
                        height: '400px',
                      }}
                    />
                  </Col>
                </Row>

              </Col>
            </Row>
          </form>
        )}
      />
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( NewProduct );
