import React, { Component } from 'react';
import MomentUtils from '@date-io/moment';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Redirect, Route, Switch } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import _ from 'lodash';
import SweetAlert from 'react-bootstrap-sweetalert';
import { NotificationContainer, NotificationManager } from 'react-notifications';

import 'assets/vendors/style';
import 'react-table/react-table.css';

import MainApp from 'routes/App';
import Login from 'routes/Login';
import ForgotPassword from 'routes/ForgotPassword';
import RecoverPassword from 'routes/RecoverPassword';
import SignUp from 'routes/SignUp';
import SignUp2 from 'routes/SignUp2';
import Error404 from 'components/theme/Error404';
import RTL from 'components/theme/RTL';
import defaultTheme from 'constants/themes/defaultTheme';
import AppLocale from 'lngProvider';
import IntlMessages from 'utils/IntlMessages';
import SettingsActions from 'store/reducers/Settings';

import * as am4core from '@amcharts/amcharts4/core';
// eslint-disable-next-line camelcase
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme( am4themes_animated );

class App extends Component {
  componentDidUpdate( prevProps ) {
    const { alert, toggleAlert } = this.props;
    if ( alert && alert.msg && alert.date !== _.get( prevProps, 'alert.date' ) ) {
      switch ( alert.alertType ) {
      case 'info':
        return NotificationManager.success( <IntlMessages id={alert.msg} />, null, 10000, () => {
          toggleAlert( null );
        } );
      default:
        return NotificationManager.error( alert.translate
          ? <IntlMessages id={alert.msg} /> : alert.msg, null, 10000, () => {
          toggleAlert( null );
        } );
      }
    }
  }

  render() {
    const {
      user, isLoading, location, locale, isDirectionRTL,
      alertNoSessionOpened, closeNoSessionAlert,
    } = this.props;
    if ( ( !user || !user.access_token ) && ( location.pathname !== '/login'
      && location.pathname !== '/forgot-password'
      && !_.startsWith( location.pathname, '/recover-password' )
      && !_.startsWith( location.pathname, '/sign-up' )
    ) ) {
      return ( <Redirect to="/login" /> );
    }

    const applyTheme = createMuiTheme( defaultTheme );

    if ( isDirectionRTL ) {
      applyTheme.direction = 'rtl';
      document.body.classList.add( 'rtl' );
    } else {
      document.body.classList.remove( 'rtl' );
      applyTheme.direction = 'ltr';
    }

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <RTL>
              <div className="app-main">
                <Switch>
                  <Route path="/login" component={Login} />
                  <Route path="/forgot-password" component={ForgotPassword} />
                  <Route path="/recover-password/:token" component={RecoverPassword} />
                  <Route path="/sign-up/:token" component={SignUp} />
                  <Route path="/sign-up2/:token" component={SignUp2} />
                  <Route path="/" component={MainApp} />
                  <Route component={Error404} />
                </Switch>

                {isLoading
                && (
                  <div className="app-loading">
                    <CircularProgress size={50} thickness={5} />
                  </div>
                )}

                <NotificationContainer />

                <SweetAlert
                  show={!!alertNoSessionOpened}
                  warning
                  confirmBtnText={<IntlMessages id="reload" />}
                  confirmBtnBsStyle="danger"
                  title={<IntlMessages id="noSessionReload" />}
                  onConfirm={() => {
                    closeNoSessionAlert();
                    if ( _.get( user, 'activeSession.id' ) ) {
                      window.location.href = `/sessions/${user.activeSession.id}`;
                    } else {
                      window.location.reload();
                    }
                  }}
                  onCancel={() => {}}
                />
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ( { settings, user } ) => {
  const { sideNavColor, locale, isDirectionRTL, isLoading, alert, alertNoSessionOpened } = settings;
  return { sideNavColor, locale, isDirectionRTL, isLoading, alert, user, alertNoSessionOpened };
};

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  closeNoSessionAlert: SettingsActions.closeNoSessionAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( App );
