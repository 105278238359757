import api from '../config/Api';

const endpoint = 'v1/session';
const endpointV2 = 'v2/session';

export default {

  getAttributeCost( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/product/attribute-cost`, params );
  },

  getAttributeCostV2( sessionId, params ) {
    return api.get( `${endpointV2}/${sessionId}/product/attribute-cost`, params );
  },

  getAttributeMaxValue( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/product/attributes-max-value`, params );
  },
};
