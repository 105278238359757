import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import IntlHtmlMessages from 'utils/IntlHtmlMessages';

const useStyles = makeStyles( theme => ( { close: { padding: theme.spacing( 0.5 ) } } ) );

export default function HelpSnackBar( { message } ) {
  const [state, setState] = React.useState( {
    open: true,
    vertical: 'bottom',
    horizontal: 'center',
  } );

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState( { ...state, open: false } );
  };
  const classes = useStyles();
  return (
    <div>
      <Snackbar
        style={{ bottom: 0 }}
        ContentProps={{ className: 'bg-white text-black' }}
        root="bg-blue-grey text-white"
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={<IntlHtmlMessages id={message} />}
        key={vertical + horizontal}
        action={(
          <React.Fragment>

            <IconButton
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        )}
      />
    </div>
  );
}
