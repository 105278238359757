import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import async from 'async';
import { Row, Col, Badge } from 'reactstrap';
import classnames from 'classnames';
// import Button from '@material-ui/core/Button';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import IntlMessages from 'utils/IntlMessages';
import ProductService from 'services/Product';
import CategoryService from 'services/Category';
import SettingsActions from 'store/reducers/Settings';
import Util from 'utils/Util';
import BreadcrumbComp from 'components/custom_v2/Breadcrumb';
import CategoryAvatar from 'components/custom_v2/CategoryAvatar';
import CategoryProductBtn from 'components/custom_v2/CategoryProductBtn';
import { TextField, Switch } from 'components/custom/FormElements';
import ConfirmationModal from 'components/custom/ConfirmationModal';
import Help from 'routes/App/components/User/Help';
import HelpSnackBar from 'routes/App/components/User/HelpSnackBar';

import ProductionService from 'services/Production';
import {
  composeValidators,
  greaterOrEqualValue,
  required,
} from 'config/InputErrors';

const COLORS = ['danger', 'purple', 'blue', 'warning', 'sepia'];

class Products extends React.Component {
  state = {
    categories: [],
    openedProducts: [],
    activeFilter: true,
    confirmSaveModal: false,
    confirmDeactivateModal: false,
  };

  componentWillMount() {
    document.removeEventListener( 'keydown', this.handleKeyPress );
  }

  componentDidMount() {
    const { toggleAlert, user } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.activeSession = user.activeSession;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }

    this.getData();
    document.addEventListener( 'keydown', e => this.handleKeyPress( e ), true );
  }

  handleKeyPress = ( event ) => {
    if ( event.key === 'Enter' ) {
      if ( event.target.type === 'button' ) {
        event.target.click();
      } else {
        event.preventDefault();
        this.setState( { confirmSaveModal: true } );
      }
    }
  }

  getData = () => {
    const { toggleAlert, toggleLoading } = this.props;
    const { activeFilter } = this.state;

    toggleLoading( true );

    async.parallel( {
      categories: ( cb ) => {
        CategoryService.getCategories( { 'filters[session]': this.activeSession.id } )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
      },
      products: ( cb ) => {
        ProductService.getProducts( this.activeSession.id, {
          workgroup: this.license.workGroup.id,
          'filters[inStatus]': activeFilter ? ['READY', 'RD'] : 'CANCELED',
        } ).then( ( response ) => {
          if ( !response.ok ) return cb( response.errors );
          cb( null, response.data.data );
        } );
      },
    }, ( error, results ) => {
      toggleLoading( false );
      if ( error ) toggleAlert( error );

      const categories = _.map( results.categories, category => ( {
        ...category,
        products: activeFilter ? [
          ..._.filter( results.products, { category: { id: category.id }, status: 'ACTIVE' } ),
          ..._.filter( results.products, { category: { id: category.id }, status: 'READY' } ),
          ..._.filter( results.products, { category: { id: category.id }, status: 'RD' } ),
        ] : _.filter( results.products, { category: { id: category.id }, status: 'CANCELED' } ),
      } ) );
      this.setState( { categories } );
    } );
  };

  submitForm = ( formData ) => {
    const { toggleAlert, toggleLoading } = this.props;

    const productions = [];
    _.map( formData.categories, ( category ) => {
      _.map( category.products, ( product ) => {
        if ( product.status === 'RD' && !product.active ) return;
        const data = {
          product: product.id,
          active: product.active,
          production: product.active ? product.production : 0,
        };
        productions.push( data );
      } );
    } );

    toggleLoading( true );
    const dataToSend = {
      workgroup: this.license.workGroup.id,
      productions,
    };
    ProductionService.updateProductionV2( this.activeSession.id, dataToSend )
      .then( ( response ) => {
        if ( !response.ok ) {
          toggleLoading( false );
          return toggleAlert( response.errors );
        }

        toggleAlert( 'dataSaved', 'info' );
        this.getData();
      } );
  };

  toggleConfirmSaveModal = () => {
    const { confirmSaveModal } = this.state;
    this.setState( { confirmSaveModal: !confirmSaveModal } );
  };

  toggleConfirmDeactivateModal = () => {
    const { confirmDeactivateModal } = this.state;
    this.setState( { confirmDeactivateModal: !confirmDeactivateModal } );
  };

  toggleProduct = ( id ) => {
    const { openedProducts } = this.state;
    const newOpenedProducts = [...openedProducts];
    const index = newOpenedProducts.indexOf( id );
    if ( index !== -1 ) {
      newOpenedProducts.splice( index, 1 );
    } else {
      newOpenedProducts.push( id );
    }
    this.setState( { openedProducts: newOpenedProducts } );
  };

  render() {
    const { history } = this.props;
    const {
      categories, activeFilter, confirmSaveModal,
      openedProducts, confirmDeactivateModal,
    } = this.state;

    return (
      <Form
        initialValues={{ categories }}
        onSubmit={this.submitForm}
        mutators={{ ...arrayMutators }}
        render={( { handleSubmit, values, form } ) => (
          <form onSubmit={handleSubmit}>
            <Row className="mb-2 align-items-center">
              <Col>
                <BreadcrumbComp links={[
                  {
                    url: '/',
                    name: 'decisionDashboard',
                  },
                  { name: 'products' },
                ]}
                />
              </Col>
              <Col md={4} className="d-flex justify-content-end align-items-center">
                <Help
                  title="help.product.title"
                  content="help.product.content"
                  multimedia="help.product.multimedia"
                />

                <Switch
                  label="Showactive"
                  checked={activeFilter}
                  onChange={checked => this.setState( { activeFilter: checked }, this.getData )}
                  marginContainer={false}
                />

              </Col>
            </Row>

            <FieldArray name="categories">
              {data => (
                data.fields.map( ( itemField, itemIndex ) => {
                  const item = data.fields.value[itemIndex];
                  return (
                    <Row key={item.id} className="mb-4">
                      <Col md="auto" className="text-center">
                        <h4 className="text-uppercase font-weight-bold">{item.name}</h4>
                        <CategoryAvatar category={item} />
                        <CategoryProductBtn
                          category={item}
                          onClick={() => history.push( `/sessions/general/products/${item.id}/new` )}
                        />
                      </Col>

                      <Col md>
                        <div className="table-responsive">
                          <table
                            className={classnames( 'table table-striped table-products mb-0 table-header', {
                              'table-products-laptop': item.label === 'CATEGORY_1',
                              'table-products-tablet': item.label === 'CATEGORY_2',
                              'table-products-cell': item.label === 'CATEGORY_3',
                            } )}
                          >
                            <thead>
                              <tr>
                                <th style={{ width: 100 }}><IntlMessages id="model" /></th>
                                <th><IntlMessages id="stock" /></th>
                                <th><IntlMessages id="unitCost" /></th>
                                <th><IntlMessages id="price" /></th>
                                <th style={{ width: 120 }}>
                                  <div><IntlMessages id="lastMonthSalesDollar" /></div>
                                </th>
                                <th style={{ width: 120 }}>
                                  <div><IntlMessages id="lastMonthSalesUnits" /></div>
                                </th>
                                <th><IntlMessages id="status" /></th>
                                <th><IntlMessages id="Activateproduct" /></th>
                                <th style={{ width: 120 }}><IntlMessages id="production" /></th>
                                <th>
                                  <div><IntlMessages id="productionCost" /></div>
                                </th>
                                <th>
                                  <div><IntlMessages id="ProductDetails" /></div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <FieldArray name={`${itemField}.products`}>
                                {productsData => (
                                  productsData.fields.map( ( productField, productIndex ) => {
                                    const product = categories[itemIndex].products[productIndex];
                                    if ( !product ) return null;
                                    product.attributes = _.orderBy( product.attributes, ['order'], ['asc'] );
                                    const openedProduct = openedProducts.indexOf(
                                      product.id,
                                    ) !== -1;
                                    return ( [
                                      <tr key={product.id}>
                                        <td>
                                          {product.name}
                                        </td>
                                        <td>
                                          {Util.formatNumber( product.stock )}
                                        </td>
                                        <td>
                                          {Util.formatCurrency( product.unitCost )}
                                        </td>
                                        <td>
                                          {Util.formatCurrency( product.price )}
                                        </td>
                                        <td>
                                          {Util.formatNumber( product.lastRoundMoneySales )}
                                        </td>
                                        <td>
                                          {Util.formatNumber( product.lastRoundUnitSales )}
                                        </td>
                                        <td>
                                          {product.status === 'READY' && product.active
                                          && <Badge color="product-active">ACTIVE</Badge>}
                                          {product.status === 'READY' && !product.active
                                          && <Badge color="product-ready">READY</Badge>}
                                          {product.status === 'CANCELED'
                                          && <Badge color="product-canceled">CANCELLED</Badge>}
                                          {product.status === 'RD' && !product.active
                                          && <Badge color="product-rd">R+D</Badge>}
                                        </td>
                                        <td>
                                          <Switch
                                            field={`${productField}.active`}
                                            marginContainer={false}
                                            disabled={product.status === 'CANCELED' || ( product.status === 'RD' && !product.active )}
                                            onChange={( checked ) => {
                                              this.productToDeactivate = productField;
                                              if ( !checked ) this.toggleConfirmDeactivateModal();
                                              this.setState( { confirmSaveModal: true } );
                                            }}
                                          />
                                        </td>
                                        <td>
                                          {product.status === 'CANCELED' || ( product.status === 'RD' && !product.active )
                                            ? Util.formatNumber( product.production )
                                            : (
                                              <TextField
                                                field={`${productField}.production`}
                                                marginContainer={false}
                                                type="number"
                                                allowZero
                                                inputProps={{ className: 'text-right' }}
                                                validate={composeValidators(
                                                  required,
                                                  greaterOrEqualValue( 0 ),
                                                )}
                                                translateValues={{ value: 0 }}
                                              />
                                            )}
                                        </td>
                                        <td>
                                          {Util.formatCurrency( _.get( productsData.fields, `value[${productIndex}].production`, 0 ) * product.unitCost )}
                                        </td>
                                        <td>
                                          <i
                                            className={classnames( 'fa text-primary p-3', {
                                              'fa-chevron-down': !openedProduct,
                                              'fa-chevron-up': openedProduct,
                                            } )}
                                            onClick={() => this.toggleProduct( product.id )}
                                          />
                                        </td>
                                      </tr>,

                                      openedProduct
                                        ? (
                                          <tr key={`${product.id}-detail`} className="bg-white">
                                            {_.map( product.attributes, ( attribute, index ) => (
                                              <td colSpan={2} key={attribute.label}>
                                                <i className={`fa mr-2 fa-${attribute.description || 'cog'} text-${COLORS[index % COLORS.length]}`} />
                                                <span>{attribute.label}</span>
                                                <Badge color="primary" className="ml-2">{attribute.valueTextDesc}</Badge>
                                              </td>
                                            ) )}
                                            <td />
                                          </tr>
                                        ) : null,
                                    ] );
                                  } ) )}
                              </FieldArray>

                              {itemIndex === categories.length - 1
                              && (
                                <tr>
                                  <td colSpan={9} />
                                  <td className="font-weight-bold">
                                    {Util.formatCurrency(
                                      _.chain( values.categories ).map( 'products' ).flatten()
                                        .sumBy( prod => prod.unitCost * prod.production )
                                        .value(),
                                    )}
                                  </td>
                                  <td colSpan={2} />
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </Row>
                  );
                } ) )}
            </FieldArray>
            <HelpSnackBar message="help.product.snackbar" />
            <ConfirmationModal
              open={confirmSaveModal}
              title="confirmSaveProductProduction"
              confirmBtnText="yesModalBtn"
              onSubmit={() => {
                this.toggleConfirmSaveModal();
                handleSubmit();
              }}
              onClose={this.toggleConfirmSaveModal}
            />

            <ConfirmationModal
              open={confirmDeactivateModal}
              title="confirmDeactivateProduct"
              confirmBtnText="ok"
              showCancel
              closeOnClickOutside={false}
              onSubmit={this.toggleConfirmDeactivateModal}
              onClose={() => {
                form.change( `${this.productToDeactivate}.active`, true );
                this.toggleConfirmDeactivateModal();
              }}
            />
          </form>
        )}
      />
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( Products );
