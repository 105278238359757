import api from '../config/Api';

const endpoint = 'v1/attribute-cost-cem';

export default {

  getAttributeCostCem( attributeId, params ) {
    return api.get( `${endpoint}/${attributeId}`, params );
  },

  updateAttributeCostCem( id, data ) {
    return api.put( `${endpoint}/${id}`, data );
  },
};
