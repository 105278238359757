import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Row, Col, Badge } from 'reactstrap';
import Button from '@material-ui/core/Button';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Avatar } from '@material-ui/core';
import async from 'async';
import { Link } from 'react-router-dom';

import IntlMessages from 'utils/IntlMessages';
import ServiceService from 'services/Service';
import BundleService from 'services/Bundle';
import SettingsActions from 'store/reducers/Settings';
import Util from 'utils/Util';
import BreadcrumbComp from 'components/custom_v2/Breadcrumb';
import { TextField, Switch } from 'components/custom/FormElements';
import ConfirmationModal from 'components/custom/ConfirmationModal';
import {
  composeValidators,
  greaterOrEqualValue,
  required,
} from 'config/InputErrors';

import film from 'assets/images/mk/film.png';
import music from 'assets/images/mk/music.png';
import news from 'assets/images/mk/news.png';

import Help from 'routes/App/components/User/Help';
import HelpSnackBar from 'routes/App/components/User/HelpSnackBar';

class Service extends React.Component {
  state = {
    services: [],
    bundles: [],
    confirmSaveModal: false,
    confirmSaveBundleModal: false,
  };

  componentDidMount() {
    const { toggleAlert, user } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.activeSession = user.activeSession;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }

    this.getData();
    document.addEventListener( 'keydown', e => this.handleKeyPress( e ), true );
  }

  handleKeyPress = ( event ) => {
    if ( event.key === 'Enter' ) {
      const fieldName = event.target.name;

      if ( event.target.type === 'button' ) {
        event.target.click();
      } else {
        event.preventDefault();
        if ( fieldName.search( 'bundles' ) >= 0 ) { // detects if the field belongs BundleForm
          this.setState( { confirmSaveBundleModal: true } );
        }
        if ( fieldName.search( 'services' ) >= 0 ) { // detects if the field belongs ServiceForm
          this.setState( { confirmSaveModal: true } );
        }
      }
    }
  }


  getData = () => {
    const { toggleAlert, toggleLoading } = this.props;

    toggleLoading( true );
    async.parallel( {
      services: ( cb ) => {
        ServiceService.getServicesV2( this.activeSession.id,
          { workgroup: this.license.workGroup.id } )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
      },
      bundles: ( cb ) => {
        BundleService.getBundlesV2( this.activeSession.id,
          { workgroup: this.license.workGroup.id } )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
      },
    }, ( error, results ) => {
      toggleLoading( false );
      if ( error ) return toggleAlert( error );
      this.setState( results );
    } );
  };

  submitServicesForm = ( formData ) => {
    const { toggleAlert, toggleLoading } = this.props;

    const priceStatus = [];
    _.map( formData.services, ( service ) => {
      const data = {
        service: service.id,
        active: service.active,
        price: service.price,
      };
      priceStatus.push( data );
    } );

    toggleLoading( true );
    const dataToSend = {
      workgroup: this.license.workGroup.id,
      priceStatus,
    };
    ServiceService.updateServicesV2( this.activeSession.id, dataToSend )
      .then( ( response ) => {
        if ( !response.ok ) {
          toggleLoading( false );
          return toggleAlert( response.errors );
        }

        toggleAlert( 'dataSaved', 'info' );
        this.getData();
      } );
  };

  submitBundlesForm = ( formData ) => {
    const { toggleAlert, toggleLoading } = this.props;

    const priceStatus = [];
    _.map( formData.bundles, ( bundle ) => {
      const data = {
        bundle: bundle.id,
        active: bundle.active,
        price: bundle.price,
        monthlyFee: bundle.monthlyFee,
      };
      priceStatus.push( data );
    } );

    toggleLoading( true );
    const dataToSend = {
      workgroup: this.license.workGroup.id,
      priceStatus,
    };
    BundleService.updateBundlesV2( this.activeSession.id, dataToSend )
      .then( ( response ) => {
        if ( !response.ok ) {
          toggleLoading( false );
          return toggleAlert( response.errors );
        }

        toggleAlert( 'dataSaved', 'info' );
        this.getData();
      } );
  };

  toggleConfirmSaveModal = () => {
    const { confirmSaveModal } = this.state;
    this.setState( { confirmSaveModal: !confirmSaveModal } );
  };

  toggleConfirmSaveBundleModal = () => {
    const { confirmSaveBundleModal } = this.state;
    this.setState( { confirmSaveBundleModal: !confirmSaveBundleModal } );
  };

  render() {
    const { services, bundles, confirmSaveModal, confirmSaveBundleModal } = this.state;

    return (
      <>
        <Row className="mb-2 align-items-center">
          <Col>
            <BreadcrumbComp links={[
              {
                url: '/',
                name: 'decisionDashboard',
              },
              { name: 'services' },
            ]}
            />
          </Col>
          <Col md="auto">
            <Help
              title="help.service.title"
              content="help.service.content"
              multimedia="help.service.multimedia"
            />
          </Col>
        </Row>

        <Form
          initialValues={{ services }}
          onSubmit={this.submitServicesForm}
          mutators={{ ...arrayMutators }}
          render={( { handleSubmit } ) => (
            <form onSubmit={handleSubmit}>
              <Row className="mb-2 align-items-center">
                <Col>
                  <h4 className="m-0"><IntlMessages id="services" /></h4>
                </Col>
                <Col md={4} className="text-right">
                  <Button
                    variant="contained"
                    type="button"
                    color="primary"
                    className="jr-btn jr-btn-lg text-white"
                    component={Link}
                    to="/sessions/general/services/new-bundle"
                  >
                    <i className="fa fa-plus" />
                    <IntlMessages id="newBundle" />
                  </Button>

                </Col>
              </Row>
              <div className="table-responsive">
                <table className="table table-striped custom-table-v2 mb-0">
                  <thead>
                    <tr>
                      <th>
                        <IntlMessages id="service" />
                      </th>
                      <th><IntlMessages id="unitCostMonth" /></th>
                      <th style={{ width: 120 }}><IntlMessages id="monthlyFee" /></th>
                      <th>
                        <div><IntlMessages id="lastMonthSalesDollar" /></div>
                      </th>
                      <th>
                        <div><IntlMessages id="lastMonthSalesUnits" /></div>
                      </th>
                      <th><IntlMessages id="status" /></th>
                      <th><IntlMessages id="Activate" /></th>
                    </tr>
                  </thead>
                  <tbody>
                    <FieldArray name="services">
                      {( { fields } ) => (
                        fields.map( ( serviceField, productIndex ) => {
                          const service = services[productIndex];
                          if ( !service ) return null;
                          return ( [
                            <tr key={service.id}>
                              <td className="d-flex align-items-center">
                                {service.serviceType.label === 'SERVICE_1'
                              && (
                                <Avatar className="size-50 border-radius-0" alt="..." src={film} />
                              )}
                                {service.serviceType.label === 'SERVICE_2'
                              && (
                                <Avatar className="size-50 border-radius-0" alt="..." src={news} />
                              )}
                                {service.serviceType.label === 'SERVICE_3'
                              && (
                                <Avatar
                                  className="size-50 border-radius-0"
                                  alt="..."
                                  src={music}
                                />
                              )}
                                <h5
                                  className="text-uppercase font-weight-bold my-0 ml-2"
                                >
                                  {service.name}
                                </h5>
                              </td>
                              <td>
                                {Util.formatCurrency( service.unitCost )}
                              </td>
                              <td>
                                <TextField
                                  field={`${serviceField}.price`}
                                  marginContainer={false}
                                  type="number"
                                  allowZero
                                  inputProps={{ className: 'text-right' }}
                                  validate={composeValidators(
                                    required,
                                    greaterOrEqualValue( 0 ),
                                  )}
                                  translateValues={{ value: 0 }}
                                />
                              </td>
                              <td>
                                {Util.formatCurrency( service.lastRoundSales )}
                              </td>
                              <td>
                                {Util.formatNumber( service.lastRoundUnitSales )}
                              </td>
                              <td>
                                {service.active
                              && <Badge color="product-active">ACTIVE</Badge>}
                                {!service.active
                              && <Badge color="product-ready">INACTIVE</Badge>}
                              </td>
                              <td>
                                <Switch
                                  field={`${serviceField}.active`}
                                  marginContainer={false}
                                  onChange={( ) => {
                                    this.setState( { confirmSaveModal: true } );
                                  }}
                                />
                              </td>
                            </tr>,
                          ] );
                        } ) )}
                    </FieldArray>
                  </tbody>
                </table>
              </div>

              <ConfirmationModal
                open={confirmSaveModal}
                title="confirmSave"
                confirmBtnText="yesModalBtn"
                onSubmit={() => {
                  this.toggleConfirmSaveModal();
                  handleSubmit();
                }}
                onClose={this.toggleConfirmSaveModal}
              />
            </form>
          )}
        />

        <Form
          initialValues={{ bundles }}
          onSubmit={this.submitBundlesForm}
          mutators={{ ...arrayMutators }}
          render={( { handleSubmit } ) => (
            <form onSubmit={handleSubmit} className="mt-4">
              <Row className="mb-2 align-items-center">
                <Col>
                  <h4 className="m-0"><IntlMessages id="bundles" /></h4>
                </Col>
              </Row>
              <div className="table-responsive">
                <table className="table table-striped custom-table-v2 mb-0">
                  <thead>
                    <tr>
                      <th><IntlMessages id="bundle" /></th>
                      <th><IntlMessages id="unitCost" /></th>
                      <th style={{ width: 120 }}><IntlMessages id="price" /></th>
                      <th style={{ width: 120 }}><IntlMessages id="monthlyFee" /></th>
                      <th>
                        <div><IntlMessages id="lastMonthSalesDollar" /></div>
                      </th>
                      <th>
                        <div><IntlMessages id="lastMonthSalesUnits" /></div>
                      </th>
                      <th><IntlMessages id="status" /></th>
                      <th><IntlMessages id="action" /></th>
                    </tr>
                  </thead>
                  <tbody>
                    <FieldArray name="bundles">
                      {( { fields } ) => (
                        fields.map( ( bundleField, bundleIndex ) => {
                          const bundle = bundles[bundleIndex];
                          if ( !bundle ) return null;
                          return ( [
                            <tr key={bundle.id}>
                              <td>
                                <h5 className="text-uppercase font-weight-bold my-0">
                                  {bundle.name}
                                </h5>
                              </td>
                              <td>
                                {Util.formatCurrency( bundle.unitCost )}
                              </td>
                              <td>
                                <TextField
                                  field={`${bundleField}.price`}
                                  marginContainer={false}
                                  type="number"
                                  allowZero
                                  inputProps={{ className: 'text-right' }}
                                  validate={composeValidators(
                                    required,
                                    greaterOrEqualValue( 0 ),
                                  )}
                                  translateValues={{ value: 0 }}
                                />
                              </td>
                              <td>
                                <TextField
                                  field={`${bundleField}.monthlyFee`}
                                  marginContainer={false}
                                  type="number"
                                  allowZero
                                  inputProps={{ className: 'text-right' }}
                                  validate={composeValidators(
                                    required,
                                    greaterOrEqualValue( 0 ),
                                  )}
                                  translateValues={{ value: 0 }}
                                />
                              </td>
                              <td>
                                {Util.formatCurrency( bundle.lastRoundSales )}
                              </td>
                              <td>
                                {Util.formatNumber( bundle.lastRoundUnitSales )}
                              </td>
                              <td>
                                {bundle.active
                              && <Badge color="product-active">ACTIVE</Badge>}
                                {!bundle.active
                              && <Badge color="product-ready">INACTIVE</Badge>}
                              </td>
                              <td>
                                <Switch
                                  field={`${bundleField}.active`}
                                  marginContainer={false}
                                  onChange={( ) => {
                                    this.setState( { confirmSaveBundleModal: true } );
                                  }}
                                />
                              </td>
                            </tr>,
                          ] );
                        } ) )}
                    </FieldArray>
                  </tbody>
                </table>
              </div>
              <HelpSnackBar message="help.service.snackbar" />
              <ConfirmationModal
                open={confirmSaveBundleModal}
                title="confirmSave"
                confirmBtnText="yesModalBtn"
                onSubmit={() => {
                  this.toggleConfirmSaveBundleModal();
                  handleSubmit();
                }}
                onClose={this.toggleConfirmSaveBundleModal}
              />
            </form>
          )}
        />
      </>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( Service );
