import api from '../config/Api';

const endpoint = 'v1/version-variable';

export default {

  getVersions( params ) {
    return api.get( endpoint, params );
  },

  getLastVersion( params ) {
    return api.get( `${endpoint}/last/version`, params );
  },

  saveVersion() {
    return api.post( endpoint );
  },
};
