import api from '../config/Api';

export default {

  loginUser( data ) {
    return api.post( 'oauth/v2/token', {
      ...data,
      grant_type: 'https://marketin21.com/grants/license',
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    } );
  },
};
