import _ from 'lodash';

export default {

  getSearchParams( searchParams, tableParams ) {
    _.forEach( searchParams, ( value, key ) => {
      if ( _.isArray( value ) ? value && value.length : value ) tableParams[`filters[${key}]`] = _.isArray( value ) ? value.join( ',' ) : value; // eslint-disable-line
      else if ( _.get( tableParams, `filters[${key}]` ) ) delete tableParams[`filters[${key}]`]; // eslint-disable-line
    } );

    return tableParams;
  },
};
