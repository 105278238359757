import React from 'react';

import CardBox from 'components/custom_v2/CardBox';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import _ from 'lodash';
import { connect } from 'react-redux';
import DashboardStudentService from 'services/DashboardStudent';
import ChartModal from 'routes/App/components/User/Chart';
import { Row, Col } from 'reactstrap';
import SessionService from 'services/Session';
import Util from 'utils/Util';
import BreadcrumbComp from 'components/custom_v2/Breadcrumb';
import IntlMessages from 'utils/IntlMessages';
import { Button } from '@material-ui/core';
import CompModal from './CompModal';

class CompanyChart extends React.Component {
  state = {
    CompModalB: false,
    chart1Modal: false,
    chart2Modal: false,
    chart3Modal: false,
    cash: 0,
    table1: [],
  };
  seriesTitle1 = [];
  componentDidMount() {
    const { user } = this.props;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    SessionService.getSessionCurrentWorkgroupRound( user.activeSession.id )
      .then( ( response ) => {
        if ( response.ok ) {
          // console.log (  response.data );
          if ( response.data.cash ) {
            this.setState( ( {
              cash: response.data.cash,
              outstandingDebt: response.data.outstandingDebt,
            } ) );
          }
        }
      } );
    DashboardStudentService.getStockValueAllWorkgroup( user.activeSession.id )
      .then( ( response ) => {
        if ( response.ok ) {
          const roundsX = [];
          // const rounds2X = [];
          _.map( response.data, ( item ) => {
            _.map( item.historical, ( round ) => {
              const exist = _.findIndex( roundsX, { id: round.round.id } );
              if ( exist === -1 ) {
                const dataToPush = { attribute: round.round.shortName, id: round.round.id };
                dataToPush[item.id] = round.stockValue;
                roundsX.push( dataToPush );
              } else {
                roundsX[exist][item.id] = round.stockValue;
                // TO CHECK, Math.floor( ( Math.random() * 1000 ) + 1 );
              }
            } );
            this.seriesTitle1.push( item.name );
          } );

          this.config3 = {
            data: roundsX,
            type: am4charts.XYChart,
            xAxes: [{
              type: 'CategoryAxis',
              dataFields: { category: 'attribute' },
            }],
            yAxes: [{ type: 'ValueAxis' }],
            series: _.map( response.data, itemX => ( {
              type: 'LineSeries',
              dataFields: {
                valueY: itemX.id,
                categoryX: 'attribute',
              },
              name: itemX.name,
              strokeWidth: 2,
            } ) ),
          };

          this.chart3 = am4core.createFromConfig( _.cloneDeep( this.config3 ), 'chartStockdiv' );
        }
      } );
    DashboardStudentService.getHistoricalChart( user.activeSession.id,
      this.license.workGroup.id, { limit: -1 } )
      .then( ( response ) => {
        if ( response.ok ) {
          this.config = {
            data: _.map( response.data.historical, item => ( {
              category: item.round.shortName,
              revenues: item.revenues,
            } ) ),
            type: am4charts.XYChart,
            xAxes: [{
              type: 'CategoryAxis',
              dataFields: { category: 'category' },
            }],
            yAxes: [{ type: 'ValueAxis' }],
            series: [
              {
                type: 'LineSeries',
                dataFields: {
                  valueY: 'revenues',
                  categoryX: 'category',
                },
              },
            ],
          };
          this.chart = am4core.createFromConfig( _.cloneDeep( this.config ), 'chartdiv' );

          this.config2 = {
            data: _.map( response.data.historical, item => ( {
              category: item.round.shortName,
              earnings: item.earnings,
            } ) ),
            type: am4charts.XYChart,
            xAxes: [{
              type: 'CategoryAxis',
              dataFields: { category: 'category' },
            }],
            yAxes: [{ type: 'ValueAxis' }],
            series: [
              {
                type: 'LineSeries',
                dataFields: {
                  valueY: 'earnings',
                  categoryX: 'category',
                },
              },
            ],
          };
          this.chart2 = am4core.createFromConfig( _.cloneDeep( this.config2 ), 'chartEarningsdiv' );

          this.setState( ( {
            revenuesFromGoodsSold: response.data.revenuesFromGoodsSold,
            revenuesFromServicesSold: response.data.revenuesFromServicesSold,
            revenuesFromBundlesSold: response.data.revenuesFromBundlesSold,
            totalRevenuesFromSales: response.data.totalRevenuesFromSales,
            costOfGoodsSold: response.data.costOfGoodsSold,
            costOfBundlesSold: response.data.costOfBundlesSold,
            totalCostsFormSales: response.data.totalCostsFormSales,
            generalAdministrative: response.data.generalAdministrative,
            marketingCost: response.data.marketingCost,
            directSalesCost: response.data.directSalesCost,
            stockHandlingCosts: response.data.stockHandlingCosts,
            rd: response.data.rd,
            interestsFromLoans: response.data.interestsFromLoans,
            totalIndirectExpenses: response.data.totalIndirectExpenses,
            totalExpenses: response.data.totalExpenses,
            costOfServicesSold: response.data.costOfServicesSold,
            earningBeforeTaxes: response.data.earningBeforeTaxes,
            incomeTaxes: response.data.incomeTaxes,
            netEarnings: response.data.netEarnings,
            grossProfit: response.data.grossProfit,
            /*
            productSales: response.data.productSales,
            serviceSales: response.data.serviceSales,
            bundleSales: response.data.bundleSales,
            totalOperatingRevenue: response.data.totalOperatingRevenue,
            costOfGoodSold: response.data.costOfGoodsSold,
            inventoryCost: response.data.inventoryCost,
            marketingExpenses: response.data.marketingExpenses,
            productDevelopment: response.data.productDevelopment,
            loanInterests: response.data.loanInterests,
             */
          } ) );

          DashboardStudentService.getPLComparison( user.activeSession.id,
            { limit: -1 } )
            .then( ( responsePL ) => {
              if ( responsePL.ok ) {
                this.setState( { data: responsePL.data } );
              }
            } );
        }
      } );


    SessionService.getSession( user.activeSession.id )
      .then( ( response ) => {
        if ( response.ok ) {
          if ( response.data.workGroups ) {
            const stock = _.map( response.data.workGroups, item => ( {
              id: item.id,
              name: item.name,
              points: item.points,
              percentagePoints: item.percentagePoints,
            } ) );

            stock.sort( ( a, b ) => b.points - a.points );

            this.setState( ( { stock } ) );
          }
        }
      } );
  }
  componentWillUnmount() {
    if ( this.chart ) {
      this.chart.dispose();
    }
    if ( this.chart2 ) {
      this.chart2.dispose();
    }
    if ( this.chart3 ) {
      this.chart3.dispose();
    }
  }

  toggleCompModal = () => {
    this.setState( previousState => ( { CompModalB: !previousState.CompModalB } ) );
  };

  toggleChart1Modal = () => {
    this.setState( previousState => ( { chart1Modal: !previousState.chart1Modal } ) );
  };


  toggleChart2Modal = () => {
    this.setState( previousState => ( { chart2Modal: !previousState.chart2Modal } ) );
  };

  toggleChart3Modal = () => {
    this.setState( previousState => ( { chart3Modal: !previousState.chart3Modal } ) );
  };

  render() {
    const {
      revenuesFromGoodsSold, revenuesFromServicesSold, revenuesFromBundlesSold,
      totalRevenuesFromSales, costOfGoodsSold, totalCostsFormSales, costOfBundlesSold,
      generalAdministrative, marketingCost, directSalesCost,
      stockHandlingCosts, rd, interestsFromLoans, totalExpenses, totalIndirectExpenses,
      CompModalB, chart1Modal, chart2Modal, chart3Modal, cash, outstandingDebt, table1,
      costOfServicesSold, grossProfit, earningBeforeTaxes, incomeTaxes, netEarnings,
      stock, data,
    } = this.state;
    return (
      <>
        <BreadcrumbComp links={[
          {
            url: '/',
            name: 'informationDashboard',
          },
          { name: 'company' },
        ]}
        />
        <Row>
          <Col lg={4}>
            <CardBox heading="ProfitLossStatement" onClick={this.toggleCompModal} headerBg="bg-product-laptop">
              <div className="jr-link" id="ProfitLossStatementdiv" />
              <Row>
                <Col><b><IntlMessages id="Revenues" /></b></Col>
                <Col />
              </Row>
              <Row>
                <Col lg={8}><IntlMessages id="RevenuesFromGoodSold" /></Col>
                <Col lg={4} className="text-right">{Util.formatNumber( revenuesFromGoodsSold )}</Col>
              </Row>
              <Row>
                <Col lg={8}><IntlMessages id="RevenuesFromServ" /></Col>
                <Col lg={4} className="text-right">{Util.formatNumber( revenuesFromServicesSold )}</Col>
              </Row>
              <Row>
                <Col lg={8}><IntlMessages id="RevenuesFromBundlesSales" /></Col>
                <Col lg={4} className="text-right">{Util.formatNumber( revenuesFromBundlesSold )}</Col>
              </Row>
              <Row>
                <Col lg={8} className="bg-gray-3"><IntlMessages id="TotalRevenuesFromSales" /></Col>
                <Col lg={4} className="text-right bg-gray-3">{Util.formatNumber( totalRevenuesFromSales )}</Col>
              </Row>

              <Row>
                <Col><b><IntlMessages id="DirectExpenses" /></b></Col>
                <Col />
              </Row>
              <Row>
                <Col lg={8}><IntlMessages id="CostOfGoodsSold" /></Col>
                <Col lg={4} className="text-right">{Util.formatNumber( costOfGoodsSold )}</Col>
              </Row>
              <Row>
                <Col lg={8}><IntlMessages id="CostOfServicesSold" /></Col>
                <Col lg={4} className="text-right">{Util.formatNumber( costOfServicesSold )}</Col>
              </Row>
              <Row>
                <Col lg={8}><IntlMessages id="CostOfBundlesSold" /></Col>
                <Col lg={4} className="text-right">{Util.formatNumber( costOfBundlesSold )}</Col>
              </Row>

              <Row>
                <Col lg={8} className="bg-gray-3"><IntlMessages id="TotalCostFromSales" /></Col>
                <Col lg={4} className="text-right bg-gray-3">{Util.formatNumber( totalCostsFormSales )}</Col>
              </Row>
              <Row>
                <Col lg={8} className="bg-gray-3" style={{ fontWeight: 'bold', fontSize: '16px' }}><b><IntlMessages id="Grossprofit" /></b></Col>
                <Col lg={4} className="text-right bg-gray-3" style={{ fontWeight: 'bold', fontSize: '16px' }}><b>{Util.formatNumber( grossProfit )}</b></Col>
              </Row>

              <Row>
                <Col><b><IntlMessages id="IndirectExpenses" /></b></Col>
                <Col />
              </Row>
              <Row>
                <Col lg={8}><IntlMessages id="GeneralAdministrative" /></Col>
                <Col lg={4} className="text-right">{Util.formatNumber( generalAdministrative )}</Col>
              </Row>
              <Row>
                <Col lg={8}><IntlMessages id="MarketingCosts" /></Col>
                <Col lg={4} className="text-right">{Util.formatNumber( marketingCost )}</Col>
              </Row>
              <Row>
                <Col lg={8}><IntlMessages id="DirectSalesCosts" /></Col>
                <Col lg={4} className="text-right">{Util.formatNumber( directSalesCost )}</Col>
              </Row>
              <Row>
                <Col lg={8}><IntlMessages id="StockHandlingCosts" /></Col>
                <Col lg={4} className="text-right">{Util.formatNumber( stockHandlingCosts )}</Col>
              </Row>
              <Row>
                <Col lg={8}><IntlMessages id="RandD" /></Col>
                <Col lg={4} className="text-right">{Util.formatNumber( rd )}</Col>
              </Row>

              <Row>
                <Col lg={8}><IntlMessages id="InterestsFromLoans" /></Col>
                <Col lg={4} className="text-right">{Util.formatNumber( interestsFromLoans )}</Col>
              </Row>
              <Row>
                <Col lg={8}><IntlMessages id="TotalIndirectsExpenses" /></Col>
                <Col lg={4} className="text-right">{Util.formatNumber( totalIndirectExpenses )}</Col>
              </Row>

              <Row>
                <Col lg={8} className="bg-gray-3"><b><IntlMessages id="TotalExpenses" /></b></Col>
                <Col lg={4} className="text-right bg-gray-3">{Util.formatNumber( totalExpenses )}</Col>
              </Row>

              <Row>
                <Col lg={8} className="bg-gray-3"><IntlMessages id="EarningsBeforeTax" /></Col>
                <Col lg={4} className="text-right bg-gray-3">{Util.formatNumber( earningBeforeTaxes )}</Col>
              </Row>
              <Row>
                <Col lg={8}><IntlMessages id="Incometaxes" /></Col>
                <Col lg={4} className="text-right">{Util.formatNumber( incomeTaxes )}</Col>
              </Row>
              <Row>
                <Col lg={8} className="bg-gray-3" style={{ fontWeight: 'bold', fontSize: '16px' }}><b><IntlMessages id="Netearnings" /></b></Col>
                <Col lg={4} className="text-right bg-gray-3" style={{ fontWeight: 'bold', fontSize: '16px' }}><b>{Util.formatNumber( netEarnings )}</b></Col>
              </Row>
              <Row>
                <hr />
              </Row>
              <Row>
                <Col />
                <Col>
                  <Button
                    variant="contained"
                    color="primary"
                    className="jr-btn jr-btn-lg"
                    type="button"
                  >
                    <IntlMessages id="ClickForComparison" />
                  </Button>
                </Col>
              </Row>
            </CardBox>
          </Col>
          <Col lg={5}>
            <CardBox heading="Revenues" onClick={this.toggleChart1Modal} headerBg="bg-product-laptop">
              <div className="jr-link" id="chartdiv" style={{ width: '100%', height: '250px' }} />
              <div>
                {!!table1 && table1.length > 0 && ( <div><h5><b>TITLE</b></h5></div> )}
                {_.map( table1, item => ( <div>{`${item.text} ${item.val}`}</div> ) )}
              </div>
            </CardBox>
            <CardBox heading="Earnings" onClick={this.toggleChart2Modal} headerBg="bg-product-laptop">
              <div className="jr-link" id="chartEarningsdiv" style={{ width: '100%', height: '250px' }} />
            </CardBox>
          </Col>
          <Col lg={3}>
            <CardBox heading="StockPrices" headerBg="bg-product-laptop">
              {_.map( stock, item => (
                <Row key={item.id}>
                  <Col lg={4} className={this.license.workGroup.id === item.id ? 'font-weight-bold' : 'font-weight-normal'}>
                    {item.name}
                  </Col>
                  <Col lg={3} className={this.license.workGroup.id === item.id ? 'font-weight-bold text-right' : 'font-weight-normal text-right'}>
                    {Util.formatCurrency( item.points )}
                  </Col>
                  <Col lg={3} className={this.license.workGroup.id === item.id ? 'font-weight-bold text-right pr-0' : 'font-weight-normal text-right pr-0'}>
                    {`${item.percentagePoints}%`}
                  </Col>
                  {item.percentagePoints && item.percentagePoints < 0 && (
                    <Col lg={2} className="text-right"><i className="fa fa-arrow-down text-danger mx-2" /></Col>
                  )}
                  {item.percentagePoints && item.percentagePoints > 0 && (
                    <Col lg={2} className="text-right"><i className="fa fa-arrow-up text-primary mx-2" /></Col>
                  )}
                  {item.percentagePoints && item.percentagePoints === 0 && (
                    <Col lg={2} className="text-right"><i className="fa fa-arrow-right text-light mx-2" /></Col>
                  )}
                </Row>
              ) )}
            </CardBox>
            <CardBox heading="StockPricesTrend" onClick={this.toggleChart3Modal} headerBg="bg-product-laptop" icon="expand">
              <div className="jr-link" id="chartStockdiv" style={{ width: '100%', height: '250px' }} />
            </CardBox>
            <CardBox heading="cashavailability" headerBg="bg-product-laptop">
              <Row>
                <Col className="text-center">
                  <b>
                    {Util.formatCurrency( cash )}
                  </b>
                </Col>
              </Row>
            </CardBox>
            <CardBox heading="outstandingDebt" headerBg="bg-product-laptop">
              <Row>
                <Col className="text-center">
                  <b>
                    {Util.formatCurrency( outstandingDebt )}
                  </b>
                </Col>
              </Row>
            </CardBox>
          </Col>
        </Row>

        <CompModal
          open={CompModalB}
          title="PLComparison"
          onClose={this.toggleCompModal}
          data={data}
        />
        <ChartModal
          open={chart1Modal}
          title="Revenues"
          onClose={this.toggleChart1Modal}
          seriesTitle={['Revenues']}
          config={this.config}
        />
        <ChartModal
          open={chart2Modal}
          title="Earnings"
          onClose={this.toggleChart2Modal}
          seriesTitle={['Earnings']}
          config={this.config2}
        />
        <ChartModal
          open={chart3Modal}
          title="StockPricesTrend"
          onClose={this.toggleChart3Modal}
          seriesTitle={this.seriesTitle1}
          config={this.config3}
        />
      </>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

export default connect( mapStateToProps )( CompanyChart );
