import React from 'react';
import Button from '@material-ui/core/Button';

import IntlMessages from 'utils/IntlMessages';

export default ( { category, onClick } ) => (
  <>
    {category.label === 'CATEGORY_1'
    && (
      <Button
        variant="contained"
        className="jr-btn bg-product-laptop mt-2 mb-2 mb-lg-0"
        onClick={onClick}
      >
        <IntlMessages id="newProduct"/>
      </Button>
    )}
    {category.label === 'CATEGORY_2'
    && (
      <Button
        variant="contained"
        className="jr-btn bg-product-tablet mt-2 mb-2 mb-lg-0"
        onClick={onClick}
      >
        <IntlMessages id="newProduct"/>
      </Button>
    )}
    {category.label === 'CATEGORY_3'
    && (
      <Button
        variant="contained"
        className="jr-btn bg-product-cell mt-2 mb-2 mb-lg-0"
        onClick={onClick}
      >
        <IntlMessages id="newProduct"/>
      </Button>
    )}
  </>
)
