import React from 'react';
import { Link } from 'react-router-dom';
import IntlMessages from 'utils/IntlMessages';
import { connect } from 'react-redux';
import _ from 'lodash';

import UserActions from 'store/reducers/User';

class UserInfoPopup extends React.Component {
  hasPermissions( role, license ) {
    const { user } = this.props;
    return user.roles && user.roles.indexOf( role ) !== -1
      && user.licenses && _.find( user.licenses, { owner: license } );
  }

  render() {
    const { user, toggle } = this.props;

    return (
      <div>
        <div className="user-profile">
          <div className="user-detail ml-2">
            <h4 className="user-name mb-0">{user.name} {user.lastName}</h4>
            {this.hasPermissions( 'ROLE_ADMIN', 'adm' ) ?
              <small><IntlMessages id="adminRole"/></small> : null
            }
            {this.hasPermissions( 'ROLE_SUPERVISOR', 'sup' ) ?
              <small><IntlMessages id="supervisorRole"/></small> : null
            }
            {this.hasPermissions( 'ROLE_CLIENT', 'user' ) ?
              <small><IntlMessages id="userRole"/></small> : null
            }
          </div>
        </div>

        <Link to="/profile-settings" className="text-decoration-none" onClick={toggle}>
          <span className="jr-link dropdown-item text-muted">
          <i className="zmdi zmdi-account zmdi-hc-fw mr-1"/>
          <IntlMessages id="profile"/>
        </span>
        </Link>
        {this.hasPermissions( 'ROLE_CLIENT', 'user' ) ?
        <Link to="/group-settings" className="text-decoration-none" onClick={toggle}>
          <span className="jr-link dropdown-item text-muted">
          <i className="zmdi zmdi-accounts zmdi-hc-fw mr-1"/>
          <IntlMessages id="profile-group"/>
        </span>
        </Link> : null}
       
        <span className="jr-link dropdown-item text-muted" onClick={() => {
          window.location.replace( '/login' );
          this.props.logoutUser();
        }}>
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1"/>
          <IntlMessages id="popup.logout"/>
        </span>
      </div>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = ( {
  logoutUser: UserActions.logoutUser,
} );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)( UserInfoPopup );
