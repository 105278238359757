import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { Row, Col } from 'reactstrap';

import CardBox from 'components/custom_v2/CardBox';
import IntlMessages from 'utils/IntlMessages';

export default ( { data, defaultOpened } ) => {
  const [opened, setOpened] = useState( defaultOpened );

  const promotePurchasing = (
    <i
      className={classnames( 'fa', {
        'fa-check': data.promotePurchasing,
        'fa-times': !data.promotePurchasing,
      } )}
    />
  );

  const toggleOpened = useCallback( () => {
    setOpened( !opened );
  }, [opened] );

  const getPropertyRender = ( label, key, value ) => (
    <p className="mb-1">
      <strong><IntlMessages id={label} /></strong>
      {': '}
      {value || ( _.get( data, key ) ? _.get( data, key ) : '-' )}
    </p>
  );

  const getTranslatePropertyRender = ( label, key, value ) => (
    <p className="mb-1">
      <strong><IntlMessages id={label} /></strong>
      {': '}
      {value ? <IntlMessages id={value} />
        : ( _.get( data, key ) ? <IntlMessages id={_.get( data, key )} /> : '-' )}
    </p>
  );

  const getTranslateArrayRender = ( label, value ) => (
    <p className="mb-1">
      <strong><IntlMessages id={label} /></strong>
      {': '}
      {_.map( value, ( item, index ) => (
        <span key={item.id}>
          <IntlMessages id={item.id} />
          {index < value.length - 1 ? ', ' : ''}
        </span>
      ) )}
    </p>
  );

  return (
    <CardBox header={(
      <div
        className="p-2 bg-primary cursor-pointer d-flex justify-content-between"
        onClick={toggleOpened}
      >
        <h4 className="card-heading m-0 text-white">
          <IntlMessages id="overview" />
        </h4>
        <i className={classnames( 'fa text-white', {
          'fa-chevron-up': !opened,
          'fa-chevron-down': opened,
        } )}
        />
      </div>
    )}
    >
      {opened
      && (
        <Row>
          <Col md={4}>
            <p className="mb-1 border-bottom border-grey">
              <span className="text-uppercase font-weight-bold"><IntlMessages id="campaign" /></span>
            </p>
            <div className={classnames( 'p-2 crm-detail-bg-1' )}>
              {getPropertyRender( 'name', 'name' )}
              {getTranslatePropertyRender( 'mainObjective', 'contentType' )}
              {data.contentType === 'PRODUCT'
            && (
              <>
                {getPropertyRender( 'product', 'product' )}
                {getPropertyRender( 'attributes', null, _.map( data.productFeature, 'id' )
                  .join( ', ' ) )}
                {getPropertyRender( 'promotePurchasingDirectChannel', null, promotePurchasing )}
              </>
            )}

              {data.contentType === 'SERVICE'
            && (
              <>
                {getPropertyRender( 'service', 'service' )}
                {getPropertyRender( 'attributes', null, _.map( data.serviceFeature, 'id' )
                  .join( ', ' ) )}
                {getPropertyRender( 'promotePurchasingDirectChannel', null, promotePurchasing )}
              </>
            )}

              {data.contentType === 'BUNDLE'
            && (
              <>
                {getPropertyRender( 'bundle', 'bundle' )}
                {getPropertyRender( 'promotePurchasingDirectChannel', null, promotePurchasing )}
              </>
            )}
              {getTranslatePropertyRender( 'contactMethod', 'directContactMethod' )}
              {getTranslatePropertyRender( 'messagePerMonth', 'messagePerMonth' )}
              {getPropertyRender( 'execution', null,
                data.execution
                  ? (
                    <span>
                      <IntlMessages id={data.execution} />
                      {data.months ? ` - ${data.months}` : ''}
                    </span>
                  ) : '-' )}
            </div>
          </Col>

          <Col md={4}>
            <p className="mb-1 border-bottom border-grey">
              <span className="text-uppercase font-weight-bold"><IntlMessages id="who" /></span>
            </p>
            {data.demographicSegmentation && ( (
              data.demographicSegmentation.segmentation
              && data.demographicSegmentation.segmentation.length )
            || data.demographicSegmentation.gender
            || data.demographicSegmentation.ageFrom
            || data.demographicSegmentation.ageTo
            )
              ? (
                <div className="p-2">
                  <p className="mb-1">
                    <span className="text-uppercase font-weight-bold font-italic"><IntlMessages id="demographicSegmentation" /></span>
                  </p>
                  <Row>
                    <Col>
                      {getTranslatePropertyRender( 'gender', null, data.demographicSegmentation.gender )}
                    </Col>
                    <Col>
                      {getPropertyRender( 'age', null, data.demographicSegmentation.ageFrom || data.demographicSegmentation.ageTo
                        ? `${data.demographicSegmentation.ageFrom || ''} ${data.demographicSegmentation.ageTo && data.demographicSegmentation.ageFrom ? 'to' : ''} ${data.demographicSegmentation.ageTo || ''}` : false )}
                    </Col>
                  </Row>
                  {getTranslateArrayRender( 'segmentation', data.demographicSegmentation.segmentation )}
                </div>
              ) : null
            }
            {( ( data.behavioralSegmentations1 && !!data.behavioralSegmentations1.length )
            || ( data.behavioralSegmentations2 && !!data.behavioralSegmentations2.length )
            ) && _.map( [...data.behavioralSegmentations1, ...data.behavioralSegmentations2],
              ( item, index ) => (
                <div key={item.id} className={classnames( 'p-2', { 'crm-detail-bg-1': index % 2 === 0 } )}>
                  <p className="mb-1">
                    <span className="text-uppercase font-weight-bold font-italic">
                      <IntlMessages id="behavioralSegmentation" />
                      {' '}
                      {item.behavioralType ? item.behavioralType.split( '_' )[1] : ''}
                    </span>
                  </p>
                  <Row>
                    <Col>
                      {getPropertyRender( 'behavioral', null, item.behavioral )}
                    </Col>
                    {item.behavioralType === 'TYPE_1' || item.behavioralType === 'TYPE_2'
                      ? (
                        <Col>
                          {getPropertyRender( 'commodity', null, _.get( item, 'commodity.name' ) )}
                        </Col>
                      ) : null
                    }
                  </Row>
                  <Row>
                    {item.afterRound
                  && (
                    <Col>
                      {getPropertyRender( 'afterRound', null, item.afterRound.name )}
                    </Col>
                  )}
                    {item.beforeRound
                  && (
                    <Col>
                      {getPropertyRender( 'beforeRound', null, item.beforeRound.name )}
                    </Col>
                  )}
                    {item.exactly
                  && (
                    <Col>
                      <strong><IntlMessages id="exactly" /></strong>
                      {': '}
                      {item.exactly}
                      {' '}
                      <IntlMessages id="monthsAgo" />
                    </Col>
                  )}
                  </Row>
                </div>
              ) )}
          </Col>

          <Col md={3}>
            <p className="mb-1 border-bottom border-grey">
              <span className="text-uppercase font-weight-bold"><IntlMessages id="iotDatabase" /></span>
            </p>
            <div className={classnames( 'p-2 crm-detail-bg-1' )}>
              {getPropertyRender( 'product', 'iotSegmentation.product' )}
              {getTranslatePropertyRender( 'feature', 'iotSegmentation.iotFeature' )}
            </div>
          </Col>
        </Row>
      )}
    </CardBox>
  );
};
