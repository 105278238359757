import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import _ from 'lodash';
import arrayMutators from 'final-form-arrays';
import { Row, Col } from 'reactstrap';
import { Button, Divider, Step, StepLabel, Stepper } from '@material-ui/core';
import { FieldArray } from 'react-final-form-arrays';
import async from 'async';

import CardBox from 'components/custom_v2/CardBox';
import BreadcrumbComp from 'components/custom_v2/Breadcrumb';
import {
  TextField,
  RadioButtons,
  Checkboxes,
  Checkbox, Dropzone,
} from 'components/custom/FormElements';
import SettingsActions from 'store/reducers/Settings';
import IntlMessages from 'utils/IntlMessages';
import { composeValidators, greaterThan, required } from 'config/InputErrors';
import RoundService from 'services/Round';
import CommodityService from 'services/Commodity';
import AttributeService from 'services/Attribute';
import AttributeServiceService from 'services/AttributeService';
import CommunicationService from 'services/Communication';
import CategoryService from 'services/Category';
import BehavioralSegmentation from 'routes/App/components/User/OneToOne/BehavioralSegmentationV2';
import CampaignDetail from 'routes/App/components/User/OneToOne/Detail';
import Util from 'utils/Util';

const contentTypes = [
  {
    id: 'LOYALTY',
    value: 'BUILD_LOYALTY',
  },
  {
    id: 'REFERRALS',
    value: 'GENERATE_REFERRALS',
  },
  {
    id: 'PRODUCT',
    value: 'productPromotion',
  },
  {
    id: 'SERVICE',
    value: 'servicePromotion',
  },
  {
    id: 'BUNDLE',
    value: 'bundlePromotion',
  },
];

const demographicSegmentationOptions = [
  {
    id: 'EXECUTIVES',
    value: 'Executives',
  },
  {
    id: 'PROFESSIONAL',
    value: 'Professionals',
  },
  {
    id: 'EMPLOYEES',
    value: 'Employees',
  },
  {
    id: 'STUDENTS',
    value: 'Students',
  },
  {
    id: 'HOME',
    value: 'Home',
  },
];

const behavioralSegmentations1 = [
  {
    id: 1,
    type: 'radio',
    options: [
      {
        id: 'PURCHASED',
        name: 'PURCHASED',
      },
      {
        id: 'NOT_PURCHASED',
        name: 'NOT_PURCHASED',
      },
    ],
    selectCommodity: true,
    behavioralType: 'TYPE_1',
  },
  {
    id: 2,
    type: 'radio',
    options: [
      {
        id: 'PURCHASED',
        name: 'PURCHASED',
      },
      {
        id: 'NOT_PURCHASED',
        name: 'NOT_PURCHASED',
      },
    ],
    selectCommodity: true,
    behavioralType: 'TYPE_2',
  },
];

const behavioralSegmentations2 = [
  {
    id: 3,
    type: 'checkbox',
    options: [
      {
        id: 'COMPLAINT',
        name: 'COMPLAINT',
      },
    ],
    selectCommodity: false,
    behavioralType: 'TYPE_3',
  },
  {
    id: 4,
    type: 'checkbox',
    options: [
      {
        id: 'REMAINED_INACTIVE',
        name: 'REMAINED_INACTIVE',
      },
    ],
    selectCommodity: false,
    behavioralType: 'TYPE_4',
  },
  {
    id: 5,
    type: 'checkbox',
    options: [
      {
        id: 'VISITED_WEBSITE',
        name: 'VISITED_WEBSITE',
      },
    ],
    selectCommodity: false,
    behavioralType: 'TYPE_5',
  },
  {
    id: 6,
    type: 'checkbox',
    options: [
      {
        id: 'SUFFERED_FAILURE',
        name: 'SUFFERED_FAILURE',
      },
    ],
    selectCommodity: false,
    behavioralType: 'TYPE_6',
  },
];

const Header = ( { title } ) => (
  <div className="p-2 bg-primary">
    <h4 className="card-heading m-0 text-white">
      <IntlMessages id={title} />
    </h4>
  </div>
);

class EditCampaign extends React.Component {
  state = {
    rounds: [],
    products: [],
    services: [],
    bundles: [],
    commodities: [],
    productAttributes: [],
    serviceAttributes: [],
    categories: [],
    activeStep: 0,
    costIndex: { value: 1 },
    detailData: {},
  };
  steps = [
    {
      id: 1,
      label: 'name',
    },
    {
      id: 2,
      label: 'who',
    },
    /* {
      id: 3,
      label: 'campaignList',
    },
    */
    {
      id: 4,
      label: 'what',
    },
    {
      id: 5,
      label: 'how',
    },
    {
      id: 6,
      label: 'finalConfirmation',
    },
  ];
  entityId = null;

  componentDidMount() {
    const { toggleAlert, user, match } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }


    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }
    this.activeSession = user.activeSession;
    this.entityId = match.params.id;
    this.getData( this.entityId );
  }

  getData = () => {
    const { toggleLoading, toggleAlert } = this.props;

    toggleLoading( true );
    async.parallel( {
      entity: this.getEntity,
      costIndex: this.getCostIndex,
      rounds: this.getRounds,
      commodities: this.getCommodities,
      productAttributes: this.getProductAttributes,
      serviceAttributes: this.getServiceAttributes,
      categories: this.getCategories,
    }, ( error, results ) => {
      toggleLoading( false );
      if ( error ) {
        return toggleAlert( error );
      }
      let entity = {};
      if ( this.entityId ) {
        entity = {
          ...results.entity,
          behavioralSegmentations1: _.cloneDeep( behavioralSegmentations1 ),
          behavioralSegmentations2: _.cloneDeep( behavioralSegmentations2 ),
        };
        if ( entity.contentType === 'PRODUCT' ) {
          entity.product = entity.product
          && _.find( results.commodities, { id: entity.product.id } ) ? entity.product.id : null;
          entity.productFeature = [];
          if ( results.entity.productFeature ) {
            entity.productFeature.push( { id: results.entity.productFeature.id } );
          }
          if ( entity.productFeature2 ) {
            entity.productFeature.push( { id: results.entity.productFeature2.id } );
          }
        }
        if ( entity.contentType === 'SERVICE' ) {
          entity.service = entity.service
          && _.find( results.commodities, { id: entity.service.id } ) ? entity.service.id : null;
          entity.serviceFeature = [];
          if ( results.entity.serviceFeature ) {
            entity.serviceFeature.push( { id: results.entity.serviceFeature.id } );
          }
          if ( entity.serviceFeature2 ) {
            entity.serviceFeature2.push( { id: results.entity.serviceFeature2.id } );
          }
        }
        if ( entity.contentType === 'BUNDLE' ) {
          entity.bundle = entity.bundle ? entity.bundle.id : null;
        }

        if ( entity.demographicSegmentation && entity.demographicSegmentation.segmentation ) {
          entity.demographicSegmentation.segmentation = _.map(
            entity.demographicSegmentation.segmentation, item => ( { id: item } ),
          );
        }
        if ( entity.behavioralSegmentation ) {
          entity.behavioralSegmentations1 = _.map( entity.behavioralSegmentations1, ( item ) => {
            const newItem = { ...item };
            const segmentation = _.find(
              entity.behavioralSegmentation, { behavioralType: item.behavioralType },
            );
            if ( segmentation ) {
              newItem.behavioral = segmentation.behavioral;
              if ( segmentation.commodity
                && _.find( results.commodities, { id: segmentation.commodity.id } ) ) {
                newItem.commodity = segmentation.commodity.id;
              }
              newItem.fromMonth = segmentation.fromMonth;
              newItem.toMonth = segmentation.toMonth;
            }
            return newItem;
          } );

          entity.behavioralSegmentations2 = _.map( entity.behavioralSegmentations2, ( item ) => {
            const newItem = { ...item };
            const segmentation = _.find(
              entity.behavioralSegmentation, { behavioralType: item.behavioralType },
            );
            if ( segmentation ) {
              newItem.behavioral = [{ id: segmentation.behavioral }];
              newItem.fromMonth = segmentation.fromMonth;
              newItem.toMonth = segmentation.toMonth;
            }
            return newItem;
          } );
        }
        if ( entity.iotSegmentation ) {
          if ( entity.iotSegmentation.product
            && _.find( results.commodities, { id: entity.iotSegmentation.product.id } ) ) {
            entity.iotSegmentation.product = entity.iotSegmentation.product.id;
          } else entity.iotSegmentation.product = null;
        }
      }
      let defaultRent = '';
      switch ( entity.rentExternalDatabase ) {
      case 'LAPTOP':
        defaultRent = _.find( results.categories, { name: 'Laptops' } );
        entity.rentExternalDatabase = defaultRent.id;
        break;
      case 'CELULAR':
        defaultRent = _.find( results.categories, { name: 'Celular' } );
        entity.rentExternalDatabase = defaultRent.id;
        break;
      case 'TABLET':
        defaultRent = _.find( results.categories, { name: 'Tablet' } );
        entity.rentExternalDatabase = defaultRent.id;
        break;
      default:
        break;
      }
      this.setState( {
        ...results,
        entity,
        products: _.filter( results.commodities, { owner: 'PRODUCT' } ),
        services: _.filter( results.commodities, { owner: 'SERVICE' } ),
        bundles: _.filter( results.commodities, { owner: 'BUNDLE' } ),
        rounds: _.map( results.rounds, round => ( {
          id: round.id,
          name: round.largeName,
        } ) ),
      }, () => this.setState( {
        detailData: this.entityId
          ? this.loadDataDetail( entity ) : {},
      } ) );
    } );
  };

  getEntity = ( cb ) => {
    if ( !this.entityId ) return cb();
    CommunicationService.getOneToOneCampaign( this.activeSession.id, this.entityId,
      { workgroup: this.license.workGroup.id } )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        return cb( null, response.data );
      } );
  };

  getCostIndex = ( cb ) => {
    CommunicationService.getOneToOneCostIndex( this.activeSession.id,
      { workgroup: this.license.workGroup.id } )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        return cb( null, response.data );
      } );
  };

  getRounds = ( cb ) => {
    RoundService.getRoundsBySession( this.activeSession.id, {
      limit: -1,
      'filters[noRoundZero]': 'R000',
    } )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        return cb( null, response.data.data );
      } );
  };

  getCommodities = ( cb ) => {
    CommodityService.getCommodities( this.activeSession.id, {
      workgroup: this.license.workGroup.id,
      limit: -1,
    } )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        return cb( null, response.data.data );
      } );
  };

  getProductAttributes = ( cb ) => {
    AttributeService.getAttributes( {
      'filters[session]': this.activeSession.id,
      limit: -1,
    } )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        cb( null, response.data.data );
      } );
  };

  getServiceAttributes = ( cb ) => {
    AttributeServiceService.getAttributes( {
      'filters[session]': this.activeSession.id,
      limit: -1,
    } )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        cb( null, response.data.data );
      } );
  };

  getCategories = ( cb ) => {
    CategoryService.getCategories( {
      'filters[session]': this.activeSession.id,
      limit: -1,
    } )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        cb( null, response.data.data );
      } );
  };

  submitForm = ( data ) => {
    const { activeStep, categories } = this.state;
    const { toggleLoading, toggleAlert, history } = this.props;

    if ( this.steps[activeStep].id < 6 ) return;

    const dataToSend = {
      workgroup: this.license.workGroup.id,
      name: data.name,
      contentType: data.contentType,
      promotePurchasing: !!data.promotePurchasing,
      directContactMethod: data.directContactMethod,
      execution: data.execution,
      months: data.months,
      messagePerMonth: data.messagePerMonth,
      rentExternalDatabase: null,
    };

    const restExternalOptionChecked = _.find( categories, { id: data.rentExternalDatabase } );

    if ( restExternalOptionChecked !== undefined ) {
      let rentExternalValue = restExternalOptionChecked.name.toUpperCase();
      if ( rentExternalValue === 'LAPTOPS' ) rentExternalValue = 'LAPTOP';

      dataToSend.rentExternalDatabase = rentExternalValue;
    }
    if ( data.contentType === 'PRODUCT' ) {
      dataToSend.product = data.product;
      if ( data.productFeature ) {
        if ( data.productFeature.length === 1 ) {
          dataToSend.productFeature = data.productFeature[0].id;
        }
        if ( data.productFeature.length === 2 ) {
          dataToSend.productFeature = data.productFeature[0].id;
          dataToSend.productFeature2 = data.productFeature[1].id;
        }
      }
    }
    if ( data.contentType === 'SERVICE' ) {
      dataToSend.service = data.service;
      if ( data.serviceFeature ) {
        if ( data.serviceFeature.length === 1 ) {
          dataToSend.serviceFeature = data.serviceFeature[0].id;
        }
        if ( data.serviceFeature.length === 2 ) {
          dataToSend.serviceFeature = data.serviceFeature[0].id;
          dataToSend.serviceFeature2 = data.serviceFeature[1].id;
        }
      }
    }
    if ( data.contentType === 'BUNDLE' ) {
      dataToSend.bundle = data.bundle;
    }

    if ( data.demographicSegmentation ) {
      dataToSend.demographicSegmentation = {
        gender: data.demographicSegmentation.gender,
        ageFrom: data.demographicSegmentation.ageFrom,
        ageTo: data.demographicSegmentation.ageTo,
        segmentation: _.map( data.demographicSegmentation.segmentation, 'id' ),
      };
    }

    if ( data.behavioralSegmentations1 || data.behavioralSegmentations2 ) {
      dataToSend.behavioralSegmentation = [];
    }
    if ( data.behavioralSegmentations1 ) {
      dataToSend.behavioralSegmentation.push( ..._.map( data.behavioralSegmentations1, ( item ) => {
        if ( !item.behavioral ) return;
        const newData = {
          behavioral: item.behavioral,
          behavioralType: item.behavioralType,
          commodity: item.commodity,
          fromMonth: item.fromMonth,
          toMonth: item.toMonth,
        };

        return newData;
      } ) );
    }
    if ( data.behavioralSegmentations2 ) {
      dataToSend.behavioralSegmentation.push( ..._.map( data.behavioralSegmentations2, ( item ) => {
        if ( !item.behavioral || !item.behavioral.length ) return;
        const newData = {
          behavioral: item.behavioral[0].id,
          behavioralType: item.behavioralType,
          fromMonth: item.fromMonth,
          toMonth: item.toMonth,
        };

        return newData;
      } ) );
    }

    dataToSend.behavioralSegmentation = _.compact( dataToSend.behavioralSegmentation );

    if ( data.iotSegmentation ) {
      dataToSend.iotSegmentation = {
        product: data.iotSegmentation.product,
        iotFeature: data.iotSegmentation.iotFeature,
      };
    }

    if ( data.uploadFile && data.uploadFile.fileDecoded ) {
      dataToSend.uploadFile = data.uploadFile.fileDecoded;
    }

    toggleLoading( true );
    CommunicationService.saveOneToOneCampaign( this.activeSession.id, dataToSend )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) return toggleAlert( response.errors );
        toggleAlert( 'dataSaved', 'info' );
        history.push( '/sessions/general/one-to-one' );
      } );
  };

  loadDataDetail = ( dataDetail ) => {
    const {
      products, services, bundles, commodities, rounds,
      productAttributes, serviceAttributes,
    } = this.state;
    const data = _.cloneDeep( dataDetail );
    if ( data.product ) {
      const product = _.find( products, { id: data.product } );
      if ( product ) data.product = product.name;
    }
    if ( data.service ) {
      const service = _.find( services, { id: data.service } );
      if ( service ) data.service = service.name;
    }
    if ( data.bundle ) {
      const bundle = _.find( bundles, { id: data.bundle } );
      if ( bundle ) data.bundle = bundle.name;
    }
    data.productFeature = _.map( data.productFeature, ( item ) => {
      const productAttribute = _.find( productAttributes, { id: item.id } );
      if ( productAttribute ) return { id: productAttribute.name };
      return item.id;
    } );
    data.serviceFeature = _.map( data.serviceFeature, ( item ) => {
      const serviceAttribute = _.find( serviceAttributes, { id: item.id } );
      if ( serviceAttribute ) return { id: serviceAttribute.name };
      return item.id;
    } );

    if ( _.get( data, 'iotSegmentation.product' ) ) {
      const product = _.find( products, { id: data.iotSegmentation.product } );
      if ( product ) data.iotSegmentation.product = product.name;
    }

    if ( data.behavioralSegmentations1 ) {
      data.behavioralSegmentations1 = _.map( data.behavioralSegmentations1, ( item ) => {
        if ( !item.behavioral ) return;
        const newData = {
          behavioral: item.behavioral,
          behavioralType: item.behavioralType,
          commodity: _.find( commodities, { id: item.commodity } ),
        };
        if ( item.date === 'afterRound' || item.date === 'beforeRound' ) {
          if ( item.date === 'afterRound' ) newData.afterRound = _.find( rounds, { id: item.round } );
          if ( item.date === 'beforeRound' ) newData.beforeRound = _.find( rounds, { id: item.round } );
        } else if ( item.date === 'exactly' ) newData.exactly = item.months;
        return newData;
      } );
    }
    data.behavioralSegmentations1 = _.compact( data.behavioralSegmentations1 );
    if ( data.behavioralSegmentations2 ) {
      data.behavioralSegmentations2 = _.map( data.behavioralSegmentations2, ( item ) => {
        if ( !item.behavioral || !item.behavioral.length ) return;
        const newData = {
          behavioral: item.behavioral[0].id,
          behavioralType: item.behavioralType,
        };
        if ( item.date === 'afterRound' || item.date === 'beforeRound' ) {
          if ( item.date === 'afterRound' ) newData.afterRound = _.find( rounds, { id: item.round } );
          if ( item.date === 'beforeRound' ) newData.beforeRound = _.find( rounds, { id: item.round } );
        } else if ( item.date === 'exactly' ) newData.exactly = item.months;
        return newData;
      } );
    }
    data.behavioralSegmentations2 = _.compact( data.behavioralSegmentations2 );
    return data;
  };

  handleNext = ( data ) => {
    const { activeStep } = this.state;

    this.setState( {
      activeStep: activeStep + 1,
      detailData: this.loadDataDetail( data ),
    } );
  };

  handleBack = ( data ) => {
    const { activeStep } = this.state;
    this.setState( {
      activeStep: activeStep - 1,
      detailData: this.loadDataDetail( data ),
    } );
  };

  getStepButtons = ( form ) => {
    const { activeStep } = this.state;
    return (
      <Row>
        <Col md={4} className="m-md-auto">
          <div className="text-center">
            {activeStep > 0
            && (
              <Button
                variant="contained"
                className="jr-btn jr-btn-lg bg-blue-grey text-white"
                type="submit"
                onClick={() => {
                  if ( form.getState().valid ) this.handleBack( form.getState().values );
                }}
              >
                <i className="fa fa-angle-double-left" />
                <IntlMessages id="back" />
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              className="jr-btn jr-btn-lg"
              type="submit"
              onClick={() => {
                if ( form.getState().valid
                  || ( activeStep === 0 && !form.getState().errors.name )
                  || ( activeStep === 1 && !form.getState().errors.behavioralSegmentations1
                    && !form.getState().errors.behavioralSegmentations2
                    && !form.getState().errors.iotSegmentation ) ) {
                  this.handleNext( form.getState().values );
                }
              }}
            >
              <IntlMessages id="next" />
              <i className="fa fa-angle-double-right" />
            </Button>
          </div>
        </Col>
      </Row>
    );
  };

  getCostbyDirectC = ( obj, objCost ) => {
    switch ( obj.directContactMethod ) {
    case 'EMAIL':
      return objCost.email;
    case 'TELEMARKETING':
      return objCost.telemarketing;
    case 'DIRECT_MAIL':
      return objCost.directEmail;
    default:
      return objCost.rentExternalDatabase;
    }
  }
  render() {
    const {
      entity, rounds, products, services, bundles, activeStep, detailData,
      productAttributes, commodities, costIndex, // categories,
    } = this.state;

    return (
      <>
        <BreadcrumbComp links={[
          {
            url: '/',
            name: 'decisionDashboard',
          },
          {
            url: '/sessions/general/one-to-one',
            name: '1to1Campaigns',
          },
          { name: 'newCampaign' },
        ]}
        />

        <CampaignDetail data={detailData} />

        <Form
          initialValues={{
            behavioralSegmentations1,
            behavioralSegmentations2,
            ...entity,
          }}
          validate={( values ) => {
            const errors = {};
            const behavioralSegmentations1Errors = [];
            const behavioralSegmentations2Errors = [];
            if ( values.behavioralSegmentations1 && values.behavioralSegmentations1.length ) {
              _.map( values.behavioralSegmentations1, ( item, index ) => {
                if ( !item.behavioral || !item.behavioral.length ) return;
                if (
                  !item.commodity
                  || !item.behavioral
                  || !item.fromMonth
                  || !item.toMonth
                  || ( parseInt( item.toMonth, 10 ) < parseInt( item.fromMonth, 10 ) )
                ) {
                  behavioralSegmentations1Errors[index] = {};
                }
                if ( !item.commodity ) {
                  behavioralSegmentations1Errors[index].commodity = 'fieldError.required';
                }

                if ( item.commodity && !item.fromMonth ) {
                  behavioralSegmentations1Errors[index].fromMonth = 'fieldError.required';
                }
                if ( item.commodity && !item.toMonth ) {
                  behavioralSegmentations1Errors[index].toMonth = 'fieldError.required';
                }
                if ( parseInt( item.toMonth, 10 ) < parseInt( item.fromMonth, 10 ) ) {
                  behavioralSegmentations1Errors[index].toMonth = 'fieldError.greaterOrEqualThanFromMonth';
                }
                // if ( item.date === 'exactly' && !item.months ) {
                //  behavioralSegmentations1Errors[index].months = 'fieldError.required';
                // }
              } );
              if ( behavioralSegmentations1Errors.length ) {
                errors.behavioralSegmentations1 = behavioralSegmentations1Errors;
              }
            }
            if ( values.behavioralSegmentations2 && values.behavioralSegmentations2.length ) {
              _.map( values.behavioralSegmentations2, ( item, index ) => {
                if ( !item.behavioral || !item.behavioral.length ) return;
                if ( !item.commodity
                  || !item.fromMonth
                  || !item.toMonth
                  || ( parseInt( item.toMonth, 10 ) > parseInt( item.fromMonth, 10 ) )
                ) {
                  behavioralSegmentations2Errors[index] = {};
                }


                if ( item.behavioral && !item.fromMonth ) {
                  behavioralSegmentations2Errors[index].fromMonth = 'fieldError.required';
                }
                if ( item.behavioral && !item.toMonth ) {
                  behavioralSegmentations2Errors[index].toMonth = 'fieldError.required';
                }
                if (
                  ( item.behavioral && item.behavioral.length && item.fromMonth && item.toMonth )
                  && ( parseInt( item.toMonth, 10 ) < parseInt( item.fromMonth, 10 ) )
                ) {
                  behavioralSegmentations2Errors[index].toMonth = 'fieldError.greaterOrEqualThanFromMonth';
                }
              } );
              if ( behavioralSegmentations2Errors.length ) {
                errors.behavioralSegmentations2 = behavioralSegmentations2Errors;
              }
            }
            if ( values.iotSegmentation && values.iotSegmentation.product
              && !values.iotSegmentation.iotFeature ) {
              errors.iotSegmentation = { iotFeature: 'fieldError.required' };
            }
            if ( values.execution && values.execution === 'DISTRIBUTE_IN_MONTH' && !values.months ) {
              errors.months = 'fieldError.required';
            }
            return errors;
          }}
          onSubmit={this.submitForm}
          mutators={{ ...arrayMutators }}
          render={( { handleSubmit, form, values } ) => (
            <form onSubmit={handleSubmit}>
              <Stepper activeStep={activeStep} className="horizontal-stepper-linear bg-transparent">
                {this.steps.map( ( step, index ) => (
                  <Step key={step.id}>
                    <StepLabel
                      className={`horizontal-stepper ${index === activeStep ? 'active' : ''}`}
                    >
                      <IntlMessages id={step.label} />
                    </StepLabel>
                  </Step>
                ) )}
              </Stepper>

              {this.steps[activeStep].id === 1
              && (
                <>
                  <Row>
                    <Col md={6}>
                      <CardBox header={<Header title="campaignInfo" />}>
                        <Row>
                          <Col md={6}>
                            <TextField field="name" label="campaignName" validate={required} />
                          </Col>
                        </Row>
                      </CardBox>
                    </Col>
                  </Row>
                  {this.getStepButtons( form )}
                </>
              )}
              {this.steps[activeStep].id === 2
              && (
                <>
                  <Row>
                    <Col>
                      <CardBox
                        styleName=""
                        header={<Header title="campaignWhoReceiveMessage" />}
                      >
                        <Row>
                          <Col md={3}>
                            <CardBox
                              header={(
                                <div
                                  className="p-2 bg-primary"
                                >
                                  <h4 className="card-heading m-0 text-white">
                                    <IntlMessages id="demographicSegmentation" />
                                    <i
                                      style={{ float: 'right' }}
                                      className="fa fa-eraser fa-1x "
                                      onClick={( ) => {
                                        form.change( 'demographicSegmentation.gender', null );
                                        form.change( 'demographicSegmentation.ageTo', null );
                                        form.change( 'demographicSegmentation.ageFrom', null );
                                        form.change( 'demographicSegmentation.segmentation', null );
                                      }
                                      }
                                    />
                                  </h4>
                                </div>
                              )}
                            >
                              <p className="mb-2" style={{ fontStyle: 'italic' }}>
                                <IntlMessages id="demographicSegmentationHelpText" />
                              </p>
                              <p className="mb-2" />
                              <IntlMessages id="gender" />
                              <RadioButtons
                                marginContainer={false}
                                field="demographicSegmentation.gender"
                                radioClassName="justify-content-around"
                                options={[
                                  {
                                    id: 'MALE',
                                    value: 'male',
                                  }, {
                                    id: 'FEMALE',
                                    value: 'female',
                                  },
                                ]}
                              />

                              <p className="mb-2">
                                <IntlMessages id="age" />
                              </p>
                              <Row>
                                <Col md>
                                  <TextField
                                    field="demographicSegmentation.ageFrom"
                                    type="number"
                                  />
                                </Col>
                                <Col md="auto">
                                  <IntlMessages id="ageto" />
                                </Col>
                                <Col md>
                                  <TextField
                                    field="demographicSegmentation.ageTo"
                                    type="number"
                                    validate={composeValidators( greaterThan( 'demographicSegmentation.ageFrom' ) )}
                                    translateValues={{ label: 'ageFrom' }}
                                  />
                                </Col>
                              </Row>

                              <p className="mb-2">
                                <IntlMessages id="segment" />
                              </p>
                              <Checkboxes
                                marginContainer={false}
                                displayBlock
                                field="demographicSegmentation.segmentation"
                                labelClassName="col-md-6 mr-0"
                                labelContainerClass="row"
                                options={demographicSegmentationOptions}
                              />
                              <Divider className="my-3" />

                              <p style={{ fontStyle: 'italic' }}><IntlMessages id="campaignListHelpText" /></p>
                              <div className="text-center mt-2">
                                <Dropzone
                                  accept=".txt"
                                  field="uploadFile"
                                  btn={(
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      className="jr-btn jr-btn-lg text-uppercase mr-0"
                                      type="button"
                                    >
                                      <IntlMessages id="upload" />
                                    </Button>
                                  )}
                                />
                              </div>
                            </CardBox>
                          </Col>

                          <FieldArray name="behavioralSegmentations1">
                            {( { fields } ) => (
                              fields.map( ( behavioralSegmentationField, index ) => {
                                const behavioralSegmentation = fields.value[index];
                                if ( !behavioralSegmentation ) return null;

                                return (
                                  <Col md={3} key={behavioralSegmentation.id}>
                                    <BehavioralSegmentation
                                      behavioralSegmentation={behavioralSegmentation}
                                      behavioralSegmentationField={behavioralSegmentationField}
                                      commodities={commodities}
                                      change={form.change}
                                      rounds={rounds}
                                      index={index + 1}
                                    />
                                  </Col>
                                );
                              } ) )}
                          </FieldArray>
                          <Col md={3}>
                            <CardBox
                              header={(
                                <div
                                  className="p-2 bg-primary"
                                >
                                  <h4 className="card-heading m-0 text-white">
                                    <IntlMessages id="iotSegmentation" />
                                    <i
                                      style={{ float: 'right' }}
                                      className="fa fa-eraser fa-1x "
                                      onClick={( ) => {
                                        form.change( 'iotSegmentation.product', null );
                                        form.change( 'iotSegmentation.iotFeature', null );
                                      }
                                      }
                                    />
                                  </h4>
                                </div>
                              )}
                            >
                              <div className="bg-primary p-2 text-white">
                                <IntlMessages id="model" />
                              </div>
                              <div className="pl-3 py-2 mb-3 bg-gray-1 h-75">
                                <RadioButtons
                                  field="iotSegmentation.product"
                                  translate={false}
                                  marginContainer={false}
                                  valueKey="name"
                                  options={products}
                                  displayBlock
                                />
                              </div>

                              <RadioButtons
                                field="iotSegmentation.iotFeature"
                                valueKey="name"
                                options={[
                                  {
                                    id: 'INTENSE_USAGE',
                                    name: 'INTENSE_USAGE',
                                  },
                                  {
                                    id: 'BATTERY_ISSUE',
                                    name: 'BATTERY_ISSUE',
                                  },
                                  {
                                    id: 'PERFORMANCE_ISSUE',
                                    name: 'PERFORMANCE_ISSUE',
                                  },
                                ]}
                                displayBlock
                              />
                            </CardBox>

                          </Col>
                        </Row>
                        <Row>
                          <FieldArray name="behavioralSegmentations2">
                            {( { fields } ) => (
                              fields.map( ( behavioralSegmentationField, index ) => {
                                const behavioralSegmentation = fields.value[index];
                                if ( !behavioralSegmentation ) return null;

                                return (
                                  <Col md={3}>
                                    <BehavioralSegmentation
                                      key={behavioralSegmentation.id}
                                      behavioralSegmentation={behavioralSegmentation}
                                      behavioralSegmentationField={behavioralSegmentationField}
                                      change={form.change}
                                      rounds={rounds}
                                      index={index + 3}
                                    />
                                  </Col>
                                );
                              } ) )}
                          </FieldArray>

                        </Row>
                      </CardBox>
                    </Col>
                  </Row>
                  {this.getStepButtons( form )}
                </>
              )}
              {this.steps[activeStep].id === 3
              && (
                <>
                  {/*
                  <Row>
                    <Col md={4} className="m-md-auto">
                      <CardBox
                        header={<Header title="campaignList" />}
                        styleName=""
                      >
                        <p><IntlMessages id="campaignListHelpText" /></p>
                        <div className="text-center mt-2">
                          <Dropzone
                            accept=".txt"
                            field="uploadFile"
                            btn={(
                              <Button
                                variant="contained"
                                color="primary"
                                className="jr-btn jr-btn-lg text-uppercase mr-0"
                                type="button"
                              >
                                <IntlMessages id="upload" />
                              </Button>
                            )}
                          />
                        </div>
                      </CardBox>
                    </Col>
                  </Row>
                  <Row className="p-3">

                    <Col md={2}>
                      <div className="text-right">
                        <b>
                          <IntlMessages id="campaignList" />
                          :
                        </b>
                      </div>
                    </Col>
                    <Col md={1}>

                      <CardBox
                        header={(
                          <div
                            className="p-2 bg-primary"
                          >
                            <h4 className="card-heading m-0 text-white">
                              <IntlMessages id="rentExternalDatabase" />
                              <i
                                style={{ float: 'right' }}
                                className="fa fa-eraser fa-1x "
                                onClick={( ) => {
                                  form.change( 'rentExternalDatabase', null );
                                }
                                }
                              />
                            </h4>
                          </div>
                        )}
                      >
                        <RadioButtons
                          field="rentExternalDatabase"
                          label="targetPeopleProduct"
                          valueKey="name"
                          options={categories}
                          displayBlock
                        />
                      </CardBox>

                      <div className="text-left">
                        {/* <Button
                          variant="contained"
                          color="primary"
                          className="jr-btn jr-btn-lg text-uppercase mb-5 mr-0"
                          type="button"
                        >
                          <IntlMessages id="DOWNLOADDATABASE" />
                        </Button>
                        <Dropzone
                          accept=".txt"
                          field="uploadFile"
                          btn={(
                            <Button
                              variant="contained"
                              color="primary"
                              className="jr-btn jr-btn-lg text-uppercase mr-0"
                              type="button"
                            >
                              <IntlMessages id="upload" />
                            </Button>
                          )}
                        />
                      </div>

                    </Col>
                    <Col md={4}>
                      <div className="text-left">
                        <IntlMessages id="campaignListHelpText" />
                      </div>
                    </Col>
                  </Row>
                  */}
                  {this.getStepButtons( form )}
                </>
              )}

              {this.steps[activeStep].id === 4
              && (
                <>
                  <Row>
                    <Col md={6}>
                      <CardBox
                        header={(
                          <div
                            className="p-2 bg-primary"
                          >
                            <h4 className="card-heading m-0 text-white">
                              <IntlMessages id="campaignMainObjective" />
                              <i
                                style={{ float: 'right' }}
                                className="fa fa-eraser fa-1x "
                                onClick={( ) => {
                                  form.change( 'contentType', null );
                                }
                                }
                              />
                            </h4>
                          </div>
                        )}
                      >
                        <RadioButtons
                          field="contentType"
                          validate={required}
                          options={contentTypes}
                          onChange={() => {
                            form.change( 'product', null );
                            form.change( 'productFeature', null );
                            form.change( 'service', null );
                            form.change( 'serviceFeature', null );
                            form.change( 'bundle', null );
                            form.change( 'promotePurchasing', null );
                          }}
                        />
                      </CardBox>
                    </Col>
                  </Row>
                  {values.contentType === 'PRODUCT'
                  && (
                    <Row>
                      <Col md={12}>
                        <CardBox
                          header={<Header title="productPromotionCampaign" />}
                          styleName=""
                        >
                          <Row>
                            <Col md={3}>
                              <p className="mb-2">
                                <IntlMessages id="selectProductPromote" />
                              </p>
                              <div className="bg-primary p-2 text-white">
                                <IntlMessages id="model" />
                              </div>
                              <div className="pl-3 pb-2 bg-gray-1">
                                <RadioButtons
                                  field="product"
                                  translate={false}
                                  marginContainer={false}
                                  valueKey="name"
                                  options={products}
                                  displayBlock
                                  validate={required}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <p className="mb-2">
                                <IntlMessages id="defineAttributePromote" />
                              </p>
                              <Checkboxes
                                marginContainer={false}
                                field="productFeature"
                                labelClassName="col-md-12 mr-0"
                                labelContainerClass="row"
                                valueKey="name"
                                options={productAttributes}
                              />
                            </Col>
                            <Col md={6} className="d-flex align-items-center">
                              <Checkbox
                                marginContainer={false}
                                field="promotePurchasing"
                                label="promotePurchasingDirectChannel"
                              />
                            </Col>
                          </Row>
                        </CardBox>
                      </Col>
                    </Row>
                  )}

                  {values.contentType === 'SERVICE'
                  && (
                    <Row>
                      <Col md={12}>
                        <CardBox
                          header={<Header title="servicePromotionCampaign" />}
                          styleName=""
                        >
                          <Row>
                            <Col md={3}>
                              <p className="mb-2">
                                <IntlMessages id="selectServicePromote" />
                              </p>
                              <div className="bg-primary p-2 text-white">
                                <IntlMessages id="model" />
                              </div>
                              <div className="pl-3 pb-2 bg-gray-1">
                                <RadioButtons
                                  field="service"
                                  translate={false}
                                  marginContainer={false}
                                  valueKey="name"
                                  options={services}
                                  displayBlock
                                  validate={required}
                                />
                              </div>
                            </Col>

                            <Col md={3} className="d-flex align-items-center">
                              <Checkbox
                                marginContainer={false}
                                field="promotePurchasing"
                                label="promotePurchasingDirectChannel"
                              />
                            </Col>
                          </Row>
                        </CardBox>
                      </Col>
                    </Row>
                  )}

                  {values.contentType === 'BUNDLE'
                  && (
                    <Row>
                      <Col md={12}>
                        <CardBox
                          header={<Header title="bundlePromotionCampaign" />}
                          styleName=""
                        >
                          <Row>
                            <Col md={3}>
                              <p className="mb-2">
                                <IntlMessages id="selectBundlePromote" />
                              </p>
                              <div className="bg-primary p-2 text-white">
                                <IntlMessages id="model" />
                              </div>
                              <div className="pl-3 pb-2 bg-gray-1">
                                <RadioButtons
                                  field="bundle"
                                  translate={false}
                                  marginContainer={false}
                                  valueKey="name"
                                  options={bundles}
                                  displayBlock
                                  validate={required}
                                />
                              </div>
                            </Col>
                            <Col md={3} className="d-flex align-items-center">
                              <Checkbox
                                marginContainer={false}
                                field="promotePurchasing"
                                label="promotePurchasingDirectChannel"
                              />
                            </Col>
                          </Row>
                        </CardBox>
                      </Col>
                    </Row>
                  )}
                  {this.getStepButtons( form )}
                </>
              )}

              {this.steps[activeStep].id === 5
              && (
                <>
                  <Row>
                    <Col md={6}>
                      <CardBox
                        header={(
                          <div
                            className="p-2 bg-primary"
                          >
                            <h4 className="card-heading m-0 text-white">
                              <IntlMessages id="selectDirectContactMethod" />
                              <i
                                style={{ float: 'right' }}
                                className="fa fa-eraser fa-1x "
                                onClick={( ) => {
                                  form.change( 'directContactMethod', null );
                                }
                                }
                              />
                            </h4>
                          </div>
                        )}
                        styleName=""
                      >
                        <RadioButtons
                          field="directContactMethod"
                          translate={false}
                          marginContainer={false}
                          valueKey="name"
                          displayBlock
                          validate={required}
                          options={[
                            {
                              id: 'DIRECT_MAIL',
                              name: (
                                <span>
                                  <i className="far fa-envelope mr-2" />
                                  <IntlMessages id="DIRECT_MAIL" />
                                </span>
                              ),
                            },
                            {
                              id: 'EMAIL',
                              name: (
                                <span>
                                  <i className="fas fa-envelope-open-text mr-2" />
                                  <IntlMessages id="EMAIL" />
                                </span>
                              ),
                            },
                            {
                              id: 'TELEMARKETING',
                              name: (
                                <span>
                                  <i className="fas fa-phone-square mr-2" />
                                  <IntlMessages id="TELEMARKETING" />
                                </span>
                              ),
                            },
                          ]}
                        />
                      </CardBox>
                    </Col>

                    <Col md={6}>
                      <CardBox
                        header={(
                          <div
                            className="p-2 bg-primary"
                          >
                            <h4 className="card-heading m-0 text-white">
                              <IntlMessages id="campaignDateExecuted" />
                              <i
                                style={{ float: 'right' }}
                                className="fa fa-eraser fa-1x "
                                onClick={( ) => {
                                  form.change( 'execution', null );
                                  form.change( 'months', null );
                                }
                                }
                              />
                            </h4>
                          </div>
                        )}
                        styleName=""
                      >
                        <Row>
                          <Col md="auto" className="pr-0">
                            <RadioButtons
                              field="execution"
                              marginContainer={false}
                              valueKey="name"
                              validate={required}
                              options={[
                                {
                                  id: 'THIS_MONTH',
                                  name: 'THIS_MONTH',
                                },
                                {
                                  id: 'DISTRIBUTE_IN_MONTH',
                                  name: 'DISTRIBUTE_IN_MONTH',
                                },
                                {
                                  id: 'PERMANENT_RUNNING',
                                  name: 'PERMANENT_RUNNING',
                                },
                              ]}
                              onChange={( value ) => {
                                if ( ( !value || value !== 'DISTRIBUTE_IN_MONTH' ) && values.months ) {
                                  form.change( 'months', null );
                                }
                              }}
                              displayBlock
                            />
                          </Col>
                          <Col className="p-0">
                            <div className="d-flex align-items-center pt-4">
                              <div className="w-25 mr-2">
                                <TextField
                                  field="months"
                                  type="number"
                                  disabled={values.execution !== 'DISTRIBUTE_IN_MONTH'}
                                  marginContainer={false}
                                />
                              </div>
                              <IntlMessages id="months" />
                            </div>
                          </Col>
                        </Row>
                      </CardBox>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <CardBox
                        header={(
                          <div
                            className="p-2 bg-primary"
                          >
                            <h4 className="card-heading m-0 text-white">
                              <IntlMessages id="messagesToSend" />
                              <i
                                style={{ float: 'right' }}
                                className="fa fa-eraser fa-1x "
                                onClick={( ) => {
                                  form.change( 'messagePerMonth', null );
                                }
                                }
                              />
                            </h4>
                          </div>
                        )}
                        styleName=""
                      >

                        <Row className="align-items-center pr-0 mb-3">
                          <Col md="auto">
                            <IntlMessages id="maximumMessagesMonth" />
                            {':'}
                          </Col>
                          <Col md={3}>
                            <TextField
                              field="messagePerMonth"
                              type="number"
                              marginContainer={false}
                              validate={required}
                            />
                          </Col>
                        </Row>

                        <p>
                          <IntlMessages id="maximumEstimatedCostMonth" />
                          {': '}
                          {form.getState().values.messagePerMonth
                            ? Util.formatCurrency(
                              Number( form.getState().values.messagePerMonth )
                              * this.getCostbyDirectC( form.getState().values, costIndex ),
                            )
                            : '$0'}
                        </p>
                      </CardBox>
                    </Col>
                  </Row>
                  {this.getStepButtons( form )}
                </>
              )}

              {this.steps[activeStep].id === 6
              && (
                <Row>
                  <Col md={4} className="m-md-auto">
                    <CardBox
                      header={<Header title="finalConfirmation" />}
                      styleName=""
                    >
                      <p><IntlMessages id="approveCampaign" /></p>
                      <div className="text-center mt-2">
                        <Button
                          variant="contained"
                          className="jr-btn jr-btn-lg bg-blue-grey text-white"
                          type="submit"
                          onClick={() => {
                            if ( form.getState().valid ) this.handleBack( form.getState().values );
                          }}
                        >
                          <i className="fa fa-angle-double-left" />
                          <IntlMessages id="back" />
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className="jr-btn jr-btn-lg"
                          type="submit"
                        >
                          <i className="fa fa-save" />
                          <IntlMessages id="saveBtn" />
                        </Button>
                      </div>
                    </CardBox>
                  </Col>
                </Row>
              )}
            </form>
          )}
        />
      </>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( EditCampaign );
