import api from '../config/Api';

const endpoint = 'v1/session';

export default {

  getProducts( id, params ) {
    return api.get( `${endpoint}/${id}/public-market/products`, params );
  },

  getBundles( id, params ) {
    return api.get( `${endpoint}/${id}/public-market/bundles`, params );
  },

  getServices( id, params ) {
    return api.get( `${endpoint}/${id}/public-market/services`, params );
  },

  getMyProducts( id, params ) {
    return api.get( `${endpoint}/${id}/public-market/my-products`, params );
  },

  getMyServices( id, params ) {
    return api.get( `${endpoint}/${id}/public-market/my-services`, params );
  },

  getMyBundles( id, params ) {
    return api.get( `${endpoint}/${id}/public-market/my-bundles`, params );
  },

  traceCommodity( id, data ) {
    return api.put( `${endpoint}/${id}/public-market/tracing`, data );
  },

  getPriceHistory( id, params ) {
    return api.get( `${endpoint}/${id}/public-market/price-history`, params );
  },

  getCompare( id, params ) {
    return api.get( `${endpoint}/${id}/public-market/compare`, params );
  },
};
