import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import IntlMessages from 'utils/IntlMessages';

export default ( { label, onClick, isEdit, linkTo, className } ) =>
  linkTo ? (
      <Link to={linkTo} className="text-decoration-none">
        <Button
          color="primary"
          variant="contained"
          className={classnames( 'jr-btn', className, {
            'jr-btn-lg': !isEdit
          } )}
        >
          <i className={isEdit ? 'fa fa-pencil-alt' : 'zmdi zmdi-plus'}/>
          {label ? <IntlMessages id={label}/> : null}
        </Button>
      </Link>
    )
    : (
      <Button
        color="primary"
        variant="contained"
        onClick={isEdit ? onClick : () => onClick()}
        className={classnames( 'jr-btn', className, {
          'jr-btn-lg': !isEdit
        } )}
      >
        <i className={isEdit ? 'fa fa-pencil-alt' : 'zmdi zmdi-plus'}/>
        {label ? <IntlMessages id={label}/> : null}
      </Button>
    )
