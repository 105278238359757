import React from 'react';
import { Route, Switch } from 'react-router-dom';

import SubHeader from 'components/custom/SubHeader';
import Error404 from 'components/theme/Error404';
import General from './General';
import Sessions from './Sessions';

const University = ( { match } ) => {
  const tabs = [
    {
      link: `${match.url}/general`,
      match: '/general',
      name: 'general',
    },
    {
      link: `${match.url}/sessions`,
      match: '/sessions',
      name: 'sessions',
    },
  ];

  return (
    <div>
      <div className="app-header-horizontal">
        <SubHeader tabs={tabs} />
      </div>

      <Switch>
        <Route path={`${match.url}/sessions`} render={props => <Sessions {...props} entityId={match.params.id} />} />
        <Route
          path={`${match.url}/general`}
          render={props => <General {...props} entityId={match.params.id} />}
        />
        <Route component={Error404} />
      </Switch>
    </div>
  );
};

export default University;
