import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import _ from 'lodash';

import IntlMessages from 'utils/IntlMessages';
import { TextFieldChips, Buttons } from 'components/custom/FormElements';
import { connect } from 'react-redux';
import { required, email } from 'config/InputErrors';
import SettingsActions from 'store/reducers/Settings';
import UserLicenseService from 'services/UserLicense';

const AssignUserLicenses = (
  { open, onClose, sessionId, toggleAlert, toggleLoading, onConfirm },
) => {
  function submitForm( formData ) {
    toggleLoading( true );

    UserLicenseService.assignUserLicense( sessionId, { emails: formData.emails.join( ';' ) } )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) return toggleAlert( response.errors );

        toggleAlert( 'dataSaved', 'info' );
        onConfirm();
      } );
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <Form
        onSubmit={submitForm}
        validate={( values ) => {
          const errors = {};
          if ( required( values.emails ) ) {
            errors.emails = required( values.emails );
          } else if ( _.filter( values.emails, item => !item || !!email( item ) ).length ) {
            errors.emails = 'fieldError.noValidEmails';
          }
          return errors;
        }}
        render={( { handleSubmit, form } ) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle className="bg-dark text-white">
              <IntlMessages id="userLicenses" />
            </DialogTitle>
            <DialogContent className="p-3 overflow-visible">
              <div className="row">
                <div className="col-12">
                  <TextFieldChips
                    field="emails"
                    label="copyOrWriteEmails"
                    splitCharacter=" "
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Buttons onClickCancel={onClose} />
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps,
)( AssignUserLicenses );
