import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { isIOS, isMobile } from 'react-device-detect';
import _ from 'lodash';

import { HORIZONTAL_NAVIGATION } from 'constants/ActionTypes';
import Header from 'components/custom_v2/Header/index';
import Footer from 'components/theme/Footer/index';
import Error404 from 'components/theme/Error404';
import Admin from './routes/Admin';
import User from './routes/V2/User';
import Professor from './routes/V2/Professor';
import ProfileSettings from './routes/ProfileSettings';

const App = ( { match, navigationStyle, user } ) => {
  // set default height and overflow for iOS mobile Safari 10+ support.
  if ( isIOS && isMobile ) {
    document.body.classList.add( 'ios-mobile-view-height' );
  } else if ( document.body.classList.contains( 'ios-mobile-view-height' ) ) {
    document.body.classList.remove( 'ios-mobile-view-height' );
  }

  function hasPermissions( role, license ) {
    // if ( role === 'ROLE_SUPERVISOR' && user.roles && user.roles.indexOf( role ) === 0 ) {
    //  return true;
    // }
    return user.roles && user.roles.indexOf( role ) !== -1
      && user.licenses && _.find( user.licenses, { owner: license } );
  }

  return (
    <div className="app-container">
      <div className="app-main-container">
        <div
          className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}
        >
          <Header user={user} />
        </div>

        <main className="app-main-content-wrapper">
          <div className="app-main-content animated slideInUpTiny animation-duration-3">
            <Switch>
              <Route
                path={`${match.url}profile-settings`}
                render={props => <ProfileSettings {...props} user={user} />}
              />
              {hasPermissions( 'ROLE_ADMIN', 'adm' )
                ? (
                  <Route
                    path={match.url}
                    component={Admin}
                  />
                ) : null}
              {hasPermissions( 'ROLE_CLIENT', 'user' )
                ? (
                  <Route
                    path={match.url}
                    render={props => <User {...props} user={user} />}
                  />
                ) : null}
              {hasPermissions( 'ROLE_SUPERVISOR', 'sup' )
                ? (
                  <Route
                    path={match.url}
                    component={Professor}
                  />
                ) : null}
              <Route component={Error404} />
            </Switch>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
};


const mapStateToProps = ( { settings, user } ) => {
  const { navigationStyle } = settings;
  return {
    navigationStyle,
    user,
  };
};
export default connect( mapStateToProps )( App );
