import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {};

const { Types, Creators } = createActions( {
  loginUser: ['data'],
  logoutUser: null,
  changeActiveSession: ['activeSession'],
}, {} );

const loginUser = ( state, { data } ) => ( { ...state, ...data } );
const logoutUser = () => ( {} );
const changeActiveSession = ( state, { activeSession } ) => ( { ...state, activeSession } );

export const reducer = createReducer( INITIAL_STATE, {
  [Types.LOGIN_USER]: loginUser,
  [Types.LOGOUT_USER]: logoutUser,
  [Types.CHANGE_ACTIVE_SESSION]: changeActiveSession,
} );

export const UserTypes = Types;
export default Creators;
