import React from 'react';

import { connect } from 'react-redux';
import BreadcrumbComp from 'components/custom_v2/Breadcrumb';
import { Col, Row } from 'reactstrap';
import _ from 'lodash';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import DashboardStudentService from 'services/DashboardStudent';
import IntlMessages from 'utils/IntlMessages';
import CardBox from 'components/custom_v2/CardBox';
import ChartModal from 'routes/App/components/User/Chart';
import SettingsActions from 'store/reducers/Settings';
import parallel from 'async/parallel';
import Util from 'utils/Util';

class CustomerCharts extends React.Component {
  state = {
    customerReport: null,
    chartModal: false,
    seriesTitle1: [],
  };

  componentDidMount() {
    const { user, toggleLoading } = this.props;
    toggleLoading( true );
    let result1 = null;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );


    parallel( [
      ( cb ) => {
        DashboardStudentService.getCustomerInformation( user.activeSession.id,
          { workgroup: this.license.workGroup.id } )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
      },
      ( cb ) => {
        DashboardStudentService.getCustomerEvolution( user.activeSession.id,
          { workgroup: this.license.workGroup.id } )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
      },
      ( cb ) => {
        DashboardStudentService.getCustomerRetention( user.activeSession.id,
          { workgroup: this.license.workGroup.id } )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data );
          } );
      },
    ], ( error, results ) => {
      if ( !error ) {
        if ( results ) {
          this.setState( { customerReport: results[0] } );

          const dataList = _.map( results[1].data, item => ( {
            attribute: item.round.shortName,
            activeCustomers: item.activeCustomers,
            inactiveCustomers: item.inactiveCustomers,
          } ) );
          result1 = this.createCharSerie( 'chartcustomeractivity', dataList,
            [
              { id: 'activeCustomers', name: 'Active Customers' },
              { id: 'inactiveCustomers', name: 'Inactive Customers' },
            ] );
          this.config = _.cloneDeep( result1.config );
          this.chart = result1.chart;

          const dataList0 = _.map( results[2].data, item => ( {
            attribute: item.product,
            valueWithService: item.valueWithService,
          } ) );
          result1 = this.createCharSerie( 'chartevolution', dataList0, [
            { id: 'valueWithService', name: 'With Customers' },
          ] );
          this.config1 = _.cloneDeep( result1.config );
          this.chart1 = result1.chart;

          const dataList2 = _.map( results[2].data, item => ( {
            attribute: item.product,
            valueWithoutService: item.valueWithoutService,
          } ) );

          result1 = this.createCharSerie( 'charaverage1', dataList2, [
            { id: 'valueWithoutService', name: 'Without Customers' },
          ] );
          this.config2 = _.cloneDeep( result1.config );
          this.chart2 = result1.chart;
        }
      }
      toggleLoading( false );
    } );

    result1 = this.createCharSerie( 'chartretention', [], [] );
    this.config3 = _.cloneDeep( result1.config );
    this.chart3 = result1.chart;

    result1 = this.createCharSerie( 'charaverage2', [], [] );
    this.config4 = _.cloneDeep( result1.config );
    this.chart4 = result1.chart;
  }

  componentWillUnmount() {
    if ( this.chart ) {
      this.chart.dispose();
    }
  }

  toggleChartModal = ( configModal, titleModal, seriesTitleModal ) => {
    this.setState( previousState => ( {
      chartModal: !previousState.chartModal,
      configModal,
      titleModal,
      seriesTitleModal,
    } ) );
  };

  createCharSerie=( key, roundsCat, datas ) => {
    const config = {
      data: roundsCat,
      type: am4charts.XYChart,
      xAxes: [{
        type: 'CategoryAxis',
        dataFields: { category: 'attribute' },
      }],
      yAxes: [{ type: 'ValueAxis' }],
      series: _.map( datas, itemX => ( {
        type: 'ColumnSeries',
        dataFields: {
          valueY: itemX.id,
          categoryX: 'attribute',
        },
        name: itemX.name,
        strokeWidth: 2,
      } ) ),
    };
    const chart = am4core.createFromConfig( _.cloneDeep( config ), key );
    return { config, chart };
  };

  render() {
    const {
      chartModal,
      configModal,
      titleModal,
      seriesTitleModal,
      seriesTitle1,
      customerReport,
    } = this.state;
    return (
      <>
        <BreadcrumbComp links={[
          {
            url: '/',
            name: 'informationDashboard',
          },
          { name: 'customer' },
        ]}
        />

        <Row>
          {!!customerReport
            && (
              <Col lg={6}>
                <CardBox heading="RegisteredCustomerBase" headerBg="bg-customer">
                  <Row>
                    <Col lg={4}><b><IntlMessages id="NumberOfCustomers" /></b></Col>
                    <Col lg={2} className="text-right">{Util.formatNumber( customerReport.numberOfCustomers )}</Col>
                    <Col lg={4}><b><IntlMessages id="ProductsPerCustomer" /></b></Col>
                    <Col lg={2} className="text-right">{Util.formatNumber( customerReport.productsPerCustomer )}</Col>
                  </Row>
                  <Row>
                    <Col lg={4}><IntlMessages id="ActiveCustomers" /></Col>
                    <Col className="text-right">{Util.formatNumber( customerReport.activeCustomers )}</Col>
                    <Col lg={4}><IntlMessages id="Devicespercustomer" /></Col>
                    <Col className="text-right">{Util.formatNumber( customerReport.devicesPerCustomer )}</Col>
                  </Row>
                  <Row className="mb-3">
                    <Col lg={4}><IntlMessages id="InactiveCustomers" /></Col>
                    <Col className="text-right">{Util.formatNumber( customerReport.inactiveCustomers )}</Col>
                    <Col lg={4}><IntlMessages id="ServicesPerCustomer" /></Col>
                    <Col className="text-right">{Util.formatNumber( customerReport.servicesPerCustomer )}</Col>
                  </Row>
                  <Row>
                    <Col lg={4}><IntlMessages id="NewCustomer (current month)" /></Col>
                    <Col className="text-right">{Util.formatNumber( customerReport.newCustomers )}</Col>
                    <Col lg={4}><IntlMessages id="RevenuesPerCustomer (12 month)" /></Col>
                    <Col className="text-right">{Util.formatNumber( customerReport.revenuesPerCustomer )}</Col>
                  </Row>
                  <Row>
                    <Col lg={4}><IntlMessages id="NewCustomer (12 month)" /></Col>
                    <Col className="text-right">{Util.formatNumber( customerReport.lastNewCustomers )}</Col>
                    <Col lg={4}><IntlMessages id="ProfitsPerCustomer (12 month)" /></Col>
                    <Col className="text-right">{Util.formatNumber( customerReport.profitPerCustomer )}</Col>
                  </Row>
                  <Row>
                    <Col lg={4}><IntlMessages id="Lost customers (current month)" /></Col>
                    <Col className="text-right">{Util.formatNumber( customerReport.lostCustomers )}</Col>
                    <Col lg={4} />
                    <Col />
                  </Row>
                  <Row>
                    <Col lg={4}><IntlMessages id="Lost customers (12 month)" /></Col>
                    <Col className="text-right">{Util.formatNumber( customerReport.lastLostCustomers )}</Col>
                    <Col lg={4}><IntlMessages id="EstimatedCustomerLifetime" /></Col>
                    <Col className="text-right">{Util.formatNumber( customerReport.estimatedCustomerLifetime )}</Col>
                  </Row>
                  <Row>
                    <Col lg={4}><IntlMessages id="RetentionRate" /></Col>
                    <Col className="text-right">{Util.formatNumber( customerReport.retentionRate )}</Col>
                    <Col lg={4} />
                    <Col />
                  </Row>
                  <Row>
                    <Col lg={4}><IntlMessages id="estimatedCustomerDuration" /></Col>
                    <Col className="text-right">{Util.formatNumber( customerReport.estimatedCustomerDuration )}</Col>
                    <Col lg={4} />
                    <Col />
                  </Row>
                </CardBox>
              </Col>
            )}
          <Col lg={6}>
            <CardBox
              heading="Evolution_of_Customer_Activity"
              childrenStyle=""
              headerBg="bg-customer"
              onClick={() => this.toggleChartModal( this.config, <IntlMessages id="Evolution_of_Customer_Activity" />, seriesTitle1 )}
            >
              <h5><IntlMessages id="Total_Active_and_Inactive_Customers" /></h5>
              <div className="jr-link" id="chartcustomeractivity" style={{ width: '100%', height: '250px' }} />
            </CardBox>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <CardBox
              heading="retention_rate_by_number_of_products_customers"
              childrenStyle=""
              headerBg="bg-customer"
              onClick={() => this.toggleChartModal( this.config1, <IntlMessages id="retention_rate_by_number_of_products_customers" />, seriesTitle1 )}
            >
              <div className="jr-link" id="chartevolution" style={{ width: '100%', height: '250px' }} />
            </CardBox>
          </Col>
          <Col lg={6}>
            <CardBox
              heading="average_profits_per_customer_by_number_of_products"
              childrenStyle=""
              headerBg="bg-customer"
              onClick={() => this.toggleChartModal( this.config2, <IntlMessages id="average_profits_per_customer_by_number_of_products" />, seriesTitle1 )}
            >
              <div className="jr-link" id="charaverage1" style={{ width: '100%', height: '250px' }} />
            </CardBox>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <CardBox
              heading="Retention_rate_by_number_of_devices_customer_has"
              childrenStyle=""
              headerBg="bg-customer"
              onClick={() => this.toggleChartModal( this.config3, <IntlMessages id="Retention_rate_by_number_of_devices_customer_has" />, seriesTitle1 )}
            >
              <div className="jr-link" id="chartretention" style={{ width: '100%', height: '250px' }} />
            </CardBox>
          </Col>
          <Col lg={6}>
            <CardBox
              heading="Average_profits_per_customer_by_number_of_devices_customer_has"
              childrenStyle=""
              headerBg="bg-customer"
              onClick={() => this.toggleChartModal( this.config4, <IntlMessages id="Average_profits_per_customer_by_number_of_devices_customer_has" />, seriesTitle1 )}
            >
              <div className="jr-link" id="charaverage2" style={{ width: '100%', height: '250px' }} />
            </CardBox>
          </Col>
        </Row>

        <ChartModal
          open={chartModal}
          title={titleModal}
          onClose={this.toggleChartModal}
          seriesTitle={seriesTitleModal}
          config={configModal}
        />

      </>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = { toggleLoading: SettingsActions.toggleLoading };

export default connect( mapStateToProps, mapDispatchToProps )( CustomerCharts );
