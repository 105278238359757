import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { Button } from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
import _ from 'lodash';

import SettingsActions from 'store/reducers/Settings';
import AttributeCostParamService from 'services/AttributeCostParam';
import AttributeCostCemService from 'services/AttributeCostCem';
import AttributeCostService from 'services/AttributeCostService';
import CardBox from 'components/theme/CardBox';
import { Select } from 'components/custom/FormElements';
import IntlMessages from 'utils/IntlMessages';
import AttributeCostMatrix from 'routes/App/components/Admin/Params/AttributeCostMatrix';
import { required } from 'config/InputErrors';

const AttributeCostParams = ( {
  data, options, selectedVariable, selectedAttribute, selectedCategory,
  getAttributeDetail, toggleLoading, toggleAlert,
} ) => {
  const saveAttributes = ( formData ) => {
    if ( !formData || !formData.data ) return toggleAlert( 'Error' );
    toggleLoading( true );

    const attributes = _.map( formData.data, item => ( {
      id: item.id,
      round: item.round,
      value1: item.value1,
      value2: item.value2,
      value3: item.value3,
      value4: item.value4,
      value5: item.value5,
      value6: item.value6,
      value7: item.value7,
      value8: item.value8,
      value9: item.value9,
      value10: item.value10,
    } ) );

    let promise = null;
    if ( selectedVariable === 1 ) {
      promise = AttributeCostParamService.updateAttributeCostParam( selectedAttribute, {
        attrCostParams: attributes,
        formulaCost: formData.formulaCost,
        category: selectedCategory,
      } );
    }
    if ( selectedVariable === 2 ) {
      promise = AttributeCostService.updateAttributeCostService( selectedAttribute, {
        attributesCostService: attributes,
        formulaCost: formData.formulaCost,
        serviceType: selectedCategory,
      } );
    }
    if ( selectedVariable === 3 ) {
      promise = AttributeCostCemService.updateAttributeCostCem( selectedAttribute, {
        attributesCostCEM: attributes,
        formulaCost: formData.formulaCost,
      } );
    }

    promise.then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) return toggleAlert( response.errors );
      toggleAlert( 'dataSaved', 'info' );
    } );
  };

  const changeVariable = ( variable ) => {
    if ( variable === 1 ) {
      const category = options.categories[0] ? options.categories[0].id : null;
      const attribute = options.productAttributes[0] ? options.productAttributes[0].id : null;
      getAttributeDetail( variable, attribute, category );
    }
    if ( variable === 2 ) {
      const category = options.serviceTypes[0] ? options.serviceTypes[0].id : null;
      const attribute = options.serviceAttributes[0] ? options.serviceAttributes[0].id : null;
      getAttributeDetail( variable, attribute, category );
    }
    if ( variable === 3 ) {
      const attribute = options.cemAttributes[0] ? options.cemAttributes[0].id : null;
      getAttributeDetail( variable, attribute, null );
    }
  };

  const attributes = selectedVariable === 1 ? options.productAttributes
    : selectedVariable === 2
      ? options.serviceAttributes : selectedVariable === 3 ? options.cemAttributes : [];

  return (
    <Form
      initialValues={data}
      onSubmit={saveAttributes}
      mutators={{ ...arrayMutators }}
      render={( { handleSubmit, form, values } ) => (
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              <CardBox
                cardStyle="jr-full-card m-0"
                headingStyle="mb-0 pb-0"
                heading={[
                  <i
                    key={1}
                    className="zmdi zmdi-globe-alt zmdi-hc-lg text-secondary mr-2"
                  />,
                  <IntlMessages id="attributeCostParams" key={2} />,
                ]}
              >
                <div className="row px-5 pt-2 py-3">
                  <div className="col-md-3">
                    <Select
                      isClearable={false}
                      translateOptions={false}
                      marginContainer={false}
                      label="variable"
                      containerClass="mb-3 mb-md-0"
                      value={selectedVariable}
                      onChange={changeVariable}
                      options={[
                        {
                          id: 1,
                          name: 'Product',
                        },
                        {
                          id: 2,
                          name: 'Service',
                        },
                        {
                          id: 3,
                          name: 'CEM',
                        },
                      ]}
                    />
                  </div>
                  {selectedVariable === 1
                  && (
                    <div className="col-md-3">
                      <Select
                        isClearable={false}
                        translateOptions={false}
                        marginContainer={false}
                        label="category"
                        containerClass="mb-3 mb-md-0"
                        options={options.categories}
                        value={selectedCategory}
                        onChange={value => getAttributeDetail(
                          selectedVariable, selectedAttribute, value,
                        )}
                      />
                    </div>
                  )}

                  {selectedVariable === 2
                  && (
                    <div className="col-md-3">
                      <Select
                        isClearable={false}
                        translateOptions={false}
                        marginContainer={false}
                        label="serviceType"
                        containerClass="mb-3 mb-md-0"
                        options={options.serviceTypes}
                        value={selectedCategory}
                        onChange={value => getAttributeDetail(
                          selectedVariable, selectedAttribute, value,
                        )}
                      />
                    </div>
                  )}

                  {selectedVariable === 3
                  && (
                    <div className="col-md-3">
                      <Select
                        isClearable={false}
                        translateOptions={false}
                        marginContainer={false}
                        label="cem"
                        containerClass="mb-3 mb-md-0"
                        options={[{ id: 'cem', name: 'CEM' }]}
                        value="cem"
                      />
                    </div>
                  )}

                  <div className="col-md-3">
                    <Select
                      isClearable={false}
                      translateOptions={false}
                      marginContainer={false}
                      label="attribute"
                      containerClass="mb-3 mb-md-0"
                      options={attributes}
                      value={selectedAttribute}
                      onChange={value => getAttributeDetail(
                        selectedVariable, value, selectedCategory,
                      )}
                    />
                  </div>
                </div>

                <AttributeCostMatrix
                  fieldName="data"
                  data={values ? values.data : []}
                  values={values}
                />

                <div className="d-flex align-items-center pt-2 pb-4">
                  <div className="col-md-3">
                    <Select
                      field="formulaCost"
                      isClearable={false}
                      translateOptions={false}
                      marginContainer={false}
                      containerClass="mb-3 mb-md-0"
                      validate={required}
                      label="costFormula"
                      options={[
                        {
                          id: 'METHOD_COST_TYPE_LINEAR',
                          name: 'Linear',
                        },
                        {
                          id: 'METHOD_COST_TYPE_EXPONENTIAL',
                          name: 'Exponential',
                        },
                        {
                          id: 'METHOD_COST_TYPE_PORCENTUAL',
                          name: 'Porcentual',
                        },
                        {
                          id: 'METHOD_COST_TYPE_UNRELATED',
                          name: 'Unrelated',
                        },
                      ]}
                    />
                  </div>

                  <div className="col-md-9 text-right">
                    <Button
                      variant="contained"
                      color="primary"
                      className="jr-btn jr-btn-lg"
                      type="submit"
                    >
                      <i className="fa fa-save" />
                      <IntlMessages id="saveBtn" />
                    </Button>
                  </div>
                </div>
              </CardBox>
            </div>
          </div>
        </form>
      )}
    />
  );
};

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps,
)( AttributeCostParams );
