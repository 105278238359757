import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import _ from 'lodash';
import moment from 'moment';
import { Button } from '@material-ui/core';

import IntlMessages from 'utils/IntlMessages';
import Util from 'utils/Util';

import UserActions from 'store/reducers/User';
import SettingsActions from 'store/reducers/Settings';
import CardBox from 'components/custom_v2/CardBox';
import BreadcrumbComp from 'components/custom_v2/Breadcrumb';
import { TextField, RadioButtons, Select, DatePicker, Checkbox, TimePicker } from 'components/custom/FormElements';
import { composeValidators, greaterThan, lessOrEqualValue, required, greaterOrEqualValue } from 'config/InputErrors';

import UserService from 'services/User';
import SessionService from 'services/Session';
import GlobalVariableService from 'services/GlobalVariable';
import utilTimeZone from 'utils/TimeZone';

import { UncontrolledPopover, PopoverHeader, PopoverBody, Row, Col, Badge, Modal, ModalBody, ModalHeader } from 'reactstrap';

import WorkingDaysCalendarV2 from '../../../../components/Professor/Session/WorkingDaysCalendarV2';


class newSession extends React.Component {
  activeClosingTime = false;
  workingDays=[];
  saturday= false;
  sunday = true;
  maxRoundsExceed=false;
  totalRounds = 0;

  state = {
    workingDays: [],
    maxGroups: 0,
    maxStudents: 0,
    studentsPerGroup: 0,
    totalRound: 0,
    workingDaysModal: false,
    open: false,
    data: { sunday: this.sunday },
    anchorEl: null,
    registrationCode: null,
    popoverItems: [],
    listOfSession: [],
    sessionsData: [],
    previewVisible: false,
    cloneSessionId: null,

  };
  componentDidMount() {
    const { user } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean( anchorEl );
    this.setState( { open } );

    try {
      this.entityId = user.activeSession.entity.id;
    } catch ( err ) {
      this.entityId = user.entity[0].id;
    }

    this.getData();
    this.setData();
  }

  setData = () => {
    const popoverItems = [];
    popoverItems.push( {
      id: 'cloneBtnID',
      header: 'Clone Session',
      content: 'Choose a Session and Clone it',
    } );
    popoverItems.push( {
      id: 'nameFieldID',
      header: 'What is Name?',
      content: 'The name session is the identifier of the one session in the MK21. It must be unique',
    } );

    popoverItems.push( {
      id: 'studentsFieldID',
      header: 'Total Students...',
      content: 'The total students by Session',
    } );
    popoverItems.push( {
      id: 'timezoneFieldID',
      header: 'Time Zone...',
      content: 'Select the Time Zone for the Session',
    } );

    popoverItems.push( {
      id: 'studentsPerGroupFieldID',
      header: 'What is stutendes per group?',
      content: 'This is a calculate fields  maxStudents / maxGroup per session',
    } );
    popoverItems.push( {
      id: 'endDateFieldID',
      header: 'Date Last Round',
      content: 'The Date Last Round of the Session',
    } );

    popoverItems.push( {
      id: 'startDateFieldID',
      header: 'Date of the First Round',
      content: 'Date of the First Round...',
    } );

    popoverItems.push( {
      id: 'sundayFieldID',
      header: 'Sunday',
      content: 'Exclude Sunday',
    } );

    popoverItems.push( {
      id: 'saturdayFieldID',
      header: 'Saturday...',
      content: 'Exclude Saturday',
    } );

    popoverItems.push( {
      id: 'workingDaysFieldID',
      header: 'Working days',
      content: 'Excludes Holidays or another days...',
    } );
    this.setState( { popoverItems: [] } );
  }
  getData() {
    GlobalVariableService.getMaxGroups()
      .then( ( response ) => {
        if ( response.ok ) {
          const maxGroups = response.data.value;
          this.setState( { maxGroups } );
        }
      } );

    GlobalVariableService.getMaxStudents()
      .then( ( response ) => {
        if ( response.ok ) {
          const maxStudents = response.data.value;
          this.setState( { maxStudents } );
        }
      } );
    SessionService.cloneList()
      .then( ( response ) => {
        if ( response.ok ) {
          const sessionsData = response.data;
          const listOfSession = _.map( sessionsData, item => ( { id: item.id, name: item.name } ) );
          this.setState( { listOfSession, sessionsData } );
        }
      } );
    const timeZones = _.map( utilTimeZone.getTimeZones(), item => ( { id: item, name: item } ) );
    this.setState( { timeZones } );
  }

  getWorkingDays = ( callbackFunc ) => {
    const { user } = this.props;
    const { workingDays } = this.state;

    if ( user.activeSession ) {
      try {
        this.entityId = user.activeSession.entity.id;
      } catch ( err ) {
        this.entityId = user.entity[0].id;
      }
      this.setState( { workingDays }, callbackFunc );
    }
  };

  toConfirm = ( data ) => {
    const { workingDays } = this.state;

    let newData = [];


    if ( data.event === 'remove' ) {
      _.forEach( workingDays, ( obj ) => {
        if ( obj.start !== data.data.start ) {
          newData.push( obj );
        }
      } );
      this.setState( { workingDays: newData } );
    }


    if ( data.event === 'add' ) {
      newData = _.concat( workingDays, data.data );
      this.setState( { workingDays: newData } );
    }

    this.workingDays = [];
    _.forEach( newData, ( obj ) => {
      this.workingDays.push( obj.start );
    } );


    this.calculateTotalRound();
  }

  toggleWorkingDaysModal = () => {
    this.setState( previousState => ( { workingDaysModal: !previousState.workingDaysModal } ) );
  };

  submitForm = async ( formData ) => {
    const { toggleLoading, toggleAlert, user, changeActiveSession } = this.props;

    const dataToSend = {};

    const exclude = [];

    if ( this.saturday ) {
      exclude.push( 'SATURDAY' );
    }

    if ( this.sunday ) {
      exclude.push( 'SUNDAY' );
    }

    toggleLoading( true );

    if ( formData.closingType === 'CLOSING_TIME' ) {
      dataToSend.endTime = moment( Number( formData.closingTime ) ).format( 'HH:mm' );
      dataToSend.manualClosing = false;
    }

    if ( formData.closingType === 'MANUAL_CLOSING' ) {
      dataToSend.manualClosing = true;
    }

    dataToSend.exclude = exclude;
    dataToSend.endDate = moment( Number( formData.endDate ) ).format( 'YYYY-MM-DD' );
    dataToSend.startDate = moment( Number( formData.startDate ) ).format( 'YYYY-MM-DD' );
    dataToSend.workingDays = this.workingDays;
    dataToSend.entity = this.entityId;
    dataToSend.totalStudents = formData.totalStudents;
    dataToSend.timeZone = formData.timeZone;
    dataToSend.name = formData.name;
    dataToSend.totalRounds = this.totalRounds;

    const promise = SessionService.saveSessionV2( dataToSend );
    await promise.then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) {
        try {
          if ( ( !!response.data.form ) && ( response.data.form.errors ) ) {
            return toggleAlert( response.data.form.errors.name );
          }
          return toggleAlert( response.errors );
        } catch ( err ) {
          return toggleAlert( 'error500' );
        }
      }
      this.setState( { registrationCode: response.data.session.registrationCode } );
      toggleAlert( 'dataSaved', 'info' );
    } );

    await UserService.getCurrentUser( user )
      .then( ( response ) => {
        if ( !response.errors ) {
          const data = { ...user, ...response.data };
          try {
            changeActiveSession( data.licenses[0].session );
          } catch ( err ) {
            // eslint-disable-next-line no-console
            console.log( err );
          }
        }
      } );
  }


  handleClickOpen = () => {
    this.setState( previousState => ( { open: !previousState.open } ) );
  }

  handleClose= () => {
    this.setState( previousState => ( { open: !previousState.open } ) );
  }

  toggle= () => {
    this.setState( previousState => ( { open: !previousState.open } ) );
  }

  calculateTotalRound = ( ) => {
    const { toggleLoading, toggleAlert } = this.props;

    const exclude = [];

    if ( this.saturday ) {
      exclude.push( 'SATURDAY' );
    }

    if ( this.sunday ) {
      exclude.push( 'SUNDAY' );
    }

    const dataToSend = {
      startDate: this.startDate,
      endDate: this.endDate,
      exclude,
      workingDays: this.workingDays,
    };

    const currentDate = new Date().setHours( 0, 0, 0, 0 );

    if (
      this.startDate === undefined
      || this.startDate === ''
      || this.endDate === undefined
      || this.endDate === ''
      || currentDate > moment( this.startDate ).toDate()
      || moment( this.endDate ).toDate() <= moment( this.startDate ).toDate()
    ) {
      return;
    }

    toggleLoading( true );

    const promise = SessionService.calculateRounds( dataToSend );

    promise.then( ( response ) => {
      toggleLoading( false );
      if ( response.ok ) {
        this.maxRoundsExceed = false;
        this.totalRounds = response.data.possibleRounds;
        this.setState( { totalRound: response.data.possibleRounds } );
      } else {
        this.maxRoundsExceed = true;
        this.setState( { totalRound: 0 } );
        toggleAlert( response.errors );
      }
    } );
  }

  handleStartDateChange = ( date ) => {
    if ( date === '' ) {
      this.startDate = '';
    } else {
      this.startDate = moment( Number( date ) ).format( 'YYYY-MM-DD' );
    }
    this.calculateTotalRound();
  };

  handleEndDateChange = ( date ) => {
    if ( date === '' ) {
      this.endDate = '';
    } else {
      this.endDate = moment( Number( date ) ).format( 'YYYY-MM-DD' );
    }
    this.calculateTotalRound();
  };

  handleSaturdayChange = ( value_ ) => {
    this.saturday = value_;
    this.calculateTotalRound();
  };

  handleSundayChange = ( value_ ) => {
    this.sunday = value_;
    this.calculateTotalRound();
  };

  handleTotalStudentsChange = ( value ) => {
    const { maxGroups } = this.state;
    this.setState( { studentsPerGroup: Math.ceil( value / maxGroups ) } );
  }
  handleClone= ( value ) => {
    this.setState( { cloneSessionId: value } );
  }
  handleCloneSession= ( ) => {
    this.handleToggle();
    const { cloneSessionId, sessionsData } = this.state;
    const obj = sessionsData.find( o => o.id === cloneSessionId );
    this.endDate = moment( Number( obj.endDate ) ).format( 'YYYY-MM-DD' );
    this.startDate = moment( Number( obj.startDate ) ).format( 'YYYY-MM-DD' );
    this.setState( { data: obj, studentsPerGroup: obj.studentPerGroup } );
    this.calculateTotalRound();
  }

  handleClick = ( event ) => {
    const open = true;
    this.setState( { anchorEl: event.currentTarget } );
    this.setState( { open } );
  };

  handleClose = () => {
    const open = false;
    this.setState( { anchorEl: null } );
    this.setState( { open } );
  };

  nextFocus = ( ) => {
    document.getElementById( 'studentsFieldID' ).click();
    // console.log(event.target.id);
  }
  handleToggle() {
    const { previewVisible } = this.state;
    this.setState( { previewVisible: !previewVisible } );
  }
  render() {
    const {
      maxStudents,
      workingDaysModal,
      workingDays,
      timeZones,
      maxGroups,
      studentsPerGroup,
      totalRound,
      data,
      registrationCode,
      popoverItems,
      previewVisible,
      listOfSession,
    } = this.state;

    return (
      <>
        <div className="border jr-border-radius p-3">

          <BreadcrumbComp links={[
            {
              url: '/',
              name: 'dashboard',
            },
            { name: 'newSession' },
          ]}
          />

          <Row>
            <Col lg={12}>
              <CardBox heading="newSession" headerBg="bg-customer">
                <Row>
                  <Col md={12}>
                    <Form
                      initialValues={data}
                      onSubmit={this.submitForm}
                      validate={( values ) => {
                        const errors = {};
                        if ( ( values.closingType === 'CLOSING_TIME' ) && ( !values.closingTime ) ) {
                          errors.closingTime = required( values.closingTime );
                        }

                        return errors;
                      }}
                      render={( { handleSubmit, form } ) => (
                        <form onSubmit={handleSubmit}>

                          <div className="row">
                            <div
                              className="col-md-4"
                            >
                              <TextField field="name" label="name" validate={required} id="nameFieldID" />
                            </div>
                            <div
                              className="col-md-2"
                            >

                              <span id="startDateFieldID">
                                <IntlMessages id="dateFirstRound" />
                              </span>

                              <DatePicker

                                field="startDate"
                                // eslint-disable-next-line max-len
                                validate={composeValidators( required, greaterOrEqualValue( new Date().setHours( 0, 0, 0, 0 ) ) )}
                                onChange={this.handleStartDateChange}
                                translateValues={{ value: moment().format( 'YYYY-MM-DD' ) }}

                              />
                            </div>

                            <div
                              className="col-md-2"
                              variant="contained"
                              color="primary"
                            >
                              <span id="endDateFieldID">
                                <IntlMessages id="dateLastRound" />
                              </span>

                              <DatePicker

                                field="endDate"
                                validate={composeValidators( required, greaterThan( 'startDate' ) )}
                                onChange={this.handleEndDateChange}
                                translateValues={{ label: <IntlMessages id="dateFirstRound" /> }}
                              />

                            </div>

                            { !registrationCode && (
                              <div className="col-md-4 text-right ">

                                <Button
                                  variant="contained"
                                  color="primary"
                                  className="jr-btn jr-btn-lg"
                                  type="submit"
                                >
                                  <i className="fa fa-arrow-circle-right" />
                                  <IntlMessages id="createSessionAndRound" />
                                </Button>
                              </div>
                            )}
                          </div>

                          <div className="row">
                            <div
                              className="col-md-2"
                            >
                              <TextField
                                field="totalStudents"
                                label="totalStudents"
                                // eslint-disable-next-line max-len
                                validate={composeValidators( required, lessOrEqualValue( maxGroups * maxStudents ) )}
                                translateValues={{ value: maxGroups * maxStudents }}
                                id="studentsFieldID"
                                onChange={this.handleTotalStudentsChange}
                              />
                            </div>
                            <div className="col-md-1" style={{ alignSelf: 'center', justifyContent: 'center' }}>
                              <h5 className="mb-1">
                                <span id="studentsPerGroupFieldID">
                                  <IntlMessages id="studensPerGroup" />
                                </span>
                              </h5>
                            </div>
                            <div className="col-md-1" style={{ alignSelf: 'center', justifyContent: 'center' }}>
                              <Badge className="bg-blue-grey text-white d-block ml-1" pill>
                                <h4 className="mb-1">{ Util.formatNumberNoDecimals( studentsPerGroup ) }</h4>
                              </Badge>
                            </div>
                            <div
                              className="col-md-4"
                            >
                              <IntlMessages id="Exclude" />
                              <Row>
                                <Col>
                                  <Checkbox
                                    id="saturdayFieldID"
                                    marginContainer={false}
                                    fullWidth={false}
                                    field="saturday"
                                    label="Saturday"
                                    onChange={this.handleSaturdayChange}
                                  />
                                  <Checkbox
                                    id="sundayFieldID"
                                    marginContainer={false}
                                    fullWidth={false}
                                    field="sunday"
                                    label="Sunday"
                                    onChange={this.handleSundayChange}
                                  />
                                </Col>
                              </Row>

                            </div>

                            <div className="col-md-2">
                              <RadioButtons
                                field="closingType"
                                valueKey="name"
                                options={[
                                  {
                                    id: 'CLOSING_TIME',
                                    name: 'CLOSING_TIME',
                                  },
                                  {
                                    id: 'MANUAL_CLOSING',
                                    name: 'MANUAL_CLOSING',
                                  },
                                ]}
                                onChange={( value ) => {
                                  if ( value === 'MANUAL_CLOSING' ) {
                                    form.change( 'closingTime', null );
                                    this.activeClosingTime = false;
                                  }
                                  if ( value === 'CLOSING_TIME' ) {
                                    this.activeClosingTime = true;
                                  }
                                }}
                                displayBlock
                              />
                            </div>
                            <div className="col-md-2">
                              <TimePicker
                                label="closingTime"
                                field="closingTime"
                                disabled={!this.activeClosingTime}
                                marginContainer={false}
                              />
                            </div>

                          </div>

                          <div className="row">
                            <div
                              className="col-md-4"
                            >
                              <Select
                                field="timeZone"
                                label="timeZone"
                                options={timeZones}
                                translateOptions={false}
                                validate={required}
                                id="timezoneFieldID"
                              />
                            </div>
                            <div
                              className="col-md-3"
                            >
                              <IntlMessages id="workingDaysLabel" />
                              <WorkingDaysCalendarV2
                                data={workingDays}
                                entityId={this.entityId}
                                open={workingDaysModal}
                                onConfirm={this.toConfirm}
                                onClose={this.toggleWorkingDaysModal}
                              />

                            </div>

                            <div className="col-md-1 text-center">
                              <h5 className="mb-1"><IntlMessages id="totalRound" /></h5>
                              <Badge className="bg-blue-grey text-white d-block ml-1" pill>
                                <h4 className="mb-1">{Util.formatNumberNoDecimals( totalRound )}</h4>
                              </Badge>
                            </div>
                            { registrationCode && (
                            <>
                              <div className="col-md-1 text-right">
                                <span className="mb-1 text-right" style={{ fontStyle: 'bold' }}><IntlMessages id="registrationCode" /></span>
                              </div>
                              <div className="col-md-2">
                                <span className="mb-1" style={{ fontSize: 32, fontStyle: 'bold' }}>{ registrationCode }</span>
                              </div>

                            </>
                            )}
                          </div>

                          <Modal
                            isOpen={previewVisible}
                            // eslint-disable-next-line react/jsx-no-bind
                            toggle={this.handleToggle.bind( this )}
                          >
                            <ModalHeader
                              // eslint-disable-next-line react/jsx-no-bind
                              toggle={this.handleToggle.bind( this )}
                            >
                              <IntlMessages id="cloneSession" />
                            </ModalHeader>
                            <ModalBody>
                              <Select
                                field="sessionToClone"
                                label="session"
                                options={listOfSession}
                                translateOptions={false}
                                onChange={this.handleClone}
                              />

                              <Button
                                id="ok"
                                variant="contained"
                                className="jr-btn bg-blue-grey text-white d-block ml-2"
                                type="button"
                                // eslint-disable-next-line react/jsx-no-bind
                                onClick={this.handleCloneSession.bind( this )}
                              >
                                <h5 className="mb-1"><IntlMessages id="toClone" /></h5>

                              </Button>
                            </ModalBody>
                          </Modal>
                        </form>
                      )}
                    />
                  </Col>

                </Row>

              </CardBox>
            </Col>
          </Row>
        </div>

        {_.map( popoverItems, ( item, i ) => (
          <div key={`popover-${item.id}-${i}-1`}>
            <UncontrolledPopover placement="top-start" trigger="hover" target={item.id}>
              <PopoverHeader>{item.header}</PopoverHeader>
              <PopoverBody>
                <p>
                  {item.content}
                </p>
              </PopoverBody>
            </UncontrolledPopover>
          </div>
        ) )
        }

      </>
    );
  }
}
const mapStateToProps = ( { user } ) => ( { user } );
const mapDispatchToProps = {
  changeActiveSession: UserActions.changeActiveSession,
  toggleLoading: SettingsActions.toggleLoading,
  toggleAlert: SettingsActions.toggleAlert,

};

export default connect( mapStateToProps, mapDispatchToProps )( newSession );
