import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Error404 from 'components/theme/Error404';
import Dashboard from './Dashboard';
import University from './University';
import User from './User';
import Params from './Params';

const App = ( { match } ) => (
  <Switch>
    <Route
      path={`${match.url}dashboard`}
      component={Dashboard}
    />
    <Route
      path={`${match.url}users`}
      component={User}
    />
    <Route
      path={`${match.url}universities`}
      component={University}
    />
    <Route
      path={`${match.url}params`}
      component={Params}
    />
    <Route
      path={`${match.url}`}
      component={Dashboard}
    />
    <Route component={Error404} />
  </Switch>
);

export default App;
