import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { Button } from '@material-ui/core';

import { composeValidators, equalTo, required } from 'config/InputErrors';
import TextField from 'components/custom/FormElements/TextField';
import UserActions from 'store/reducers/User';
import IntlMessage from 'utils/IntlMessages';
import SettingsActions from 'store/reducers/Settings';
import UserService from 'services/User';
import logo from 'assets/images/logo.png';

class RecoverPassword extends React.Component {
  componentDidMount() {
    const { accessToken, logoutUser } = this.props;
    if ( accessToken ) logoutUser();
  }

  submitForm = ( data ) => {
    const { toggleLoading, toggleAlert, history, match } = this.props;
    toggleLoading( true );

    UserService.recoverPasswordConfirmation( match.params.token, { password: data.password } )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) return toggleAlert( response.errors );

        history.push( '/login' );
        toggleAlert( 'passwordResetted', 'info' );
      } );
  };

  render() {
    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3"
      >
        <div className="app-login-main-content">

          <div className="app-logo-content bg-primary d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="MK">
              <img src={logo} alt="MK" title="MK" width={200} />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1><IntlMessage id="enterNewPassword" /></h1>
            </div>

            <div className="app-login-form">
              <Form
                onSubmit={this.submitForm}
                render={( { handleSubmit } ) => (
                  <form onSubmit={handleSubmit} noValidate className="w-100">
                    <TextField
                      field="password"
                      type="password"
                      label="password"
                      validate={required}
                    />
                    <TextField
                      field="confirmPassword"
                      type="password"
                      label="confirmPassword"
                      validate={composeValidators( required, equalTo( 'password' ) )}
                      translateValues={{ label: <IntlMessage id="password" /> }}
                    />

                    <div className="text-right">
                      <Button variant="contained" color="primary" className="jr-btn jr-btn-lg" type="submit">
                        <i className="fa fa-save" />
                        <span><IntlMessage id="send" /></span>
                      </Button>
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ( { user, settings } ) => ( {
  accessToken: user.access_token,
  isLoading: settings.isLoading,
} );

const mapDispatchToProps = ( {
  loginUser: UserActions.loginUser,
  logoutUser: UserActions.logoutUser,
  toggleLoading: SettingsActions.toggleLoading,
  toggleAlert: SettingsActions.toggleAlert,
  changeActiveSession: UserActions.changeActiveSession,
} );

export default connect( mapStateToProps, mapDispatchToProps )( RecoverPassword );
