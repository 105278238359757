import api from '../config/Api';

const endpoint = 'v1/session';
const endpoint2 = 'v2/session';

export default {

  getUserLicensesBySession( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/licenseuser`, params );
  },

  getUserLicensesByEntity( entityId, params ) {
    return api.get( `v1/entity/${entityId}/licenses-user`, params );
  },

  getProfLicensesByEntity( entityId, params ) {
    return api.get( `v2/entity/${entityId}/licensesupervisor`, params );
  },

  getUserLicense( sessionId, supervisorLicenseId ) {
    return api.get( `${endpoint}/${sessionId}/licenseuser/${supervisorLicenseId}` );
  },

  saveUserLicense( sessionId, data ) {
    return api.post( `${endpoint}/${sessionId}/licenseuser`, data );
  },

  updateUserLicense( sessionId, licenseId, data ) {
    return api.put( `${endpoint}/${sessionId}/licenseuser/${licenseId}`, data );
  },

  updateUserLicenseWorkGroup( sessionId, licenseId, data ) {
    return api.put( `${endpoint}/${sessionId}/licenseuser/${licenseId}/workgroup`, data );
  },

  assignUserLicense( sessionId, data ) {
    return api.post( `${endpoint}/${sessionId}/licenseuser/assign`, data );
  },
  refuseSession( id, sessionForRefuse ) {
    return api.put( `${endpoint2}/${id}/licenseuser/${sessionForRefuse}/refuse` );
  },
};
