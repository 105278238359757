import api from '../config/Api';

const endpoint = 'v1/session';

export default {

  getGeneralData( id ) {
    return api.get( `${endpoint}/${id}/dashboard/professor/general-data` );
  },

  getRankingRounds( id, params ) {
    return api.get( `${endpoint}/${id}/dashboard/professor/ranking-round`, params );
  },

  getRankingChart( id, params ) {
    return api.get( `${endpoint}/${id}/dashboard/professor/ranking-graph`, params );
  },

  getProducts( id, params ) {
    return api.get( `${endpoint}/${id}/dashboard/professor/products`, params );
  },

  getServices( id, params ) {
    return api.get( `${endpoint}/${id}/dashboard/professor/services`, params );
  },

  getBundles( id, params ) {
    return api.get( `${endpoint}/${id}/dashboard/professor/bundles`, params );
  },

  getUnitsSalesMargin( id, params ) {
    return api.get( `${endpoint}/${id}/dashboard/professor/units-sales-margin`, params );
  },
};
