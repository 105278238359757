import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { NavLink, withRouter } from 'react-router-dom';
import _ from 'lodash';

import logo from 'assets/images/logo.png';
import IntlMessages from 'utils/IntlMessages';
import CustomScrollbars from 'components/theme/CustomScrollbars';

const SideNav = ( { menus, open, onToggle } ) => (
  <div className="app-sidebar d-none green">
    <Drawer
      className="app-sidebar-content"
      variant="temporary"
      open={open}
      onClose={onToggle}
      classes={{
        paper: 'side-nav',
        root: 'green'
      }}
    >
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          <li className="nav-header bg-primary mt-0 mb-3 py-4">
            <img src={logo} alt="MK21" title="MK21" style={{ height: '25px' }}/>
          </li>
          {_.map( menus, listItem => (
            listItem.headerId
              ? [
                <li className="nav-header mt-2 pt-3" key={listItem.headerId}>
                  <IntlMessages id={listItem.heading}/>
                </li>,
                listItem.items.map( item => (
                  [
                    <li className="menu no-arrow" key={item.name}>
                      <NavLink to={item.url}>
                        <i
                          className={`zmdi zmdi-${item.icon} text-${item.color} font-size-20 ml-2`}/>
                        <span className="nav-text"><IntlMessages id={item.name}/></span>
                      </NavLink>
                    </li>,
                  ]
                ) ),
              ]
              : (
                <li className="menu no-arrow" key={listItem.name}>
                  <NavLink to={listItem.url}>
                    <i
                      className={`zmdi zmdi-${listItem.icon} text-${listItem.color} font-size-20 ml-2`}/>
                    <span className="nav-text"><IntlMessages id={listItem.name}/></span>
                  </NavLink>
                </li>
              )
          ) )}
        </ul>
      </CustomScrollbars>
    </Drawer>
  </div>
);

export default withRouter( SideNav );
