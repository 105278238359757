import React from 'react';
import CardBox from 'components/custom_v2/CardBox';

import { connect } from 'react-redux';
import BreadcrumbComp from 'components/custom_v2/Breadcrumb';
import { Col, Row } from 'reactstrap';
import _ from 'lodash';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import CategoryService from 'services/Category';
import IntlMessages from 'utils/IntlMessages';
import ChartModal from '../../../../../../components/User/Chart';
// import Util from '../../../../../../../../utils/Util';
// import { FieldArray } from 'react-final-form-arrays';


class PublicMarketCharts extends React.Component {
  state = {
    chartModal: false,
    products1: [],
    products2: [],
    products3: [],
  };

  componentDidMount() {
    const { user } = this.props;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );

    // Get all categories

    CategoryService.getCategories( { 'filters[session]': user.activeSession.id } )
      .then( ( response ) => {
        this.categories = response.data.data;
        this.LoadGraphLaptop(
          user.activeSession.id,
          this.categories[0].id,
          this.license.workGroup.id,
        );
        this.LoadGraphTablet(
          user.activeSession.id,
          this.categories[1].id,
          this.license.workGroup.id,
        );
        this.LoadGraphCell(
          user.activeSession.id,
          this.categories[2].id,
          this.license.workGroup.id,
        );
      } );
  }

  // eslint-disable-next-line react/sort-comp
  LoadGraphLaptop( activeSession, categories, workGroup ) {
    if ( categories ) {
      CategoryService.getProducts( activeSession,
        categories, { workgroup: workGroup } )
        .then( ( response ) => {
          if ( response.ok ) {
            this.Products = response.data;
            // console.log( 'Products' );
            // console.log( this.Products );
            if ( this.Products.data.length ) {
              const attributes = [];
              const titles1 = [];
              _.map( this.Products.data[0].attributes, ( attribute ) => {
                const item = { attribute: attribute.label };
                _.map( this.Products.data, ( product ) => {
                  item[product.id] = _.find( product.attributes, { id: attribute.id } ).value;
                  titles1.push( product.name );
                } );
                attributes.push( item );
              } );
              this.config1 = {
                data: attributes,
                type: am4charts.RadarChart,
                xAxes: [{
                  type: 'CategoryAxis',
                  dataFields: { category: 'attribute' },
                }],
                // cursor: { type: 'RadarCursor' },
                // legend: { type: 'Legend' },
                yAxes: [{ type: 'ValueAxis' }],
                series: _.map( this.Products.data, product => ( {
                  type: 'RadarSeries',
                  dataFields: {
                    valueY: product.id,
                    categoryX: 'attribute',
                  },
                  strokeWidth: 3,
                  tooltipText: '{valueY}',
                  name: product.name,
                  bullets: [{ type: 'CircleBullet' }],
                  // dataItems: { template: { locations: { categoryX: 0.5 } } },
                } ) ),
              };
              this.chart1 = am4core.createFromConfig( _.cloneDeep( this.config1 ), 'laptopCaracteristicas' );
              this.setState( ( { products1: response.data.data, titles1 } ) );
            }
          }
        } );
    }
  }
  LoadGraphTablet( activeSession, categories, workGroup ) {
    if ( categories ) {
      CategoryService.getProducts( activeSession,
        categories, { workgroup: workGroup } )
        .then( ( response ) => {
          if ( response.ok ) {
            // console.log( response.data );
            this.Products = response.data;
            if ( this.Products.data.length ) {
              const attributes = [];
              const titles2 = [];
              _.map( this.Products.data[0].attributes, ( attribute ) => {
                const item = { attribute: attribute.label };
                _.map( this.Products.data, ( product ) => {
                  item[product.id] = _.find( product.attributes, { id: attribute.id } ).value;
                  titles2.push( product.name );
                } );
                attributes.push( item );
              } );
              this.config2 = {
                data: attributes,
                type: am4charts.RadarChart,
                xAxes: [{
                  type: 'CategoryAxis',
                  dataFields: { category: 'attribute' },
                }],
                // legend: { type: 'Legend' },
                yAxes: [{ type: 'ValueAxis' }],
                series: _.map( this.Products.data, product => ( {
                  type: 'RadarSeries',
                  dataFields: {
                    valueY: product.id,
                    categoryX: 'attribute',
                  },
                  strokeWidth: 3,
                  tooltipText: '{valueY}',
                  name: product.name,
                  bullets: [{ type: 'CircleBullet' }],
                  // dataItems: { template: { locations: { categoryX: 0.5 } } },
                } ) ),
              };
              this.chart2 = am4core.createFromConfig( _.cloneDeep( this.config2 ), 'tabletsCaracteristicas' );
              this.setState( ( { products2: response.data.data, titles2 } ) );
            }
          }
        } );
    }
  }
  LoadGraphCell( activeSession, categories, workGroup ) {
    if ( categories ) {
      CategoryService.getProducts( activeSession,
        categories, { workgroup: workGroup } )
        .then( ( response ) => {
          if ( response.ok ) {
            // console.log( response.data );
            this.Products = response.data;
            if ( this.Products.data.length ) {
              const attributes = [];
              const titles3 = [];
              _.map( this.Products.data[0].attributes, ( attribute ) => {
                const item = { attribute: attribute.label };
                _.map( this.Products.data, ( product ) => {
                  item[product.id] = _.find( product.attributes, { id: attribute.id } ).value;
                  titles3.push( product.name );
                } );
                attributes.push( item );
              } );
              this.config3 = {
                data: attributes,
                type: am4charts.RadarChart,
                xAxes: [{
                  type: 'CategoryAxis',
                  dataFields: { category: 'attribute' },
                }],
                // legend: { type: 'Legend' },
                yAxes: [{ type: 'ValueAxis' }],
                series: _.map( this.Products.data, product => ( {
                  type: 'RadarSeries',
                  dataFields: {
                    valueY: product.id,
                    categoryX: 'attribute',
                  },
                  strokeWidth: 3,
                  tooltipText: '{valueY}',
                  name: product.name,
                  bullets: [{ type: 'CircleBullet' }],
                  // dataItems: { template: { locations: { categoryX: 0.5 } } },
                } ) ),
              };
              this.chart3 = am4core.createFromConfig( _.cloneDeep( this.config3 ), 'cellCaracteristicas' );
              this.setState( ( { products3: response.data.data, titles3 } ) );
            }
          }
        } );
    }
  }

  componentWillUnmount() {
    if ( this.chart1 ) {
      this.chart1.dispose();
    }
    if ( this.chart2 ) {
      this.chart2.dispose();
    }
    if ( this.chart3 ) {
      this.chart3.dispose();
    }
  }


  toggleChartModal = ( configModal, titleModal, seriesTitleModal ) => {
    this.setState( previousState => ( {
      chartModal: !previousState.chartModal,
      configModal,
      titleModal,
      seriesTitleModal,
    } ) );
  };

  render() {
    const {
      chartModal,
      titleModal,
      seriesTitleModal,
      configModal,
      titles1,
      titles2,
      titles3,
      products1,
      products2,
      products3,
    } = this.state;

    return (
      <div>
        <BreadcrumbComp links={[
          {
            url: '/',
            name: 'informationDashboard',
          },
          { name: 'publicMarket' },
        ]}
        />

        <Row>
          <Col lg={4}>
            <CardBox
              heading="laptops"
              childrenStyle=""
              headerBg="bg-product-laptop"
              icon="laptop"
              onClick={() => this.toggleChartModal( this.config1, 'laptops', titles1 )}
            >
              <div className="jr-link" id="laptopCaracteristicas" style={{ width: '100%', height: '350px' }} />
              <div className="table-responsive  mt-3">
                <table
                  className="table table-striped table-products mb-0 table-products-laptop table-header"
                >
                  <thead>
                    <tr>
                      <th className="thead-report"><IntlMessages id="model" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-pencil-ruler text-purple" title="Design" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-arrows-alt text-blue" title="Size" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-cookie text-warning" title="Reliability" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-cog text-sepia" title="Weight" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-tachometer-alt text-danger" title="Speed" /></th>
                      <th className="thead-report"><IntlMessages id="price" /></th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map( products1, ( item ) => {
                      const design = _.find( item.attributes, { label: 'Design' } );
                      const size = _.find( item.attributes, { label: 'Size' } );
                      const reliability = _.find( item.attributes, { label: 'Reliability' } );
                      const weight = _.find( item.attributes, { label: 'Weight' } );
                      const speed = _.find( item.attributes, { label: 'Speed' } );
                      return (
                        <tr key={item.id}>
                          <td className="td-report">{item.name}</td>
                          <td className="td-report">{( design ) ? design.value : null}</td>
                          <td className="td-report">{( size ) ? size.value : null}</td>
                          <td className="td-report">{( reliability ) ? reliability.value : null}</td>
                          <td className="td-report">{( weight ) ? weight.value : null}</td>
                          <td className="td-report">{( speed ) ? speed.value : null}</td>
                          <td className="td-report">{item.price}</td>
                        </tr>
                      );
                    } )}
                  </tbody>
                </table>
              </div>
            </CardBox>
          </Col>
          <Col lg={4}>
            <CardBox
              heading="tablets"
              childrenStyle=""
              headerBg="bg-product-tablet"
              icon="tablet-alt"
              onClick={() => this.toggleChartModal( this.config2, 'tablets', titles2 )}
            >
              <div className="jr-link" id="tabletsCaracteristicas" style={{ width: '100%', height: '350px' }} />

              <div className="table-responsive  mt-3">
                <table
                  className="table table-striped table-products mb-0 table-products-tablet table-header"
                >
                  <thead>
                    <tr>
                      <th className="thead-report"><IntlMessages id="model" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-pencil-ruler text-purple" title="Design" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-arrows-alt text-blue" title="Size" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-cookie text-warning" title="Reliability" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-cog text-sepia" title="Weight" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-tachometer-alt text-danger" title="Speed" /></th>
                      <th className="thead-report"><IntlMessages id="price" /></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!products2 && _.map( products2, ( item ) => {
                      const design = _.find( item.attributes, { label: 'Design' } );
                      const size = _.find( item.attributes, { label: 'Size' } );
                      const reliability = _.find( item.attributes, { label: 'Reliability' } );
                      const weight = _.find( item.attributes, { label: 'Weight' } );
                      const speed = _.find( item.attributes, { label: 'Speed' } );
                      return (
                        <tr key={item.id}>
                          <td className="td-report">{item.name}</td>
                          <td className="td-report">{( design ) ? design.value : null}</td>
                          <td className="td-report">{( size ) ? size.value : null}</td>
                          <td className="td-report">{( reliability ) ? reliability.value : null}</td>
                          <td className="td-report">{( weight ) ? weight.value : null}</td>
                          <td className="td-report">{( speed ) ? speed.value : null}</td>
                          <td className="td-report" />
                        </tr>
                      );
                    } )}

                    {/* {!!products2 && !products2.products.length
                    && (
                    <tr>
                      <td className="td-report" colSpan={7}>Data not found</td>
                    </tr>
                    ) } */}
                  </tbody>
                </table>
              </div>
            </CardBox>
          </Col>
          <Col lg={4}>
            <CardBox
              heading="cellulars"
              childrenStyle=""
              headerBg="bg-product-cell"
              icon="mobile-alt"
              onClick={() => this.toggleChartModal( this.config3, 'cellulars', titles3 )}
            >
              <div className="jr-link" id="cellCaracteristicas" style={{ width: '100%', height: '350px' }} />
              <div className="table-responsive  mt-3">
                <table
                  className="table table-striped table-products mb-0 table-products-cell table-header"
                >
                  <thead>
                    <tr>
                      <th className="thead-report"><IntlMessages id="model" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-pencil-ruler text-purple" title="Design" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-arrows-alt text-blue" title="Size" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-cookie text-warning" title="Reliability" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-cog text-sepia" title="Weight" /></th>
                      <th className="thead-report"><i className="fa mr-2 fa-tachometer-alt text-danger" title="Speed" /></th>
                      <th className="thead-report"><IntlMessages id="price" /></th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map( products3, ( item ) => {
                      const design = _.find( item.attributes, { label: 'Design' } );
                      const size = _.find( item.attributes, { label: 'Size' } );
                      const reliability = _.find( item.attributes, { label: 'Reliability' } );
                      const weight = _.find( item.attributes, { label: 'Weight' } );
                      const speed = _.find( item.attributes, { label: 'Speed' } );
                      return (
                        <tr key={item.id}>
                          <td className="td-report">{item.name}</td>
                          <td className="td-report">{( design ) ? design.value : null}</td>
                          <td className="td-report">{( size ) ? size.value : null}</td>
                          <td className="td-report">{( reliability ) ? reliability.value : null}</td>
                          <td className="td-report">{( weight ) ? weight.value : null}</td>
                          <td className="td-report">{( speed ) ? speed.value : null}</td>
                          <td className="td-report" />
                        </tr>
                      );
                    } )}
                  </tbody>
                </table>
              </div>
            </CardBox>
          </Col>
        </Row>

        <ChartModal
          open={chartModal}
          title={titleModal}
          onClose={this.toggleChartModal}
          seriesTitle={seriesTitleModal}
          config={configModal}
        />

      </div>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

export default connect( mapStateToProps )( PublicMarketCharts );
