import React, { useCallback, useState } from 'react';
import { DialogContent, Dialog, DialogTitle, IconButton } from '@material-ui/core';
import { Row, Col } from 'reactstrap';

import IntlMessages from 'utils/IntlMessages';
import IntlHtmlMessages from 'utils/IntlHtmlMessages';

const CostDetailModal = ( { title, content, multimedia, children } ) => {
  const [modalOpened, setModalOpened] = useState( false );

  const toggleModal = useCallback( () => {
    setModalOpened( !modalOpened );
  }, [modalOpened] );

  return (
    <>
      <i className="fa fa-question-circle fa-2x cursor-pointer mr-3" onClick={toggleModal} />
      {modalOpened
      && (
        <Dialog
          maxWidth="md"
          open={modalOpened}
          onClose={toggleModal}
          fullWidth
        >
          <DialogTitle className="border-bottom py-2">
            <IntlMessages id={title} />
            <IconButton
              aria-label="close"
              className="closeButton float-right"
              onClick={toggleModal}
            >
              <i className="zmdi zmdi-close" />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Row>
              <Col>
                <IntlHtmlMessages id={content} />
              </Col>
              <Col>
                <IntlHtmlMessages id={multimedia} />
              </Col>
            </Row>
            {children}
          </DialogContent>

        </Dialog>
      )}
    </>
  );
};

export default CostDetailModal;
