import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import UserActions from 'store/reducers/User';
import SettingsActions from 'store/reducers/Settings';
// import _ from 'lodash';
import UserService from 'services/User';

import IconCard from 'components/custom_v2/IconCard';
import IntlMessages from 'utils/IntlMessages';
import SessionService from 'services/Session';
import SessionInfoBar from 'routes/App/components/User/SessionInfoBar';
import UtilService from 'utils/Util';
import Ticker from 'react-ticker';

const Dashboard = ( { user, changeActiveSession, toggleAlert, toggleLoading } ) => {
  // const activeSession = UtilService.getUserActiveSession( user );
  // eslint-disable-next-line no-unused-vars
  const [workgroup, setWorkgroup] = useState( {} );

  const [data, setData] = useState( {} );
  let Stock1 = '<><><>';
  let StockArrow1 = '';
  let TextColor1 = '';
  let Stock2 = '';
  let StockArrow2 = '';
  let TextColor2 = '';
  let Stock3 = '';
  let StockArrow3 = '';
  let TextColor3 = '';
  let Stock4 = '';
  let StockArrow4 = '';
  let TextColor4 = '';
  let Stock5 = '';
  let StockArrow5 = '';
  let TextColor5 = '';


  if ( data.workGroups && data.workGroups.length > 4 ) {
    Stock1 = `${data.workGroups[0].name} ${UtilService.formatCurrency( data.workGroups[0].points )}  ${data.workGroups[0].percentagePoints}%  `;
    if ( data.workGroups[0].percentagePoints && data.workGroups[0].percentagePoints < 0 ) {
      StockArrow1 = 'fa-arrow-down';
      TextColor1 = 'text-danger';
    } else if ( data.workGroups[0].percentagePoints && data.workGroups[0].percentagePoints > 0 ) {
      StockArrow1 = 'fa-arrow-up';
      TextColor1 = 'text-primary';
    } else {
      StockArrow1 = 'fa-arrow-right';
      TextColor1 = 'text-light';
    }
    Stock2 = `${data.workGroups[1].name} ${UtilService.formatCurrency( data.workGroups[1].points )}  ${data.workGroups[1].percentagePoints}%  `;
    if ( data.workGroups[1].percentagePoints && data.workGroups[1].percentagePoints < 0 ) {
      StockArrow2 = 'fa-arrow-down';
      TextColor2 = 'text-danger';
    } else if ( data.workGroups[1].percentagePoints && data.workGroups[1].percentagePoints > 0 ) {
      StockArrow2 = 'fa-arrow-up';
      TextColor2 = 'text-primary';
    } else {
      StockArrow2 = 'fa-arrow-right';
      TextColor2 = 'text-light';
    }
    Stock3 = `${data.workGroups[2].name} ${UtilService.formatCurrency( data.workGroups[2].points )}  ${data.workGroups[2].percentagePoints}%  `;
    if ( data.workGroups[2].percentagePoints && data.workGroups[2].percentagePoints < 0 ) {
      StockArrow3 = 'fa-arrow-down';
      TextColor3 = 'text-danger';
    } else if ( data.workGroups[2].percentagePoints && data.workGroups[2].percentagePoints > 0 ) {
      StockArrow3 = 'fa-arrow-up';
      TextColor3 = 'text-primary';
    } else {
      StockArrow3 = 'fa-arrow-right';
      TextColor3 = 'text-light';
    }
    Stock4 = `${data.workGroups[3].name} ${UtilService.formatCurrency( data.workGroups[3].points )}  ${data.workGroups[3].percentagePoints}%  `;
    if ( data.workGroups[3].percentagePoints && data.workGroups[3].percentagePoints < 0 ) {
      StockArrow4 = 'fa-arrow-down';
      TextColor4 = 'text-danger';
    } else if ( data.workGroups[3].percentagePoints && data.workGroups[3].percentagePoints > 0 ) {
      StockArrow4 = 'fa-arrow-up';
      TextColor4 = 'text-primary';
    } else {
      StockArrow4 = 'fa-arrow-right';
      TextColor4 = 'text-light';
    }
    Stock5 = `${data.workGroups[4].name} ${UtilService.formatCurrency( data.workGroups[4].points )}  ${data.workGroups[4].percentagePoints}%  `;
    if ( data.workGroups[4].percentagePoints && data.workGroups[4].percentagePoints < 0 ) {
      StockArrow5 = 'fa-arrow-down';
      TextColor5 = 'text-danger';
    } else if ( data.workGroups[4].percentagePoints && data.workGroups[4].percentagePoints > 0 ) {
      StockArrow5 = 'fa-arrow-up';
      TextColor5 = 'text-primary';
    } else {
      StockArrow5 = 'fa-arrow-right';
      TextColor5 = 'text-light';
    }
  }


  useEffect( () => {
    toggleLoading( true );
    UserService.getCurrentUser()
      .then( () => {
        toggleLoading( false );
      } );

    SessionService.getSessionCurrentWorkgroupRound( user.activeSession.id )
      .then( ( response ) => {
        if ( response.ok ) {
          setWorkgroup( response.data );
        }
      } );

    SessionService.getSession( user.activeSession.id )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) return toggleAlert( response.errors );
        changeActiveSession( response.data );
        setData( response.data );

        if ( !user.activeSession ) {
          return toggleAlert( 'sessionNotConfigured', 'danger', true );
        }
        if ( !user.activeSession.currentRound ) {
          return toggleAlert( 'sessionNoRounds', 'danger', true );
        }

        /* const license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
        if ( !license || !license.workGroup ) {
          return toggleAlert( 'sessionNotConfigured', 'danger', true );
        } */

        if ( response.data.status === 'READY_NO_ROUND' ) {
          return toggleAlert( 'sessionNoRounds', 'danger', true );
        }

        if ( !response.data.status ) {
          return toggleAlert( 'sessionNotConfigured', 'danger', true );
        }
      } );
  }, [user.activeSession.id] ); // eslint-disable-line

  return (
    <>
      <SessionInfoBar data={data} workgroup={workgroup} />
      <div className="container-fluidr">

        <div className="app-dashboard-container app-user-dashboard-container">

          <div className="container-fluid">
            <div className="app-dashboard-container app-user-dashboard-container" style={{ marginTop: '-2.5rem' }}>
              <Row>
                <Col md={10} />
                <Col md={2}>
                  <Button
                    key="save"
                    variant="contained"
                    color="primary"
                    className="jr-btn jr-btn-lg"
                    onClick={() => {
                      window.location.assign( '/sessions/add' );
                    }}
                  >
                    <i className="fa fa-plus" />
                    <IntlMessages id="createSessionBtn" />
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
          <div className="container-fluid">
            <div className="app-dashboard-container app-user-dashboard-container">
              <Row>
                <Col md={1} />
                <Col md={6}>
                  <div className="text-center border jr-border-radius p-3">
                    <Row>
                      <Col md={12}>
                        <h1 className="mb-3 text-center text-primary">
                          <IntlMessages id="informationDashboard" />
                        </h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4} md={5}>
                        <IconCard
                          icon="building"
                          title="company"
                          bgColor="bg-primary"
                          link={`/sessions/${user.activeSession.id}/info/company`}
                        />
                      </Col>
                      <Col lg={4} md={5}>
                        <IconCard
                          icon="server"
                          title="products"
                          bgColor="bg-primary"
                          link={`/sessions/${user.activeSession.id}/info/products`}
                        />
                      </Col>
                      <Col lg={4} md={5}>
                        <IconCard
                          icon="th-list"
                          title="activity"
                          bgColor="bg-primary"
                          link={`/sessions/${user.activeSession.id}/info/activity`}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4} md={5}>
                        <IconCard
                          icon="chart-line"
                          title="market"
                          bgColor="bg-primary"
                          link={`/sessions/${user.activeSession.id}/info/market`}
                        />
                      </Col>
                      <Col lg={4} md={5}>
                        <IconCard
                          icon="search"
                          title="research"
                          bgColor="bg-primary"
                          link={`/sessions/${user.activeSession.id}/info/research`}
                        />
                      </Col>
                      <Col lg={4} md={5}>
                        <IconCard
                          icon="users"
                          title="customer"
                          bgColor="bg-primary"
                          link={`/sessions/${user.activeSession.id}/info/customer`}
                        />
                      </Col>
                    </Row>

                  </div>
                </Col>

                <Col md={3}>
                  <div className="text-center border jr-border-radius p-3">
                    <h1 className="mb-3 text-center  text-primary">
                      <IntlMessages id="decisionDashboard" />
                    </h1>
                    <Row>
                      <Col lg={2} md={5} />
                      <Col lg={8} md={5}>
                        <IconCard
                          icon="cogs"
                          title="roundmngmnt"
                          bgColor="cbg-blue"
                          link={`/sessions/${user.activeSession.id}/general/rounds`}
                          onClick={() => changeActiveSession( user.activeSession )}
                        />
                      </Col>

                    </Row>
                    <Row>
                      <Col lg={2} md={5} />
                      <Col lg={8} md={5}>
                        <IconCard
                          icon="users-cog"
                          title="workgroups"
                          bgColor="cbg-blue"
                          link={`/sessions/${user.activeSession.id}/general/user-licenses`}
                          onClick={() => changeActiveSession( user.activeSession )}
                        />
                      </Col>
                    </Row>

                  </div>
                </Col>
              </Row>
            </div>

            <div className="cbg-primary-dark  text-center text-white" style={{ marginTop: '1rem' }}>
              <Ticker offset="run-in" speed={5}>
                {/* eslint-disable-next-line no-empty-pattern */}
                {( { } ) => (
                  <>
                    <span>
                      {' '}
                      {Stock1}
                    </span>
                    <i className={`fa ${StockArrow1} ${TextColor1} mx-2`} />
                    <span>
                      {' '}
                      {Stock2}
                    </span>
                    <i className={`fa ${StockArrow2} ${TextColor2} mx-2`} />
                    <span>
                      {' '}
                      {Stock3}
                    </span>
                    <i className={`fa ${StockArrow3} ${TextColor3} mx-2`} />
                    <span>
                      {' '}
                      {Stock4}
                    </span>
                    <i className={`fa ${StockArrow4} ${TextColor4} mx-2`} />
                    <span>
                      {' '}
                      {Stock5}
                    </span>
                    <i className={`fa ${StockArrow5} ${TextColor5} mx-2`} />
                    <span>
                      {' <><><>'}
                    </span>
                  </>
                )}
              </Ticker>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ( { user } ) => ( { user } );
const mapDispatchToProps = {
  changeActiveSession: UserActions.changeActiveSession,
  toggleLoading: SettingsActions.toggleLoading,
  toggleAlert: SettingsActions.toggleAlert,

};

export default connect( mapStateToProps, mapDispatchToProps )( Dashboard );
