import React from 'react';
import _ from 'lodash';
import async from 'async';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Bar,
  BarChart,
  CartesianGrid, Cell,
  Legend, Line,
  LineChart, Pie, PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import { Form } from 'react-final-form';

import CardBox from 'components/theme/CardBox';
import AxisTick from 'components/custom/Charts/AxisTick';
import Table from 'components/custom/Table/Custom';
import { Select, TextField } from 'components/custom/FormElements';
import IntlMessages from 'utils/IntlMessages';
import DashboardProfessorService from 'services/DashboardProfessor';
import CategoryService from 'services/Category';
import ServiceTypeService from 'services/ServiceType';
import Util from 'utils/Util';
import SettingsActions from 'store/reducers/Settings';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8d8c82'];

class Dashboard extends React.Component {
  state = {
    generalData: {},
    rankingRounds: [],
    rankingChart: {},
    categories: [],
    products: [],
    serviceTypes: [],
    services: [],
    bundles: [],
    activeTab: 0,
    productForm: {},
    serviceForm: {},
    unitsSalesMargin: [],
    unitsForm: { unitsType: 'TYPE_PRODUCT' },
  };

  componentDidMount() {
    const { toggleAlert, user } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.activeSession = user.activeSession;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license ) {
      return toggleAlert( 'ERROR' );
    }

    this.getData();
  }

  componentDidUpdate( prevProps ) {
    if ( _.get( prevProps, 'user.activeSession.id' ) !== _.get( this.props, 'user.activeSession.id' ) ) {
      const { toggleAlert, user } = this.props;

      if ( !user.activeSession ) {
        return toggleAlert( 'ERROR' );
      }
      this.activeSession = user.activeSession;
      this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
      if ( !this.license ) {
        return toggleAlert( 'ERROR' );
      }

      this.getData();
    }
  }

  getData = () => {
    const { toggleAlert, toggleLoading } = this.props;
    toggleLoading( true );

    async.parallel( {
      generalData: this.getGeneralData,
      rankingChart: this.getRankingChart,
      rankingRounds: this.getRankingRound,
      serviceTypes: this.getServicesTypes,
      unitsSalesMargin: cb => this.getUnitsSalesMargin( { unitsType: 'TYPE_PRODUCT' }, cb ),
      products: ( cb ) => {
        async.waterfall( [
          this.getCategories,
          ( categories, wCb ) => {
            if ( !categories || !categories[0] ) return wCb();
            this.getProducts( { category: categories[0].id }, ( error, results ) => {
              if ( error ) return wCb( error );
              wCb( null, {
                categories,
                products: results,
                productForm: { category: categories[0].id },
              } );
            } );
          },
        ], cb );
      },
    }, ( error, results ) => {
      toggleLoading( false );
      if ( error ) return toggleAlert( error );

      const chartNames = _.map( results.rankingChart, 'name' );
      const chartData = [];
      _.map( results.rankingRounds, ( round ) => {
        const formattedData = {
          id: round.id,
          name: round.largeName,
        };
        _.map( results.rankingChart, ( chart ) => {
          const roundChart = _.find( chart.workgroupRound, { round: { id: round.id } } );
          if ( roundChart ) formattedData[chart.name] = roundChart.accumulated;
        } );
        chartData.push( formattedData );
      } );

      this.setState( {
        ...results,
        categories: results.products.categories,
        products: results.products.products,
        productForm: results.products.productForm,
        rankingChart: {
          chartNames,
          chartData,
        },
      } );
    } );
  };

  getGeneralData = ( cb ) => {
    DashboardProfessorService.getGeneralData( this.activeSession.id )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        cb( null, response.data );
      } );
  };

  getRankingRound = ( cb ) => {
    const params = { limit: -1 };
    DashboardProfessorService.getRankingRounds( this.activeSession.id, params )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        cb( null, response.data.data );
      } );
  };

  getUnitsSalesMargin = ( values, cb ) => {
    const { toggleAlert, toggleLoading } = this.props;

    const params = { limit: -1 };
    if ( values.unitsType ) {
      params.commodityType = values.unitsType;
    }
    if ( values.unitsRound ) {
      params.round = values.unitsRound;
    }
    if ( !cb ) toggleLoading( true );
    DashboardProfessorService.getUnitsSalesMargin( this.activeSession.id, params )
      .then( ( response ) => {
        if ( cb ) {
          if ( !response.ok ) return cb( response.errors );
          cb( null, response.data );
        } else {
          toggleLoading( false );
          if ( !response.ok ) return toggleAlert( response.errors );
          this.setState( { unitsSalesMargin: response.data } );
        }
      } );
  };

  getRankingChart = ( cb ) => {
    const params = { limit: -1 };
    DashboardProfessorService.getRankingChart( this.activeSession.id, params )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        cb( null, response.data.data );
      } );
  };

  getCategories = ( cb ) => {
    CategoryService.getCategories( {
      'filters[session]': this.activeSession.id,
      limit: -1,
    } )
      .then( response => cb( response.errors, response.data ? response.data.data : [] ) );
  };

  getProducts = ( filters, cb ) => {
    const { toggleAlert, toggleLoading } = this.props;
    const params = { limit: -1 };
    if ( filters.category ) {
      params.category = filters.category;
    }
    if ( filters.criteria ) {
      params['filters[criteria]'] = filters.criteria;
    }

    if ( !cb ) toggleLoading( true );
    DashboardProfessorService.getProducts( this.activeSession.id, params )
      .then( ( response ) => {
        if ( cb ) {
          if ( !response.ok ) return cb( response.errors );
          cb( null, response.data.data );
        } else {
          toggleLoading( false );
          if ( !response.ok ) return toggleAlert( response.errors );

          this.setState( { products: response.data.data } );
        }
      } );
  };

  getProductsData = () => {
    const { toggleAlert, toggleLoading } = this.props;
    const { categories } = this.state;
    toggleLoading( true );

    this.getProducts( { category: categories[0].id }, ( error, results ) => {
      if ( error ) return toggleAlert( error );
      toggleLoading( false );
      this.setState( {
        products: results,
        productForm: { category: categories[0].id },
      } );
    } );
  };

  getServicesTypes = ( cb ) => {
    ServiceTypeService.getServiceTypes( {
      'filters[session]': this.activeSession.id,
      limit: -1,
    } )
      .then( response => cb( response.errors, response.data ? response.data.data : [] ) );
  };

  getServices = ( filters, cb ) => {
    const { toggleAlert, toggleLoading } = this.props;
    const params = { limit: -1 };
    if ( filters.serviceType ) {
      params.serviceType = filters.serviceType;
    }
    if ( filters.criteria ) {
      params['filters[criteria]'] = filters.criteria;
    }

    if ( !cb ) toggleLoading( true );
    DashboardProfessorService.getServices( this.activeSession.id, params )
      .then( ( response ) => {
        if ( cb ) {
          if ( !response.ok ) return cb( response.errors );
          cb( null, response.data.data );
        } else {
          toggleLoading( false );
          if ( !response.ok ) return toggleAlert( response.errors );
          this.setState( { services: response.data.data } );
        }
      } );
  };

  getServicesData = () => {
    const { toggleAlert, toggleLoading } = this.props;
    const { serviceTypes } = this.state;
    toggleLoading( true );

    this.getServices( { serviceType: serviceTypes[0].id }, ( error, results ) => {
      toggleLoading( false );
      if ( error ) return toggleAlert( error );
      this.setState( {
        services: results,
        serviceForm: { serviceType: serviceTypes[0].id },
      } );
    } );
  };

  getBundles = ( filters, cb ) => {
    const { toggleAlert, toggleLoading } = this.props;
    if ( !cb ) toggleLoading( true );

    const params = { limit: -1 };
    if ( filters.criteria ) {
      params['filters[criteria]'] = filters.criteria;
    }
    DashboardProfessorService.getBundles( this.activeSession.id, params )
      .then( ( response ) => {
        if ( cb ) {
          if ( !response.ok ) return cb( response.errors );
          cb( null, response.data.data );
        } else {
          toggleLoading( false );
          if ( !response.ok ) return toggleAlert( response.errors );
          this.setState( { bundles: response.data.data } );
        }
      } );
  };

  changeTab = ( event, value ) => {
    this.setState( { activeTab: value } );

    if ( value === 0 ) {
      this.getProductsData();
    }
    if ( value === 1 ) {
      this.getBundles( {} );
    }
    if ( value === 2 ) {
      this.getServicesData();
    }
  };

  findAttribute = ( attributes, label ) => {
    const attibute = _.find( attributes, { label } );
    return attibute ? attibute.value : '';
  };

  render() {
    const {
      generalData, rankingRounds, rankingChart, activeTab, categories, unitsSalesMargin, unitsForm,
      products, productForm, bundleForm, bundles, serviceTypes, services, serviceForm,
    } = this.state;

    return (
      <div className="pt-4">
        <div className="row">
          <div className="col-12">
            <div className="page-heading m-0 py-3 shadow-none border-primary border">
              <h3 className="title font-weight-normal mb-2">
                <IntlMessages id="session" />
                {': '}
                {generalData.entityName}
                {' '}
                {generalData.name}
              </h3>
              <div className="row">
                <div className="col-md-6">
                  <p className="mb-1">
                    <IntlMessages id="currentRound" />
                    {': '}
                    {_.get( generalData, 'round.numberRound' )}
                    /
                    {_.get( generalData, 'round.totalRound' )}
                  </p>
                  <p className="m-0">
                    <IntlMessages id="totalActiveStudents" />
                    {': '}
                    {generalData.activeStudents}
                    /
                    {generalData.totalLicenses}
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="m-0">
                    <IntlMessages id="roundClosesAt" />
                    {': '}
                    {generalData.round && generalData.round.endDate
                      ? moment.utc( Number( generalData.round.endDate ) )
                        .format( 'DD/MM/YYYY hh:mm a' ) : ''}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <CardBox styleName="col-12" heading="historic">
            <ResponsiveContainer width="100%" height={200}>
              <LineChart
                data={rankingChart.chartData}
                margin={{
                  left: -10,
                  right: 20,
                }}
              >
                <XAxis
                  interval={0}
                  dataKey="name"
                  height={60}
                  tick={<AxisTick />}
                />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip separator=": " formatter={item => Util.formatNumber( item )} />
                <Legend verticalAlign="top" />
                {_.map( rankingChart.chartNames, ( item, index ) => (
                  <Line
                    key={item}
                    type="monotone"
                    dataKey={item}
                    name={item}
                    stroke={COLORS[index % COLORS.length]}
                  />
                ) )}
              </LineChart>
            </ResponsiveContainer>
          </CardBox>
        </div>

        <div className="row mt-3">
          <CardBox styleName="col-12 mt-3 mt-md-0" heading="rounds">
            <div className="overflow-auto" style={{ maxHeight: 350 }}>
              <Table
                sortable={false}
                data={rankingRounds}
                pageSize={rankingRounds.length}
                showPaginationTop={false}
                columns={[
                  {
                    Header: <IntlMessages id="round" />,
                    accessor: 'largeName',
                    Cell: ( { value } ) => `${value}`,
                  },
                  {
                    Header: <IntlMessages id="winner" />,
                    accessor: 'winner',
                  },
                  {
                    Header: <IntlMessages id="leader" />,
                    accessor: 'leader',
                  },
                  {
                    Header: <IntlMessages id="points" />,
                    accessor: 'points',
                    Cell: ( { value } ) => Util.formatNumber( value ),
                  },
                  {
                    Header: <IntlMessages id="end" />,
                    accessor: 'endDate',
                    Cell: ( { value } ) => (
                      value ? moment( Number( value ) )
                        .format( 'DD/MM/YYYY' ) : ''
                    ),
                  },
                  {
                    Header: <IntlMessages id="duration" />,
                    accessor: 'duration',
                    Cell: ( { value } ) => `${value || 0}d`,
                  },
                  {
                    Header: <IntlMessages id="status" />,
                    accessor: 'status',
                  },
                  {
                    Header: <IntlMessages id="roundReport" />,
                    accessor: 'roundReport',
                    Cell: ( { value } ) => ( value
                      ? (
                        <a
                          rel="noopener noreferrer"
                          className="text-blue"
                          target="_blank"
                          href={value}
                        >
                          <IntlMessages id="report" />
                        </a>
                      ) : '' ),
                  },
                ]}
              />
            </div>
          </CardBox>
        </div>

        <div className="row mt-3">
          <CardBox cardStyle="p-0 m-0" styleName="col-12">
            <AppBar className="bg-primary public-market-tabs" position="static">
              <Tabs
                value={activeTab}
                onChange={this.changeTab}
                variant="fullWidth"
                indicatorColor="primary"
              >
                <Tab className="tab" label={<IntlMessages id="products" />} />
                <Tab className="tab" label={<IntlMessages id="bundles" />} />
                <Tab className="tab" label={<IntlMessages id="services" />} />
              </Tabs>
            </AppBar>

            {activeTab === 0
            && (
              <div>
                <Form
                  initialValues={productForm}
                  onSubmit={values => this.getProducts( values )}
                  render={( { handleSubmit } ) => (
                    <form onSubmit={handleSubmit} className="row mx-0 my-1">
                      <div className="col-sm-3">
                        <Select
                          placeholder="categories"
                          field="category"
                          options={categories}
                          isClearable={false}
                          marginContainer={false}
                          translateOptions={false}
                          onChange={() => {
                            this.productCategoryChange = true;
                            handleSubmit();
                          }}
                        />
                      </div>

                      <div className="col-sm-3">
                        <TextField
                          placeholder="search"
                          field="criteria"
                          marginContainer={false}
                          onKeyPress={( e ) => {
                            if ( e.key === 'Enter' ) {
                              this.productCategoryChange = false;
                              handleSubmit();
                            }
                          }}
                        />
                      </div>
                    </form>
                  )}
                />

                <div className="row">
                  <div className="col-12">
                    <Table
                      sortable={false}
                      data={products}
                      pageSize={products.length}
                      showPaginationTop={false}
                      columns={[
                        {
                          Header: <IntlMessages id="group" />,
                          accessor: 'workgroup.name',
                        },
                        {
                          Header: <IntlMessages id="category" />,
                          accessor: 'category',
                        },
                        {
                          Header: <IntlMessages id="name" />,
                          accessor: 'name',
                        },
                        {
                          Header: <IntlMessages id="cost" />,
                          accessor: 'cost',
                          Cell: ( { value } ) => Util.formatCurrency( value ),
                        },
                        {
                          Header: <IntlMessages id="price" />,
                          accessor: 'price',
                          Cell: ( { value } ) => Util.formatCurrency( value ),
                        },
                        {
                          Header: <IntlMessages id="unitsSold" />,
                          accessor: 'lastRoundUnitSales',
                          Cell: ( { value } ) => Util.formatNumber( value ),
                        },
                        {
                          Header: <IntlMessages id="marketShare" />,
                          accessor: 'marketShare',
                          Cell: ( { value } ) => `${Util.formatNumber( value )}%`,
                        },
                        {
                          Header: <IntlMessages id="design" />,
                          accessor: 'attributesParam',
                          Cell: ( { value } ) => this.findAttribute( value, 'Design' ),
                        },
                        {
                          Header: <IntlMessages id="speed" />,
                          accessor: 'attributesParam',
                          Cell: ( { value } ) => this.findAttribute( value, 'Speed' ),
                        },
                        {
                          Header: <IntlMessages id="size" />,
                          accessor: 'attributesParam',
                          Cell: ( { value } ) => this.findAttribute( value, 'Size' ),
                        },
                        {
                          Header: <IntlMessages id="reliability" />,
                          accessor: 'attributesParam',
                          Cell: ( { value } ) => this.findAttribute( value, 'Reliability' ),
                        },
                        {
                          Header: <IntlMessages id="weight" />,
                          accessor: 'attributesParam',
                          Cell: ( { value } ) => this.findAttribute( value, 'Weight' ),
                        },
                        {
                          Header: <IntlMessages id="bundle" />,
                          accessor: 'bundle',
                          Cell: ( { value } ) => _.map( value, item => `${item.productName || ''} + ${item.serviceName || ''}` ).join( ', ' ),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            )}


            {activeTab === 1
            && (
              <div>
                <Form
                  initialValues={bundleForm}
                  onSubmit={values => this.getBundles( values )}
                  render={( { handleSubmit } ) => (
                    <form onSubmit={handleSubmit} className="row mx-0 my-1">
                      <div className="col-sm-3">
                        <TextField
                          placeholder="search"
                          field="criteria"
                          marginContainer={false}
                        />
                      </div>
                    </form>
                  )}
                />

                <div className="row">
                  <div className="col-12">
                    <Table
                      sortable={false}
                      data={bundles}
                      pageSize={bundles.length}
                      showPaginationTop={false}
                      columns={[
                        {
                          Header: <IntlMessages id="group" />,
                          accessor: 'workgroup.name',
                        },
                        {
                          Header: <IntlMessages id="name" />,
                          accessor: 'name',
                        },
                        {
                          Header: <IntlMessages id="product" />,
                          accessor: 'product.name',
                        },
                        {
                          Header: <IntlMessages id="service" />,
                          accessor: 'service.name',
                        },
                        {
                          Header: <IntlMessages id="from" />,
                          accessor: 'from',
                          Cell: ( { value } ) => `${value.name} - ${value.startDate ? moment( Number( value.startDate ) ).format( 'MMM YYYY' ) : ''}`,
                        },
                        {
                          Header: <IntlMessages id="to" />,
                          accessor: 'to',
                          Cell: ( { value } ) => `${value.name} - ${value.endDate ? moment( Number( value.endDate ) ).format( 'MMM YYYY' ) : ''}`,
                        },
                        {
                          Header: <IntlMessages id="price" />,
                          accessor: 'price',
                          Cell: ( { value } ) => Util.formatCurrency( value ),
                        },
                        {
                          Header: <IntlMessages id="lastRoundSalesUnits" />,
                          accessor: 'lastRoundUnitSales',
                          Cell: ( { value } ) => Util.formatNumber( value ),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            )}

            {activeTab === 2
            && (
              <div>
                <Form
                  initialValues={serviceForm}
                  onSubmit={values => this.getServices( values )}
                  render={( { handleSubmit } ) => (
                    <form onSubmit={handleSubmit} className="row mx-0 my-1">
                      <div className="col-sm-3">
                        <Select
                          placeholder="serviceType"
                          field="serviceType"
                          options={serviceTypes}
                          isClearable={false}
                          marginContainer={false}
                          translateOptions={false}
                          onChange={() => {
                            this.serviceServiceTypeChange = true;
                            handleSubmit();
                          }}
                        />
                      </div>

                      <div className="col-sm-3">
                        <TextField
                          placeholder="search"
                          field="criteria"
                          marginContainer={false}
                          onKeyPress={( e ) => {
                            if ( e.key === 'Enter' ) {
                              this.serviceServiceTypeChange = false;
                              handleSubmit();
                            }
                          }}
                        />
                      </div>
                    </form>
                  )}
                />

                <div className="row">
                  <div className="col-12">
                    <Table
                      sortable={false}
                      data={services}
                      pageSize={services.length}
                      showPaginationTop={false}
                      columns={[
                        {
                          Header: <IntlMessages id="group" />,
                          accessor: 'workgroup.name',
                        },
                        {
                          Header: <IntlMessages id="description" />,
                          accessor: 'description',
                        },
                        {
                          Header: <IntlMessages id="unitaryCost" />,
                          accessor: 'unitaryCost',
                          Cell: ( { value } ) => Util.formatCurrency( value ),
                        },
                        {
                          Header: <IntlMessages id="price" />,
                          accessor: 'price',
                          Cell: ( { value } ) => Util.formatCurrency( value ),
                        },
                        {
                          Header: <IntlMessages id="unitsSold" />,
                          accessor: 'lastRoundUnitSales',
                        },
                        {
                          Header: <IntlMessages id="marketShare" />,
                          accessor: 'marketShare',
                          Cell: ( { value } ) => `${Util.formatNumber( value )}%`,
                        },
                        {
                          Header: <IntlMessages id="bundle" />,
                          accessor: 'bundle',
                          Cell: ( { value } ) => _.map( value, item => `${item.productName || ''} + ${item.serviceName || ''}` ).join( ', ' ),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            )}
          </CardBox>
        </div>

        <CardBox styleName="mt-3">
          <Form
            initialValues={unitsForm}
            onSubmit={values => this.getUnitsSalesMargin( values )}
            render={( { handleSubmit } ) => (
              <form onSubmit={handleSubmit} className="row">
                <div className="col-sm-3">
                  <Select
                    placeholder="round"
                    field="unitsRound"
                    options={rankingRounds}
                    marginContainer={false}
                    translateOptions={false}
                    onChange={() => {
                      handleSubmit();
                    }}
                  />
                </div>
                <div className="col-sm-3">
                  <Select
                    placeholder="type"
                    field="unitsType"
                    options={[
                      { id: 'TYPE_PRODUCT', name: 'products' },
                      { id: 'TYPE_SERVICE', name: 'services' },
                    ]}
                    marginContainer={false}
                    isClearable={false}
                    onChange={() => {
                      handleSubmit();
                    }}
                  />
                </div>
              </form>
            )}
          />

          <div className="row mt-3">
            <div className="col-md-4">
              <h4 className="m-0"><IntlMessages id="units" /></h4>
              <ResponsiveContainer width="100%" height={250}>
                <PieChart>
                  <Pie
                    data={unitsSalesMargin}
                    dataKey="totalUnits"
                    label={item => Util.formatNumber( item.value )}
                    cy={120}
                    outerRadius={80}
                    isAnimationActive={false}
                  >
                    {_.map( unitsSalesMargin, ( entry, index ) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ) )}
                  </Pie>
                  <Tooltip separator=": " formatter={item => Util.formatCurrency( item )} />
                </PieChart>
              </ResponsiveContainer>
            </div>

            <div className="col-md-4">
              <h4 className="m-0"><IntlMessages id="sales" /></h4>
              <ResponsiveContainer width="100%" height={250}>
                <PieChart>
                  <Pie
                    data={unitsSalesMargin}
                    dataKey="totalSales"
                    label={item => Util.formatCurrency( item.value )}
                    cy={120}
                    outerRadius={80}
                    isAnimationActive={false}
                  >
                    {_.map( unitsSalesMargin, ( entry, index ) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ) )}
                  </Pie>
                  <Tooltip separator=": " formatter={item => Util.formatCurrency( item )} />
                </PieChart>
              </ResponsiveContainer>
            </div>

            <div className="col-md-4">
              <h4 className="m-0"><IntlMessages id="margin" /></h4>
              <ResponsiveContainer width="100%" height={250}>
                <BarChart
                  data={unitsSalesMargin}
                >
                  <XAxis
                    interval={0}
                    dataKey="name"
                    height={60}
                    tick={<AxisTick />}
                  />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip separator=": " formatter={item => Util.formatCurrency( item )} />
                  <Legend verticalAlign="top" />
                  <Bar dataKey="totalMargin" name="Margin" fill="#3367d6" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </CardBox>
      </div>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( Dashboard );
