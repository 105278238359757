import React, { Component } from 'react';
import classnames from 'classnames';
import _ from 'lodash';

import { Select, TextField } from 'components/custom/FormElements';
import IntlMessages from 'utils/IntlMessages';

export default class ReactTablePagination extends Component {
  constructor( props ) {
    super();

    this.getSafePage = this.getSafePage.bind( this );
    this.changePage = this.changePage.bind( this );
    this.applyPage = this.applyPage.bind( this );

    this.state = { page: props.page };
  }

  componentWillReceiveProps( nextProps ) {
    this.setState( { page: nextProps.page } );
  }

  getSafePage( page ) {
    if ( isNaN( page ) ) {
      page = this.props.page;
    }
    return Math.min( Math.max( page, 0 ), this.props.pages - 1 );
  }

  changePage( page ) {
    page = this.getSafePage( page );
    this.setState( { page } );
    if ( this.props.page !== page ) {
      this.props.onPageChange( page );
    }
  }

  applyPage( e ) {
    e && e.preventDefault();
    const { page } = this.state;
    this.changePage( page === '' ? this.props.page : page );
  }

  render() {
    let {
      // Computed
      pages,
      // Props
      page,
      showPageSizeOptions,
      pageSizeOptions,
      showPageJump,
      canPrevious,
      canNext,
      className,
      onPageSizeChange,
      pageSize,
      extraElement,
    } = this.props;

    pageSizeOptions = _.map( pageSizeOptions, item => ( { id: item, name: item } ) );

    return (
      <div className={classnames( className, '-pagination' )}>
        <div className="-pageInfo w-100">
          <div className="row">
            <div className="col-12 col-sm-6 text-center text-sm-left px-2 d-flex align-items-end">
              <span className="text-muted text-small"><IntlMessages id="page" /></span>
              <i
                className={classnames( 'fas fa-angle-left p-2', {
                  'text-muted': !canPrevious,
                  pointer: canPrevious,
                } )}
                onClick={( e ) => {
                  if ( !canPrevious ) return;
                  this.changePage( page - 1 );
                }}
                disabled={!canPrevious}
              />

              {showPageJump
                ? (
                  <div className="-pageJump">
                    <TextField
                      marginContainer={false}
                      type={this.state.page === '' ? 'text' : 'number'}
                      onChange={( val ) => {
                        const page = val - 1;
                        if ( val === '' ) {
                          return this.setState( { page: val } );
                        }
                        this.setState( { page: this.getSafePage( page ) } );
                      }}
                      value={this.state.page === '' ? '' : this.state.page + 1}
                      onBlur={this.applyPage}
                      onKeyPress={( e ) => {
                        if ( e.which === 13 || e.keyCode === 13 ) {
                          this.applyPage();
                        }
                      }}
                    />
                  </div>
                )
                : (
                  <span className="-currentPage">
                    {page + 1}
                  </span>
                )
              }
              <h5 className="d-inline m-0">
                <i
                  className={classnames( 'fas fa-angle-right p-2', {
                    'text-muted': !canNext,
                    pointer: canNext,

                  } )}
                  onClick={( e ) => {
                    if ( !canNext ) return;
                    this.changePage( page + 1 );
                  }}
                />
              </h5>

              <span className="text-muted text-small">
                {this.props.ofText}
                {' '}
                <span className="-totalPages">{pages || 1}</span>
              </span>
            </div>

            <div className="col-12 col-sm-6 d-flex align-items-center justify-content-end">
              {showPageSizeOptions
              && (
              <div className="mr-2 align-items-center justify-content-sm-end justify-content-center d-flex">
                <Select
                  translateOptions={false}
                  marginContainer={false}
                  fullWidth={false}
                  emptyValue={false}
                  onChange={onPageSizeChange}
                  options={pageSizeOptions}
                  value={pageSize}
                  labelKey="name"
                  isClearable={false}
                />
                {' '}
                <span className="text-muted text-small pl-1">{this.props.rowsText}</span>
              </div>
              )}

              {extraElement}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
