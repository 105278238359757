import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import IconCard from 'components/custom/IconCard';
import SettingsActions from 'store/reducers/Settings';
import UserService from 'services/User';

const Dashboard = ( { toggleLoading } ) => {
  useEffect( () => {
    toggleLoading( true );
    UserService.getCurrentUser()
      .then( () => {
        toggleLoading( false );
      } );
  }, [toggleLoading] );

  return (
    <div className="app-dashboard-container">
      <div className="row justify-content-center align-items-center">
        <div className="col-12 col-sm-3 offset-sm-1">
          <IconCard icon="users" title="users" bgColor="cbg-dark" link="/users" />
        </div>

        <div className="col-12 col-sm-3">
          <IconCard icon="clipboard-list" title="params" bgColor="cbg-dark" link="/params" />
        </div>

        <div className="col-12 col-sm-3">
          <IconCard icon="university" title="university" bgColor="cbg-dark" link="/universities" />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = { toggleLoading: SettingsActions.toggleLoading };

export default connect( null, mapDispatchToProps )( Dashboard );
