import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert'

import IntlMessages from 'utils/IntlMessages';

export default ( { translateValues, open, onSubmit, onClose, title, confirmBtnText, showCancel = true, closeOnClickOutside = true } ) => {

  return (
    <SweetAlert show={open}
                warning
                showCancel={showCancel}
                closeOnClickOutside={closeOnClickOutside}
                confirmBtnText={confirmBtnText ?
                  <IntlMessages id={confirmBtnText}/> : <IntlMessages id="deleteModal.confirmBtn"/>}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title={title ? <IntlMessages id={title} values={translateValues}/> :
                  <IntlMessages id="deleteModal.confirmation" values={translateValues}/>}
                onConfirm={onSubmit}
                onCancel={onClose}
    />
  )
}
