import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import DisplayDate from 'components/theme/wall/DisplayDate/index';
import Card from '@material-ui/core/Card';
import IntlMessages from 'utils/IntlMessages';

class SystemPost extends Component {
  state = {
    post: {
      id: 0,
      content: '',
      createdAt: '',
      comments: [],
    },
  };
  componentWillMount() {
    this.setState({ post: this.props.postData });
  }

  render() {
    const { createdAt, content } = this.state.post;
    const date = new Date(parseInt(createdAt));

    return (
      <Card className="jr-card">
        <div className="jr-wall-content">
          <div className="media jr-wall-user-info flex-nowrap align-items-center">
            <Avatar className="mr-3 mb-2 size-50">S</Avatar>
            <div className="media-body">
              <h5 className="jr-wall-user-title">
                <IntlMessages id="post-system" />
              </h5>
              <DisplayDate date={date} />
            </div>
          </div>
          <p>{content}</p>
        </div>
      </Card>
    );
  }
}

export default SystemPost;
