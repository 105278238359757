import React from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@material-ui/core';
import _ from 'lodash';

import IntlMessages from 'utils/IntlMessages';

const CompareCommodityModal = ( { data, activeTab, open, onClose } ) => {
  const findAttribute = ( attributes, label ) => {
    const attribute = _.find( attributes, { label } );
    return attribute ? attribute.value : '';
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      {activeTab === 0 && (
        <>
          <DialogTitle className="bg-dark text-white">
            <IntlMessages id="products" />
          </DialogTitle>
          <DialogContent className="p-3">
            <div className="table-responsive-material">
              <table className="table custom-table mb-0">
                <thead>
                  <tr>
                    <td><IntlMessages id="group" /></td>
                    <td><IntlMessages id="product" /></td>
                    <td><IntlMessages id="design" /></td>
                    <td><IntlMessages id="speed" /></td>
                    <td><IntlMessages id="reliability" /></td>
                    <td><IntlMessages id="size" /></td>
                    <td><IntlMessages id="weight" /></td>
                  </tr>
                </thead>
                <tbody>
                  {_.map( data, product => (
                    <tr key={product.id}>
                      <td>
                        {_.get( product, 'workgroup.name' )}
                      </td>
                      <td>
                        {product.name}
                      </td>
                      <td>
                        {findAttribute( product.attributes, 'Design' )}
                      </td>
                      <td>
                        {findAttribute( product.attributes, 'Speed' )}
                      </td>
                      <td>
                        {findAttribute( product.attributes, 'Reliability' )}
                      </td>
                      <td>
                        {findAttribute( product.attributes, 'Size' )}
                      </td>
                      <td>
                        {findAttribute( product.attributes, 'Weight' )}
                      </td>
                    </tr>
                  ) )}
                </tbody>
              </table>
            </div>
          </DialogContent>
        </>
      ) }

      {activeTab === 2 && (
        <>
          <DialogTitle className="bg-dark text-white">
            <IntlMessages id="services" />
          </DialogTitle>
          <DialogContent className="p-3">
            <div
              className="table-responsive-material table-overflow-x-visible pb-2"
            >
              <table className="table custom-table mb-0">
                <thead>
                  <tr>
                    <td><IntlMessages id="group" /></td>
                    <td><IntlMessages id="service" /></td>
                    <td><IntlMessages id="content" /></td>
                    <td><IntlMessages id="easyToUse" /></td>
                    <td><IntlMessages id="speed" /></td>
                    <td><IntlMessages id="reliability" /></td>
                  </tr>
                </thead>
                <tbody>
                  {_.map( data, service => (
                    <tr key={service.id}>
                      <td>
                        {_.get( service, 'workgroup.name' )}
                      </td>
                      <td>
                        {service.name}
                      </td>
                      <td>
                        {findAttribute( service.attributes, 'CONTENT' )}
                      </td>
                      <td>
                        {findAttribute( service.attributes, 'EASY_TO_USE' )}
                      </td>
                      <td>
                        {findAttribute( service.attributes, 'SPEED' )}
                      </td>
                      <td>
                        {findAttribute( service.attributes, 'RELIABILITY' )}
                      </td>
                    </tr>
                  ) )}
                </tbody>
              </table>
            </div>
          </DialogContent>
        </>
      ) }
      <DialogActions>
        <Button
          variant="contained"
          className="jr-btn jr-btn-lg bg-blue-grey text-white"
          onClick={onClose}
        >
          <i className="fa fa-times" />
          <IntlMessages id="closeBtn" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompareCommodityModal;
