import React from 'react';
import { Avatar } from '@material-ui/core';

import cell from 'assets/images/mk/cell.png';
import laptop from 'assets/images/mk/laptop.png';
import tablet from 'assets/images/mk/tablet.png';

export default ({category}) => (
  <>
    {category.label === 'CATEGORY_1'
    && (
      <Avatar className="size-100 border-radius-0 m-auto" alt="..." src={laptop} />
    )}
    {category.label === 'CATEGORY_2'
    && (
      <Avatar className="size-100 border-radius-0 m-auto" alt="..." src={tablet} />
    )}
    {category.label === 'CATEGORY_3'
    && (
      <Avatar
        className="size-100 border-radius-0 m-auto"
        alt="..."
        src={cell}
      />
    )}
  </>
)
