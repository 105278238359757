import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Divider } from '@material-ui/core';

import ContainerHeader from 'components/theme/ContainerHeader';
import CardBox from 'components/theme/CardBox';
import SettingsActions from 'store/reducers/Settings';
import UserService from 'services/User';
import IntlMessages from 'utils/IntlMessages';

class Edit extends React.Component {
  state = { data: {} };

  entityId = null;

  componentDidMount() {
    const { match } = this.props;
    this.entityId = match.params.id;

    this.getEntity();
  }

  getEntity = () => {
    const { toggleLoading } = this.props;
    toggleLoading( true );
    UserService.getUser( this.entityId )
      .then( ( response ) => {
        toggleLoading( false );

        if ( response.ok ) {
          this.setState( { data: response.data } );
        }
      } );
  };

  render() {
    const { match } = this.props;
    const { data } = this.state;

    return (
      <div className="app-wrapper">
        <ContainerHeader
          match={match}
          title="user"
          name={`${data.name || ''} ${data.lastName || ''}`}
        />

        <CardBox>
          <div className="row mb-4">
            <div className="col-12">
              <h3><IntlMessages id="general" /></h3>
              <Divider />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <p>
                <strong>
                  <IntlMessages id="name" />
                  :
                  {' '}
                </strong>
                {data.name || data.lastName ? `${data.name || ''} ${data.lastName || ''}` : '---'}
              </p>
              <p>
                <strong>
                  <IntlMessages id="phone" />
                  :
                  {' '}
                </strong>
                {data.phone || '---'}
              </p>
              <p>
                <strong>
                  <IntlMessages id="birthdate" />
                  :
                  {' '}
                </strong>
                {data.birthDate ? moment( Number( data.birthDate ) ).format( 'DD/MM/YYYY' ) : '---'}
              </p>
            </div>
            <div className="col-md-6">
              <p>
                <strong>
                  <IntlMessages id="email" />
                  :
                  {' '}
                </strong>
                {data.email || '---'}
              </p>
              <p>
                <strong>
                  <IntlMessages id="username" />
                  :
                  {' '}
                </strong>
                {data.username || '---'}
              </p>
              <p>
                <strong>
                  <IntlMessages id="enabled" />
                  :
                  {' '}
                </strong>
                {data.enabled ? <i className="fa fa-check" /> : <i className="fa fa-times" />}
              </p>
              <p>
                <strong>
                  <IntlMessages id="lastLogin" />
                  :
                  {' '}
                </strong>
                {data.lastLogin ? moment( Number( data.lastLogin ) ).format( 'DD/MM/YYYY HH:mm' ) : '---'}
              </p>
            </div>
          </div>

          <div className="row my-4">
            <div className="col-12">
              <h3><IntlMessages id="licenses" /></h3>
              <Divider />
            </div>
          </div>
        </CardBox>
      </div>
    );
  }
}

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps,
)( Edit );
