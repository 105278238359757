import React from 'react';
import { Route, Switch } from 'react-router-dom';
import moment from 'moment';
import {
  ExpansionPanelSummary,
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import FixedSidebar from 'components/custom/FixedSidebar';
import Error404 from 'components/theme/Error404';
import SessionService from 'services/Session';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import IntlMessages from 'utils/IntlMessages';
import Util from 'utils/Util';
import ProfessorLicenses from './ProfessorLicenses';
import UserLicenses from './UserLicenses';

class Detail extends React.Component {
  state = { data: {} };
  menus = [];

  componentWillMount() {
    const { entityId, match } = this.props;
    this.menus = [
      {
        headerId: 1,
        heading: 'sessions',
        items: [
          {
            id: 2,
            icon: 'format-indent-increase',
            name: 'list',
            color: 'primary',
            url: `/universities/detail/${entityId}/sessions`,
          },
          {
            id: 3,
            icon: 'assignment-account',
            name: 'professorLicenses',
            color: 'secondary',
            url: `${match.url}/professor-licenses`,
          },
          {
            id: 3,
            icon: 'layers',
            name: 'userLicenses',
            color: 'info',
            url: `${match.url}/user-licenses`,
          },
        ],
      },
    ];
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { toggleLoading, match } = this.props;
    toggleLoading( true );

    SessionService.getSession( match.params.id )
      .then( ( response ) => {
        toggleLoading( false );

        this.setState( { data: response.data } );
      } );
  }

  render() {
    const { match } = this.props;
    const { data } = this.state;

    return (
      <div className="d-flex flex-wrap">
        <FixedSidebar
          items={this.menus}
          cardClasses="col-lg-2 col-md-6 col-12 pl-0"
        />

        <div className="col-12 col-md-6 col-lg-10 mt-3">
          <div className="row">
            <div className="col-12">

              <ExpansionPanel defaultExpanded className="mb-3 expansion-panel">
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{data.name || '---'}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className="w-100 pt-3">
                    <div className="row">
                      <div className="col-sm-4">
                        <p>
                          <strong>
                            <IntlMessages id="numberGroup" />
                          </strong>
                          {': '}
                          {data.numberGroup || '---'}
                        </p>
                        <p>
                          <strong>
                            <IntlMessages id="startDate" />
                          </strong>
                          {': '}
                          {data.startDate ? moment( Number( data.startDate ) )
                            .format( 'DD/MM/YYYY' ) : '---'}
                        </p>
                        <p className="m-0">
                          <strong>
                            <IntlMessages id="endDate" />
                          </strong>
                          {': '}
                          {data.endDate ? moment( Number( data.endDate ) )
                            .format( 'DD/MM/YYYY' ) : '---'}
                        </p>
                      </div>
                      <div className="col-sm-4">
                        <p>
                          <strong>
                            <IntlMessages id="licensesTotal" />
                          </strong>
                          {': '}
                          {Util.formatNumber( data.licensesTotal || '---' )}
                        </p>
                        <p>
                          <strong>
                            <IntlMessages id="usedLicenses" />
                          </strong>
                          {': '}
                          {Util.formatNumber( data.usedLicenses || '---' )}
                        </p>
                        <p>
                          <strong>
                            <IntlMessages id="initialMoneyPerGroup" />
                          </strong>
                          {': '}
                          {Util.formatCurrency( data.initialMoneyPerGroup || '---' )}
                        </p>
                        <p className="m-0">
                          <strong>
                            <IntlMessages id="fixCostPerGroup" />
                          </strong>
                          {': '}
                          {Util.formatCurrency( data.fixCostPerGroup || '---' )}
                        </p>
                      </div>
                      <div className="col-sm-4">
                        <p>
                          <strong>
                            <IntlMessages id="maxProductPerGroup" />
                          </strong>
                          {': '}
                          {Util.formatNumber( data.maxProductPerGroup || '---' )}
                        </p>
                        <p>
                          <strong>
                            <IntlMessages id="maxUserGroup" />
                          </strong>
                          {': '}
                          {Util.formatNumber( data.maxUserGroup || '---' )}
                        </p>
                        <p>
                          <strong>
                            <IntlMessages id="stockFinalValue" />
                          </strong>
                          {': '}
                          {Util.formatNumber( data.stockFinalValue || '---' )}
                        </p>
                        <p className="m-0">
                          <strong>
                            <IntlMessages id="taxes" />
                          </strong>
                          {': '}
                          {Util.formatNumber( data.taxes || '---' )}
                        </p>
                      </div>
                    </div>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          </div>
          <Switch>
            <Route
              path={`${match.url}/professor-licenses`}
              render={props => ( <ProfessorLicenses {...props} sessionId={match.params.id} /> )}
            />
            <Route
              path={`${match.url}/user-licenses`}
              render={props => ( <UserLicenses {...props} sessionId={match.params.id} /> )}
            />
            <Route component={Error404} />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps,
)( Detail );
