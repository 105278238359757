import React from 'react';
import { Field } from 'react-final-form';
import { FormControl, FormHelperText } from '@material-ui/core';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

import IntlMessages from 'utils/IntlMessages';

const InputRangeField = ( {
                            field, containerClass, fullWidth = true, onChange,
                            translateValues, validate, intl, marginContainer = true,
                            label, ...rest
                          } ) => (
  <Field name={field} validate={validate}>
    {( { meta, input } ) => (
      <FormControl
        error={meta.touched && !!meta.error}
        className={classNames( containerClass, { 'mb-4': marginContainer } )}
        fullWidth={fullWidth}
      >
        {label && (
          <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
            <IntlMessages id={label}/>
          </label>
        )}
        <InputRange
          {...input}
          {...rest}
          value={input.value || rest.minValue}
          onChange={( value ) => {
            input.onChange( value );
            if (onChange) onChange( value );
          }}
        />
        {meta.touched && meta.error
        && (
          <FormHelperText>
            <IntlMessages id={meta.error} values={translateValues}/>
          </FormHelperText>
        )}
      </FormControl>
    )}
  </Field>
);

export default injectIntl( InputRangeField );
