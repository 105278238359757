import React from 'react';
import { Button } from '@material-ui/core';
import { Form } from 'react-final-form';

import IntlMessages from 'utils/IntlMessages';

export default ( { children, newBtn, onClear, onSubmit } ) => {
  return (
    <Form
      onSubmit={onSubmit}
      render={( { handleSubmit, form } ) => (
        <form onSubmit={handleSubmit} className="px-4 pt-3 pb-4">
          <div className="row align-items-center">
            {children}

            <div className="col-lg-3">
              <Button className="jr-btn bg-blue-grey text-white text-uppercase jr-btn-lg"
                      type="submit">
                <i className="zmdi zmdi-filter-list"/>
                <IntlMessages id="filterBtn"/>
              </Button>
              <Button
                className="jr-btn bg-red text-white jr-btn-lg"
                onClick={() => {
                  form.reset();
                  onClear()
                }}
              >
                <i className="fa fa-times"/>
              </Button>
            </div>

            {newBtn ?
              <div className="col-lg-6 mt-2 mt-lg-0 text-lg-right">
                {newBtn}
              </div> : null}
          </div>
        </form>
      )}
    />
  );
}
