import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';

import SettingsActions from 'store/reducers/Settings';
import IntlMessages from 'utils/IntlMessages';
import { Form } from 'react-final-form';
import { TextField, Buttons } from 'components/custom/FormElements';
import { required } from 'config/InputErrors';
import WorkGroupService from 'services/WorkGroup';

const GroupProfile = ( {
  activeSessionId,
  toggleLoading,
  toggleAlert,
  onEdit,
  data,
  readOnly,
} ) => {
  const submitForm = ( group ) => {
    toggleLoading( true );

    WorkGroupService.updateWorkGroupSettings(
      activeSessionId,
      data.id,
      { name: group.name },
    ).then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) return toggleAlert( response.errors );
      onEdit();
      toggleAlert( 'profile-group-updated', 'info' );
    } );
  };

  return (
    <div>
      <Form
        initialValues={{ name: data.name }}
        onSubmit={submitForm}
        render={( { handleSubmit, form } ) => (
          <form onSubmit={handleSubmit} noValidate>

            <div className="col-xl-7 col-lg-8 col-md-12 col-sm-12 col-12 mx-auto">
              <div>
                <div className="app-login-header mb-4">
                  <h1><IntlMessages id="profile-group-title" /></h1>
                </div>
                <div className="app-login-main-content d-flex align-items-center justify-content-center p-4">
                  <TextField
                    field="name"
                    label="name"
                    disabled={readOnly}
                    validate={required}
                  />
                  {readOnly ? (
                    <div className="col-md-12 text-right">
                      <Button
                        id="edit"
                        variant="contained"
                        color="primary"
                        className="jr-btn jr-btn-lg"
                        onClick={onEdit}
                        type="button"
                      >
                        <i className="fa fa-pencil-alt mr-1" />
                        <IntlMessages id="profile-edit" />
                      </Button>
                    </div>
                  ) : (
                    <div className="col-md-12 text-right">
                      <div className="row">
                        <div className="col-md-12 text-right">
                          <Buttons onClickCancel={() => {
                            form.reset();
                            onEdit();
                          }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        )}
      />
    </div>

  );
};
const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( null, mapDispatchToProps )( GroupProfile );
