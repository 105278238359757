import api from '../config/Api';

const endpoint = 'v1/entity';

export default {

  getEntities( params ) {
    return api.get( endpoint, params );
  },

  getEntity( id ) {
    return api.get( `${endpoint}/${id}` );
  },

  getWorkingDays( id, params ) {
    return api.get( `${endpoint}/${id}/workingday`, params );
  },

  updateEntity( id, data ) {
    return api.put( `${endpoint}/${id}`, data );
  },

  saveEntity( data ) {
    return api.post( endpoint, data );
  },

  deleteEntity( id ) {
    return api.delete( `${endpoint}/${id}` );
  },

  saveWorkingDays( id, data ) {
    return api.post( `${endpoint}/${id}/workingday`, data );
  },

  deleteWorkingDays( id, data ) {
    return api.delete( `${endpoint}/${id}/workingday`, {}, { data } );
  },
};
