import React from 'react';
import { connect } from 'react-redux';
import Post from 'services/Post';

import SettingsActions from 'store/reducers/Settings';
import Grid from '@material-ui/core/Grid';
import WriteBox from 'components/custom_v2/WriteBox/index';
import PostItem from 'components/custom_v2/PostList/PostItem';
import SystemPost from 'components/custom_v2/PostList/SystemPost';
import Auxiliary from 'components/theme/Auxiliary';

class Dashboard extends React.Component {
  state = { postList: [] };

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { user, toggleLoading, toggleAlert } = this.props;
    toggleLoading( true );
    Post.getPosts( user.activeSession.id, { 'filters[reply]': 0 } ).then(
      ( response ) => {
        if ( response.ok ) {
          toggleLoading( false );
          this.setState( { postList: response.data.data } );
        } else {
          toggleLoading( false );
          toggleAlert( response.errors );
        }
      },
    );
  }

  addPost = ( content ) => {
    const { toggleLoading, user } = this.props;
    const { postList } = this.state;

    const postArray = postList;

    toggleLoading( true );
    Post.savePost( user.activeSession.id, { content } ).then( ( response ) => {
      toggleLoading( false );
      if ( response.ok ) {
        const post = {
          ...response.data,
          owner: {
            user: {
              id: user.id,
              name: user.name,
            },
          },
          replies: [],
          createdAt: new Date().toString(),
        };
        postArray.unshift( post );
        this.setState( { postList: postArray } );
      }
    } );
  };

  render() {
    const { user, toggleLoading } = this.props;
    const { postList } = this.state;

    return (
      <div className="app-wrapper">
        <Grid container direction="row" justify="center" alignItems="center">
          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
            <div className="jr-wall-postlist jr">
              <Auxiliary>
                <WriteBox addPost={this.addPost} user={user} />
                {postList.map( ( post ) => {
                  if ( post.typeMessage === 'USER' ) {
                    return (
                      <PostItem
                        key={post.id}
                        index={post.id}
                        postData={post}
                        user={user}
                        toggleLoading={toggleLoading}
                      />
                    );
                  }
                  return (
                    <SystemPost key={post.id} index={post.id} postData={post} />
                  );
                } )}
              </Auxiliary>
            </div>
          </div>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( Dashboard );
