import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  AppBar,
  Avatar,
  Toolbar,
  IconButton,
  Divider,
  ListItem,
  ListItemText,
  List
} from '@material-ui/core';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import ThumbDown from '@material-ui/icons/PermIdentity';
import _ from 'lodash';

import {
  BELOW_THE_HEADER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import SettingsActions from 'store/reducers/Settings';
import UserActions from 'store/reducers/User';
import IntlMessages from 'utils/IntlMessages';
import LanguageSwitcher from 'components/theme/LanguageSwitcher/index';
import UserInfoPopup from 'components/theme/UserInfo/UserInfoPopup';
import UtilService from 'utils/Util';

class Header extends React.Component {
  onAppNotificationSelect = () => {
    this.setState( { appNotification: !this.state.appNotification } );
  };

  onMailNotificationSelect = () => {
    this.setState( { mailNotification: !this.state.mailNotification } );
  };

  onLangSwitcherSelect = ( event ) => {
    this.setState( {
      langSwitcher: !this.state.langSwitcher,
      anchorEl: event.currentTarget
    } );
  };

  onSearchBoxSelect = () => {
    this.setState( { searchBox: !this.state.searchBox } );
  };

  onAppsSelect = () => {
    this.setState( { apps: !this.state.apps } );
  };

  onSessionsSelect = () => {
    this.setState( { sessions: !this.state.sessions } );
  };

  onUserInfoSelect = () => {
    this.setState( { userInfo: !this.state.userInfo } );
  };

  handleRequestClose = () => {
    this.setState( {
      langSwitcher: false,
      userInfo: false,
      mailNotification: false,
      appNotification: false,
      searchBox: false,
      apps: false,
    } );
  };

  onToggleCollapsedNav = () => {
    this.props.toggleCollapsedNav();
  };

  constructor( props ) {
    super();
    this.state = {
      anchorEl: undefined,
      searchBox: false,
      searchText: '',
      mailNotification: false,
      userInfo: false,
      langSwitcher: false,
      appNotification: false,
    };
    this.userSessions = _.map( UtilService.getUserSessions( props.user ) );
  }

  updateSearchText( evt ) {
    this.setState( { searchText: evt.target.value } );
  }

  hasPermissions( role, license ) {
    const { user } = this.props;
    return user.roles && user.roles.indexOf( role ) !== -1
      && user.licenses && _.find( user.licenses, { owner: license } );
  }

  AdminApps = () => (
    <ul className="jr-list jr-list-half">
      <li className="jr-list-item">
        <Link className="jr-list-link" to="/users" onClick={this.onAppsSelect.bind( this )}>
          <i className=" fa fa-users"/>
          <span className="jr-list-text"><IntlMessages id="users"/></span>
        </Link>
      </li>

      <li className="jr-list-item">
        <Link className="jr-list-link" to="/params" onClick={this.onAppsSelect.bind( this )}>
          <i className=" fa fa-clipboard-list"/>
          <span className="jr-list-text"><IntlMessages id="params"/></span>
        </Link>
      </li>

      <li className="jr-list-item">
        <Link className="jr-list-link" to="/universities" onClick={this.onAppsSelect.bind( this )}>
          <i className=" fa fa-university"/>
          <span className="jr-list-text"><IntlMessages id="university"/></span>
        </Link>
      </li>
    </ul>
  );

  Sessions = () => (
    <List>
      {_.map( this.userSessions, session =>
        session && (
        <Link key={session.id} to="/" className="text-decoration-none"
              onClick={() => {
                this.props.changeActiveSession( session );
                this.onSessionsSelect();
              }}
        >
          <ListItem button>
            <i className="zmdi zmdi-account zmdi-hc-lg text-primary mr-2"/>
            <ListItemText primary={`${session.entity.name} - ${session.name}`}/>
          </ListItem>
          <Divider light/>
        </Link>
        )
      )}
    </List>
  );

  render() {
    const { locale, navigationStyle, horizontalNavPosition, history, user } = this.props;
    const activeSession = user.activeSession;

    return (
      <AppBar
        className={`app-main-header ${( navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER ) ? 'app-main-header-top' : ''}`}
      >
        <Toolbar className="app-toolbar justify-content-between d-flex" disableGutters={false}>

          <div className="d-flex align-items-center" onClick={this.onToggleCollapsedNav}>
            {( this.hasPermissions( 'ROLE_CLIENT', 'user' ) 
             || this.hasPermissions( 'ROLE_SUPERVISOR', 'sup' )
             )
             &&
            ( history.location.pathname.indexOf( '/info' ) !== -1 ||
              history.location.pathname.indexOf( '/general' ) !== -1
            )
              ?
              <div className="pointer mr-3">
              <span className="jr-menu-icon">
                <span className="menu-icon"/>
              </span>
              </div> : null
            }

            <Link className="app-logo mr-2" to="/">
              <img src={require( 'assets/images/logo.png' )} alt="LiveMax" title="LiveMax"/>
            </Link>

            {history.location.pathname !== '/dashboard' && this.hasPermissions( 'ROLE_ADMIN', 'adm' ) &&
            <ul className="header-notifications list-inline ml-sm-3">
              <li className="list-inline-item">
                <Dropdown
                  className="quick-menu app-notification"
                  isOpen={this.state.apps}
                  toggle={this.onAppsSelect.bind( this )}
                >

                  <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown"
                  >
                  <span className="app-notification-menu">
                    <i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg"/>
                    <span>Menu</span>
                  </span>
                  </DropdownToggle>

                  {this.hasPermissions( 'ROLE_ADMIN', 'adm' )
                    ? <DropdownMenu>{this.AdminApps()}</DropdownMenu> : null
                  }
                </Dropdown>
              </li>
            </ul>
            }
          </div>

          {(
            ( this.hasPermissions( 'ROLE_SUPERVISOR', 'sup' ) && history.location.pathname.indexOf( '/sessions' ) !== -1 )
            || ( this.hasPermissions( 'ROLE_CLIENT', 'user' ) && history.location.pathname === '/')
            || ( this.hasPermissions( 'ROLE_SUPERVISOR', 'sup' ) && history.location.pathname === '/')
          ) &&
          !!this.userSessions.length && activeSession &&
          <ul className="header-notifications list-inline d-none d-sm-block">
            <li className="list-inline-item">
              <Dropdown
                className="quick-menu app-notification"
                isOpen={this.state.sessions}
                toggle={this.onSessionsSelect.bind( this )}
              >

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown"
                >
                  <span className="app-notification-menu">
                    <i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg"/>
                    <span>{activeSession.entity.name} - {activeSession.name}</span>
                  </span>
                </DropdownToggle>

                <DropdownMenu className="p-0">
                  {this.Sessions()}
                </DropdownMenu>
              </Dropdown>
            </li>
          </ul>
          }

          <ul className="header-notifications list-inline">
            {this.hasPermissions( 'ROLE_CLIENT', 'user' ) ?
              <li className="list-inline-item">
                <IconButton className="icon-btn text-decoration-none " component={Link}
                            to={`/tutorial`}
                >
                  <span className="fa fa-info-circle fa-1x"/>
                </IconButton>
              </li> : null
            }

            <li className="list-inline-item">
              <Dropdown
                className="quick-menu"
                isOpen={this.state.langSwitcher}
                toggle={this.onLangSwitcherSelect.bind( this )}
              >
                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown"
                >
                  <IconButton className="icon-btn">
                    <i className={`flag flag-24 flag-${locale.icon}`}/>
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right className="w-50">
                  <LanguageSwitcher
                    switchLanguage={this.props.switchLanguage}
                    handleRequestClose={this.handleRequestClose}
                  />
                </DropdownMenu>
              </Dropdown>
            </li>

            {this.hasPermissions( 'ROLE_CLIENT', 'user' ) ?
              <li className="list-inline-item">
                <IconButton className="icon-btn text-decoration-none" component={Link}
                                             to={`/sessions/messages`}
                >
                  <span className="zmdi zmdi-comment-alt-text text-white"/>
                </IconButton>
              </li> : null
            }

            <li className="list-inline-item user-nav">
              <Dropdown
                className="quick-menu user"
                isOpen={this.state.userInfo}
                toggle={this.onUserInfoSelect.bind( this )}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <IconButton className="icon-btn">
                    <ThumbDown/>
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right>
                  <UserInfoPopup toggle={this.onUserInfoSelect.bind( this )}/>
                </DropdownMenu>
              </Dropdown>
            </li>

            {navigationStyle === HORIZONTAL_NAVIGATION
            && (
              <li className="list-inline-item user-nav">
                <Dropdown
                  className="quick-menu"
                  isOpen={this.state.userInfo}
                  toggle={this.onUserInfoSelect.bind( this )}
                >

                  <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown"
                  >
                    <IconButton className="icon-btn size-30">
                      <Avatar
                        alt="..."
                        src="https://via.placeholder.com/150x150"
                        className="size-30"
                      />
                    </IconButton>
                  </DropdownToggle>

                  <DropdownMenu right>
                    <UserInfoPopup/>
                  </DropdownMenu>
                </Dropdown>
              </li>
            )}
          </ul>

          <div className="ellipse-shape"/>
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = ( { settings } ) => {
  const { drawerType, locale, navigationStyle, horizontalNavPosition } = settings;
  return {
    drawerType,
    locale,
    navigationStyle,
    horizontalNavPosition
  };
};

export default withRouter( connect( mapStateToProps, {
  changeActiveSession: UserActions.changeActiveSession,
  toggleCollapsedNav: SettingsActions.toggleCollapsedNav,
  switchLanguage: SettingsActions.switchLanguage,
} )( Header ) );
