import React from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import async from 'async';
import moment from 'moment';

import IntlMessages from 'utils/IntlMessages';
import { Select, TextField } from 'components/custom/FormElements';
import CardBox from 'components/theme/CardBox';
import Table from 'components/custom/Table/Custom';
import { required } from 'config/InputErrors';
import SettingsActions from 'store/reducers/Settings';
import ProductService from 'services/Product';
import BundleService from 'services/Bundle';
import ContainerHeader from 'components/theme/ContainerHeader';
import SessionServiceService from 'services/Service';
import RoundService from 'services/Round';
import ConfirmationModal from 'components/custom/ConfirmationModal';
import HistorySalesModal from 'routes/App/components/User/HistorySalesModal';
import Util from 'utils/Util';

class BundleManagement extends React.Component {
  state = {
    bundles: [],
    services: [],
    products: [],
    rounds: [],
    showActiveBundles: false,
    deactivateBundleModal: false,
    historySalesModal: false,
  };

  componentDidMount() {
    const { toggleAlert, user } = this.props;
    if ( !user.activeSession ) {
      this.setState( { corruptedData: true } );
      return toggleAlert( 'ERROR' );
    }

    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      this.setState( { corruptedData: true } );
      return toggleAlert( 'ERROR' );
    }
    this.activeSession = user.activeSession;
    this.getData();
  }

  getData = () => {
    const { toggleAlert, toggleLoading } = this.props;

    toggleLoading( true );
    async.parallel( {
      bundles: this.getBundles,
      services: this.getServices,
      products: this.getProducts,
      rounds: this.getRounds,
    }, ( error, results ) => {
      toggleLoading( false );
      if ( error ) {
        this.setState( { corruptedData: true } );
        return toggleAlert( 'ERROR' );
      }
      this.setState( results );
    } );
  };

  getBundles = ( cb ) => {
    const { toggleLoading, user } = this.props;
    const { showActiveBundles } = this.state;
    if ( !cb ) toggleLoading( true );

    const filters = {
      workgroup: this.license.workGroup.id,
      limit: -1,
    };
    if ( showActiveBundles ) {
      filters['filters[active]'] = showActiveBundles;
    }
    BundleService.getBundles( user.activeSession.id, filters )
      .then( ( response ) => {
        if ( !cb ) toggleLoading( false );
        if ( !response.ok ) return cb ? cb( true ) : null;

        if ( cb ) return cb( null, response.data.data );
        this.setState( { bundles: response.data.data } );
      } );
  };

  getServices = ( cb ) => {
    const { user } = this.props;

    const filters = {
      workgroup: this.license.workGroup.id,
      limit: -1,
    };
    SessionServiceService.getServices( user.activeSession.id, filters )
      .then( ( response ) => {
        if ( !response.ok ) return cb( true );

        cb( null, response.data.data );
      } );
  };

  getProducts = ( cb ) => {
    const filters = {
      workgroup: this.license.workGroup.id,
      'filters[active]': '1',
      limit: -1,
    };
    ProductService.getProductsBySession( this.activeSession.id, filters )
      .then( ( response ) => {
        if ( !response.ok ) return cb( true );
        cb( null, response.data.data );
      } );
  };

  getRounds = ( cb ) => {
    RoundService.getRoundsBySession( this.activeSession.id, { limit: -1 } )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        return cb( null, response.data.data );
      } );
  };

  getRoundDate = ( id, attr ) => {
    const { rounds } = this.state;
    const round = _.find( rounds, { id } );
    if ( !round ) return null;
    return moment( Number( round[attr] ) )
      .format( 'MMM YYYY' );
  };

  getRoundEndDate = ( startRoundId, id ) => {
    if ( !startRoundId || !id ) return null;

    const { rounds } = this.state;
    const startRound = _.find( rounds, { id: startRoundId } );
    const endRound = _.find( rounds, { id } );
    if ( !startRound || !endRound ) return null;

    return moment( Number( startRound.startDate ) ).add( endRound.cont, 'month' ).format( 'MMM YYYY' );
  };

  submitForm = ( formData ) => {
    const { toggleAlert, toggleLoading } = this.props;

    toggleLoading( true );
    const dataToSend = {
      workGroup: this.license.workGroup.id,
      name: formData.name,
      description: formData.description,
      code: formData.code,
      product: formData.product.id,
      service: formData.service.id,
      fromRound: formData.from,
      toRound: formData.to,
    };

    BundleService.saveBundle( this.activeSession.id, dataToSend )
      .then( ( response ) => {
        if ( !response.ok ) {
          toggleLoading( false );
          return toggleAlert( response.errors );
        }

        toggleAlert( 'dataSaved', 'info' );
        this.getBundles();
        this.form.initialize( {} );
      } );
  };

  toggleDeactivateBundleModal = ( entity ) => {
    this.entityToEdit = entity;
    this.setState( previousState => (
      { deactivateBundleModal: !previousState.deactivateBundleModal }
    ) );
  };

  toggleHistorySalesModal = ( entity ) => {
    if ( entity ) {
      const { toggleAlert, toggleLoading } = this.props;

      toggleLoading( true );

      BundleService.getBundle( this.activeSession.id, {
        workgroup: this.license.workGroup.id,
        bundle: entity.id,
      } )
        .then( ( response ) => {
          toggleLoading( false );
          if ( !response.ok ) {
            return toggleAlert( response.errors );
          }

          this.entityToShowSales = response.data;
          this.setState( { historySalesModal: true } );
        } );
    } else {
      this.setState( { historySalesModal: false } );
    }
  };

  deactivateBundle = () => {
    const { toggleAlert, toggleLoading } = this.props;

    toggleLoading( true );
    const dataToSend = {
      workGroup: this.license.workGroup.id,
      bundle: this.entityToEdit.id,
      active: !this.entityToEdit.active,
    };
    BundleService.updateBundle( this.activeSession.id, dataToSend )
      .then( ( response ) => {
        if ( !response.ok ) {
          toggleLoading( false );
          return toggleAlert( response.errors );
        }
        this.setState( { deactivateBundleModal: false } );
        this.getBundles();
      } );
  };

  render() {
    const {
      bundles, services, products, rounds, corruptedData,
      showActiveBundles, deactivateBundleModal, historySalesModal,
    } = this.state;

    return (
      <Form
        onSubmit={this.submitForm}
        render={( { handleSubmit, values, form } ) => {
          this.form = form;
          return (
            <form onSubmit={handleSubmit}>
              <ContainerHeader title="bundleManagement" />

              <div className="row mt-3">
                <div className="col-12">
                  <ExpansionPanel defaultExpanded className="mb-3 expansion-panel">
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography><IntlMessages id="bundlesList" /></Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="p-0 d-block">
                      <div className="col-sm-3 offset-sm-9 mb-2">
                        <Select
                          placeholder="status"
                          value={showActiveBundles}
                          marginContainer={false}
                          translateOptions={false}
                          onChange={( value ) => {
                            this.setState( { showActiveBundles: value }, this.getBundles );
                          }}
                          isDisabled={corruptedData}
                          options={[
                            {
                              id: '1',
                              name: 'Active',
                            },
                            {
                              id: '0',
                              name: 'Not Active',
                            },
                          ]}
                        />
                      </div>

                      <div className="col-12 p-0">
                        {bundles.length === 0
                          ? (
                            <div
                              className="text-muted h-100 d-flex align-items-center justify-content-center p-3"
                            >
                              No bundles founds
                            </div>
                          )
                          : (
                            <Table
                              data={bundles}
                              pageSize={bundles.length}
                              showPaginationTop={false}
                              rowsText=""
                              columns={[
                                {
                                  Header: <IntlMessages id="name" />,
                                  accessor: 'name',
                                },
                                {
                                  Header: <IntlMessages id="description" />,
                                  accessor: 'description',
                                },
                                {
                                  Header: <IntlMessages id="product" />,
                                  accessor: 'product.name',
                                },
                                {
                                  Header: <IntlMessages id="service" />,
                                  accessor: 'service.name',
                                },
                                {
                                  Header: <IntlMessages id="from" />,
                                  accessor: 'from',
                                  Cell: ( { value } ) => ( value && value.startDate
                                    ? `${moment( Number( value.startDate ) )
                                      .format( 'MMM YYYY' )} (${value.name})` : '' ),
                                },
                                {
                                  Header: <IntlMessages id="to" />,
                                  accessor: 'to',
                                  Cell: ( { value } ) => ( value && value.endDate
                                    ? `${moment( Number( value.endDate ) )
                                      .format( 'MMM YYYY' )} (${value.name})` : '' ),
                                },
                                {
                                  Header: <IntlMessages id="individualPrice" />,
                                  accessor: 'individualPrice',
                                  Cell: ( { value } ) => Util.formatCurrency( value ),
                                },
                                {
                                  Header: <IntlMessages id="price" />,
                                  accessor: 'bundlePrice',
                                  Cell: ( { value } ) => Util.formatCurrency( value ),
                                },
                                {
                                  Header: <IntlMessages id="totalSales" />,
                                  accessor: 'totalUnitSales',
                                  Cell: ( { value } ) => Util.formatNumber( value ),
                                },
                                {
                                  Header: <IntlMessages id="lastRoundSales" />,
                                  accessor: 'lastRoundUnitSales',
                                  Cell: ( { value } ) => Util.formatNumber( value ),
                                },
                                {
                                  Header: '',
                                  accessor: 'id',
                                  maxWidth: 120,
                                  Cell: props => (
                                    <>
                                      {
                                        props.original.active
                                          ? (
                                            <Button
                                              variant="contained"
                                              className="jr-btn"
                                              color="primary"
                                              onClick={() => {
                                                this.toggleDeactivateBundleModal( props.original );
                                              }}
                                            >
                                              <i className="fa fa-check" />
                                            </Button>
                                          )
                                          : (
                                            <div className="text-center w-100">
                                              <i className="fa fa-times" />
                                            </div>
                                          )
                                      }
                                      <Button
                                        variant="contained"
                                        className="jr-btn bg-blue-grey text-white"
                                        onClick={() => {
                                          this.toggleHistorySalesModal( props.original );
                                        }}
                                      >
                                        <i className="fa fa-chart-line" />
                                      </Button>
                                    </>
                                  ),
                                },
                              ]}
                            />
                          )}
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              </div>

              <div className="row mt-3">
                <CardBox styleName="col-12" heading="bundleDefinition">
                  <div className="row mt-3">
                    <div className="col-md-8">
                      <Field name="product" validate={required}>
                        {( { input, meta } ) => (
                          <>
                            <Table
                              data={products}
                              pageSize={products.length}
                              rowsText=""
                              showPaginationTop={false}
                              columns={[
                                {
                                  Header: <IntlMessages id="category" />,
                                  accessor: 'category',
                                },
                                {
                                  Header: <IntlMessages id="code" />,
                                  accessor: 'code',
                                },
                                {
                                  Header: <IntlMessages id="name" />,
                                  accessor: 'name',
                                },
                                {
                                  Header: <IntlMessages id="description" />,
                                  accessor: 'description',
                                },
                                {
                                  Header: <IntlMessages id="cost" />,
                                  accessor: 'cost',
                                  Cell: ( { value } ) => Util.formatCurrency( value ),
                                },
                                {
                                  Header: <IntlMessages id="price" />,
                                  accessor: 'priceCurrentRound',
                                  Cell: ( { value } ) => Util.formatCurrency( value ),
                                },
                                {
                                  Header: <IntlMessages id="lastRoundSales" />,
                                  accessor: 'lastRoundSales',
                                  Cell: ( { value } ) => Util.formatNumber( value ),
                                },
                              ]}
                              getTrProps={( state, rowInfo ) => ( {
                                className: input.value && rowInfo.original.id === input.value.id ? 'font-weight-bold pointer' : 'pointer',
                                onClick: () => input.onChange( {
                                  id: rowInfo.original.id,
                                  cost: rowInfo.original.cost,
                                } ),
                              } )}
                            />

                            {meta.touched && meta.error
                            && (
                              <FormHelperText className="text-danger">
                                <IntlMessages id={meta.error} />
                              </FormHelperText>
                            )}
                          </>
                        )}
                      </Field>

                      <div className="mt-3">
                        <Field name="service" validate={required}>
                          {( { input, meta } ) => (
                            <>
                              <Table
                                data={services}
                                pageSize={services.length}
                                showPaginationTop={false}
                                rowsText=""
                                columns={[
                                  {
                                    Header: <IntlMessages id="service" />,
                                    accessor: 'serviceType',
                                  },
                                  {
                                    Header: <IntlMessages id="name" />,
                                    accessor: 'name',
                                  },
                                  {
                                    Header: <IntlMessages id="description" />,
                                    accessor: 'description',
                                  },
                                  {
                                    Header: <IntlMessages id="cost" />,
                                    accessor: 'cost',
                                    Cell: ( { value } ) => Util.formatCurrency( value ),
                                  },
                                  {
                                    Header: <IntlMessages id="price" />,
                                    accessor: 'price',
                                    Cell: ( { value } ) => Util.formatCurrency( value ),
                                  },
                                  {
                                    Header: <IntlMessages id="lastRoundSales" />,
                                    accessor: 'lastRoundSales',
                                    Cell: ( { value } ) => Util.formatNumber( value ),
                                  },
                                ]}
                                getTrProps={( state, rowInfo ) => ( {
                                  className: input.value && rowInfo.original.id === input.value.id ? 'font-weight-bold pointer' : 'pointer',
                                  onClick: () => input.onChange( {
                                    id: rowInfo.original.id,
                                    cost: rowInfo.original.cost,
                                  } ),
                                } )}
                              />

                              {meta.touched && meta.error
                              && (
                                <FormHelperText className="text-danger">
                                  <IntlMessages id={meta.error} />
                                </FormHelperText>
                              )}
                            </>
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <TextField
                        field="code"
                        label="code"
                        validate={required}
                      />

                      <TextField
                        field="name"
                        label="name"
                        validate={required}
                      />

                      <TextField
                        field="description"
                        label="description"
                        validate={required}
                      />

                      <div className="mb-2">
                        <span>
                          <IntlMessages id="accumulatedCost" />
                          {': '}
                          {Util.formatCurrency(
                            _.get( values, 'product.cost', 0 ) + ( 12 * _.get( values, 'service.cost', 0 ) ),
                          )}
                        </span>
                      </div>

                      <div className="d-flex align-items-center">
                        <Select
                          field="from"
                          label="from"
                          translateOptions={false}
                          options={rounds}
                          validate={required}
                        />
                        {values.from ? (
                          <span
                            className="ml-2"
                          >
                            {this.getRoundDate( values.from, 'startDate' )}
                          </span>
                        ) : ''}
                      </div>

                      <div className="d-flex align-items-center">
                        <Select
                          field="to"
                          label="to"
                          translateOptions={false}
                          options={rounds}
                          validate={required}
                        />
                        {values.to
                          ? <span className="ml-2">{this.getRoundEndDate( values.from, values.to )}</span> : ''}
                      </div>

                      <div className="text-right mt-2">
                        <Button
                          variant="contained"
                          color="primary"
                          className="jr-btn jr-btn-lg"
                          type="submit"
                          disabled={corruptedData}
                        >
                          <i className="fa fa-save" />
                          <IntlMessages id="saveBtn" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </CardBox>
              </div>

              <ConfirmationModal
                open={deactivateBundleModal}
                translateValues={{ name: this.entityToEdit ? this.entityToEdit.name : '' }}
                title={this.entityToEdit && this.entityToEdit.active ? 'deactivateBundle' : ''}
                confirmBtnText="yes"
                onSubmit={this.deactivateBundle}
                onClose={this.toggleDeactivateBundleModal}
              />

              <HistorySalesModal
                data={this.entityToShowSales}
                open={historySalesModal}
                onClose={() => this.toggleHistorySalesModal()}
              />
            </form>
          );
        }}
      />
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( BundleManagement );
