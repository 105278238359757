import React from 'react';
import moment from 'moment'

const Footer = () => {
    return (
      <footer className="app-footer py-50 px-10">
        <span className="d-inline-block">LiveMax &copy; {moment().format('YYYY')} v1.04</span>
      </footer>
    );
  }
;

export default Footer;
