import api from '../config/Api';

const endpoint = 'v1/session';
const endpoint2 = 'v2/session';

export default {

  getWorkGroupsBySession( sessionId, params ) {
    return api.get( `${endpoint}/${sessionId}/workgroup/statistics`, params );
  },

  getWorkGroup( sessionId, workGroupId ) {
    return api.get( `${endpoint}/${sessionId}/workgroup/${workGroupId}` );
  },

  getWorkGroupInfo( sessionId, workGroupId ) {
    return api.get( `${endpoint2}/${sessionId}/workgroup/${workGroupId}/info` );
  },

  saveWorkGroup( sessionId, data ) {
    return api.post( `${endpoint}/${sessionId}/workgroup`, data );
  },

  updateWorkGroup( sessionId, workGroupId, data ) {
    return api.put( `${endpoint}/${sessionId}/workgroup/${workGroupId}`, data );
  },

  updateWorkGroupSettings( sessionId, workGroupId, data ) {
    return api.put( `${endpoint2}/${sessionId}/workgroup/${workGroupId}/setting`, data );
  },
};
