import React from 'react';
import {Link} from 'react-router-dom';
import IntlMessages from 'utils/IntlMessages';

const IconCardCRM = ( { icon, title, link, onClick } ) => (
  <Link to={link} className="icon-card-link" onClick={onClick}>
    <div className={`jr-card jr-card-full mb-0 m-auto icon-card cbg-crm borderless`}>
      <div className="jr-fillchart cbg-crm mb-4 border">
        <div className="vertical-crm">
          <div className="jr-fillchart align-items-center justify-content-center vertical-crm-text">CRM</div>
          <div className="jr-fillchart-content align-items-center justify-content-center ml-2">
          <i className={`fa fa-${icon} fa-4x text-crm`}/>
          <h2 className="mb-0 mt-2 text-crm" ><IntlMessages id={title}/></h2>
        </div>
        </div>
      </div>
    </div>
  </Link>
);

export default IconCardCRM;
