import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import _ from 'lodash';
import moment from 'moment';

import IntlMessages from 'utils/IntlMessages';
import { TextField, DatePicker, Buttons } from 'components/custom/FormElements';
import { required, composeValidators, email } from 'config/InputErrors';
import PurchaseLicenseService from 'services/PurchaseLicense';
import SettingsActions from 'store/reducers/Settings';

const EditForm = ( { open, onClose, onConfirm, toggleLoading, toggleAlert, entityId } ) => {
  function submitForm( formData, form ) {
    const dataToSend = {};

    toggleLoading( true );
    _.map( _.keys( form.getState().modified ), ( key ) => {
      if ( formData[key] && ( key === 'startDate' ) ) {
        dataToSend[key] = moment( Number( formData[key] ) ).format( 'YYYY-MM-DD' );
      } else dataToSend[key] = formData[key];
    } );

    PurchaseLicenseService.savePurchaseLicense( entityId, dataToSend ).then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) return toggleAlert( response.errors );

      toggleAlert( 'dataSaved', 'info' );
      onConfirm();
    } );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      classes={{ paperScrollPaper: 'overflow-visible' }}
    >
      <Form
        onSubmit={submitForm}
        render={( { handleSubmit, form } ) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle className="bg-dark text-white">
              <IntlMessages id="newPurchaseLicense" />
            </DialogTitle>
            <DialogContent className="p-3 overflow-visible">
              <div className="row">
                <div className="col-md-6">
                  <TextField field="invoice" label="invoice" validate={required} />
                  <TextField field="email" label="email" validate={composeValidators( required, email )} />
                  <TextField field="buyer" label="buyer" validate={required} />
                </div>
                <div className="col-md-6">
                  <TextField field="quantity" label="quantity" type="number" validate={required} />
                  <DatePicker field="startDate" label="startDate" validate={required} />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Buttons onClickCancel={onClose} />
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps,
)( EditForm );
