import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import _ from 'lodash';
import { Badge, Col, Row } from 'reactstrap';

import ConfirmationModal from 'components/custom/ConfirmationModal';
import BreadcrumbComp from 'components/custom_v2/Breadcrumb';
import { Switch } from 'components/custom/FormElements';
import SettingsActions from 'store/reducers/Settings';
import IntlMessages from 'utils/IntlMessages';
import CommunicationService from 'services/Communication';
import Help from 'routes/App/components/User/Help';
import HelpSnackBar from 'routes/App/components/User/HelpSnackBar';

class Dashboard extends React.Component {
  state = {
    data: [],
    showActiveCampaigns: 1,
    confirmSaveModal: false,
    confirmDeactivateModal: false,
  };

  componentDidMount() {
    const { toggleAlert, user } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.activeSession = user.activeSession;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }

    this.getData();
  }

  getData = () => {
    const { user, toggleLoading, toggleAlert } = this.props;
    const { showActiveCampaigns } = this.state;

    toggleLoading( true );

    CommunicationService.getOneToOneCampaigns( user.activeSession.id, {
      workgroup: this.license.workGroup.id,
      'filters[active]': showActiveCampaigns ? 1 : 0,
    } )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) return toggleAlert( response.errors );
        this.setState( { data: response.data.data } );
      } );
  };

  toggleConfirmDeactivateModal = () => {
    const { confirmDeactivateModal } = this.state;
    this.setState( { confirmDeactivateModal: !confirmDeactivateModal } );
  };

  toggleConfirmSaveModal = () => {
    const { confirmSaveModal } = this.state;
    this.setState( { confirmSaveModal: !confirmSaveModal } );
  };

  changeStatus = () => {
    const { user, toggleLoading, toggleAlert } = this.props;

    toggleLoading( true );
    CommunicationService.changeStatusCampaign(
      user.activeSession.id,
      // eslint-disable-next-line max-len
      this.campaignToDeactivate, { workgroup: this.license.workGroup.id, active: !this.campaignActive },
    )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) return toggleAlert( response.errors );
        this.getData();
      } );
    this.toggleConfirmSaveModal();
  }

  render() {
    const { showActiveCampaigns, data, confirmDeactivateModal, confirmSaveModal } = this.state;
    const { match } = this.props;

    return (
      <div>
        <Row className="mb-2 align-items-center">
          <Col>
            <BreadcrumbComp links={[
              {
                url: '/',
                name: 'decisionDashboard',
              },
              { name: '1to1Campaigns' },
            ]}
            />
          </Col>
          <Col md="auto">
            <Help
              title="help.1to1.title"
              content="help.1to1.content"
              multimedia="help.1to1.multimedia"
            />
            <Switch
              label="ShowActiveCampaign"
              checked={showActiveCampaigns}
              onChange={checked => this.setState( { showActiveCampaigns: checked }, this.getData )}
              marginContainer={false}
            />
            <Link to={`${match.url}/new/campaign`} className="text-decoration-none">
              <Button
                color="primary"
                variant="contained"
                className="jr-btn jr-btn-lg"
              >
                <i className="zmdi zmdi-plus" />
                <IntlMessages id="newCampaign" />
              </Button>
            </Link>
          </Col>
        </Row>

        <table className="table table-striped custom-table-v2 mb-0">
          <thead>
            <tr>
              <th><IntlMessages id="campaign" /></th>
              <th><IntlMessages id="endDate" /></th>
              <th><IntlMessages id="status" /></th>
              <th><IntlMessages id="activateCampaign" /></th>

              <th style={{ width: 100 }} />
            </tr>
          </thead>
          <tbody>
            {_.map( data, campaign => (
              <tr key={campaign.id}>
                <td>
                  <h5 className="text-uppercase font-weight-bold my-0">
                    {campaign.name}
                  </h5>
                </td>
                <td>
                  {campaign.endDate ? campaign.endDate.largeName : ''}
                </td>
                <td>
                  {campaign.active
                && <Badge color="product-active">ACTIVE</Badge>}
                  {!campaign.active
                && <Badge color="product-ready">INACTIVE</Badge>}
                </td>
                <td>
                  <Switch
                    checked={campaign.active}
                    marginContainer={false}
                    disabled={!campaign.active}
                    onChange={( checked ) => {
                      this.campaignToDeactivate = campaign.id;
                      this.campaignActive = campaign.active;
                      if ( !checked ) this.toggleConfirmDeactivateModal();
                      this.setState( { confirmSaveModal: true } );
                    }}
                  />
                </td>
                <td>
                  <Link to={`/sessions/general/one-to-one/campaign/${campaign.id}`}>
                    <i className="fa fa-eye mr-3" style={{ fontSize: 20 }} />
                  </Link>
                  <Link to={`/sessions/general/one-to-one/campaign/${campaign.id}/clone`}>
                    <i className="fa fa-clone" style={{ fontSize: 20 }} />
                  </Link>
                </td>
              </tr>
            ) )}
          </tbody>
        </table>
        <HelpSnackBar message="help.1to1.snackbar" />

        <ConfirmationModal
          open={confirmSaveModal}
          title="confirmSaveProductProduction"
          confirmBtnText="yesModalBtn"
          onSubmit={this.changeStatus}
          onClose={this.toggleConfirmSaveModal}
        />
        <ConfirmationModal
          open={confirmDeactivateModal}
          title="confirmDeactivateProduct"
          confirmBtnText="ok"
          showCancel
          closeOnClickOutside={false}
          onSubmit={() => {
            this.toggleConfirmDeactivateModal();
          }}
          onClose={() => {
            this.toggleConfirmDeactivateModal();
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( Dashboard );
