import api from '../config/Api';

const endpoint = 'v1/attribute-cost-service';

export default {

  getAttributeCostService( attributeId, params ) {
    return api.get( `${endpoint}/${attributeId}`, params );
  },

  updateAttributeCostService( id, data ) {
    return api.put( `${endpoint}/${id}`, data );
  },
};
