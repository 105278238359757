import _ from 'lodash';

function loadState() {
  try {
    const serializedState = localStorage.getItem( 'mk21' );
    if ( serializedState === null ) {
      return undefined;
    }
    return JSON.parse( serializedState );
  } catch ( err ) {
    return undefined;
  }
}

function saveState( state ) {
  try {
    const stateCloned = _.cloneDeep( state );
    delete stateCloned.settings.alert;
    delete stateCloned.settings.isLoading;
    const stateToPersist = { user: stateCloned.user, settings: stateCloned.settings };
    const serializedState = JSON.stringify( stateToPersist );
    localStorage.setItem( 'mk21', serializedState );
  } catch ( err ) {
    // show error
  }
}

export { loadState, saveState };
