import api from '../config/Api';

const endpoint = 'v2';

export default {
  getPosts( sessionId, data ) {
    return api.get( `${endpoint}/session/${sessionId}/publication`, data );
  },

  savePost( sessionId, data ) {
    return api.post( `${endpoint}/session/${sessionId}/publication`, data );
  },
};
