import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import _ from 'lodash';
import async from 'async';

import IntlMessages from 'utils/IntlMessages';
import CardBox from 'components/theme/CardBox';
import Table from 'components/custom/Table/Custom';
import SettingsActions from 'store/reducers/Settings';
import MarketReportService from 'services/MarketReport';
import ContainerHeader from 'components/theme/ContainerHeader';
import ConfirmationModal from 'components/custom/ConfirmationModal';
import Util from 'utils/Util';

class MarketReport extends React.Component {
  state = {
    purchasedReports: [],
    availableReports: [],
    confirmPurchaseModal: false,
  };

  componentDidMount() {
    const { toggleAlert, user } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }
    this.activeSession = user.activeSession;
    this.getData();
  }

  getData = () => {
    const { toggleAlert, toggleLoading } = this.props;
    toggleLoading( true );

    async.parallel( {
      availableReports: this.getAvailableReports,
      purchasedReports: this.getPurchasedReports,
    }, ( error, results ) => {
      toggleLoading( false );
      if ( error ) return toggleAlert( error );
      this.setState( results );
    } );
  };

  getAvailableReports = ( cb ) => {
    const params = { workgroup: this.license.workGroup.id };
    MarketReportService.getAvailableReports( this.activeSession.id, params )
      .then( ( response ) => {
        if ( !response.ok ) return cb( response.errors );
        cb( null, response.data.data );
      } );
  };

  getPurchasedReports = ( cb ) => {
    const params = { workgroup: this.license.workGroup.id };
    MarketReportService.getPurchasedReports( this.activeSession.id, params )
      .then( ( response ) => {
        if ( !response.ok ) return cb( true );
        cb( null, response.data.data );
      } );
  };

  moveToPurchased = ( report ) => {
    const { purchasedReports } = this.state;
    const newReports = [...purchasedReports];
    const purchased = {
      boughtRound: { name: _.get( this.activeSession, 'currentRound.name' ), startDateInMonth: _.get( this.activeSession, 'currentRound.startDateInMonth' ) },
      availableReport: report,
      status: 'PENDING',
    };
    newReports.push( purchased );
    this.setState( { purchasedReports: newReports } );
  };

  moveToAvailable = ( report ) => {
    const { purchasedReports } = this.state;
    const newReports = [...purchasedReports];

    const index = _.findIndex(
      purchasedReports, { availableReport: { id: report.availableReport.id } },
    );
    if ( index !== -1 ) {
      newReports.splice( index, 1 );
      this.setState( { purchasedReports: newReports } );
    }
  };

  toggleConfirmPurchaseModal = () => {
    const { toggleAlert } = this.props;
    const { purchasedReports, confirmPurchaseModal } = this.state;

    const reports = _.chain( purchasedReports ).filter( { status: 'PENDING' } ).map( 'availableReport.id' ).value();
    if ( !reports.length ) return toggleAlert( 'selectAvailableReport', 'danger', true );

    this.setState( { confirmPurchaseModal: !confirmPurchaseModal } );
  };

  submitPurchase = () => {
    const { toggleAlert, toggleLoading } = this.props;
    const { purchasedReports } = this.state;

    const reports = _.chain( purchasedReports ).filter( { status: 'PENDING' } ).map( 'availableReport.id' ).value();
    if ( !reports.length ) return toggleAlert( 'selectAvailableReport', 'danger', true );

    toggleLoading( true );
    const data = {
      workgroup: this.license.workGroup.id,
      availableReports: reports,
    };
    MarketReportService.sendPurchaseReports( this.activeSession.id, data ).then( ( response ) => {
      if ( !response.ok ) {
        toggleLoading( false );
        return toggleAlert( response.errors );
      }

      toggleAlert( 'dataSaved', 'info' );
      this.toggleConfirmPurchaseModal();
      this.getData();
    } );
  };

  getStatus = ( status ) => {
    switch ( status ) {
    case 'PURCHASED':
      return <i className="fa fa-money-bill font-size-20 text-info" />;
    case 'READY':
      return <i className="fa fa-check font-size-20 text-primary" />;
    case 'PENDING':
      return <i className="fa fa-clock font-size-20 text-danger" />;
    default: return '';
    }
  };

  render() {
    const { availableReports, purchasedReports, confirmPurchaseModal } = this.state;

    return (
      <>
        <ContainerHeader
          title="marketReport"
          rightContent={(
            <div>
              <span className="font-weight-bold mr-3">
                <IntlMessages id="cost" />
                {': '}
                {Util.formatCurrency(
                  _.chain( purchasedReports ).filter( { status: 'PENDING' } ).sumBy( 'availableReport.cost' ).value(),
                )}
              </span>
              <Button
                variant="contained"
                color="primary"
                className="jr-btn jr-btn-lg"
                onClick={this.toggleConfirmPurchaseModal}
              >
                <i className="fa fa-save" />
                <IntlMessages id="confirmPurchase" />
              </Button>
            </div>
          )}
        />

        <div className="row">
          <CardBox styleName="col-md-6" heading="availableReports">
            <Table
              data={availableReports}
              pageSize={availableReports.length}
              showPaginationTop={false}
              columns={[
                {
                  Header: <IntlMessages id="report" />,
                  accessor: 'report',
                },
                {
                  Header: <IntlMessages id="name" />,
                  accessor: 'name',
                },
                {
                  Header: <IntlMessages id="cost" />,
                  accessor: 'cost',
                  Cell: ( { value } ) => Util.formatCurrency( value ),
                },
                {
                  Header: <IntlMessages id="deliveryTime" />,
                  accessor: 'deliveryTime',
                  Cell: ( { value } ) => `${value || ''}R`,
                },
                {
                  maxWidth: 70,
                  sortable: false,
                  accessor: 'id',
                  Cell: props => (
                    <Button
                      color="primary"
                      variant="contained"
                      className="jr-btn"
                      onClick={() => this.moveToPurchased( props.original )}
                    >
                      <i className="fa fa-arrow-right" />
                    </Button>
                  ),
                },
              ]}
            />
          </CardBox>

          <CardBox styleName="col-md-6 mt-3 mt-md-0" heading="purchasedReports">
            <Table
              data={purchasedReports}
              pageSize={purchasedReports.length}
              showPaginationTop={false}
              columns={[
                {
                  Header: <IntlMessages id="round" />,
                  accessor: 'boughtRound.name',
                  Cell: ( { value, original } ) => `${value} ${_.get( original, 'boughtRound.startDateInMonth', '' )}`,
                },
                {
                  Header: <IntlMessages id="report" />,
                  accessor: 'availableReport.report',
                },
                {
                  Header: <IntlMessages id="name" />,
                  accessor: 'availableReport.name',
                  Cell: ( { value, original } ) => ( original.status === 'READY'
                    ? (
                      <a
                        rel="noopener noreferrer"
                        className="text-blue"
                        target="_blank"
                        href={original.link}
                      >
                        {value}
                      </a>
                    ) : value ),
                },
                {
                  maxWidth: 70,
                  Header: <IntlMessages id="status" />,
                  accessor: 'status',
                  Cell: ( { value } ) => this.getStatus( value ),
                },
                {
                  maxWidth: 70,
                  sortable: false,
                  accessor: 'id',
                  Cell: props => (
                    props.original.status === 'PENDING'
                      ? (
                        <Button
                          variant="contained"
                          className="jr-btn bg-danger text-white"
                          onClick={() => this.moveToAvailable( props.original )}
                        >
                          <i className="fa fa-times" />
                        </Button>
                      ) : null
                  ),
                },
              ]}
            />
          </CardBox>
        </div>

        <ConfirmationModal
          open={confirmPurchaseModal}
          title="confirmReportPurchase"
          confirmBtnText="yesModalBtn"
          onSubmit={this.submitPurchase}
          onClose={this.toggleConfirmPurchaseModal}
        />
      </>
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( MarketReport );
