import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import CommentBox from './CommentBox';
import DisplayDate from 'components/theme/wall/DisplayDate/index';
import Card from '@material-ui/core/Card';
import { Input } from 'reactstrap';
import Post from 'services/Post';
import IntlMessages from 'utils/IntlMessages';

class PostItem extends Component {
  state = {
    message: '',
    user: {},
    post: {
      id: 0,
      content: '',
      owner: {},
      createdAt: '',
      replies: [],
    },
  };
  _handleKeyPress = e => {
    const { user, message, post } = this.state;
    const { toggleLoading, toggleAlert } = this.props;
    if (e.key === 'Enter') {
      let commentsArray = post.replies;

      toggleLoading(true);
      Post.savePost(user.activeSession.id, {
        reply: post.id,
        content: message,
      }).then(response => {
        if (response.ok) {
          toggleLoading(false);
          const commentData = {
            ...response.data,
            owner: {
              user: {
                id: user.id,
                name: user.name,
              },
            },
            replies: [],
            createdAt: new Date().toString(),
          };

          commentsArray.push(commentData);
          this.setState(previousState => ({
            post: {
              ...previousState.post,
              replies: commentsArray,
            },
            message: '',
          }));
        } else {
          toggleLoading(false);
          toggleAlert(response.errors);
        }
      });
    }
  };

  componentWillMount() {
    this.setState({ post: this.props.postData, user: this.props.user });
  }

  updateCommentValue(evt) {
    this.setState({
      message: evt.target.value,
    });
  }

  render() {
    const { owner, createdAt, replies, content } = this.state.post;
    const date = new Date(parseInt(createdAt));
    return (
      <Card className="jr-card">
        <div className="jr-wall-content">
          <div className="media jr-wall-user-info flex-nowrap align-items-center">
            {owner.user.avatar ? (
              <Avatar
                alt="..."
                className="mr-3 mb-2 size-50"
                src={owner.user.avatar}
              />
            ) : (
              <Avatar alt="..." className="mr-3 mb-2 size-50">
                {owner.user.name.charAt()}
              </Avatar>
            )}
            <div className="media-body">
              <h5 className="jr-wall-user-title">{owner.user.name}</h5>
              <DisplayDate date={date} />
            </div>
          </div>
          <p>{content}</p>
          <p className="jr-fs-sm pointer mr-3 text-grey">
            <i className="zmdi zmdi-comment-more jr-fs-lg mr-2 d-inline-flex align-middle" />
            <span className="d-inline-flex align-middle">
              {replies.length > 0 ? replies.length : null} &nbsp;
              <IntlMessages id="post-comments" />
            </span>
          </p>
        </div>
        <div className="jr-wall-comment-box">
          {replies.map((commentData, index) => (
            <CommentBox key={index} index={index} commentData={commentData} />
          ))}
        </div>
        <div className="jr-wall-comment-box">
          <div className="media mb-2">
            {owner.user.avatar ? (
              <Avatar
                alt="..."
                className="mr-3 size-36"
                src={owner.user.avatar}
              />
            ) : (
              <Avatar alt="..." className="mr-3 size-36">
                {owner.user.name.charAt()}
              </Avatar>
            )}
            <div className="media-body">
              <Input
                type="textarea"
                id="required"
                className="border-0"
                onChange={event => this.updateCommentValue(event)}
                onKeyPress={event => this._handleKeyPress(event)}
                value={this.state.message}
                placeholder="Type Comments"
              />
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

export default PostItem;
