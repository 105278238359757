import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import async from 'async';
import { Row, Col } from 'reactstrap';
import classnames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
// import Button from '@material-ui/core/Button';

import IntlMessages from 'utils/IntlMessages';
import CategoryService from 'services/Category';
import SettingsActions from 'store/reducers/Settings';
import Util from 'utils/Util';
import BreadcrumbComp from 'components/custom_v2/Breadcrumb';
import CategoryAvatar from 'components/custom_v2/CategoryAvatar';
import { TextField } from 'components/custom/FormElements';
import ConfirmationModal from 'components/custom/ConfirmationModal';
import ProductionService from 'services/Production';
import PriceService from 'services/Price';
import {
  composeValidators,
  greaterOrEqualValue,
  required,
} from 'config/InputErrors';
import Help from 'routes/App/components/User/Help';
import HelpSnackBar from 'routes/App/components/User/HelpSnackBar';

class Products extends React.Component {
  state = {
    categories: [],
    confirmSaveModal: false,
  };

  componentDidMount() {
    const { toggleAlert, user } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.activeSession = user.activeSession;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }

    this.getData();
    document.addEventListener( 'keydown', e => this.handleKeyPress( e ), true );
  }

  handleKeyPress = ( event ) => {
    if ( event.key === 'Enter' ) {
      if ( event.target.type === 'button' ) {
        event.target.click();
      } else {
        event.preventDefault();
        this.setState( { confirmSaveModal: true } );
      }
    }
  }

  getData = () => {
    const { toggleAlert, toggleLoading } = this.props;

    toggleLoading( true );

    async.parallel( {
      categories: ( cb ) => {
        CategoryService.getCategories( { 'filters[session]': this.activeSession.id } )
          .then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
      },
      products: ( cb ) => {
        PriceService.getProducts( this.activeSession.id, {
          workgroup: this.license.workGroup.id,
          'filters[active]': 1,
        } ).then( ( response ) => {
          if ( !response.ok ) return cb( response.errors );
          cb( null, response.data.data );
        } );
      },
    }, ( error, results ) => {
      toggleLoading( false );
      if ( error ) toggleAlert( error );

      const categories = _.map( results.categories, category => ( {
        ...category,
        products: _.filter( results.products, { category: { id: category.id } } ),
      } ) );
      this.setState( { categories } );
    } );
  };

  submitForm = ( formData ) => {
    const { toggleAlert, toggleLoading } = this.props;

    const prices = [];
    _.map( formData.categories, ( category ) => {
      _.map( category.products, ( product ) => {
        const data = {
          product: product.id,
          price: product.price,
          tradeSalePrice: product.tradeSalePrice,
        };
        prices.push( data );
      } );
    } );

    toggleLoading( true );
    const dataToSend = {
      workgroup: this.license.workGroup.id,
      prices,
    };
    ProductionService.updateProductionPriceV2( this.activeSession.id, dataToSend )
      .then( ( response ) => {
        if ( !response.ok ) {
          toggleLoading( false );
          return toggleAlert( response.errors );
        }

        toggleAlert( 'dataSaved', 'info' );
        this.getData();
      } );
  };

  toggleConfirmSaveModal = () => {
    const { confirmSaveModal } = this.state;
    this.setState( { confirmSaveModal: !confirmSaveModal } );
  };

  render() {
    const { categories, confirmSaveModal } = this.state;

    return (
      <Form
        initialValues={{ categories }}
        onSubmit={this.submitForm}
        mutators={{ ...arrayMutators }}
        render={( { handleSubmit } ) => (
          <form onSubmit={handleSubmit}>
            <Row className="mb-2 align-items-center">
              <Col>
                <BreadcrumbComp links={[
                  {
                    url: '/',
                    name: 'decisionDashboard',
                  },
                  { name: 'price' },
                ]}
                />
              </Col>
              <Col md={4} className="d-flex justify-content-end align-items-center">
                <Help
                  title="help.price.title"
                  content="help.price.content"
                  multimedia="help.price.multimedia"
                />

              </Col>
            </Row>

            <FieldArray name="categories">
              {data => (
                data.fields.map( ( itemField, itemIndex ) => {
                  const item = data.fields.value[itemIndex];

                  return (
                    <Row key={item.id} className="mb-4">
                      <Col md="auto" className="text-center">
                        <h4 className="text-uppercase font-weight-bold">{item.name}</h4>
                        <CategoryAvatar category={item} />
                      </Col>

                      <Col md>
                        <div className="table-responsive">
                          <table
                            className={classnames( 'table table-striped table-products mb-0 table-header', {
                              'table-products-laptop': item.label === 'CATEGORY_1',
                              'table-products-tablet': item.label === 'CATEGORY_2',
                              'table-products-cell': item.label === 'CATEGORY_3',
                            } )}
                          >
                            <thead>
                              <tr>
                                <th style={{ width: 100 }}><IntlMessages id="model" /></th>
                                <th><IntlMessages id="unitCost" /></th>
                                <th style={{ width: 120 }}><IntlMessages id="price" /></th>
                                <th>
                                  <div className="d-inline-block text-center">
                                    <IntlMessages id="margin" />
                                    <span className="d-block">($)</span>
                                  </div>
                                </th>
                                <th>
                                  <div className="d-inline-block text-center">
                                    <IntlMessages id="margin" />
                                    <span className="d-block">(%)</span>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <FieldArray name={`${itemField}.products`}>
                                {productsData => (
                                  productsData.fields.map( ( productField, productIndex ) => {
                                    const product = categories[itemIndex].products[productIndex];
                                    if ( !product ) return null;
                                    return ( [
                                      <tr key={product.id}>
                                        <td>
                                          {product.name}
                                        </td>
                                        <td>
                                          {Util.formatCurrency( product.unitCost )}
                                        </td>
                                        <td>
                                          <TextField
                                            field={`${productField}.price`}
                                            marginContainer={false}
                                            type="number"
                                            allowZero
                                            inputProps={{ className: 'text-right' }}
                                            validate={composeValidators(
                                              required,
                                              greaterOrEqualValue( 0 ),
                                            )}
                                            translateValues={{ value: 0 }}
                                          />
                                        </td>
                                        <td>
                                          {Util.formatCurrency( _.get( productsData.fields, `value[${productIndex}].price`, 0 ) - product.unitCost )}
                                        </td>
                                        <td>
                                          {Util.formatNumberNoDecimals( ( ( _.get( productsData.fields, `value[${productIndex}].price`, 0 ) - product.unitCost ) / product.unitCost ) * 100 )}
                                        </td>
                                      </tr>,
                                    ] );
                                  } ) )}
                              </FieldArray>
                            </tbody>
                          </table>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="table-responsive">
                          <table
                            className={classnames( 'table table-striped table-products mb-0 table-header', {
                              'table-products-laptop': item.label === 'CATEGORY_1',
                              'table-products-tablet': item.label === 'CATEGORY_2',
                              'table-products-cell': item.label === 'CATEGORY_3',
                            } )}
                          >
                            <thead>
                              <tr>
                                <th style={{ width: 120 }}>
                                  <IntlMessages id="retailerPrice" />
                                </th>
                                <th>
                                  <div className="d-inline-block text-center">
                                    <IntlMessages id="margin" />
                                    <span className="d-block">($)</span>
                                  </div>
                                </th>
                                <th>
                                  <div className="d-inline-block text-center">
                                    <IntlMessages id="margin" />
                                    <span className="d-block">(%)</span>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <FieldArray name={`${itemField}.products`}>
                                {productsData => (
                                  productsData.fields.map( ( productField, productIndex ) => {
                                    const product = categories[itemIndex].products[productIndex];
                                    if ( !product ) return null;
                                    return ( [
                                      <tr key={product.id}>
                                        <td>
                                          <TextField
                                            field={`${productField}.tradeSalePrice`}
                                            marginContainer={false}
                                            type="number"
                                            allowZero
                                            inputProps={{ className: 'text-right' }}
                                            validate={composeValidators(
                                              required,
                                              greaterOrEqualValue( 0 ),
                                            )}
                                            translateValues={{ value: 0 }}
                                          />
                                        </td>
                                        <td>
                                          {Util.formatCurrency( _.get( productsData.fields, `value[${productIndex}].tradeSalePrice`, 0 ) - product.unitCost )}
                                        </td>
                                        <td>
                                          {Util.formatNumberNoDecimals( ( ( _.get( productsData.fields, `value[${productIndex}].tradeSalePrice`, 0 ) - product.unitCost ) / product.unitCost ) * 100 )}
                                        </td>
                                      </tr>,
                                    ] );
                                  } ) )}
                              </FieldArray>
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </Row>
                  );
                } ) )}
            </FieldArray>
            <HelpSnackBar message="help.price.snackbar" />

            <ConfirmationModal
              open={confirmSaveModal}
              title="confirmSaveProductProduction"
              confirmBtnText="yesModalBtn"
              onSubmit={() => {
                this.toggleConfirmSaveModal();
                handleSubmit();
              }}
              onClose={this.toggleConfirmSaveModal}
            />
          </form>
        )}
      />
    );
  }
}

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( Products );
