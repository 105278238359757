import React from 'react';
import { Field } from 'react-final-form';
import { InputLabel, FormControl, FormHelperText, Input } from '@material-ui/core';
import _ from 'lodash';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import IntlMessages from 'utils/IntlMessages';
import SelectWrapper from './selectWrapper';

const SelectField = ( {
                        field, idKey, label, labelKey, onChange,
                        options, defaultOptions, translateOptions = false,
                        containerClass, emptyValue = false, fullWidth = true, marginContainer = true,
                        intl, errorValues, validate, ...rest
                      } ) => {

  return (
    <Field name={field} validate={validate}>
      {( { input, meta } ) => {
        const defaultValue = input.value ? {
          value: input.value[idKey || 'id'],
          label: input.value[labelKey || 'name']
        } : '';

        return (
          <FormControl
            className={classNames( containerClass, { 'mb-4': marginContainer } )}
            fullWidth={fullWidth}
            error={meta.touched && !!meta.error}
          >
            {label && (
              <InputLabel
                className={classNames( { 'MuiInputLabel-shrink': !!defaultValue } )}>
                <IntlMessages id={label}/>
              </InputLabel>
            )}
            <Input
              inputComponent={SelectWrapper}
              inputProps={{
                ...rest,
                className: { 'pb-0': input.value ? rest.isMulti ? !!input.value.length : false : !!input.value },
                placeholder: rest.placeholder || '',
                loadOptions: ( value, callback ) => {
                  rest.loadOptions( value, ( options ) => {
                    callback( _.map( options, option => ( {
                      value: option[idKey || 'id'],
                      label: translateOptions ? option[labelKey || 'name'] ? intl.formatMessage( { id: option[labelKey || 'name'] } )
                        : option[labelKey || 'name']
                        : option[labelKey || 'name'],
                    } ) ) );
                  } );
                },
                error: meta.touched && !!meta.error,
                defaultValue: defaultValue,
                onChange: ( val ) => {
                  if (val !== input.value) {
                    input.onChange( val ? rest.isMulti ? _.map( val, 'value' ) : val.value : null );
                    if (onChange) onChange( rest.isMulti ? _.map( val, 'value' ) : val.value ? val.value : null );
                  }
                },
              }}
            />
            {meta.touched && meta.error
            &&
            <FormHelperText><IntlMessages id={meta.error} values={errorValues}/></FormHelperText>
            }
          </FormControl>
        );
      }}
    </Field>
  );
};

export default injectIntl( SelectField );
