import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { Button } from '@material-ui/core';

import { composeValidators, equalTo, required } from 'config/InputErrors';
import TextField from 'components/custom/FormElements/TextField';
import IntlMessages from 'utils/IntlMessages';
import SettingsActions from 'store/reducers/Settings';
import UserService from 'services/User';

class ChangePassword extends React.Component {
  submitForm = ( data ) => {
    const { toggleLoading, toggleAlert } = this.props;

    toggleLoading( true );

    UserService.changePassword( {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    } )
      .then( ( response ) => {
        toggleLoading( false );
        if ( !response.ok ) return toggleAlert( response.errors );
        toggleAlert( 'passwordChanged', 'info' );
      } );
  };

  render() {
    return (
      <div className="col-xl-7 col-lg-8 col-md-12 col-sm-12 col-12 mx-auto">
        <div className="app-login-header">
          <h1><IntlMessages id="changePassword" /></h1>
        </div>
        <div className="app-login-main-content d-flex align-items-center justify-content-center p-4">
          <Form
            onSubmit={this.submitForm}
            render={( { handleSubmit } ) => (
              <form onSubmit={handleSubmit} noValidate>
                <TextField
                  field="oldPassword"
                  type="password"
                  label="currentPassword"
                  validate={required}
                />
                <TextField
                  field="newPassword"
                  type="password"
                  label="newPassword"
                  validate={required}
                />
                <TextField
                  field="confirmNewPassword"
                  type="password"
                  label="confirmNewPassword"
                  validate={composeValidators( required, equalTo( 'newPassword' ) )}
                  translateValues={{ label: <IntlMessages id="newPassword" /> }}
                />

                <div className="text-right">
                  <Button
                    variant="contained"
                    color="primary"
                    className="jr-btn jr-btn-lg"
                    type="submit"
                  >
                    <i className="fa fa-key" />
                    <IntlMessages id="changePassword" />
                  </Button>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = ( {
  toggleLoading: SettingsActions.toggleLoading,
  toggleAlert: SettingsActions.toggleAlert,
} );

export default connect( null, mapDispatchToProps )( ChangePassword );
