import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import CardBox from 'components/theme/CardBox';
import Table from 'components/custom/Table/AsyncTable';
import FilterForm from 'components/custom/FilterForm';
import { TextField } from 'components/custom/FormElements';
import SettingsActions from 'store/reducers/Settings';
import UserLicenseService from 'services/UserLicense';
import IntlMessages from 'utils/IntlMessages';
import SearchParams from 'utils/SearchParams';
import PurchaseLicenseEditForm from '../../../../../../components/Admin/University/PurchaseLicenseEditForm';

class UserLicenses extends React.Component {
  state = {
    data: [],
    editModal: false,
  };

  getData = ( params ) => {
    const { toggleLoading, entityId } = this.props;
    toggleLoading( true );

    this.params = _.cloneDeep( params );
    const formattedParams = SearchParams.getSearchParams( this.search, params );

    UserLicenseService.getUserLicensesByEntity( entityId, formattedParams )
      .then( ( response ) => {
        toggleLoading( false );

        if ( response.ok ) {
          this.setState( {
            data: response.data.data || [],
            pages: Math.ceil( response.data.total / params.limit ),
            total: response.data.total,
          } );
        }
      } );
  };

  submitForm = ( data ) => {
    this.search = data;
    this.getData( this.params );
  };

  toggleEditModal = ( cb ) => {
    this.setState( previousState => ( { editModal: !previousState.editModal } ), cb );
  };

  render() {
    const { editModal, ...rest } = this.state;
    const { entityId } = this.props;

    return (
      <div>
        <CardBox cardStyle="p-0">
          <FilterForm
            onSubmit={this.submitForm}
            onClear={() => {
              this.search = {};
              this.getData( this.params );
            }}
          >
            <div className="col-lg-3">
              <TextField
                field="user"
                label="user"
              />
            </div>
          </FilterForm>

          <Table
            onFetchData={this.getData}
            {...rest}
            columns={[
              {
                maxWidth: 150,
                Header: <IntlMessages id="id" />,
                accessor: 'id',
                Cell: props => ( props.value ? `${props.value.slice( 0, 8 ).toUpperCase()}` : null ),
              },
              {
                Header: <IntlMessages id="session" />,
                accessor: 'session.name',
                id: 'session',
              },
              {
                Header: <IntlMessages id="user" />,
                accessor: 'user.email',
                id: 'user',
              },
              {
                Header: <IntlMessages id="userEnabled" />,
                accessor: 'user.enabled',
                Cell: props => ( props.value ? <i className="fa fa-check" />
                  : <i className="fa fa-times" /> ),
              },
              {
                Header: <IntlMessages id="enabled" />,
                accessor: 'enabled',
                maxWidth: 120,
                Cell: props => ( props.value ? <i className="fa fa-check" />
                  : <i className="fa fa-times" /> ),
              },
            ]}
          />
        </CardBox>

        <PurchaseLicenseEditForm
          open={editModal}
          onClose={() => this.toggleEditModal()}
          onConfirm={() => {
            this.toggleEditModal();
            this.getData( this.params );
          }}
          entityId={entityId}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps,
)( UserLicenses );
