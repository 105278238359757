import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';

import DisplayDate from 'components/theme/wall/DisplayDate/index';

class CommentBox extends Component {
  state = {
    commentData: {
      id: 0,
      owner: {},
      createdAt: '',
      content: '',
    },
  };

  componentWillMount() {
    this.setState({ commentData: this.props.commentData });
  }

  render() {
    const { owner, createdAt, content } = this.state.commentData;
    const date = new Date(parseInt(createdAt));

    return (
      <div className="media flex-nowrap jr-wall-user-info mb-3">
        {owner.user.avatar ? (
          <Avatar
            alt="..."
            className="mr-3 jr-size-40"
            src={owner.user.avatar}
          />
        ) : (
          <Avatar alt="..." className="mr-3 jr-size-40">
            {owner.user.name.charAt()}
          </Avatar>
        )}
        <div className="media-body">
          <h5 className="jr-wall-user-title">{owner.user.name}</h5>
          <DisplayDate date={date} />
          <p className="mt-2">{content}</p>
        </div>
      </div>
    );
  }
}

export default CommentBox;
