import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';

import ContainerHeader from 'components/theme/ContainerHeader';
import CardBox from 'components/theme/CardBox';
import { TextField, Buttons } from 'components/custom/FormElements';
import SettingsActions from 'store/reducers/Settings';
import IntlMessages from 'utils/IntlMessages';
import { composeValidators, required, equalTo } from 'config/InputErrors';

class ChangePassword extends React.Component {
  entityId = null;

  componentDidMount() {
    const { match } = this.props;
    this.entityId = match.params.id;
  }

  submitForm = () => {
    const { history } = this.props;
    history.push( '/users' );
  };

  render() {
    const { match } = this.props;

    return (
      <div className="app-wrapper">
        <ContainerHeader match={match} title="changePassword" />

        <CardBox>
          <Form
            onSubmit={this.submitForm}
            render={( { handleSubmit, form } ) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <TextField field="plainPassword" label="password" type="password" validate={required} />
                    <TextField
                      field="passwordConfirmation"
                      label="passwordConfirmation"
                      type="password"
                      translateValues={{ label: <IntlMessages id="password" /> }}
                      validate={composeValidators( required, equalTo( 'plainPassword' ) )}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 text-right">
                    <Buttons cancelTo="/users" />
                  </div>
                </div>
              </form>
            )}
          />
        </CardBox>
      </div>
    );
  }
}

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  null,
  mapDispatchToProps,
)( ChangePassword );
