import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, DialogContent } from '@material-ui/core';
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import IntlMessages from 'utils/IntlMessages';
import AxisTick from 'components/custom/Charts/AxisTick';
import Table from 'components/custom/Table/Custom';
import _ from 'lodash';
import Util from '../../../../../utils/Util';

const HistorySalesModal = ( { data, open, onClose } ) => (
  <Dialog
    open={open}
    onClose={onClose}
    fullWidth
    maxWidth="md"
  >
    <DialogTitle className="bg-dark text-white">
      <IntlMessages id="historySales" />
    </DialogTitle>
    <DialogContent className="p-3">
      <ResponsiveContainer width="100%" height={200}>
        <ComposedChart
          data={data ? data.bundleRound : []}
          margin={{ left: -10, right: 20 }}
        >
          <XAxis
            interval={0}
            dataKey="round.name"
            height={60}
            tick={<AxisTick />}
          />
          <YAxis />
          <Tooltip />
          <Legend verticalAlign="top" />
          <CartesianGrid strokeDasharray="3 3" />
          <Line type="monotone" name="Unit Sales" dataKey="unitSales" stroke="#3367d6" />
          <Line type="monotone" name="Total Cost" dataKey="totalCost" stroke="#59AA2B" />
          <Line type="monotone" name="Price" dataKey="price" stroke="#ffc658" />
          <Line type="monotone" name="Total Margin" dataKey="totalMargin" stroke="#ff749d" />
        </ComposedChart>
      </ResponsiveContainer>

      <Table
        data={data ? data.bundleRound : []}
        rowsText=""
        pageSizeOptions={[10, 15]}
        columns={[
          {
            Header: <IntlMessages id="round" />,
            accessor: 'round.name',
            Cell: ( { value, original } ) => `${value} ${_.get( original, 'round.startDateInMonth', '' )}`,
          },
          {
            Header: <IntlMessages id="unitSales" />,
            accessor: 'unitSales',
            Cell: ( { value } ) => Util.formatNumber( value ),
          },
          {
            Header: <IntlMessages id="totalCost" />,
            accessor: 'totalCost',
            Cell: ( { value } ) => Util.formatCurrency( value ),
          },
          {
            Header: <IntlMessages id="price" />,
            accessor: 'price',
            Cell: ( { value } ) => Util.formatCurrency( value ),
          },
          {
            Header: <IntlMessages id="totalMargin" />,
            accessor: 'totalMargin',
            Cell: ( { value } ) => Util.formatCurrency( value ),
          },
        ]}
      />
    </DialogContent>
    <DialogActions>
      <Button
        variant="contained"
        className="jr-btn jr-btn-lg bg-blue-grey text-white"
        onClick={onClose}
      >
        <i className="fa fa-times" />
        <IntlMessages id="close" />
      </Button>
    </DialogActions>
  </Dialog>
);

export default HistorySalesModal;
