import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Error404 from 'components/theme/Error404';
import Dashboard from './Dashboard';
import Detail from './Detail';
import NewUniversity from './New';

const University = ( { match } ) => (
  <Switch>
    <Route path={`${match.url}/new`} component={NewUniversity} />
    <Route path={`${match.url}/detail/:id`} component={Detail} />
    <Route path={match.url} component={Dashboard} exact />
    <Route component={Error404} />
  </Switch>
);

export default University;
