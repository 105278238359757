import React from 'react';
import _ from 'lodash';
import IntlMessages from 'utils/IntlMessages';
import classnames from 'classnames';

const CardBox = ( { heading, children, styleName, childrenStyle, header, headerBg, onClick, icon, footer } ) => {

  return (
    <div className={`${styleName}`} onClick={onClick}>
      <div className={`jr-card p-0`}>
        {header || <div className={classnames( 'p-2', headerBg ? headerBg : '', {
          'bg-green lighten-4': !headerBg,
        } )}>
          <h3 className="card-heading m-0 text-uppercase" style={{color: '#fff'}}>{heading ? _.isString( heading ) ?
            <IntlMessages id={heading}/> : heading : ''}
            <i style={{ float: 'right' }} className={`fa fa-${icon} fa-1x `}/>
          </h3>
        </div>
        }

        <div className={`jr-card-body ${childrenStyle}`}>
          {children}
        </div>

        {!!footer &&
        (<div className={`jr-card-footer p-3`}>
          {footer}
        </div>)}
      </div>
    </div>
  );
};

export default CardBox;

CardBox.defaultProps = {
  cardStyle: 'px-4 py-3 mb-0',
  headingStyle: 'mb-3',
  childrenStyle: 'p-3',
  styleName: 'col-12 p-0' //mt-3
};


/*
className="bg-primary text-white"
 */
